import { MenuItem } from '@material-ui/core';
import React, { FocusEvent, Fragment, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Control, FieldErrors, FieldValues, ManualFieldError, ValidateResult } from 'react-hook-form/dist/types';
import { connect } from 'react-redux';
import { ObjectSchema } from 'yup';
import Address from '../../../components/Address/Address';
import KeyboardDatePickerController from '../../../components/DatePicker/KeyboardDatePickerController';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../components/Select/SelectController';
import FncAddDocuments from '../../../components/UploadFile/FncAddDocuments';
import { tErrorKey, tFormKey } from '../../../helpers/translate';
import {
  CodeNamePair,
  DocumentationItem,
  IdCodeNamePair,
  docTypesArray,
  gendersArray,
} from '../../../redux/common/definitions';
import { GenericMetadata } from '../../../redux/metadata/definitions';
import { selectChannels } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import { UserData } from '../../../redux/user/definitions';
import RoleCode from '../../../types/Roles/roleCode';
import { validateIdentificationCard } from '../../../validations/validatorUser';
import validate from '../../../validations/validators';

interface PersonalDataFormProps<T extends FieldValues> {
  channels: CodeNamePair[];
  createMode?: boolean;
  readOnly?: boolean;
  schema: ObjectSchema<T>;
  profileData: UserData;
  errors: FieldErrors<T>;
  documentation?: DocumentationItem[];
  roles: IdCodeNamePair[];
  genericMetadata: GenericMetadata;
  control: Control<T>;
  isInterviewer?: boolean;
  actions?: string[];
  roleEditable?: boolean;
  defaultLang: string;
  setValue: (name: string, value: any, shouldValidate?: boolean) => void;
  uploadDocument?: (data: File, documentation_id: number) => void;
  downloadDocument?: (file_id: number, name: string) => void;
  removeDocument?: (file_id: number) => void;
  getDocument?: (file_id: number, name: string) => void;
  addDoc?: (document: File, description: string, genericId?: number) => void;
  loadDocumentation?: () => void;
  setError: ReturnType<typeof useForm>['setError'];
  clearError: (names?: string | string[] | undefined) => void;
  triggerValidation: (payload?: string | string[], shouldRender?: boolean) => Promise<boolean>;
  watch: ReturnType<typeof useForm>['watch'];
}

const PersonalDataForm = <T extends FieldValues>({
  channels,
  createMode,
  profileData,
  errors,
  genericMetadata,
  schema,
  readOnly,
  control,
  isInterviewer = false,
  documentation,
  roles,
  actions,
  roleEditable,
  defaultLang,
  addDoc,
  watch,
  uploadDocument,
  downloadDocument,
  removeDocument,
  getDocument,
  triggerValidation,
  setValue,
  setError,
  clearError,
}: PersonalDataFormProps<T>): ReactElement => {
  const { languages } = genericMetadata;

  const {
    document_type,
    document_number,
    gender,
    name,
    surname,
    surname2,
    account_number,
    phone,
    phone2,
    email2,
    meet_link,
    whats_app,
    role,
    role_id,
    birthdate,
    country_origin,
    nationality,
    scholarship_academy_id,
    language,
    role_code,
    center_cost_channel_code,
    user_age,
  } = profileData;

  const roleCode = new RoleCode(role_code);

  const checkAccountNumber = (number: string) => {
    !validate.accountNumber(number, 'España')
      ? setError('account_number', 'invalidAccountNumber', tErrorKey('IBAN incorrecto'))
      : clearError('account_number');
  };

  return (
    <FormContainer title={tFormKey('Datos personales')}>
      <SelectController
        label={tFormKey('Tipo de Documento')}
        disabled={readOnly}
        name="document_type"
        schema={schema}
        size="50"
        control={control}
        errors={errors}
        defaultValue={document_type}
        onBlur={(_e: FocusEvent<HTMLInputElement>) =>
          validateIdentificationCard(
            _e.target.value,
            watch('document_number') || '',
            'document_number',
            setError,
            clearError,
          )
        }
      >
        {docTypesArray.map(docType => (
          <MenuItem key={docType} value={docType}>
            {docType}
          </MenuItem>
        ))}
      </SelectController>

      <TextInputController
        schema={schema}
        label={tFormKey('Nº Documento')}
        size="50"
        name="document_number"
        errors={errors}
        disabled={readOnly}
        control={control}
        defaultValue={document_number}
        onBlur={(_e: FocusEvent<HTMLInputElement>) =>
          validateIdentificationCard(watch('document_type'), _e.target.value, 'document_number', setError, clearError)
        }
      />
      {roleEditable && !readOnly && role_code !== 'dir_fnc' ? (
        <SelectController
          control={control}
          size="50"
          name="role_id"
          defaultValue={role_id || roles[0]?.id}
          errors={errors}
          label={tFormKey('Rol')}
          disabled={readOnly}
          schema={schema}
        >
          {roles.map(role => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </SelectController>
      ) : (
        <TextInputController
          name="role"
          label={tFormKey('Rol')}
          size="50"
          errors={errors}
          schema={schema}
          control={control}
          defaultValue={role}
          disabled
        />
      )}

      <TextInputController
        name="name"
        label={tFormKey('Nombre')}
        size="50"
        errors={errors}
        schema={schema}
        control={control}
        defaultValue={name}
        disabled={readOnly}
      />
      <TextInputController
        control={control}
        size="50"
        label={tFormKey('Primer Apellido')}
        name="surname"
        errors={errors}
        defaultValue={surname}
        schema={schema}
        disabled={readOnly}
      />
      <TextInputController
        control={control}
        size="50"
        label={tFormKey('Segundo Apellido')}
        name="surname2"
        errors={errors}
        defaultValue={surname2}
        schema={schema}
        disabled={readOnly}
      />
      <SelectController
        control={control}
        size="50"
        name="gender"
        defaultValue={gender}
        errors={errors}
        label={tFormKey('Sexo')}
        disabled={readOnly}
        schema={schema}
      >
        {gendersArray.map(gender => (
          <MenuItem key={gender} value={gender}>
            {tFormKey(gender)}
          </MenuItem>
        ))}
      </SelectController>
      {roleCode.isStudent() && (
        <TextInputController
          size="20"
          label={tFormKey('Edad')}
          name="created_at"
          errors={errors}
          control={control}
          disabled
          schema={schema}
          defaultValue={user_age}
        />
      )}
      <TextInputController
        control={control}
        size={roleCode.isStudent() ? '20' : '50'}
        label={tFormKey('Teléfono')}
        name="phone"
        errors={errors}
        defaultValue={phone}
        disabled={readOnly}
        schema={schema}
        onBlur={() => {
          triggerValidation('phone');
        }}
      />
      <Address
        errors={errors}
        genericMetadata={genericMetadata}
        setValue={setValue}
        disabled={readOnly}
        addressData={profileData}
        addressSchema={schema}
        triggerValidation={triggerValidation}
        control={control}
        setError={
          setError as (
            name: string | ManualFieldError<any>[],
            type?: Record<string, ValidateResult> | string,
            message?: string | undefined,
          ) => void
        }
      />

      {documentation && actions && (
        <FncAddDocuments
          documents={documentation}
          actions={actions}
          title={tFormKey('Documentos')}
          uploadAnonymous={uploadDocument}
          download={downloadDocument}
          remove={removeDocument}
          getFile={getDocument}
          required
          addDoc={addDoc}
          multidocument
        />
      )}

      <Fragment>
        {!createMode ? (
          <Fragment>
            {isInterviewer ? (
              <FormContainer title={tFormKey('Datos de Contacto')}>
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Número Whatsapp')}
                  name="whats_app"
                  schema={schema}
                  errors={errors}
                  defaultValue={whats_app}
                  disabled={readOnly}
                />
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Enlace Meet')}
                  name="meet_link"
                  schema={schema}
                  errors={errors}
                  defaultValue={meet_link}
                  disabled={readOnly}
                />
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Teléfono de Contacto')}
                  name="phone2"
                  schema={schema}
                  errors={errors}
                  defaultValue={phone2}
                  disabled={readOnly}
                  onBlur={() => triggerValidation('phone2')}
                />
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Email de Contacto')}
                  name="email2"
                  schema={schema}
                  errors={errors}
                  defaultValue={email2}
                  disabled={readOnly}
                />
              </FormContainer>
            ) : (
              <React.Fragment>
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Número de Cuenta')}
                  name="account_number"
                  schema={schema}
                  errors={errors}
                  defaultValue={account_number}
                  disabled={readOnly}
                  onBlur={(_e: FocusEvent<HTMLInputElement>) => checkAccountNumber(_e.target.value)}
                />
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Teléfono 2')}
                  name="phone2"
                  schema={schema}
                  errors={errors}
                  defaultValue={phone2}
                  disabled={readOnly}
                  onBlur={() => triggerValidation('phone2')}
                />
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Email alternativo')}
                  name="email2"
                  schema={schema}
                  errors={errors}
                  defaultValue={email2}
                  disabled={readOnly}
                />
                {profileData &&
                  (profileData.role_code === 'dir_bns' ||
                    profileData.role_code === 'dir_dev' ||
                    profileData.role_code === 'dir_ocu' ||
                    profileData.role_code === 'dir_fnc') && (
                    <SelectController
                      size="50"
                      control={control}
                      errors={errors}
                      schema={schema}
                      defaultValue={center_cost_channel_code}
                      name="center_cost_channel_code"
                      label={tFormKey('Canal')}
                      disabled={readOnly}
                    >
                      {channels.map((type: CodeNamePair) => (
                        <MenuItem key={type.code} value={type.code}>
                          {type.code}
                        </MenuItem>
                      ))}
                    </SelectController>
                  )}
              </React.Fragment>
            )}
            {roleCode.isStudent() && (
              <React.Fragment>
                <KeyboardDatePickerController
                  label={tFormKey('Fecha de nacimiento')}
                  name="birthdate"
                  control={control}
                  schema={schema}
                  defaultValue={birthdate}
                  errors={errors}
                  size="50"
                  disabled={readOnly}
                />
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('País de origen')}
                  name="country_origin"
                  errors={errors}
                  defaultValue={country_origin}
                  schema={schema}
                  disabled={readOnly}
                />
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Nacionalidad')}
                  name="nationality"
                  errors={errors}
                  defaultValue={nationality}
                  schema={schema}
                  disabled={readOnly}
                />

                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Instituto')}
                  name="scholarship_academy_id"
                  visible={false}
                  errors={errors}
                  defaultValue={scholarship_academy_id}
                  schema={schema}
                />
              </React.Fragment>
            )}
          </Fragment>
        ) : null}
        <SelectController
          label={tFormKey('Idioma')}
          name="language"
          schema={schema}
          size="50"
          control={control}
          errors={errors}
          defaultValue={language || defaultLang}
        >
          {languages.map(lang => (
            <MenuItem key={lang.code} value={lang.code}>
              {lang.name}
            </MenuItem>
          ))}
        </SelectController>
      </Fragment>
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  channels: selectChannels(state),
});

export default connect(mapStateToProps)(PersonalDataForm);

import { useState } from 'react';
import { ScholarshipPaymentListItem } from '../../../../redux/scholarship/payments/definitions';

type useScholarshipPaymentsAdjustmentType = {
  showAdjustmentModal: boolean;
  scholarshipId: number;
  entityId: number | null;
  studentName: string;
  closeAdjustmentModal: () => void;
  setAdjustmentInfo: (scholarshipPayment: ScholarshipPaymentListItem) => void;
};

const useScholarshipPaymentsAdjustment = (): useScholarshipPaymentsAdjustmentType => {
  const [showAdjustmentModal, setShowAdjustmentModal] = useState(false);
  const [scholarshipId, setScholarshipId] = useState(0);
  const [studentName, setStudentName] = useState('');
  const [entityId, setEntityId] = useState<number | null>(null);

  const setAdjustmentInfo = (scholarshipPayment: ScholarshipPaymentListItem) => {
    setScholarshipId(scholarshipPayment.scholarship_id);
    setEntityId(scholarshipPayment.entity_id);
    setStudentName(scholarshipPayment.student_name);
    setShowAdjustmentModal(true);
  };

  const closeAdjustmentModal = () => {
    setShowAdjustmentModal(false);
  };

  return {
    showAdjustmentModal,
    scholarshipId,
    entityId,
    studentName,
    closeAdjustmentModal,
    setAdjustmentInfo,
  };
};

export default useScholarshipPaymentsAdjustment;

import { ButtonProps } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Title from '../../../../components/Title/Title';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import { tScholarshipKey } from '../../../../helpers/translate';
import { AppState } from '../../../../redux/root-reducer';
import {
  addScholarshipJustificationsAction,
  deleteScholarshipJustificationsAction,
} from '../../../../redux/scholarship/justifications/actions';
import { ScholarshipJustificationsState } from '../../../../redux/scholarship/justifications/definitions';
import {
  selectScholarshipJustifications,
  selectScholarshipJustificationsError,
  selectScholarshipJustificationsLoading,
  selectScholarshipJustificationsTotalAmount,
} from '../../../../redux/scholarship/justifications/selectors';
import { selectTotalConfirmedScholarship } from '../../../../redux/scholarship/selectors';
import { pendingJustificationTransitionAction } from '../../../../redux/scholarship/transitions/actions';
import { RemainderAmount } from '../../../../redux/scholarship/transitions/definitions';
import JustificationsTable from '../../Justifications/JustificationsTable/JustificationsTable';
import ScholarshipRemainderModal from '../../Modals/ScholarshipRemainder/ScholarshipRemainderModal';
import EconomicJustificationTotals from './EconomicJustificationTotals';
import styles from './economicJustificationForm.module.scss';
import { ScholarshipId, ScholarshipStateCode } from '../../../../redux/scholarship/definitions';
import { Justification } from '../../../../redux/scholarship/justifications/Justification';

interface EconomicJustificationFormProps {
  loading: boolean;
  errorMessage: string;
  scholarshipId: number;
  scholarshipStateCode: ScholarshipStateCode;
  totalPaid: number;
  totalConfirmed: number;
  totalJustified: number;
  readOnly: boolean;
  justifications: Justification[];
  buttonActionSendJustifications: ButtonProps;
  createJustification: (data: Justification) => void;
  removeJustification: (data: Justification) => void;
  sendPendingJustification: (
    scholarship_id: ScholarshipId,
    remainder_amount: RemainderAmount,
    remainder_remarks: string | null,
  ) => void;
}

const EconomicJustificationForm: React.FC<EconomicJustificationFormProps> = ({
  loading,
  errorMessage,
  scholarshipId,
  scholarshipStateCode,
  totalPaid,
  totalConfirmed,
  totalJustified,
  readOnly,
  justifications,
  buttonActionSendJustifications,
  createJustification,
  removeJustification,
  sendPendingJustification,
}) => {
  const [sendJustifications, setSendJustifications] = useState(false);

  const calcRemainingAmount = (): number => {
    const remaining = Math.round((totalPaid - totalJustified) * 100) / 100;
    if (remaining > 0) {
      return remaining;
    }
    return 0;
  };

  buttonActionSendJustifications.disabled = [
    ScholarshipStateCode.ABANDONMENT,
    ScholarshipStateCode.RENUNCIATION,
    ScholarshipStateCode.COMPLETED,
  ].includes(scholarshipStateCode);
  useButtonAction(buttonActionSendJustifications, () => setSendJustifications(true));

  const renderRemainderModal = (): JSX.Element | undefined => {
    if (!sendJustifications) {
      return undefined;
    }
    const remainingAmount = calcRemainingAmount();
    if (remainingAmount === 0) {
      sendPendingJustification(scholarshipId, remainingAmount, null);
      return undefined;
    }
    return (
      <ScholarshipRemainderModal
        show={sendJustifications}
        remainingAmount={remainingAmount}
        onClose={() => setSendJustifications(false)}
        onConfirm={(remainingAmount: number, remainderRemarks: string) =>
          sendPendingJustification(scholarshipId, remainingAmount, remainderRemarks)
        }
      />
    );
  };

  return (
    <div className={styles.root}>
      <Title>{tScholarshipKey('justification_menu_title')}</Title>
      <JustificationsTable
        scholarshipId={scholarshipId}
        justifications={justifications}
        disabled={readOnly}
        loading={loading}
        error_message={errorMessage}
        addJustification={createJustification}
        deleteJustification={removeJustification}
        setJustification={() => undefined}
      />
      <EconomicJustificationTotals
        totalConfirmed={totalConfirmed}
        totalPaid={totalPaid}
        totalJustified={totalJustified}
        remainingAmount={calcRemainingAmount()}
      />
      {renderRemainderModal()}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  justifications: selectScholarshipJustifications(state),
  loading: selectScholarshipJustificationsLoading(state),
  totalConfirmed: selectTotalConfirmedScholarship(state),
  totalJustified: selectScholarshipJustificationsTotalAmount(state),
  errorMessage: selectScholarshipJustificationsError(state),
});

const mapDispatchToProps = (dispatch: ScholarshipJustificationsState) => ({
  createJustification: (data: Justification): void => dispatch(addScholarshipJustificationsAction(data)),
  removeJustification: (data: Justification): void => dispatch(deleteScholarshipJustificationsAction(data)),
  sendPendingJustification: (
    scholarship_id: ScholarshipId,
    remainder_amount: RemainderAmount,
    remainder_remarks: string | null,
  ): void => dispatch(pendingJustificationTransitionAction(scholarship_id, remainder_amount, remainder_remarks)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EconomicJustificationForm);

import { useState } from 'react';
import { ScholarshipPaymentListItem } from '../../../../redux/scholarship/payments/definitions';
import { PaymentId } from '../../../../redux/scholarship/payments/Payment';

type UseScholarshipChangeDateType = {
  showDateModal: boolean;
  paymentId: PaymentId | null;
  paymentDate: string | null;
  closeDateModal: () => void;
  setChangeDateInfo: (scholarshipPayment: ScholarshipPaymentListItem) => void;
};

const useScholarshipChangeDate = (): UseScholarshipChangeDateType => {
  const [showDateModal, setShowDateModal] = useState(false);
  const [paymentId, setPaymentId] = useState(null as PaymentId | null);
  const [paymentDate, setPaymentDate] = useState(null as string | null);

  const setChangeDateInfo = (scholarshipPayment: ScholarshipPaymentListItem) => {
    setPaymentId(scholarshipPayment.payment_id);
    setPaymentDate(scholarshipPayment.payment_date);
    setShowDateModal(true);
  };

  const closeDateModal = () => {
    setShowDateModal(false);
  };

  return {
    showDateModal,
    paymentId,
    paymentDate,
    closeDateModal,
    setChangeDateInfo,
  };
};

export default useScholarshipChangeDate;

import { PaymentSettingData } from '../redux/paymentSettings/PaymentSetting';
import { apiBaseUrl, apiCall, FetchOptions } from './api';

const baseURL = apiBaseUrl;

export const updatePaymentSettingFromApi = async (
  paymentSettingData: PaymentSettingData,
): Promise<PaymentSettingData> => {
  const init: FetchOptions = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(paymentSettingData),
  };

  const { data } = await apiCall<{ data: PaymentSettingData }>(
    `${baseURL}/payment-settings/${paymentSettingData.agreement_number}`,
    init,
  );
  return data;
};

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { downloadFileFromApi } from '../../../../../api/file';
import { DATE_FORMAT } from '../../../../../constants/date';
import { formatNumber } from '../../../../../helpers/formatNumber';
import { getDocs } from '../../../../../helpers/getDocs';
import { tKey } from '../../../../../helpers/translate';
import { deleteScholarshipPaymentAdjustmentAction } from '../../../../../redux/scholarship/paymentAdjustments/actions';
import { ScholarshipPaymentAdjustmentItem } from '../../../../../redux/scholarship/paymentAdjustments/PaymentAdjustment';
import { Delete, CloudDownload } from '@material-ui/icons';
import PaymentStatus from '../../../../../types/Payments/PaymentStatus';
import { UUID } from '../../../../../types/Shared/standard';
import styles from './scholarshipAdjustmentsTable.module.scss';

interface ScholarshipAdjustmentRowProps {
  editable: boolean;
  scholarshipPaymentJustification: ScholarshipPaymentAdjustmentItem;
  deleteScholarshipPaymentAdjustment: (scholarshipPaymentId: UUID) => void;
}

const ScholarshipAdjustmentRow: React.FC<ScholarshipAdjustmentRowProps> = ({
  editable,
  scholarshipPaymentJustification,
  deleteScholarshipPaymentAdjustment,
}) => {
  const paymentStatus = new PaymentStatus(scholarshipPaymentJustification.status);

  const iconColor = (disabled: boolean) => {
    const opacity = disabled ? 0.2 : 0.54;
    return { color: `rgba(0, 0, 0, ${opacity})` };
  };

  const downloadFile = async (file_id: number, filename: string) => {
    const blobFile = await downloadFileFromApi(file_id);
    getDocs(blobFile, true, filename);
  };

  const renderActions = (): JSX.Element | undefined => {
    if (!editable) {
      return undefined;
    }
    return (
      <td className={`${styles.justificationCellRow}  ${styles.actionCell}`}>
        <Tooltip title="Eliminar">
          <IconButton aria-label="Eliminar">
            <Delete
              style={iconColor(!paymentStatus.isPending())}
              onClick={() =>
                paymentStatus.isPending()
                  ? deleteScholarshipPaymentAdjustment(scholarshipPaymentJustification.id)
                  : undefined
              }
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Descargar">
          <IconButton aria-label="Descargar">
            <CloudDownload
              style={iconColor(!scholarshipPaymentJustification.file_id)}
              onClick={() =>
                scholarshipPaymentJustification.file_id
                  ? downloadFile(scholarshipPaymentJustification.file_id, scholarshipPaymentJustification.filename)
                  : undefined
              }
            />
          </IconButton>
        </Tooltip>
      </td>
    );
  };

  const { payment_amount, payment_date, remarks, status } = scholarshipPaymentJustification;

  const paymentDate = moment(payment_date).format(DATE_FORMAT);
  return (
    <tr className={styles.tableRow}>
      <td className={`${styles.cellRow}  ${styles.amountCell}`}>{`${formatNumber({
        value: payment_amount,
      })} €`}</td>
      <td className={`${styles.cellRow}  ${styles.dateCell}`}>{paymentDate}</td>
      <td className={`${styles.cellRow}  ${styles.remarksCell}`}>
        <Tooltip title={remarks}>
          <span>{remarks}</span>
        </Tooltip>
      </td>
      <td className={`${styles.cellRow}  ${styles.statusCell}`}>{tKey(PaymentStatus.translationName(status))}</td>
      {renderActions()}
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  deleteScholarshipPaymentAdjustment: (scholarshipPaymentId: UUID): void =>
    dispatch(deleteScholarshipPaymentAdjustmentAction(scholarshipPaymentId)),
});

export default connect(undefined, mapDispatchToProps)(ScholarshipAdjustmentRow);

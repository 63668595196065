import { AppState } from '../../root-reducer';
import { ScholarshipPaymentDataState, ScholarshipPaymentList } from './definitions';

const selectScholarshipPaymentsReducer = (state: AppState): ScholarshipPaymentDataState =>
  state.scholarshipPaymentsReducer;

export const selectScholarshipPaymentLoading = (state: AppState): boolean =>
  selectScholarshipPaymentsReducer(state).loading;

export const selectScholarshipPaymentReload = (state: AppState): boolean =>
  selectScholarshipPaymentsReducer(state).reload;

export const selectEntityScholarshipPayments = (state: AppState): ScholarshipPaymentList =>
  selectScholarshipPaymentsReducer(state).entityScholarshipPayments;

export const selectInstituteScholarshipPayments = (state: AppState): ScholarshipPaymentList =>
  selectScholarshipPaymentsReducer(state).instituteScholarshipPayments;

export const selectSelectedAcademicYear = (state: AppState): string => {
  const academic_year = selectScholarshipPaymentsReducer(state).selectedAcademicYear;
  return academic_year ? academic_year : state.metadataReducer.generic.currentAcademicYear;
};

export const selectSelectedPaymentStatus = (state: AppState): string =>
  selectScholarshipPaymentsReducer(state).selectedPaymentStatus;

export const selectSelectedEntityId = (state: AppState): number | null =>
  selectScholarshipPaymentsReducer(state).selectedEntityId;

import { AppState } from '../../root-reducer';
import { EntityAgreementDataState } from './definitions';
import { EntityAgreementData } from './EntityAgreement';

export const selectEntityAgreement = (state: AppState): EntityAgreementDataState => state.entityAgreementReducer;
export const selectEntityAgreementIsLoading = (state: AppState): boolean => selectEntityAgreement(state).loading;
export const selectEntityAgreementAcademicYears = (state: AppState): string[] =>
  selectEntityAgreement(state).academic_years;
export const selectEntityAgreementData = (state: AppState): EntityAgreementData =>
  selectEntityAgreement(state).agreementData;

import { CodeNamePair } from '../redux/common/definitions';
import {
  AccountingMetadata,
  GenericMetadata,
  ProjectMetadata,
  ResponsibleMetadata,
  ScholarshipInfoMetadata,
  TeamMetadata,
} from '../redux/metadata/definitions';
import { apiBaseUrl, apiCall } from './api';

const baseURL = apiBaseUrl;

export const getGenericMetadata = (): Promise<{ data: GenericMetadata }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: GenericMetadata }>(`${baseURL}/metadata/generic`, init);
};

export const getProjectMetadata = (): Promise<{ data: ProjectMetadata }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: ProjectMetadata }>(`${baseURL}/metadata/project`, init);
};

export const getResponsbiles = (): Promise<ResponsibleMetadata[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<ResponsibleMetadata[]>(`${baseURL}/metadata/responsibles`, init);
};

export const getTeam = (): Promise<TeamMetadata[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<TeamMetadata[]>(`${baseURL}/metadata/team`, init);
};

export const getAccountingMetadataFromApi = (): Promise<{ data: AccountingMetadata }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: AccountingMetadata }>(`${baseURL}/metadata/accounting`, init);
};

export const getScholarshipMetadata = (): Promise<{ data: ScholarshipInfoMetadata }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: ScholarshipInfoMetadata }>(`${baseURL}/metadata/scholarship`, init);
};

export const getScholarshipAcademiesMetadata = (zipCode: string): Promise<{ data: ScholarshipInfoMetadata }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: ScholarshipInfoMetadata }>(`${baseURL}/metadata/scholarship/${zipCode}`, init);
};

export const getCustomFieldSizes = (): Promise<{ data: CodeNamePair[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: CodeNamePair[] }>(`${baseURL}/metadata/custom_field_sizes`, init);
};

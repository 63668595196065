export enum AccountingConsts {
  GET_EXPENSE_ACCOUNT_LIST = 'GET:EXPENSE:ACCOUNT:LIST',
  GET_EXPENSE_ACCOUNT_LIST_OK = 'GET:EXPENSE:ACCOUNT:LIST:OK',
  GET_EXPENSE_ACCOUNT_LIST_KO = 'GET:EXPENSE:ACCOUNT:LIST:KO',

  CREATE_EXPENSE_ACCOUNT = 'CREATE:EXPENSE:ACCOUNT',
  CREATE_EXPENSE_ACCOUNT_OK = 'CREATE:EXPENSE:ACCOUNT:OK',
  CREATE_EXPENSE_ACCOUNT_KO = 'CREATE:EXPENSE:ACCOUNT:KO',

  DELETE_EXPENSE_ACCOUNT = 'DELETE:EXPENSE:ACCOUNT',
  DELETE_EXPENSE_ACCOUNT_OK = 'DELETE:EXPENSE:ACCOUNT:OK',
  DELETE_EXPENSE_ACCOUNT_KO = 'DELETE:EXPENSE:ACCOUNT:KO',

  GET_ONE_EXPENSE_ACCOUNT = 'GET:ONE:EXPENSE:ACCOUNT',
  GET_ONE_EXPENSE_ACCOUNT_OK = 'GET:ONE:EXPENSE:ACCOUNT_OK',
  GET_ONE_EXPENSE_ACCOUNT_KO = 'GET:ONE:EXPENSE:ACCOUNT_KO',

  EDIT_EXPENSE_ACCOUNT = 'EDIT:EXPENSE:ACCOUNT',
  EDIT_EXPENSE_ACCOUNT_OK = 'EDIT:EXPENSE:ACCOUNT:OK',
  EDIT_EXPENSE_ACCOUNT_KO = 'EDIT:EXPENSE:ACCOUNT:KO',

  GET_ACCOUNTING_SETTINGS = 'GET:ACCOUNTING:SETTINGS',
  GET_ACCOUNTING_SETTINGS_OK = 'GET:ACCOUNTING:SETTINGS_OK',
  GET_ACCOUNTING_SETTINGS_KO = 'GET:ACCOUNTING:SETTINGS_KO',

  EDIT_ACCOUNTING_SETTINGS = 'EDITACCOUNTING:SETTINGS',
  EDIT_ACCOUNTING_SETTINGS_OK = 'EDITACCOUNTING:SETTINGS:OK',
  EDIT_ACCOUNTING_SETTINGS_KO = 'EDITACCOUNTING:SETTINGS_KO',

  GET_INVOICE_DATA = 'GET:INVOICE:DATA',
  GET_INVOICE_DATA_OK = 'GET:INVOICE:DATA:OK',
  GET_INVOICE_DATA_KO = 'GET:INVOICE:DATA:KO',

  EDIT_INVOICE_DATA = 'EDIT:INVOICE:DATA',
  EDIT_INVOICE_DATA_OK = 'EDIT:INVOICE:DATA:OK',
  EDIT_INVOICE_DATA_KO = 'EDIT:INVOICE:DATA:KO',

  DELETE_INVOICE_DATA = 'DELETE:INVOICE:DATA',
  DELETE_INVOICE_DATA_OK = 'DELETE:INVOICE:DATA:OK',
  DELETE_INVOICE_DATA_KO = 'DELETE:INVOICE:DATA:KO',

  INVOICE_SET_DATA = 'INVOICE:SET:DATA',
  INVOICE_VALIDATION_ERRORS = 'INVOICE:VALIDATION:ERRORS',

  UPLOAD_INVOICE_DOCUMENT = 'UPLOAD:INVOICE',
  UPLOAD_INVOICE_DOCUMENT_OK = 'UPLOAD:INVOICE:OK',
  UPLOAD_INVOICE_DOCUMENT_KO = 'UPLOAD:INVOICE:KO',

  REMOVE_INVOICE_DOCUMENT = 'REMOVE:INVOICE:DOCUMENT',
  REMOVE_INVOICE_DOCUMENT_OK = 'REMOVE:INVOICE:DOCUMENT:OK',
  REMOVE_INVOICE_DOCUMENT_KO = 'REMOVE:INVOICE:DOCUMENT:KO',

  DOWNLOAD_INVOICE = 'DOWNLOAD:INVOICE',
  DOWNLOAD_INVOICE_OK = 'DOWNLOAD:INVOICE:OK ',
  DOWNLOAD_INVOICE_KO = 'DOWNLOAD:INVOICE:KO',

  CREATE_INVOICE = 'CREATE:INVOICE',
  CREATE_INVOICE_OK = 'CREATE:INVOICE:OK',
  CREATE_INVOICE_KO = 'CREATE:INVOICE:KO',

  GET_INVOICE_DETAIL = 'GET:INVOICE:DETAIL',
  GET_INVOICE_DETAIL_OK = 'GET:INVOICE:DETAIL:OK',
  GET_INVOICE_DETAIL_KO = 'GET:INVOICE:DETAIL:KO',

  GET_INVOICES_DETAIL_LIST = 'GET_INVOICES_DETAIL_LIST',
  GET_INVOICES_DETAIL_LIST_OK = 'GET_INVOICES_DETAIL_LIST_OK',
  GET_INVOICES_DETAIL_LIST_KO = 'GET_INVOICES_DETAIL_LIST_KO',

  CREATE_NEW_INVOICE_DETAIL = 'CREATE_NEW_INVOICE_DETAIL',
  CREATE_NEW_INVOICE_DETAIL_OK = 'CREATE_NEW_INVOICE_DETAIL_OK',
  CREATE_NEW_INVOICE_DETAIL_KO = 'CREATE_NEW_INVOICE_DETAIL_KO',

  EDIT_INVOICE_DETAIL = 'EDIT_INVOICE_DETAIL',
  EDIT_INVOICE_DETAIL_OK = 'EDIT_INVOICE_DETAIL_OK',
  EDIT_INVOICE_DETAIL_KO = 'EDIT_INVOICE_DETAIL_KO',

  DELETE_INVOICE_DETAIL = 'DELETE_INVOICE_DETAIL',
  DELETE_INVOICE_DETAIL_OK = 'DELETE_INVOICE_DETAIL_OK',
  DELETE_INVOICE_DETAIL_KO = 'DELETE_INVOICE_DETAIL_KO',

  RESET_INVOICE_DETAIL = 'RESET_INVOICE_DETAIL',

  INVOICE_DETAIL_SET_DATA = 'INVOICE:DETAIL:SET:DATA',

  CHANGE_STATE = 'CHANGE_STATE',
  CHANGE_STATE_OK = 'CHANGE_STATE_OK',
  CHANGE_STATE_KO = 'CHANGE_STATE_KO',

  MOVE_BACK_INVOICE = 'MOVE_BACK_INVOICE',
  MOVE_BACK_INVOICE_OK = 'MOVE_BACK_INVOICE_OK',
  MOVE_BACK_INVOICE_KO = 'MOVE_BACK_INVOICE_KO',

  SEND_INVOICE = 'SEND:INVOICE',
  SEND_INVOICE_OK = 'SEND:INVOICE:OK',
  SEND_INVOICE_KO = 'SEND:INVOICE:KO',

  ACCEPT_INVOICE = 'ACCEPT:INVOICE',
  ACCEPT_INVOICE_OK = 'ACCEPT:INVOICE:OK',
  ACCEPT_INVOICE_KO = 'ACCEPT:INVOICE:KO',

  CONFIRM_INVOICE = 'CONFIRM:INVOICE',
  CONFIRM_INVOICE_OK = 'CONFIRM:INVOICE:OK',
  CONFIRM_INVOICE_KO = 'CONFIRM:INVOICE:KO',

  TO_PAY_INVOICE = 'TO_PAY:INVOICE',
  TO_PAY_INVOICE_OK = 'TO_PAY:INVOICE:OK',
  TO_PAY_INVOICE_KO = 'TO_PAY:INVOICE:KO',

  TO_PAY_INVOICES = 'TO_PAY:INVOICES',
  TO_PAY_INVOICES_OK = 'TO_PAY:INVOICES:OK',

  AUTORIZE_INVOICE = 'AUTHORIZE:INVOICE',
  AUTORIZE_INVOICE_OK = 'AUTHORIZE:INVOICE:OK',
  AUTORIZE_INVOICE_KO = 'AUTHORIZE:INVOICE:KO',

  PAY_INVOICES = 'ACCOUNTING:PAY:INVOICE',
  PAY_INVOICES_OK = 'ACCOUNTING:PAY:INVOICE:OK',

  OPEN_MODAL = 'OPEN:MODAL',
  CLOSE_MODAL = 'CLOSE:MODAL',

  GET_SCHOLARSHIP_SETTING = 'GET:SCHOLARSHIP:SETTING',
  GET_SCHOLARSHIP_SETTING_OK = 'GET:SCHOLARSHIP:SETTING:OK',
  GET_SCHOLARSHIP_SETTING_KO = 'GET:SCHOLARSHIP:SETTING:KO',

  UPDATE_SCHOLARSHIP_SETTING = 'UPDATE:SCHOLARSHIP:SETTING',
  UPDATE_SCHOLARSHIP_SETTING_OK = 'UPDATE:SCHOLARSHIP:SETTING:OK',
  UPDATE_SCHOLARSHIP_SETTING_KO = 'UPDATE:SCHOLARSHIP:SETTING:KO',

  SCHOLARSHIP_SET_DATA_SETTING = 'SCHOLARSHIP:SET:DATA:SETTING',
  SCHOLARSHIP_SETTING_VALIDATION_ERRORS = 'SCHOLARSHIP:SETTING:VALIDATION:ERRORS',

  GET_PENDING_INVOICES = 'GET:PENDING:INVOICES',
  GET_PENDING_INVOICES_OK = 'GET:PENDING:INVOICES:OK',
  GET_PENDING_INVOICES_KO = 'GET:PENDING:INVOICES:KO',

  OPEN_PAYMENT_MODAL = 'OPEN:PAYMENT:MODAL',
  CLOSE_PAYMENT_MODAL = 'CLOSE:PAYMENT:MODAL',

  REMOVE_PENDING_INVOICE = 'REMOVE:PENDING:INVOICE',
  REMOVE_PENDING_INVOICE_KO = 'REMOVE:PENDING:INVOICE:KO',

  GET_ONE_PENDING_INVOICE = 'GET:ONE:PENDING:INVOICE',
  GET_ONE_PENDING_INVOICE_OK = 'GET:ONE:PENDING:INVOICE:OK',
  GET_ONE_PENDING_INVOICE_KO = 'GET:ONE:PENDING:INVOICE:KO',

  UPDATE_PENDING_INVOICE = 'UPDATE:ONE:PENDING:INVOICE',
  UPDATE_PENDING_INVOICE_KO = 'UPDATE:PENDING:INVOICE:KO',

  SET_ONE_PENDING_INVOICE = 'SET:ONE:PENDING:INVOICE',

  MARK_INVOICE_AS_PAID = 'MARK:INVOICE:AS:PAID',
  MARK_INVOICE_AS_PAID_OK = 'MARK:INVOICE:AS:PAID:OK ',
  MARK_INVOICE_AS_PAID_KO = 'MARK:INVOICE:AS:PAID:KO',

  SET_INS_SCHOLARSHIPS_PAYMENT_DATE_FILTER = 'SET:INS:SCHOLARSHIPS:PAYMENT:DATE:FILTER',

  CLOSE_INVOICES = 'CLOSE:INVOICES',
  CLOSE_INVOICES_OK = 'CLOSE:INVOICES:OK',
  CLOSE_INVOICES_KO = 'CLOSE:INVOICES:KO',

  DOWNLOAD_INVOICE_CONTRACT = 'DOWNLOAD:INVOICE:CONTRACT',
  DOWNLOAD_INVOICE_CONTRACT_OK = 'DOWNLOAD:INVOICE:CONTRACT:OK',
  DOWNLOAD_INVOICE_CONTRACT_KO = 'DOWNLOAD:INVOICE:CONTRACT:KO',
}

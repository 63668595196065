import React from 'react';
import { downloadFileFromApi } from '../../../../api/file';
import FncButton from '../../../../components/FncButton/FncButton';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import SubTitle from '../../../../components/SubTitle/Subtitle';
import { getDocs } from '../../../../helpers/getDocs';
import { tKey, tScholarshipKey } from '../../../../helpers/translate';
import { generateUuid } from '../../../../helpers/uuidGenerator';
import JustificationTableModal from './JustificationTableModal';
import styles from '../Shared/justificationsTable.module.scss';
import JustificationRow from '../Shared/JustificationRow';
import { ScholarshipId } from '../../../../redux/scholarship/payments/Payment';
import { Justification } from '../../../../redux/scholarship/justifications/Justification';

interface JustificationsTableProps {
  scholarshipId: ScholarshipId;
  justifications: Justification[];
  disabled: boolean;
  info_message?: string;
  error_message?: string;
  loading: boolean;
  addJustification: (justification: Justification) => void;
  deleteJustification: (justification: Justification) => void;
  setJustification: (data: Justification) => void;
}

const JustificationsTable: React.FC<JustificationsTableProps> = ({
  scholarshipId,
  justifications,
  disabled = false,
  info_message,
  error_message,
  loading,
  addJustification,
  deleteJustification,
  setJustification,
}) => {
  const justificationModalDefault = {
    view: false,
    title: '',
    children: <></>,
  };
  const [justificationModal, setJustificationModal] = React.useState(justificationModalDefault);

  const closeModal = () => {
    setJustificationModal(justificationModalDefault);
  };

  const downloadFile = async (file_id: number, filename: string) => {
    const blobFile = await downloadFileFromApi(file_id);
    getDocs(blobFile, true, filename);
  };

  const renderModal = (justification: Justification, onSave: (justification: Justification) => void): JSX.Element => {
    return (
      <JustificationTableModal
        closeModal={() => closeModal()}
        justification={justification}
        onSave={onSave}
        onDownloadFile={downloadFile}
        setJustification={setJustification}
      />
    );
  };

  const addOne = () => {
    const newJustification = {
      id: generateUuid(),
      scholarship_id: scholarshipId,
      description: null,
      type: 'Matrícula',
      amount: 0,
    } as Justification;
    setJustificationModal({
      view: true,
      title: tScholarshipKey('add_new_justification'),
      children: renderModal(newJustification, addJustification),
    });
  };

  const renderAddNew = () => {
    return (
      <div className={styles.addNewJustification}>
        <FncButton color="primary" disabled={disabled} onClick={() => addOne()}>
          {tScholarshipKey('add_new_justification')}
        </FncButton>
      </div>
    );
  };

  const renderFamilyIncomes = () => {
    if (justifications && justifications.length > 0) {
      return justifications.map((justification: Justification) => {
        return (
          <JustificationRow
            key={justification.id}
            justification={justification}
            disabled={disabled}
            onRemove={deleteJustification}
            onDownload={downloadFile}
          />
        );
      });
    }
    return (
      <tr className={styles.justificationTableRow}>
        <td colSpan={4} className={`${styles.justificationCellRow} ${styles.justificationEmptyCellRow}`}>
          {loading ? tKey('Cargando...') : tKey('No hay justificantes')}
        </td>
      </tr>
    );
  };

  const renderMessages = (): JSX.Element => {
    if (error_message) {
      return <span className={styles.justificationError}>{error_message}</span>;
    }
    return <span className={styles.justificationInfo}>{info_message}</span>;
  };

  const renderMessageArea = (): JSX.Element | undefined => {
    if (error_message || info_message) {
      return <div className={styles.tableMessageArea}>{renderMessages()}</div>;
    }
    return undefined;
  };

  return (
    <>
      <div className={styles.justificationTableContainer}>
        <div className={styles.tableTitle}>
          <SubTitle>{tScholarshipKey('justification_title')}</SubTitle>
          {renderAddNew()}
        </div>
        <table className={styles.justificationTable}>
          <thead className={styles.justificationTableHeader}>
            <tr>
              <th className={styles.justificationCellHeader}>{tScholarshipKey('justification_type')}</th>
              <th className={styles.justificationCellHeader}>{tScholarshipKey('justification_description')}</th>
              <th className={styles.justificationCellHeader}>{tScholarshipKey('justification_amount')}</th>
              <th className={styles.justificationCellHeader}></th>
            </tr>
          </thead>
          <tbody>{renderFamilyIncomes()}</tbody>
        </table>
        {renderMessageArea()}
      </div>
      <TransitionModal
        view={justificationModal.view}
        handleClose={() => {
          closeModal();
        }}
        title={justificationModal.title}
        bodyModal={justificationModal.children}
      />
    </>
  );
};

export default JustificationsTable;

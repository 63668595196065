import React from 'react';
import { connect } from 'react-redux';
import { tFormKey, tKey, tProjectKey } from '../../../helpers/translate';
import { AppState } from '../../../redux/root-reducer';
import { MenuItem } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import FncButton from '../../../components/FncButton/FncButton';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../components/Select/SelectController';
import { EntityAgreementData } from '../../../redux/entity/agreement/EntityAgreement';
import { Areas, Delegations, GenericMetadata, Projects } from '../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../redux/metadata/selectors';
import { updatePaymentSettingAction } from '../../../redux/paymentSettings/actions';
import { PaymentSettingData } from '../../../redux/paymentSettings/PaymentSetting';
import AgreementState from '../../../types/Agreements/AgreementState';
import { PaymentSettingsSchema } from '../../../validations/paymentSettingsForm';
import styles from './accountingData.module.scss';
import { selectUserCan } from '../../../redux/permissions/selectors';

interface AccountingDataPaymentSettingsProps {
  entityAgreementData: EntityAgreementData;
  genericMetadata: GenericMetadata;
  disabled: boolean;
  allowScholarshipPayments: boolean;
  updatePaymentSetting: (paymentSettingData: PaymentSettingData) => void;
}

const AccountingDataPaymentSettings: React.FC<AccountingDataPaymentSettingsProps> = ({
  entityAgreementData,
  genericMetadata,
  disabled,
  allowScholarshipPayments,
  updatePaymentSetting,
}) => {
  const { channels, delegations, areas, projects } = genericMetadata;
  const {
    center_cost_channel_code,
    center_cost_delegation_code,
    center_cost_area_code,
    center_cost_project_code,
    agreement_number,
    concept,
    description,
    agreement_state,
  } = entityAgreementData;

  const agreementState = new AgreementState(agreement_state);

  const isDisabled = disabled || agreementState.isConfirm() || !allowScholarshipPayments;

  const { handleSubmit, watch, setValue, clearError, errors, control } = useForm<EntityAgreementData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: PaymentSettingsSchema,
  });

  const handleSelectChange = (target: any, child: string[]) => {
    if (target.name) {
      child.forEach(input => {
        setValue(input, null);
      });
    }
  };

  const onSubmit = (data: EntityAgreementData) => {
    const {
      agreement_number,
      center_cost_area_code,
      center_cost_channel_code,
      center_cost_delegation_code,
      center_cost_project_code,
      concept,
      description,
    } = data;
    const paymentSetting = {
      agreement_number,
      center_cost_area_code,
      center_cost_channel_code,
      center_cost_delegation_code,
      center_cost_project_code,
      concept,
      description,
    } as PaymentSettingData;
    clearError();
    updatePaymentSetting(paymentSetting);
  };

  const renderCenterCostChannel = (): JSX.Element => {
    return (
      <SelectController
        name={'center_cost_channel_code'}
        control={control}
        errors={errors}
        schema={PaymentSettingsSchema}
        disabled={isDisabled}
        label={tProjectKey('Canal')}
        defaultValue={center_cost_channel_code}
        onClick={e =>
          handleSelectChange(e.target, [
            'center_cost_delegation_code',
            'center_cost_area_code',
            'center_cost_project_code',
          ])
        }
      >
        {channels.map(element => (
          <MenuItem key={'ch'.concat(element.code || '')} value={element.code}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderCenterCostDelegation = (): JSX.Element => {
    const delegationsFiltered = delegations.filter(
      (delegation: Delegations) =>
        delegation.channel_code === watch('center_cost_channel_code', center_cost_channel_code) ||
        delegation.channel_code === null,
    );

    return (
      <SelectController
        name={'center_cost_delegation_code'}
        control={control}
        errors={errors}
        schema={PaymentSettingsSchema}
        disabled={isDisabled}
        label={tProjectKey('Delegación')}
        defaultValue={center_cost_delegation_code}
        onClick={e => handleSelectChange(e.target, ['center_cost_area_code', 'center_cost_project_code'])}
      >
        {delegationsFiltered.map(element => (
          <MenuItem key={'de'.concat(element.code || '')} value={element.code}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderCenterCostAreas = (): JSX.Element => {
    const areasFiltered = areas.filter(
      (area: Areas) =>
        area.delegation_code === watch('center_cost_delegation_code', center_cost_delegation_code) ||
        area.delegation_code === null,
    );

    return (
      <SelectController
        name={'center_cost_area_code'}
        control={control}
        errors={errors}
        schema={PaymentSettingsSchema}
        disabled={isDisabled}
        label={tProjectKey('Departamento')}
        defaultValue={center_cost_area_code}
        onClick={e => handleSelectChange(e.target, ['center_cost_project_code'])}
      >
        {areasFiltered.map(element => (
          <MenuItem key={'ar'.concat(element.code || '')} value={element.code}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderCenterCostProject = (): JSX.Element => {
    const projectsFiltered = projects.filter(
      (project: Projects) =>
        project.area_code === watch('center_cost_area_code', center_cost_area_code) || project.area_code === null,
    );

    return (
      <SelectController
        name={'center_cost_project_code'}
        control={control}
        errors={errors}
        schema={PaymentSettingsSchema}
        disabled={isDisabled}
        label={tProjectKey('Proyecto')}
        defaultValue={center_cost_project_code}
      >
        {projectsFiltered.map(element => (
          <MenuItem key={'pr'.concat(element.code || '')} value={element.code}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderAgreementNumber = (): JSX.Element => {
    return (
      <TextInputController
        schema={PaymentSettingsSchema}
        label={tProjectKey('Número de convenio')}
        name={'agreement_number'}
        errors={errors}
        disabled
        control={control}
        defaultValue={agreement_number}
      />
    );
  };

  const renderConcept = (): JSX.Element => {
    return (
      <TextInputController
        schema={PaymentSettingsSchema}
        label={tProjectKey('Concepto')}
        name={'concept'}
        errors={errors}
        control={control}
        disabled={isDisabled}
        defaultValue={concept}
      />
    );
  };

  const renderDescription = (): JSX.Element => {
    return (
      <TextInputController
        className={styles.paymentDescription}
        schema={PaymentSettingsSchema}
        label={tProjectKey('Descripcion')}
        name={'description'}
        errors={errors}
        control={control}
        defaultValue={description}
        rows="4"
        rowsMax="10"
        multiline
        disabled={isDisabled}
        fullWidth
      />
    );
  };

  const renderSubmitButton = (): JSX.Element | undefined => {
    if (isDisabled) {
      return undefined;
    }
    return (
      <div className={`${styles.submitPaymentSettings} ${styles.buttonActionRight}`}>
        <FncButton type="submit" color="primary">
          {tFormKey('Actualizar')}
        </FncButton>
      </div>
    );
  };

  return (
    <FormContainer title={tKey('Configuración de pago')}>
      <form className={`${styles.paymentSettingForm} ${styles.sectionContainer}`} onSubmit={handleSubmit(onSubmit)}>
        {renderCenterCostChannel()}
        {renderCenterCostDelegation()}
        {renderCenterCostAreas()}
        {renderCenterCostProject()}
        {renderAgreementNumber()}
        {renderConcept()}
        {renderDescription()}
        {renderSubmitButton()}
      </form>
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  genericMetadata: selectGenericMetadata(state),
  allowScholarshipPayments: selectUserCan(state)('allow_scholarship_payments'),
});

const mapDispatchToProps = (dispatch: any) => ({
  updatePaymentSetting: (paymentSettingData: PaymentSettingData) =>
    dispatch(updatePaymentSettingAction(paymentSettingData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDataPaymentSettings);

class AgreementState {
  static INIT = 'Init';
  static WAITING = 'Waiting';
  static CONFIRM = 'Confirm';
  static SIGN_ERROR = 'Error';
  static SIGN_DECLINED = 'Rechazado';
  static SIGN_CANCELED = 'Cancelado';
  static SIGN_EXPIRED = 'Caducado';

  constructor(private state: string) {}

  public isInit(): boolean {
    return this.state === AgreementState.INIT;
  }

  public isWaiting(): boolean {
    return this.state === AgreementState.WAITING;
  }

  public isConfirm(): boolean {
    return this.state === AgreementState.CONFIRM;
  }

  public isCanceled(): boolean {
    return this.state === AgreementState.SIGN_CANCELED;
  }

  public value(): string {
    return this.state;
  }

  static toArray(): string[] {
    return [
      AgreementState.INIT,
      AgreementState.WAITING,
      AgreementState.CONFIRM,
      AgreementState.SIGN_CANCELED,
      AgreementState.SIGN_ERROR,
      AgreementState.SIGN_DECLINED,
      AgreementState.SIGN_EXPIRED,
    ];
  }
}

export default AgreementState;

import { DocumentationItem } from '../../common/definitions';

export type EntityAgreementData = {
  id: number;
  entity_id: number;
  academic_year: string;
  agreement_number: string;
  agreement_state: string;
  signature_id: string | null;
  advance_payment: number | null;
  advance_payment_date: string | null;
  advance_payment_remainder: number | null;
  admin_management_amount: number;
  admin_management_date: string | null;
  center_cost_channel_code: string;
  center_cost_delegation_code: string;
  center_cost_area_code: string;
  center_cost_project_code: string | null;
  concept: string | null;
  description: string | null;
  documentation: DocumentationItem[];
  total_paid_amount: number;
  total_confirm_amount: number;
  total_justified_amount: number;
  total_rejected_and_dropped: number;
  course_remainder: number;
  remainder: number;
  has_payments: boolean;
};

export const EntityAgreementInitialState = {
  loading: false as boolean,
  error: null as string | null,
  academic_years: [] as string[],
  agreementData: {} as EntityAgreementData,
};

import { useState } from 'react';
import { ScholarshipPaymentListItem } from '../../../../redux/scholarship/payments/definitions';

type UseScholarshipPaymentInfoType = {
  paymentsToPay: ScholarshipPaymentListItem[];
  showPaymentModal: boolean;
  openPaymentModal: () => void;
  closePaymentModal: () => void;
  setPaymentsToPay: (scholarshipPayment: ScholarshipPaymentListItem[]) => void;
};

const useScholarshipPaymentInfo = (): UseScholarshipPaymentInfoType => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentsToPay, setPaymentsToPay] = useState([] as ScholarshipPaymentListItem[]);

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };

  const openPaymentModal = () => {
    setShowPaymentModal(true);
  };

  return {
    paymentsToPay,
    showPaymentModal,
    openPaymentModal,
    closePaymentModal,
    setPaymentsToPay,
  };
};

export default useScholarshipPaymentInfo;

import { IconButton } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import { FileCopy as DuplicateIcon, Edit } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import React, { Fragment } from 'react';
import { tKey } from '../../../helpers/translate';
import TransitionModal from '../../Modal/TransitionModal';
import styles from './actionButtonsTab.module.scss';

interface ActionButtonProps {
  title?: string;
  children: JSX.Element;
}

const ButtonRow: React.FC<ActionButtonProps> = ({ title, children }) => <Tooltip title={title}>{children}</Tooltip>;

interface ActionBUttonsTabProps {
  row?: any;
  rows?: any[];
  disabled?: boolean;
  handleEditClick?: (row: any) => void;
  handleDeleteClick?: (row: any) => void;
  handleDuplicateClick?: (row: any) => void;
}

const ActionButtonsTab: React.FC<ActionBUttonsTabProps> = ({
  row,
  disabled,
  handleEditClick,
  handleDeleteClick,
  handleDuplicateClick,
}) => {
  const [deleteModal, setDeleteModal] = React.useState({
    view: false,
    children: <div style={{ padding: '20px', color: '#666666' }}>{tKey('¿Seguro que desea eliminar este campo?')}</div>,
    title: tKey('¡Atención!'),
  });

  const [paymentAdjustmentModal, setPaymentAdjustmentModal] = React.useState({
    view: false,
    children: <Fragment />,
    title: tKey('Ajustes de pago'),
    startIcon: <EuroSymbolIcon />,
  });

  return (
    <TableCell>
      <div className={styles.container}>
        <Fragment>
          {!!handleEditClick && row && !disabled && (
            <ButtonRow title={tKey('Editar')}>
              <span>
                <IconButton
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    handleEditClick(row);
                  }}
                >
                  <Edit style={{ color: '#666666' }}></Edit>
                </IconButton>
              </span>
            </ButtonRow>
          )}
          {!!handleDuplicateClick && row && !disabled && (
            <ButtonRow title={tKey('Duplicar')}>
              <span>
                <IconButton
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    handleDuplicateClick(row);
                  }}
                >
                  <DuplicateIcon style={{ color: '#666666' }}></DuplicateIcon>
                </IconButton>
              </span>
            </ButtonRow>
          )}
          {!!handleDeleteClick && row && !disabled && (
            <ButtonRow title={tKey('Eliminar')}>
              <span>
                <IconButton
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setDeleteModal(prevState => ({ ...prevState, view: true }));
                  }}
                >
                  <DeleteIcon style={{ color: '#666666' }}></DeleteIcon>
                </IconButton>
              </span>
            </ButtonRow>
          )}
        </Fragment>
      </div>

      <TransitionModal
        view={deleteModal.view}
        handleClose={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          setDeleteModal(prevState => ({ ...prevState, view: false }));
        }}
        title={deleteModal.title}
        buttonOk={tKey('Eliminar')}
        buttonKo={tKey('Cancelar')}
        bodyModal=" "
        handleYes={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          // deleteFolloupDocumentApi(followUp.id ? followUp.id : 0, deleteFileModal.id);
          // prepareToDelete(e);
          handleDeleteClick && handleDeleteClick(row);
          setDeleteModal(prevState => ({ ...prevState, view: false }));
        }}
        handleNo={() => setDeleteModal(prevState => ({ ...prevState, view: false }))}
      >
        {deleteModal.children}
      </TransitionModal>
      <TransitionModal
        view={paymentAdjustmentModal.view}
        handleClose={() => {
          setPaymentAdjustmentModal(prevState => ({ ...prevState, view: false }));
        }}
        title={paymentAdjustmentModal.title}
        bodyModal=" "
        ultraWidth
      >
        {paymentAdjustmentModal.children}
      </TransitionModal>
    </TableCell>
  );
};

export default ActionButtonsTab;

import { PaymentInfo } from '../../redux/project/definitions';
import { ScholarshipId } from '../../redux/scholarship/definitions';
import {
  ScholarshipPaymentAdjustmentData,
  ScholarshipPaymentAdjustmentItem,
} from '../../redux/scholarship/paymentAdjustments/PaymentAdjustment';
import { ScholarshipPaymentList } from '../../redux/scholarship/payments/definitions';
import {
  EntityPaymentSendSageData,
  PaymentDatesReplace,
  PaymentId,
  ScholarshipPaymentId,
} from '../../redux/scholarship/payments/Payment';
import PaymentStatus from '../../types/Payments/PaymentStatus';
import { UUID } from '../../types/Shared/standard';
import { apiBaseUrl, apiCall, apiCallBlob } from '../api';

const baseURL = apiBaseUrl;

export const changeScholarshipPaymentDateFromApi = async (
  payment_id: PaymentId,
  payment_date: string,
): Promise<boolean> => {
  const init = {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify({ payment_date }),
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/payments/${payment_id}/change_date`, init);
  return data === 'OK';
};

export const getScholarshipPaymentAdjustmentFromApi = async (
  scholarshipId: ScholarshipId,
): Promise<ScholarshipPaymentAdjustmentData> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: ScholarshipPaymentAdjustmentData }>(
    `${baseURL}/scholarship_payments/scholarship/${scholarshipId}`,
    init,
  );
  return data;
};

export const addScholarshipPaymentFromApi = async (
  scholarshipPayment: ScholarshipPaymentAdjustmentItem,
): Promise<void> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(scholarshipPayment),
  };

  await apiCall<{}>(`${baseURL}/scholarship_payments/scholarship`, init);
};

export const updateScholarshipPayments = async (
  scholarship_id: number,
  scholarship_payments: ScholarshipPaymentAdjustmentItem[],
): Promise<void> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ scholarship_payments }),
  };

  await apiCall<{}>(`${baseURL}/scholarship_payments/scholarship/${scholarship_id}`, init);
};

export const deleteScholarshipPaymentFromApi = async (scholarshipPaymentId: UUID): Promise<void> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  await apiCall<{}>(`${baseURL}/scholarship_payments/${scholarshipPaymentId}`, init);
};

export const getInstituteScholarshipPaymentFromApi = async (
  academic_year: string,
  status: PaymentStatus,
): Promise<ScholarshipPaymentList> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const statusParam = !status.isEmpty() ? `&status=${status.value()}` : '';
  const { data } = await apiCall<{ data: ScholarshipPaymentList }>(
    `${baseURL}/scholarship_payments/institute/list?academic_year=${academic_year}${statusParam}`,
    init,
  );

  return data;
};

export const getEntityScholarshipPaymentFromApi = async (
  academic_year: string,
  entity_id: number | null,
  status: PaymentStatus,
): Promise<ScholarshipPaymentList> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const entityParam = entity_id ? `&entity_id=${entity_id}` : '';
  const statusParam = !status.isEmpty() ? `&status=${status.value()}` : '';
  const { data } = await apiCall<{ data: ScholarshipPaymentList }>(
    `${baseURL}/scholarship_payments/entity/list?academic_year=${academic_year}${entityParam}${statusParam}`,
    init,
  );

  return data;
};

export const exportInstituteScholarshipsPaymentsFromApi = async (academic_year: string): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCallBlob(`${baseURL}/scholarship_payments/institute/export?academic_year=${academic_year}`, init);
};

export const exportEntityScholarshipsPaymentsFromApi = async (
  academic_year: string,
  entity_id: number,
): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCallBlob(
    `${baseURL}/scholarship_payments/entity/export?academic_year=${academic_year}&entity_id=${entity_id}`,
    init,
  );
};

export const replacePaymentsDatesFromApi = async (paymentDates: PaymentDatesReplace): Promise<number> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(paymentDates),
  };

  const { data } = await apiCall<{ data: { payments_replaced: number } }>(
    `${baseURL}/scholarship_payments/dates/replace`,
    init,
  );
  return data.payments_replaced;
};

export const getEntityScholarshipPaymentInfoFromApi = async (
  entity_id: number,
  academic_year: string,
): Promise<{ data: PaymentInfo }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: PaymentInfo }>(
    `${baseURL}/scholarship_payments/entity/${entity_id}/payments?academic_year=${academic_year}`,
    init,
  );
};

export const entityScholarshipPaymentPayFromApi = async (
  entity_id: number,
  paymentsendSageData: EntityPaymentSendSageData,
): Promise<void> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(paymentsendSageData),
  };

  await apiCall<{}>(`${baseURL}/scholarship_payments/entity/${entity_id}/payments/pay`, init);
};

export const instituteScholarshipPaymentPayFromApi = async (
  scholarship_payment_id: ScholarshipPaymentId,
  account_number: string,
): Promise<boolean> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ account_number }),
  };

  const { data } = await apiCall<{ data: string }>(
    `${baseURL}/scholarship_payments/institute/payments/${scholarship_payment_id}/pay`,
    init,
  );
  return data === 'OK';
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';
import {
  addEntityRemainingAmountFromApi,
  cancelEntityAgreementFromApi,
  getEntityAgreementAcademicYearsFromApi,
  getEntityAgreementDataFromApi,
  makeAgreementAdvancePaymentFromApi,
  sendAdminManagementFromApi,
} from '../../../api/Entity/entityAgreement';
import errorMessage from '../../../helpers/errorMessage';
import { showSnackbar } from '../../FeedbackAPI/actions';
import { EntityAgreementDataConsts } from './action_types';
import {
  addEntityRemainingAmountKoAction,
  addEntityRemainingAmountOkAction,
  cancelEntityAgreementKoAction,
  cancelEntityAgreementOkAction,
  getEntityAgreementAcademicYearsKoAction,
  getEntityAgreementAcademicYearsOkAction,
  getEntityAgreementDataKoAction,
  getEntityAgreementDataOkAction,
  sendEntityAdminManagementKoAction,
  sendEntityAdminManagementOkAction,
  sendEntityAdvancePaymentKoAction,
  sendEntityAdvancePaymentOkAction,
} from './actions';
import {
  AddEntityRemainingAmountAction,
  CancelEntityAgreementAction,
  GetEntityAgreementAcademicYearsAction,
  GetEntityAgreementDataAction,
  SendEntityAdminManagementAction,
  SendEntityAdvancePaymentAction,
} from './definitions';

function* getEntityAgreementAcademicYearsFromSaga(
  action: GetEntityAgreementAcademicYearsAction,
): Generator<any, void, any> {
  try {
    const { entity_id } = action;
    const academic_years = yield getEntityAgreementAcademicYearsFromApi(entity_id);
    yield put(getEntityAgreementAcademicYearsOkAction(academic_years));
  } catch (e) {
    yield put(getEntityAgreementAcademicYearsKoAction(errorMessage(e)));
  }
}

function* getEntityAgreementDataFromSaga(action: GetEntityAgreementDataAction): Generator<any, void, any> {
  try {
    const { entity_id, academic_year } = action;
    const entityAgreementData = yield getEntityAgreementDataFromApi(entity_id, academic_year);
    yield put(getEntityAgreementDataOkAction(entityAgreementData));
  } catch (e) {
    yield put(getEntityAgreementDataKoAction(errorMessage(e)));
  }
}

function* sendEntityAdvancePaymentFromSaga(action: SendEntityAdvancePaymentAction): Generator<any, void, any> {
  try {
    const { entity_id, academic_year, payment_amount, payment_date, account_number } = action;
    yield makeAgreementAdvancePaymentFromApi(entity_id, academic_year, payment_amount, payment_date, account_number);
    yield put(sendEntityAdvancePaymentOkAction(payment_date, payment_amount));
    yield put(showSnackbar('Se ha creado el avance de pago correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(sendEntityAdvancePaymentKoAction(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 3000));
  }
}

function* addEntityRemainingAmountFromSaga(action: AddEntityRemainingAmountAction): Generator<any, void, any> {
  try {
    const { entity_id, academic_year, amount, comment } = action;
    yield addEntityRemainingAmountFromApi(entity_id, academic_year, amount, comment);
    yield put(addEntityRemainingAmountOkAction(amount));
  } catch (e) {
    yield put(addEntityRemainingAmountKoAction(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 3000));
  }
}

function* sendEntityAdminManagementFromSaga(action: SendEntityAdminManagementAction): Generator<any, void, any> {
  try {
    const { entity_id, academic_year, amount, payment_date } = action;
    yield sendAdminManagementFromApi(entity_id, academic_year, amount, payment_date);
    yield put(sendEntityAdminManagementOkAction(amount, payment_date));
    yield put(showSnackbar('La Gestión administrativa se ha enviado correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(sendEntityAdminManagementKoAction(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 3000));
  }
}

function* cancelEntityAgreementFromSaga(action: CancelEntityAgreementAction): Generator<any, void, any> {
  try {
    const { signature_id } = action;
    const agreement_state = yield cancelEntityAgreementFromApi(signature_id);
    yield put(cancelEntityAgreementOkAction(agreement_state));
    yield put(showSnackbar('El convenio se ha cancelado correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(cancelEntityAgreementKoAction(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 3000));
  }
}

export default [
  takeLatest<GetEntityAgreementAcademicYearsAction>(
    EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_ACADEMIC_YEARS,
    getEntityAgreementAcademicYearsFromSaga,
  ),
  takeLatest<GetEntityAgreementDataAction>(
    EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_DATA,
    getEntityAgreementDataFromSaga,
  ),
  takeLatest<SendEntityAdvancePaymentAction>(
    EntityAgreementDataConsts.SEND_ENTITY_ADVANCE_PAYMENT,
    sendEntityAdvancePaymentFromSaga,
  ),
  takeLatest<AddEntityRemainingAmountAction>(
    EntityAgreementDataConsts.ADD_ENTITY_REMAINING_AMOUNT,
    addEntityRemainingAmountFromSaga,
  ),
  takeLatest<SendEntityAdminManagementAction>(
    EntityAgreementDataConsts.SEND_ENTITY_ADMIN_MANAGEMENT,
    sendEntityAdminManagementFromSaga,
  ),
  takeLatest<CancelEntityAgreementAction>(
    EntityAgreementDataConsts.CANCEL_ENTITY_AGREEMENT,
    cancelEntityAgreementFromSaga,
  ),
];

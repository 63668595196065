import { ButtonProps } from '@material-ui/core';
import { GetApp as GetAppIcon, Payment } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getEntitiesInfo } from '../../../../api/Entity/entity';
import {
  exportEntityScholarshipsPaymentsFromApi,
  getEntityScholarshipPaymentInfoFromApi,
} from '../../../../api/ScholarshipPayment/scholarshipPayment';
import Layout from '../../../../components/Layout/Layout';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import { getDocs } from '../../../../helpers/getDocs';
import { tKey } from '../../../../helpers/translate';
import { IdNamePair, KeyButton } from '../../../../redux/common/definitions';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { PaymentInfo } from '../../../../redux/project/definitions';
import { AppState } from '../../../../redux/root-reducer';
import {
  entityScholarshipPaymentSendSageAction,
  getEntityScholarshipPaymentsAction,
} from '../../../../redux/scholarship/payments/actions';
import { ScholarshipPaymentList } from '../../../../redux/scholarship/payments/definitions';
import { EntityPaymentSendSageData } from '../../../../redux/scholarship/payments/Payment';
import {
  selectEntityScholarshipPayments,
  selectScholarshipPaymentLoading,
  selectScholarshipPaymentReload,
  selectSelectedAcademicYear,
  selectSelectedEntityId,
} from '../../../../redux/scholarship/payments/selectors';
import PaymentStatus from '../../../../types/Payments/PaymentStatus';
import PaymentModal from '../../../Project/Modal/PaymentModal/PaymentModal';
import AccountingSubmenu from '../../AccountingSubmenu';
import ScholarshipsPaymentFilters from '../ScholarshipsPaymentFilters';
import ScholarshipsPaymentList from '../ScholarshipsPaymentList';

interface Props {
  loading: boolean;
  reload: boolean;
  selectedAcademicYear: string;
  selectedEntityId: number | null;
  scholarshipPaymentList: ScholarshipPaymentList;
  getEntityScholarshipPayments: (academic_year: string, entity_id: number | null, status: string) => void;
  entityScholarshipPaymentSendSage: (entity_id: number, entityPaymentSendSageData: EntityPaymentSendSageData) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const EntitiesScholarshipPayments = ({
  loading,
  reload,
  selectedAcademicYear,
  selectedEntityId,
  scholarshipPaymentList,
  getEntityScholarshipPayments,
  entityScholarshipPaymentSendSage,
}: Props) => {
  const [payModal, setPayModal] = useState({ view: false, modal: {} as PaymentInfo });
  const [entities, setEntities] = useState<IdNamePair[]>();
  useEffect(() => {
    if (selectedAcademicYear) {
      getEntitiesInfo(selectedAcademicYear)
        .then(response => {
          setEntities(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [selectedAcademicYear]);

  useEffect(() => {
    if (selectedAcademicYear) {
      getEntityScholarshipPayments(selectedAcademicYear, selectedEntityId, PaymentStatus.EMPTY_STATUS);
    }
  }, [selectedAcademicYear, getEntityScholarshipPayments, selectedEntityId]);

  useEffect(() => {
    if (selectedAcademicYear && reload) {
      getEntityScholarshipPayments(selectedAcademicYear, selectedEntityId, PaymentStatus.EMPTY_STATUS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const exportExcel = async () => {
    if (selectedAcademicYear) {
      const file = await exportEntityScholarshipsPaymentsFromApi(selectedAcademicYear, selectedEntityId || 0);
      const fileName = `pagos_entidad_becas_entidad_${selectedAcademicYear.replace('/', '_')}.xlsx`;
      getDocs(file, true, fileName);
    }
  };

  const paymentModal = async () => {
    try {
      if (!selectedAcademicYear) {
        return;
      }
      if (selectedEntityId === 0) {
        showSnackbar('Se debe seleccionar una entidad', 'warning', undefined, 1500);
        return;
      }
      const { data } = await getEntityScholarshipPaymentInfoFromApi(selectedEntityId || 0, selectedAcademicYear);
      setPayModal({ view: true, modal: data });
    } catch (error) {
      const e = error as ErrorEvent;
      showSnackbar(e.message, 'error', undefined, 1500);
    }
  };

  const doPayment = async (dataForm: PaymentInfo) => {
    if (!selectedEntityId) {
      return;
    }
    const data = {
      account_number: dataForm.account_number,
      academic_year: selectedAcademicYear,
      payment_date: dataForm.payment_date,
      payment_amount: dataForm.amount,
      to_pay_amount: dataForm.to_pay_amount || 0,
    } as EntityPaymentSendSageData;
    entityScholarshipPaymentSendSage(selectedEntityId, data);
  };

  const buttonExport = {
    children: tKey('Exportar'),
    disableElevation: true,
    fullWidth: true,
    color: 'primary',
    variant: 'contained',
    startIcon: <GetAppIcon />,
    onClick: () => exportExcel(),
    disabled: !selectedAcademicYear,
  } as ButtonProps;

  const buttonPay = {
    children: tKey('Pagar'),
    disableElevation: true,
    key: KeyButton.PAY,
    fullWidth: true,
    color: 'primary',
    variant: 'contained',
    startIcon: <Payment />,
    disabled: !selectedEntityId,
    onClick: () => paymentModal(),
  } as ButtonProps;

  const actionButtons: ButtonProps[] = [buttonExport, buttonPay];

  return (
    <Layout
      leftSubmenu={<AccountingSubmenu selected="entity" submenu={tKey('Pagos')} />}
      rightSubmenu={<ActionsMenu actionsButtons={actionButtons} goBack />}
    >
      <ScholarshipsPaymentFilters
        loading={loading}
        isEntity
        entities={entities}
        title={tKey('Becas de entidad')}
        scholarshipPaymentListSummary={scholarshipPaymentList.payments_summary}
      />
      <ScholarshipsPaymentList
        loading={loading}
        isEntity
        title={'Pagos becas entidad'}
        payments={scholarshipPaymentList.payments}
      />
      <PaymentModal
        view={payModal.view}
        onClose={() => setPayModal(prevModal => ({ ...prevModal, view: false }))}
        onSubmit={doPayment}
        inputsData={payModal.modal}
      />
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedAcademicYear: selectSelectedAcademicYear(state),
  selectedEntityId: selectSelectedEntityId(state),
  scholarshipPaymentList: selectEntityScholarshipPayments(state),
  loading: selectScholarshipPaymentLoading(state),
  reload: selectScholarshipPaymentReload(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getEntityScholarshipPayments: (academic_year: string, entity_id: number | null, status: string) =>
    dispatch(getEntityScholarshipPaymentsAction(academic_year, entity_id, status)),
  entityScholarshipPaymentSendSage: (entity_id: number, entityPaymentSendSageData: EntityPaymentSendSageData) =>
    dispatch(entityScholarshipPaymentSendSageAction(entity_id, entityPaymentSendSageData)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesScholarshipPayments);

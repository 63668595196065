// initialState, ScholarshipDataState ScholarshipDataAction
import { Moment } from 'moment';
import { Action } from 'redux';
import { APIValidationError } from '../../api/api';
import {
  CodeNamePair,
  DocType,
  DocumentationItem,
  ExtractKeys,
  IdCodeNamePair,
  IdNamePair,
  IdTypeNamePair,
} from '../common/definitions';
import { ScholarshipDataConsts } from './action_types';
import { AllowedStates } from './changeState/definitions';
import { CreateFamilyIncomeDataAction } from './familyIncomes/create/definitions';
import createFamilyIncomeReducer from './familyIncomes/create/reducers';
import { DeleteFamilyIncomeDataAction } from './familyIncomes/delete/definitions';
import deleteFamilyIncomeReducer from './familyIncomes/delete/reducers';
import { GetFamilyIncomesDataAction } from './familyIncomes/list/definitions';
import getFamilyIncomesReducer from './familyIncomes/list/reducers';

export const initialState = {
  loading: false as boolean,
  formStep: 0 as number,
  loadingScholarshipUser: false as boolean,
  loadingMaterials: false as boolean,
  error: null as string | null,
  scholarshipData: {} as ScholarshipData,
  scholarshipMetadata: {} as ScholarshipMetadata,
  scholarshipAllowedStates: [] as AllowedStates,
  documentation: [] as DocumentationItem[],
  validationErrors: null as APIValidationError | null,
  configuratorFollowupData: {} as any,
  scholarshipAcademies: [] as ScholarshipAcademy[],
  rejectScholarshipData: {} as RejectScholarshipData,
  state_transition: 's01s02' as ScholarshipStateChange,
  entityScholarshipPaymentDateFilter: null as moment.Moment | null,
  familyIncomes:
    getFamilyIncomesReducer(undefined, {} as GetFamilyIncomesDataAction) &&
    createFamilyIncomeReducer(undefined, {} as CreateFamilyIncomeDataAction) &&
    deleteFamilyIncomeReducer(undefined, {} as DeleteFamilyIncomeDataAction),
};

export type ScholarshipDataState = typeof initialState;

export type ScholarshipType = 'institute' | 'entity' | 'study_center';
export type ScholarshipUserType = 'user' | 'institute';

export interface StepItem {
  errorFields: string[];
  color: 'red' | 'green';
  isEmpty: boolean;
}

export interface RejectScholarshipData {
  reject_state_code: ScholarshipStateCode;
  reasons: IdNamePair[];
  reject_reason_id: number;
  renounce_comment: string | null;
  scholarship_amount: number;
  paid_amount: number;
  justify_amount: number;
  remainder_amount: number;
  remainder_remarks: string | null;
  has_remainder: boolean;
}

export interface CheckScholarshipRemainderData {
  scholarship_amount: number;
  paid_amount: number;
  justify_amount: number;
  remainder_amount: number;
  has_remainder: boolean;
}

export interface ScholarshipSteps {
  personalInformation: StepItem;
  motivationalInformation: StepItem;
  currentSituation: StepItem;
  educationalValoration: StepItem;
  economicalValoration: StepItem;
  scholarshipCost: StepItem;
  complete: boolean;
}

export type ScholarshipStepsNoComplete = Omit<ScholarshipSteps, 'complete'>;

export type ScholarshipId = number;

export interface ScholarshipData {
  id: ScholarshipId;
  state: string;
  state_id: number;
  state_code: ScholarshipStateCode;
  student_id: number;
  student_name: string | null;
  student_surname: string | null;
  student_surname2: string | null;
  student_document_type: string; //select
  student_document_number: string | null;
  student_document_expedition_date: number | null;
  student_document_expedition_place: string | null;
  student_document_expiration_date: number | null;
  student_birthdate: number | null;
  student_gender: string;
  student_nationality: string;
  tutor_name: string;
  tutor_phone: string;
  tutor_email: string;
  tutor_document_type: string; //select
  tutor_document_number: string | null;
  student_country_origin: string;
  interview_entity: string | null;

  enrollment_approval: number;
  monthly_price_approval: number;
  materials_approval: number;
  transport_price_approval: number;
  other_costs_approval: number;
  total_price: number;
  total_price_approval: number;
  young_contrib_approval: number;
  mec_approval: number;
  approval_comments: string;

  student_address_type: string;
  student_address: string;
  student_flat: string;
  student_floor: string;
  student_room: string;
  student_staircase: string;
  student_zip_code: string;
  student_city: string;
  student_country: string;
  student_province: string;
  student_phone: string | null;
  student_phone2: string | null;
  student_email: string | null;
  student_email2: string | null;
  student_instagram: string | null;
  student_linkedin: string;
  student_twitter: string;
  student_facebook: string;
  student_youtube: string;
  student_other_social_networks: string;
  student_provider_code: string;
  student_payable_account: string;
  student_expense_account: string;
  educational_level_id: number | null;
  isEntity: boolean;
  scholarshipType: string;
  last_course: string;
  academic_year: string;

  // EconomicalValorationData
  number_people_coexists: number;
  legal_situation: string;
  was_protected: number;
  is_protected: number;
  father_incomes_type: string;
  father_incomes: number;
  mother_incomes_type: string;
  mother_incomes: number;
  family_incomes_type: string;
  family_incomes: number;
  other_incomes_type: string;
  other_incomes: number;
  student_incomes_type: string;
  student_incomes: number;
  living_place_type: string;
  living_place_cost: number;
  extra_expending: string;
  tax_declaration: string;
  extra_costs_resume: string;
  extra_costs: number;
  extra_documentation: string;
  family_info: string;
  has_economic_changes: number;
  economic_changes_comments: string;
  alternative_incomes: number;
  alternative_incomes_type: string;
  alternative_incomes_resume: string;
  has_dependent_children: number;
  has_disability: number;
  disability_percentage: number;
  disability_details: string | null;
  has_special_needs: number;
  special_needs_details: string | null;

  // EducationalValorationData
  has_formal_studies_done: number | null;
  last_educational_level_id: number | null;
  finished_educational_type_id: number | null;
  last_professional_group_id: number | null;
  last_scholarship_course_id: number | null;
  last_course_name: string | null;
  formal_studies_completed: string | null;
  formal_studies_academy_name: string | null;
  finished_educational_type_year: number | null;
  last_academic_year_remarks: string | null;

  academy_name: string | null;
  scholarship_academy_id: number | null;
  last_scholarship_academy_id: number | null;
  last_educational_level: string | null;

  professional_group: string | null;
  professional_group_id: number | null;
  course_name: string | null;
  scholarship_course_id: number | null;
  other_reasons: string | null;
  linked_periods: string;
  scholarship_academy: string | null;
  scholarship_academy_code: string | null;
  scholarship_academy_confirm_code: string | null;
  academic_return: string | null;
  previous_year_highlights: string | null;
  has_academic_return: number | null;

  finished_educational_type_code: string | null;
  educational_level: string | null;
  educational_type_id: number | null;
  educational_type_code: string | null;
  follow_up_type: string;
  scholarship_academy_choose_reason_id: number | null;
  motivational_program: number | null;
  mentoring_program: number;
  vocational_workshop: number | null;
  individual_coaching: number | null;
  parents_with_post_compulsory_studies: string;

  //MotivationalValorationData
  last_year_info: string;
  motivational_checkbox: boolean;
  educator_reason: string;
  linked_periods_comments: string;

  // ScholarshipCostData
  enrollment_cost: number;
  monthly_enrollment_cost: number;
  transportation_id: number;
  transportation_type: string;
  transportation_cost: number;
  other_costs_amount: number;
  other_transportation_options: string;
  other_costs: number;
  able_to_pay: number;
  how_to_pay: string;
  state_scholarship: number;
  state_scholarship_amount: number;
  why_not_renewal: string;
  is_renewal: number;
  material_type: string;
  materials_cost: number;
  materials: Material[];
  transport_type_quantity: number;
  mec_amount: number;

  // CurrentSituationData
  current_situation_id: number;
  current_situation: string | null;
  company_name: string | null;
  employee_role: string | null;
  current_incomes: string | null;
  contract_type: string | null;
  workday_type: string | null;
  working_start_month: string | null;
  working_start_year: string | null;

  // SendData
  privacy_policy: number;
  send_information: number;
  image_use: number;
  whatsapp_aggregation: number;
  whatsapp_group: number;

  // ScholarshipFields
  //Perdidas
  salary_document: string;
  academic_inform: string;
  motivational_letter: string;
  scholarship_resolution: string;
  young_document: string;
  tutor_document: string;
  academy_recommendation_letter: string;
  complementary_documentation: string;

  // Documentation
  young_document_documentation_id: number;
  young_document_file_id: number;
  young_document_file_name: string;
  motivational_letter_documentation_id: number;
  motivational_letter_file_id: number;
  motivational_letter_file_name: string;
  salary_document_documentation_id: number;
  salary_document_file_id: number;
  salary_document_file_name: string;
  academic_report_documentation_id: number;
  academic_report_file_id: number;
  academic_report_file_name: string;
  scholarship_resolution_documentation_id: number;
  scholarship_resolution_file_id: number;
  scholarship_resolution_file_name: string;
  tutor_document_documentation_id: number;
  tutor_document_file_id: number;
  tutor_document_file_name: string;
  academy_recommendation_letter_documentation_id: number;
  academy_recommendation_letter_file_id: number;
  academy_recommendation_letter_file_name: string;
  tax_declaration_documentation_id: number;
  tax_declaration_file_id: number;
  tax_declaration_file_name: string;
  complementary_documentation_documentation_id: number;
  complementary_documentation_file_id: number;
  complementary_documentation_file_name: string;

  //Interview
  interview_comment: string;
  motivation_assessment: number;
  motivation_comment: string;
  itinerary_assessment: number;
  itinerary_comment: string;
  capacities_assessment: number;
  capacities_comment: string;
  interview_mentor: number;
  interview_assessment: number;

  //steps
  steps: ScholarshipSteps;
  comments: string | null | undefined;
  reject_reason_id: number;

  //Assessment
  claim_comments: string;
  scholarship_risk: number;
  scholarship_mentoring: number | null;
  scholarship_num_nouscims: number;
  scholarship_committee: number | null;
  scholarship_tracking: number | null;
  scholarship_motivation: number;
  scholarship_skill: number;
  scholarship_socialrisc: number;
  scholarship_impact: number;
  scholarship_eco_sit: number;
  assessment_user_id: number;
  scholarship_risk_comm: string;
  scholarship_proposal_comm: string;
  scholarship_mentoring_comm: string;
  scholarship_contrib_comm: string;
  scholarship_motivation_com: string;
  scholarship_skills_comm: string;
  scholarship_socialrisc_com: string;
  scholarship_impact_comm: string;
  scholarship_eco_sit_comm: string;
  scholarship_contrib: string;
  scholarship_proposal: string;
  assessment_user: string;
  top_student: number;
  top_student_comment: string;
  scholarship_mentoring_approval: string | null;
  scholarship_mentoring_finished: string | null;
  scholarship_mentoring_quality: string | null;
  scholarship_mentoring_comm_approval: string | null;
  scholarship_type: string | null;

  //Confirmation
  enrollment_confirm: number;
  monthly_price_confirm: number;
  materials_confirm: number;
  transport_price_confirm: number;
  other_costs_confirm: number;
  educational_type: string;
  educational_type_id_confirm: number | null;
  professional_group_id_confirm: number | null;
  scholarship_course_id_confirm: number | null;
  educational_level_id_confirm: number | null;
  scholarship_academy_confirm: string | null;
  scholarship_academy_confirm_nature: string | null;
  payment_comment: string | null;
  educational_type_confirm_code: string;
  mec_confirm: number;
  student_account_number: string | null;

  //Payment
  last_scholarship_academy: string;
  payment_date: number;
  confirmed_with_diff: boolean;
  approval_confirm_comments: string;

  //Justification
  enrollment_justify: number;
  monthly_price_justify: number;
  transport_price_justify: number;
  materials_justify: number;
  other_costs_justify: number;
  young_contrib_justify: number;
  mec_justify: number;
  adjustment_amount_justify: number;
  total_paid: number;

  //Entity
  entity_name?: string;
  educator_name?: string;
  educator_surname?: string;
  educator_email?: string;
  educator_phone?: string;

  renounce_comment: string | null;
  renounce_date: string | null;

  adjustment_amount: number;
  adjustment_comment?: string | null;

  //Follow
  follow_t1: ScholarshipFollow | null;
  follow_t2: ScholarshipFollow | null;
  follow_t3: ScholarshipFollow | null;
  payment_blocked: boolean;

  zing_network_resource_file_id: number | null;
  zing_network_resource_id: number | null;

  enrollment_avg: number;
  transport_avg: number;
  material_avg: number;
  monthly_price_avg: number;
  enrollment_dev: number;
  transport_dev: number;
  material_dev: number;
  monthly_price_dev: number;

  province_academy: string | null;
  study_center: number | null;

  is_top: string | null;
  deserves_grant: string | null;
  reason_grant_approved_rejected: string | null;
  initial_transversal_competences_link: string;
  final_transversal_competences_link: string;
  edit_interview: boolean;
}

export interface GroupsScholarshipTransversalCompetencesData {
  title: string;
  questions: {
    title: string;
    name: string;
    value: number;
  }[];
}
export interface ScholarshipTransversalCompetencesData {
  scholarship_id: number;
  role_code: string;
  period: string;
  options: { label: string; value: number }[];
  groups: GroupsScholarshipTransversalCompetencesData[];
}

export interface TransversalCompetencesQuestions {
  question_A: string;
  question_B: string;
  question_C: string;
  question_D: string;
  question_E: string;
  question_F: string;
  question_G: string;
  question_H: string;
  question_I: string;
  question_J: string;
  question_K: string;
  question_L: string;
  question_M: string;
  question_N: string;
  question_O: string;
  question_P: string;
  question_Q: string;
  question_R: string;
  question_S: string;
  question_T: string;
  question_U: string;
}

export interface ScholarshipTransversalCompetencesSendData {
  scholarship_id: number;
  role_code: string;
  period: string;
  questions: TransversalCompetencesQuestions;
}

export interface TransversalCompetenceResponseItem {
  question: string;
  first_educator_response: string;
  last_educator_response: string;
  first_student_response: string;
  last_student_response: string;
}

export interface ScholarshipTransversalCompetencesResponses {
  scholarship_id: number;
  responses: TransversalCompetenceResponseItem[];
}

type PersonalInformationKeys = ExtractKeys<
  ScholarshipData,
  | 'state_code'
  | 'student_name'
  | 'student_surname'
  | 'student_surname2'
  | 'student_document_type'
  | 'student_document_number'
  | 'student_document_expedition_date'
  | 'student_document_expedition_place'
  | 'student_document_expiration_date'
  | 'student_gender'
  | 'student_birthdate'
  | 'student_nationality'
  | 'student_country_origin'
  | 'student_address_type'
  | 'student_address'
  | 'student_flat'
  | 'student_floor'
  | 'student_room'
  | 'student_staircase'
  | 'student_zip_code'
  | 'student_city'
  | 'student_country'
  | 'student_province'
  | 'student_phone'
  | 'student_phone2'
  | 'student_email'
  | 'student_email2'
  | 'student_instagram'
  | 'student_linkedin'
  | 'student_twitter'
  | 'student_facebook'
  | 'student_youtube'
  | 'student_other_social_networks'
  | 'interview_entity'
  | 'entity_name'
  | 'educator_name'
  | 'educator_surname'
  | 'educator_email'
  | 'educator_phone'
  | 'renounce_comment'
  | 'last_scholarship_academy_id'
  | 'finished_educational_type_id'
  | 'tutor_name'
  | 'tutor_phone'
  | 'tutor_email'
  | 'tutor_document_number'
  | 'tutor_document_type'
  | 'isEntity'
  | 'scholarshipType'
>;

export type PersonalInformationFormType = Pick<ScholarshipData, PersonalInformationKeys>;
export interface ScholarshipHistoryEntry {
  course_name: string | null;
  academic_year: string;
  academy_name: string | null;
  education_type: string | null;
}

export interface ScholarshipHistoryGlobal {
  scholarship_years: number | null;
  status: boolean;
  top: boolean;
  mentor: boolean;
  mentorship: number;
  entries: ScholarshipHistoryEntry[];
}

export interface ScholarshipFollow {
  id: number;
  keep_studying: number;
  scholarship_abandoment_reasons_id: number | null;
  average_grade: number | null;
  number_subject_studying: number | null;
  number_failed_subject: number | null;
  number_approved_subject: number | null;
  significant_situation_change: number;
  scholarship_change_reasons_id: number | null;
  scholarship_student_meetings_id: number | null;
  scholarship_student_meetings_justify: string | null;
  support_for_approved: number;
  scholarship_supports_id: number | null;
  has_mentor: number;
  scholarship_valorations_id: number | null;
  scholarship_mentor_supports_id: number | null;
  has_scholarship_state: number;
  scholarship_results_id: number | null;
  state_scholarship_amount: number | null;
  has_quaterly_internship: number;
  has_work_quaterly: number;
  has_work_relation_study: number;
  justify_scholarship_amount: number | null;
  scholarship_future_actions_id: number | null;
  scholarship_personal_skills_id: number | null;
  scholarship_academic_levels_id: number | null;
  scholarship_academy_supports_id: number | null;
  scholarship_opportunities_id: number | null;
  special_academic_center: number;
  special_academic_center_reasons: string | null;
  academic_center_social_risk: number;
  academic_center_social_risk_reasons: string | null;
  recommend_academic_center: number | null;
  fnc_scholarship_valorations_id: number | null;
  scholarship_valorations_reasons: string | null;
  recommend_scholarship: number | null;
  fnc_sup_scholarship_valorations_id: number | null;
  valoration_nouscims_support_reason: string | null;
  end_scholarship_future_actions_id: number | null;
  tracking_code: number | null;
  tracking_requested_date: number | null;
  scholarship_student_changes_id: number | null;
  per_scholarship_academic_levels_id: number | null;
  scholarship_abandoment_reasons_others: string | null;
  scholarship_supports_reason_others: string | null;
  scholarship_student_personal_changes_reason_others: string | null;
  scholarship_personal_skills_reason_others: string | null;
  trackings_comments: string | null;
  company_name: string | null;
  employee_role: string | null;
  current_incomes: string | null;
  contract_type: string | null;
  working_start_month: string | null;
  working_start_year: string | null;
  workday_type: string | null;
  enrollment_justify: number;
  monthly_price_justify: number;
  transport_price_justify: number;
  materials_justify: number;
  other_costs_justify: number;
  young_contrib_justify: number;
  mec_justify: number;
  adjustment_amount_justify: number;
}

export interface EntityScholarshipPaymentError {
  scholarship_id: number;
  name: string;
  message: string;
}

export interface EducatorScholarshipAcademicYear {
  scholarship_id: number;
  student_name: string;
  student_surname: string;
  student_surname2: string | null;
  educator_name: string;
  educator_surname: string;
  educator_surname2: string | null;
  academic_year: string;
  state_name: string;
}

export type EntitiesScholarships = {
  id: number;
  entity_name: string;
  active_scholarship: number;
  payment_in_month: number;
  agreement: number;
};

export interface ScholarshipReloadData {
  id: number;
  card_number: string | null;
  provider_code: string;
  name: string;
  amount: number;
  payment_date: string;
  payment_field: string;
}
export interface ChangeScholarshipPaymentValues {
  payment_amount: number;
  payment_date: Moment;
}

export interface ScholarshipReloadData {
  id: number;
  card_number: string | null;
  provider_code: string;
  name: string;
  amount: number;
  payment_date: string;
  payment_field: string;
}

export type EntityScholarshipApiPayment = {
  entity_id: number | null;
  entity_name: string | null;
  agreement_number: string;
  account_number: string;
  remaining_amount: number;
  advance_payment: number;
  advance_payment_remainder: number;
  advance_payment_date: string | null;
  admin_management_amount: number;
  admin_management_date: string | null;
  admin_management_send_date: string | null;
};

export enum paymentFiltersCodes {
  PENDING = 'PEND',
  PENDING_ACTIVES = 'PEAC',
  PAID = 'PAID',
  ACTIVES = 'ACTV',
  ALL = 'ALL',
}

export const paymentFilters = [
  { code: paymentFiltersCodes.PENDING, name: 'Pendientes de pago' },
  { code: paymentFiltersCodes.PENDING_ACTIVES, name: 'Pendientes de pago (activos)' },
  { code: paymentFiltersCodes.PAID, name: 'Pagados' },
  { code: paymentFiltersCodes.ACTIVES, name: 'Activos' },
  { code: paymentFiltersCodes.ALL, name: 'Todos' },
] as CodeNamePair[];

export interface Material {
  id: number;
  scholarship_id: number;
  name: string;
  amount: number;
  file_id: number | null;
  filename: string | null;
}

export type LinkedPeriodsType = '< 6 MESES' | '< 1 AÑO' | 'DE UNO A 3 AÑOS' | '> 3 AÑOS';
export type ScholarshipStateChange =
  | 's01s02'
  | 's02s01'
  | 's02s03'
  | 's02s07'
  | 's03s04'
  | 's04s05'
  | 's05s08'
  | 's08s06'
  | 's08s07'
  | 's08s09'
  | 's09s08'
  | 's06s10'
  | 's12s24'
  | 's13s25'
  | 's14s26'
  | 's15s22'
  | 's16s23'
  | 'reject_pending_justify'
  | 'pending_justify_reject'
  | 'to_admin_review'
  | 'admin_review_confirmation';

export enum ScholarshipStateCode {
  CREATION = 'S01',
  REQUEST = 'S02',
  PENDING_INTERVIEW = 'S03',
  PENDING_INTERVIEW_VALORATION = 'S04',
  PENDING_VALUATION = 'S05',
  VALUATED = 'S08',
  PENDING_INFO = 'S09',
  APPROVED = 'S06',
  DESTIMATED = 'S07',
  MIGRATED = 'S20',
  CONFIRMATION = 'S10',
  ASSIGNED = 'S11',
  FOLLOW_T1 = 'S12',
  FOLLOW_T2 = 'S13',
  FOLLOW_T3 = 'S14',
  FOLLOW_S1 = 'S15',
  FOLLOW_S2 = 'S16',
  END_EVALUATION_S1 = 'S22',
  END_EVALUATION_S2 = 'S23',
  END_EVALUATION_T1 = 'S24',
  END_EVALUATION_T2 = 'S25',
  END_EVALUATION_T3 = 'S26',
  COMPLETED = 'S27',
  END_EVALUATION = 'S21',
  ABANDONMENT = 'S17',
  RENUNCIATION = 'S18',
  CLOSURE = 'S19',
  PENDING_JUSTIFICATION = 'S28',
  ADMIN_REVIEW = 'S29',
}

export enum ScholarshipAgreementCode {
  INIT = 'Init',
  WAITING = 'Waiting',
  VALIDATING = 'Validating',
  CONFIRM = 'Confirm',
  CANCELLED = 'Cancelado',
}

export enum ScholarshipCurrentSituationCodes {
  NINI = 'NNN',
  ESTUDIA = 'EST',
  ESTUDIA_TRABAJA = 'EYT',
  TRABAJA = 'TRA',
}

export enum ScholarshipContribCodes {
  YES = 'Sí',
  NO = 'No',
}

export interface Transportation extends IdNamePair {
  cost: number;
}

export interface ScholarshipMetadata {
  linkedPeriods: LinkedPeriodsType[];
  currentSituation: IdCodeNamePair[];
  incomesOrigins: string[];
  interviewChannels: string[];
  educationalLevels: IdNamePair[];
  educationalFollowups: CodeNamePair[];
  educationalTypes: IdCodeNamePair[];
  transports: Transportation[];
  professionalGroups: IdCodeNamePair[];
  reasons: IdNamePair[];
  academies: IdNamePair[];
  courses: IdCodeNamePair[];
  livingPlaceTypes: string[];
  fnc_academies: IdNamePair[];
  academicLevels: IdNamePair[];
  scholarshipAbandonmentReasons: IdTypeNamePair<ScholarshipAbandonmentReasonsType>[];
  changeReasonsStudent: IdNamePair[];
  mentorSupports: IdNamePair[];
  supports: IdNamePair[];
  valorations: IdNamePair[];
  results: IdNamePair[];
  studentChangePersonalReasons: IdNamePair[];
  studentSatisfactions: IdNamePair[];
  jobOpportunities: IdNamePair[];
  futureStudentPlans: IdNamePair[];
  studentMeets: IdNamePair[];
  decisionsStudyEnd: IdNamePair[];
  academicSupports: IdNamePair[];
  satisfactionLeves: IdNamePair[];
  triState: string[];
  proposal: string[];
}

export interface ScholarshipListItem {
  id: number;
  created_at: number;
  updated_at: number;
  request_date: string;
  student_name: string | null;
  student_surname: string | null;
  student_surname2: string | null;
  student_email: string;
  student_phone: string;
  student_document_type: DocType;
  student_document_number: string;
  assigned_to_user_id: number;
  academic_year: string;
  state: string;
  state_code: ScholarshipStateCode;
  educator_name?: string | null;
  educator_surname?: string | null;
  educator_surname2?: string | null;
  educator_email?: string | null;
  canRenewal: boolean;
  user_id: number;
  request_date_nf: number;
  entity: string;
  academy: string;
  confirmed_with_diff: boolean;
  assigned_to_user: string;
  study_center: boolean | null;
  is_renewal: string;
  total_requested: number;
  initial_educator_competences: number;
  final_educator_competences: number;
  initial_student_competences: number;
  final_student_competences: number;
  transversal_competences: string[];
}

export type ScholarshipTransversalCompetenceFields =
  | 'initial_educator_competences'
  | 'final_educator_competences'
  | 'initial_student_competences'
  | 'final_student_competences';

export type TransversalCompetenceTypes = {
  id: number;
  code: ScholarshipTransversalCompetenceFields;
  name: string;
  color: string;
};

export const transversalCompetenceTypes = [
  { id: 1, code: 'initial_educator_competences', name: 'Iniciales educador', color: '#1e9833' },
  { id: 2, code: 'final_educator_competences', name: 'Finales educador', color: '#135f20' },
  { id: 3, code: 'initial_student_competences', name: 'Iniciales joven', color: '#e08d49' },
  { id: 4, code: 'final_student_competences', name: 'Finales joven', color: '#7d4f29' },
] as TransversalCompetenceTypes[];

export interface Filters {
  states: string[];
  academic_years: string[];
  renewal: string[];
}

export interface ListOfScholarships {
  scholarships: ScholarshipListItem[];
  filters: Filters;
}
export interface ScholarshipUserCheck {
  email: string;
  phone: string;
  scholarship_id: number;
  current: boolean;
  user_id: number;
  is_renewal: boolean;
  is_allowed: boolean;
}

export interface PerStateStatisticData {
  id: number;
  code: StateCode;
  name: string;
  scholarships: number;
}

export type StateCode =
  | 'S01'
  | 'S02'
  | 'S03'
  | 'S04'
  | 'S05'
  | 'S06'
  | 'S07'
  | 'S08'
  | 'S09'
  | 'S10'
  | 'S11'
  | 'S17'
  | 'S18'
  | 'S19'
  | 'S12'
  | 'S13'
  | 'S14'
  | 'S15'
  | 'S16'
  | 'S22'
  | 'S23'
  | 'S24'
  | 'S25'
  | 'S26'
  | 'S27'
  | 'S21'
  | 'S28'
  | 'S29';

export type ColorStepElement = 'red' | 'green' | 'yellow' | 'grey';

export type ScholarshipAcademy = {
  id: number;
  name: string;
  fnc_institute: boolean;
};

export interface Element {
  id: number;
  name: string;
  icon: JSX.Element;
  state: ColorStepElement;
  enable: boolean;
}

export interface Block {
  title: string;
  icon: JSX.Element;
  id: number;
  elements: Element[];
}

export interface StepMenuProps {
  menuStep: Block;
  step: number;
  handleClick: Function;
  subHeader?: boolean;
}

export interface ScholarshipCreatedDataItem {
  id: number;
  student_name: string;
  student_surname: string;
  student_surname2: string;
  student_email: string;
  student_document_number: string;
  academy: string;
  entity: string;
  state: string;
}

export interface ScholarshipCreatedData {
  scholarships: ScholarshipCreatedDataItem[];
  count: number;
}

export type AggreementDataItem = {
  field: string;
  value: string | null;
  error: string | null;
};

export interface ScholarshipGetAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_GET;
  id: number;
}

export interface ScholarshipGetOkAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_GET_OK;
  scholarshipData: ScholarshipData;
  metadata: ScholarshipMetadata | null;
}

export interface ScholarshipGetKoAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_GET_KO;
  error: string;
}

export interface ScholarshipValidationErrorsAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_VALIDATION_ERRORS;
  validationErrors: APIValidationError;
}

export interface ScholarshipGetMetadataAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA;
}

export interface ScholarshipGetMetadataOkAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA_OK;
  scholarshipMetadata: any;
}

export interface ScholarshipGetMetadataKoAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA_KO;
  error: string;
}

export interface ScholarshipSubmitInformationAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION;
  data: ScholarshipData;
}

export interface ScholarshipSubmitInformationOkAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION_OK;
  scholarshipData: ScholarshipData;
}

export interface ScholarshipSubmitInformationKoAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION_KO;
  error: string;
}

export interface UploadDocumentAction extends Action {
  type: ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT;
  documentation_id: number;
  data: File;
  scholarshipId: number;
  multiDocument?: boolean;
}
export interface UploadDocumentOkAction extends Action {
  type: ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_OK;
  documents: DocumentationItem[];
}
export interface UploadDocumentKoAction extends Action {
  type: ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_KO;
  error: string;
}

export interface ScholarshipGetDocumentationAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION;
  id: number;
}

export interface ScholarshipGetDocumentationOkAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION_OK;
  documentation: DocumentationItem[];
}

export interface ScholarshipGetDocumentationKoAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION_KO;
  error: string;
}

export interface ScholarshipDownloadDocumentAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT;
  file_id: number;
  scholarshipId: number;
  name: string;
}
export interface ScholarshipDownloadDocumentOkAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT_OK;
  data: Blob;
  name: string;
}
export interface ScholarshipDownloadDocumentKoAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT_KO;
  error: string;
}

export interface GetDocumentScholarshipAction extends Action {
  type: ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT;
  file_id: number;
  scholarshipId: number;
  name: string;
}
export interface GetDocumentScholarshipOkAction extends Action {
  type: ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT_OK;
  dataDoc: Blob;
  name: string;
}
export interface GetDocumentScholarshipKoAction extends Action {
  type: ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT_KO;
  error: string;
}

export interface RemoveDocumentScholarshipAction extends Action {
  type: ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT;
  file_id: number;
  scholarshipId: number;
}
export interface RemoveDocumentScholarshipOkAction extends Action {
  type: ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT_OK;
  documents: DocumentationItem[];
}
export interface RemoveDocumentScholarshipKoAction extends Action {
  type: ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT_KO;
  error: string;
}

export interface CleanScholarshipDataAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_CLEAN_DATA;
}

export interface ScholarshipSetDataAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA;
  key: string;
  value: string | number;
  subKey?: string;
}

export interface ScholarshipSetPersonalInfoDataAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_PERSONAL_INFO;
  key: string;
  value: string | number;
}

export interface ScholarshipSetMotivationalInfoDataAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_MOTIVATIONAL_INFO;
  key: string;
  value: string | number | null;
}

export interface ScholarshipSetSituationInfoDataAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_SITUATION_INFO;
  key: string;
  value: string | number | null;
}

export interface ScholarshipSetEducationalInfoDataAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_EDUCATIONAL_INFO;
  key: string;
  value: string | number | null;
}

export interface ScholarshipSetEconomicalInfoDataAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_ECONOMICAL_INFO;
  key: string;
  value: string | number | null;
}

export interface ScholarshipSetCostInfoDataAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_COST_INFO;
  key: string;
  value: string | number | null;
}

export interface ScholarshipSetInterviewInfoDataAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_INTERVIEW_INFO;
  key: string;
  value: string | number | null;
}

export interface ScholarshipSetAssessmentInfoDataAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_ASSESSMENT_INFO;
  key: string;
  value: string | number | null;
}

export interface ScholarshipSetApprovalInfoDataAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_APPROVAL_INFO;
  key: string;
  value: string | number | null;
}

export interface ScholarshipSetDataFollowAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_FOLLOW;
  key: string;
  subKey: string;
  value: string | number;
}
export interface SendConfigurationFollowupAction extends Action {
  type: ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP;
  data: any;
}

export interface SendConfigurationFollowupOkAction extends Action {
  type: ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP_OK;
}

export interface SendConfigurationFollowupKoAction extends Action {
  type: ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP_KO;
  error: string;
}

// RUN CONFIGURATION FOLLOWUP

export interface RunConfigurationFollowupAction extends Action {
  type: ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP;
}

export interface RunConfigurationFollowupOkAction extends Action {
  type: ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP_OK;
}

export interface RunConfigurationFollowupKoAction extends Action {
  type: ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP_KO;
  error: string;
}

export interface GetConfigurationFollowupAction extends Action {
  type: ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP;
}

export interface GetConfigurationFollowupOkAction extends Action {
  type: ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP_OK;
  configurationFollowupData: any;
}

export interface GetConfigurationFollowupKoAction extends Action {
  type: ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP_KO;
  error: string;
}

export interface SaveFollowUpAction extends Action {
  type: ScholarshipDataConsts.SAVE_FOLLOW_UP;
  follow_up: ScholarshipFollow;
}

export interface SwitchBlockPaymentAction extends Action {
  type: ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT;
  id: number;
}
export interface SwitchBlockPaymentOkAction extends Action {
  type: ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT_OK;
  response: boolean;
}
export interface SwitchBlockPaymentKoAction extends Action {
  type: ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT_KO;
  error: string;
}
export interface ScholarshipAddMaterialAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_ADD_MATERIAL;
  material: Material;
}
export interface ScholarshipAddMaterialOkAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_ADD_MATERIAL_OK;
  material: Material;
}

export interface ScholarshipRemoveMaterialAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_REMOVE_MATERIAL;
  scholarship_id: number;
  material_id: number;
}

export interface ScholarshipRemoveMaterialOkAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_REMOVE_MATERIAL_OK;
  scholarship_id: number;
  material_id: number;
}

export interface ScholarshipUpdateMaterialAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_UPDATE_MATERIAL;
  material: Material;
}
export interface ScholarshipUpdateMaterialOkAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_UPDATE_MATERIAL_OK;
  material: Material;
}

export interface ScholarshipMaterialKoAction extends Action {
  type: ScholarshipDataConsts.SCHOLARSHIP_MATERIAL_KO;
  error: string;
}

export interface ExportScholarshipReportsAction extends Action {
  type: ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS;
  source: 'committee' | 'zing';
  scholarship_type: 'entity' | 'institute' | 'study_center';
  academic_year: string;
}
export interface ExportScholarshipReportsOkAction extends Action {
  type: ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS_OK;
  source: 'committee' | 'zing';
  scholarship_type: 'entity' | 'institute' | 'study_center';
  data: Blob;
}
export interface ExportScholarshipReportsKoAction extends Action {
  type: ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS_KO;
  error: string;
}

export interface UploadDocumentTrackingAction extends Action {
  type: ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_TRACKING;
  documentation_id: number;
  data: File;
  scholarshipId: number;
  trackingId: number;
}

export interface ScholarshipAcademiesMetadataAction extends Action {
  type: ScholarshipDataConsts.GET_SCHOLARSHIP_ACADEMIES_METADATA;
  code: string | 'all';
}

export interface ScholarshipAcademiesMetadataOkAction extends Action {
  type: ScholarshipDataConsts.GET_SCHOLARSHIP_METADATA_ACADEMIES_OK;
  scholarshipAcademies: ScholarshipAcademy[];
}

export interface ScholarshipAcademiesMetadataKoAction extends Action {
  type: ScholarshipDataConsts.GET_SCHOLARSHIP_METADATA_ACADEMIES_KO;
  error: string;
}

export interface SetEntityPaymentDateFilterAction extends Action {
  type: ScholarshipDataConsts.SET_ENTITY_PAYMENT_DATE_FILTER;
  paymentDate: moment.Moment | null;
}

export interface SetScholarshipStepAction extends Action {
  type: ScholarshipDataConsts.SET_SCHOLARSHIP_STEP;
  step: number;
}

export type ScholarshipDataAction =
  | ScholarshipGetAction
  | ScholarshipGetOkAction
  | ScholarshipGetKoAction
  | ScholarshipValidationErrorsAction
  | ScholarshipGetMetadataAction
  | ScholarshipGetMetadataOkAction
  | ScholarshipGetMetadataKoAction
  | ScholarshipSubmitInformationAction
  | ScholarshipSubmitInformationOkAction
  | ScholarshipSubmitInformationKoAction
  | UploadDocumentAction
  | UploadDocumentOkAction
  | UploadDocumentKoAction
  | ScholarshipGetDocumentationAction
  | ScholarshipGetDocumentationOkAction
  | ScholarshipGetDocumentationKoAction
  | ScholarshipDownloadDocumentAction
  | ScholarshipDownloadDocumentOkAction
  | ScholarshipDownloadDocumentKoAction
  | GetDocumentScholarshipAction
  | GetDocumentScholarshipOkAction
  | GetDocumentScholarshipKoAction
  | RemoveDocumentScholarshipAction
  | RemoveDocumentScholarshipOkAction
  | RemoveDocumentScholarshipKoAction
  | CleanScholarshipDataAction
  | ScholarshipSetDataAction
  | SendConfigurationFollowupAction
  | SendConfigurationFollowupOkAction
  | SendConfigurationFollowupKoAction
  | GetConfigurationFollowupAction
  | GetConfigurationFollowupOkAction
  | GetConfigurationFollowupKoAction
  | SwitchBlockPaymentAction
  | SwitchBlockPaymentOkAction
  | SwitchBlockPaymentKoAction
  | ScholarshipSetDataFollowAction
  | RunConfigurationFollowupAction
  | RunConfigurationFollowupOkAction
  | RunConfigurationFollowupKoAction
  | ScholarshipSetPersonalInfoDataAction
  | ScholarshipSetMotivationalInfoDataAction
  | ScholarshipSetSituationInfoDataAction
  | ScholarshipSetEducationalInfoDataAction
  | ScholarshipSetEconomicalInfoDataAction
  | ScholarshipSetCostInfoDataAction
  | ScholarshipAddMaterialAction
  | ScholarshipAddMaterialOkAction
  | ScholarshipRemoveMaterialAction
  | ScholarshipRemoveMaterialOkAction
  | ScholarshipUpdateMaterialAction
  | ScholarshipUpdateMaterialOkAction
  | ScholarshipMaterialKoAction
  | ScholarshipSetInterviewInfoDataAction
  | ScholarshipSetAssessmentInfoDataAction
  | ScholarshipSetApprovalInfoDataAction
  | SaveFollowUpAction
  | ExportScholarshipReportsAction
  | ExportScholarshipReportsOkAction
  | ExportScholarshipReportsKoAction
  | UploadDocumentTrackingAction
  | ScholarshipAcademiesMetadataAction
  | ScholarshipAcademiesMetadataOkAction
  | ScholarshipAcademiesMetadataKoAction
  | SetEntityPaymentDateFilterAction
  | SetScholarshipStepAction;

export type ScholarshipGetAllDispatch = (action: ScholarshipDataAction) => void;

export enum SubmenuItemTypeOption {
  DATOS_JOVEN,
  VALORACION_MOTIVACIONAL,
  SITUACION_ACTUAL,
  VALORACION_EDUCATIVA,
  VALORACION_ECONOMICA,
  IMPORTE_BECA,
  ENTREVISTA,
  VALORACION_FORM,
  APROBACION,
  CONFIRMACION,
  PAGOS,
  JUSTIFICACION_ECONOMICA,
  FOLLOW_T1,
  FOLLOW_T2,
  FOLLOW_T3,
}

export enum ScholarshipAbandonmentReasonsType {
  NO = 'NO',
  ALU = 'ALU',
  FIN = 'FIN',
}

export enum KeepStudyingType {
  NO = 'NO',
  SI = 'SI',
  FIN = 'FIN',
}

export enum KeepStudyingOption {
  NO = 0,
  SI = 1,
  FIN = 2,
}

import { ButtonProps } from '@material-ui/core';
import React from 'react';
import Title from '../../../components/Title/Title';
import { tKey } from '../../../helpers/translate';
import { EntityInfo } from '../../../redux/entity/definitions';
import AccountingDataProvider from './AccountingDataProvider';

interface AccountingDataFNC {
  entityData: EntityInfo;
  disabled: boolean;
  buttonActionSave: ButtonProps;
  buttonSageProvider: ButtonProps;
  buttonModifyAccounts: ButtonProps;
}

const AccountingDataFNC: React.FC<AccountingDataFNC> = ({
  entityData,
  disabled = false,
  buttonActionSave,
  buttonSageProvider,
  buttonModifyAccounts,
}) => {
  return (
    <>
      <Title>{tKey('Datos contables')}</Title>
      <AccountingDataProvider
        disabled={disabled}
        entityData={entityData}
        buttonActionSave={buttonActionSave}
        buttonSageProvider={buttonSageProvider}
        buttonModifyAccounts={buttonModifyAccounts}
        allowSetAccount={true}
      />
    </>
  );
};

export default AccountingDataFNC;

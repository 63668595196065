import assertUnreachable from '../../../helpers/assertUnreachable';
import { EntityAgreementDataConsts } from './action_types';
import { EntityAgreementDataAction, EntityAgreementDataState } from './definitions';
import { EntityAgreementInitialState } from './EntityAgreement';

const entityAgreementReducer = (
  state = EntityAgreementInitialState,
  action: EntityAgreementDataAction,
): EntityAgreementDataState => {
  switch (action.type) {
    case EntityAgreementDataConsts.ADD_ENTITY_REMAINING_AMOUNT:
    case EntityAgreementDataConsts.SEND_ENTITY_ADVANCE_PAYMENT:
    case EntityAgreementDataConsts.SEND_ENTITY_ADMIN_MANAGEMENT:
    case EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_ACADEMIC_YEARS:
    case EntityAgreementDataConsts.CANCEL_ENTITY_AGREEMENT:
      return {
        ...state,
        error: null,
      };
    case EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_ACADEMIC_YEARS_OK:
      const { academic_years } = action;
      return {
        ...state,
        academic_years,
      };
    case EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_DATA_OK:
      const { agreementData } = action;
      return {
        ...state,
        loading: false,
        agreementData,
      };
    case EntityAgreementDataConsts.SEND_ENTITY_ADVANCE_PAYMENT_OK:
      const { payment_amount, payment_date } = action;
      return {
        ...state,
        loading: false,
        agreementData: {
          ...state.agreementData,
          advance_payment: payment_amount,
          advance_payment_remainder: payment_amount,
          advance_payment_date: payment_date,
        },
      };
    case EntityAgreementDataConsts.ADD_ENTITY_REMAINING_AMOUNT_OK:
      const { amount } = action;
      const {
        agreementData: { remainder, course_remainder },
      } = state;
      const new_remainder = remainder + amount;
      const new_course_remainder = course_remainder + amount;
      return {
        ...state,
        loading: false,
        agreementData: {
          ...state.agreementData,
          remainder: new_remainder,
          course_remainder: new_course_remainder,
        },
      };
    case EntityAgreementDataConsts.SEND_ENTITY_ADMIN_MANAGEMENT_OK: {
      const { amount, payment_date } = action;
      return {
        ...state,
        loading: false,
        agreementData: {
          ...state.agreementData,
          admin_management_amount: amount,
          admin_management_date: payment_date,
        },
      };
    }
    case EntityAgreementDataConsts.CANCEL_ENTITY_AGREEMENT_OK:
      const { agreement_state } = action;
      return {
        ...state,
        loading: false,
        agreementData: {
          ...state.agreementData,
          documentation: [],
          agreement_state,
        },
      };
    case EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_ACADEMIC_YEARS_KO:
    case EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_DATA_KO:
    case EntityAgreementDataConsts.SEND_ENTITY_ADVANCE_PAYMENT_KO:
    case EntityAgreementDataConsts.ADD_ENTITY_REMAINING_AMOUNT_KO:
    case EntityAgreementDataConsts.SEND_ENTITY_ADMIN_MANAGEMENT_KO:
    case EntityAgreementDataConsts.CANCEL_ENTITY_AGREEMENT_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default entityAgreementReducer;

export enum ScholarshipAgreementDataConsts {
  GET_SCHOLARSHIP_AGREEMENT = 'GET:SCHOLARSHIP:AGREEMENT',
  GET_SCHOLARSHIP_AGREEMENT_OK = 'GET:SCHOLARSHIP:AGREEMENT:OK',
  GET_SCHOLARSHIP_AGREEMENT_KO = 'GET:SCHOLARSHIP:AGREEMENT:KO',

  UPDATE_SCHOLARSHIP_AGREEMENT = 'UPDATE:SCHOLARSHIP:AGREEMENT',
  UPDATE_SCHOLARSHIP_AGREEMENT_OK = 'UPDATE:SCHOLARSHIP:AGREEMENT:OK',
  UPDATE_SCHOLARSHIP_AGREEMENT_KO = 'UPDATE:SCHOLARSHIP:AGREEMENT:KO',

  SEND_STUDENT_PROVIDER_INFO = 'SEND:STUDENT:PROVIDER:INFO',
  SEND_STUDENT_PROVIDER_INFO_OK = 'SEND:STUDENT:PROVIDER:INFO:OK',
  SEND_STUDENT_PROVIDER_INFO_KO = 'SEND:STUDENT:PROVIDER:INFO:KO',

  CANCEL_SCHOLARSHIP_AGREEMENT = 'CANCEL:SCHOLARSHIP:AGREEMENT',
  CANCEL_SCHOLARSHIP_AGREEMENT_OK = 'CANCEL:SCHOLARSHIP:AGREEMENT_OK',
  CANCEL_SCHOLARSHIP_AGREEMENT_KO = 'CANCEL:SCHOLARSHIP:AGREEMENT_KO',

  SCHOLARSHIP_AGREEMENT_SET_DATA = 'SCHOLARSHIP:AGREEMENT:SET:DATA',
  SCHOLARSHIP_AGREEMENT_VALIDATION_ERRORS = 'SCHOLARSHIP:AGREEMENT:VALIDATION:ERRORS',
}

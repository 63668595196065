/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { getScholarshipAgreementTemplateData } from '../../../../api/Scholarship/scholarship';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInput from '../../../../components/Inputs/TextInput';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../../components/Loading/Loading';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import Title from '../../../../components/Title/Title';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import { tErrorKey, tKey, tScholarshipKey } from '../../../../helpers/translate';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { selectIsNousCims } from '../../../../redux/auth/selectors';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { scholarshipSetData, scholarshipSubmitInformation } from '../../../../redux/scholarship/actions';
import {
  AggreementDataItem,
  ScholarshipData,
  ScholarshipStateChange,
  ScholarshipStateCode,
} from '../../../../redux/scholarship/definitions';
import {
  selectGetScholarshipDocumentationPerCode,
  selectStateTransition,
  selectTotalConfirmedScholarship,
} from '../../../../redux/scholarship/selectors';
import { scholarshipStateTransitionAction } from '../../../../redux/scholarship/transitions/actions';
import Documentation from '../../../../types/Scholarships/Documentation';
import StudyCenterNature from '../../../../types/Scholarships/StudyCenterNature';
import { confirmationSchema } from '../../../../validations/formSchema';
import TransversalCompetencesSection from '../../Components/TransversalCompetencesSection';
import StudiesConfirmationForm from './StudiesConfirmationForm';
import TotalsConfirmationForm from './TotalsConfirmationForm';
import styles from './confirmationForm.module.scss';

interface ConfirmationFormProps {
  scholarshipData: ScholarshipData;
  readOnly: boolean;
  isNousCims: boolean;
  buttonActionSave: ButtonProps;
  buttonActionSendConfirmation: ButtonProps;
  state_transition: ScholarshipStateChange;
  can_confirm: boolean;
  documentation: DocumentationItem[];
  total_confirmation: number;
  setData: (key: string, value: string | number) => void;
  saveData: (data: ScholarshipData) => void;
  sendConfirmation: (
    id: number,
    state: ScholarshipStateChange,
    redirect: string,
    scholarshipData?: ScholarshipData,
  ) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
  uploadDocument: (documentation_id: number, data: File, scholarshipId: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  getFile: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument: (documentation_id: number, scholarship_id: number) => void;
  addDoc: (document: File, description: string, genericId?: number, documentation_id?: number) => void;
}

const ConfirmationForm: React.FC<ConfirmationFormProps> = ({
  scholarshipData,
  readOnly,
  isNousCims,
  buttonActionSave,
  buttonActionSendConfirmation,
  state_transition,
  can_confirm,
  documentation,
  total_confirmation,
  setData,
  saveData,
  sendConfirmation,
  showSnackbar,
  uploadDocument,
  downloadDocument,
  getFile,
  deleteDocument,
  addDoc,
}) => {
  const {
    id,
    payment_comment,
    isEntity,
    scholarship_academy_confirm_nature,
    state_code,
    student_account_number,
  } = scholarshipData;

  const isAdminReview = state_code === ScholarshipStateCode.ADMIN_REVIEW;
  const confirmationFormSchema = confirmationSchema(!isEntity && isNousCims && isAdminReview);

  const [adminReviewModal, setAdminReviewModal] = React.useState({
    open: false,
    loading: false,
    data: [] as AggreementDataItem[],
  });

  const [showSendInfoModal, setShowSendInfoModal] = useState<boolean>(false);
  const [showConfirmationInfoModal, setShowConfirmationInfoModal] = useState<boolean>(false);

  useEffect(() => {
    if (state_transition === 'to_admin_review' && isAdminReview) {
      setShowSendInfoModal(true);
    }
  }, [isAdminReview, state_transition]);

  const { control, errors, handleSubmit, clearError, setValue } = useForm<ScholarshipData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: confirmationFormSchema,
  });

  const saveConfirmationInfo = () => {
    clearError();
    saveData(scholarshipData);
  };

  const getConfirmationText = (): string => {
    return tScholarshipKey('confirmation.alert').replace('{XX}', total_confirmation.toLocaleString());
  };

  const sendAdminConfirmation = () => {
    setAdminReviewModal(prev => ({ ...prev, loading: true, open: true }));
    getScholarshipAgreementTemplateData(id)
      .then((res: AggreementDataItem[]) => setAdminReviewModal({ open: true, loading: false, data: res }))
      .catch(() => {
        setAdminReviewModal(prev => ({ ...prev, loading: false, open: true }));
        showSnackbar(tErrorKey('Ha habido un error'), 'error', undefined, 1500);
      });
  };

  const sendUserConfirmation = () => {
    if (isEntity) {
      sendConfirmation(id, 's06s10', '/becas-entidad', scholarshipData);
    } else {
      sendConfirmation(id, 'to_admin_review', '', scholarshipData);
    }
  };

  const sendConfirmationInfo = () => {
    clearError();
    if (isNousCims && isAdminReview) {
      sendAdminConfirmation();
    } else {
      setShowConfirmationInfoModal(true);
    }
  };

  buttonActionSave.hidden = readOnly;
  buttonActionSendConfirmation.hidden = readOnly || !can_confirm;

  useButtonAction(buttonActionSave, handleSubmit(saveConfirmationInfo));
  useButtonAction(buttonActionSendConfirmation, handleSubmit(sendConfirmationInfo));

  const renderTotalsConfirmation = (): JSX.Element => {
    return (
      <TotalsConfirmationForm
        scholarshipData={scholarshipData}
        readOnly={readOnly}
        errors={errors}
        schema={confirmationFormSchema}
        control={control}
      />
    );
  };

  const renderConfirmationStudies = (): JSX.Element => {
    return (
      <StudiesConfirmationForm
        scholarshipData={scholarshipData}
        readOnly={readOnly}
        errors={errors}
        schema={confirmationFormSchema}
        control={control}
        setValue={setValue}
      />
    );
  };

  const renderTransversalCompetences = (): JSX.Element | undefined => {
    if (isEntity && state_code === ScholarshipStateCode.APPROVED) {
      return <TransversalCompetencesSection isFinal={false} scholarshipData={scholarshipData} />;
    }
    return undefined;
  };

  const studentIBANDocument = (): DocumentationItem | undefined => {
    const instituteDocument = documentation.find(
      (document: DocumentationItem) => document.code === Documentation.CERTIFICADO_IBAN,
    );
    if (instituteDocument) {
      instituteDocument.mandatory = true;
      return instituteDocument;
    }
    return undefined;
  };

  const studyCenterDocument = (): DocumentationItem | undefined => {
    if (scholarship_academy_confirm_nature) {
      const studyCenterNature = new StudyCenterNature(scholarship_academy_confirm_nature);
      if (!studyCenterNature.isPublic()) {
        return documentation.find(
          (document: DocumentationItem) => document.code === Documentation.CERTIFICADO_IBAN_CENTRO,
        );
      }
    }
    return undefined;
  };

  const materialsDocument = (): DocumentationItem | undefined => {
    return documentation.find((document: DocumentationItem) => document.code === Documentation.CERTIFICADO_MATERIALES);
  };

  const documentationItems = (): DocumentationItem[] => {
    const documents = documentation.filter(
      (document: DocumentationItem) => document.code === Documentation.HOJA_DE_MATRICULA,
    );
    if (scholarshipData.materials_confirm > 0) {
      const materialsCert = materialsDocument();
      if (materialsCert) {
        documents.push(materialsCert);
      }
    }
    if (!isEntity) {
      const studyCenterIBAN = studyCenterDocument();
      if (studyCenterIBAN) {
        documents.push(studyCenterIBAN);
      }
      const studentIBAN = studentIBANDocument();
      if (studentIBAN) {
        documents.push(studentIBAN);
      }
    }
    return documents;
  };

  const renderStudentIBAN = (): JSX.Element | undefined => {
    if (!isEntity && state_code !== ScholarshipStateCode.APPROVED) {
      const disabled = !isAdminReview || !isNousCims;
      return (
        <FormContainer title={tScholarshipKey('student_account_number.title')}>
          <TextInputController
            control={control}
            errors={errors}
            schema={confirmationFormSchema}
            label={tScholarshipKey('student_account_number')}
            size="100"
            name="student_account_number"
            disabled={disabled}
            defaultValue={student_account_number}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('student_account_number', e.target.value)}
          />
        </FormContainer>
      );
    }
    return undefined;
  };

  const renderConfirmationDocuments = (): JSX.Element | undefined => {
    const documentationFiltered = documentationItems();
    if (documentationFiltered.length > 0) {
      return (
        <FncAddDocuments
          title={tScholarshipKey('Documentos confirmación')}
          documents={documentationFiltered}
          getFile={getFile}
          disabled={readOnly}
          upload={uploadDocument}
          genericId={scholarshipData.id}
          download={downloadDocument}
          remove={deleteDocument}
          addDoc={addDoc}
          required
          multidocument
        />
      );
    }
    return undefined;
  };

  const renderPaymentRemarks = (): JSX.Element => {
    return (
      <FormContainer
        title={tScholarshipKey('Observaciones de pago')}
        subtitle={tScholarshipKey('Si has modificado cualquier cifra justifícalo')}
      >
        <TextInputController
          control={control}
          errors={errors}
          schema={confirmationFormSchema}
          label={tScholarshipKey('Observaciones')}
          size="100"
          rows="8"
          rowsMax="8"
          multiline
          name="payment_comment"
          disabled={readOnly}
          defaultValue={payment_comment}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('payment_comment', e.target.value)}
        />
      </FormContainer>
    );
  };

  return (
    <div className={styles.root}>
      <Title>{tScholarshipKey('Confirmación')}</Title>
      <form className={styles.form}>
        {renderTotalsConfirmation()}
        {renderConfirmationStudies()}
        {renderTransversalCompetences()}
        {renderStudentIBAN()}
        {renderConfirmationDocuments()}
        {renderPaymentRemarks()}
      </form>
      <TransitionModal
        view={adminReviewModal.open}
        title={tScholarshipKey('Revisión campos de convenio')}
        buttonOk={tKey('Confirmar')}
        buttonKo={tKey('Cancelar')}
        buttonOkDisabled={adminReviewModal.data.some((item: AggreementDataItem) => !!item.error)}
        bodyModal={
          <div className={styles.adminReviewModalContainer}>
            {adminReviewModal.loading ? (
              <Loading big />
            ) : (
              <FormContainer title="">
                {adminReviewModal.data.map((e: AggreementDataItem, index: number) => (
                  <TextInput key={index} label={e.field} value={e.value} size="50" error={!!e.error} disabled />
                ))}
              </FormContainer>
            )}
          </div>
        }
        handleYes={() => {
          sendConfirmation(id, 'admin_review_confirmation', '', scholarshipData);
          setAdminReviewModal(prev => ({ ...prev, open: false }));
        }}
        handleNo={() => setAdminReviewModal(prev => ({ ...prev, open: false }))}
        handleClose={() => setAdminReviewModal(prev => ({ ...prev, open: false }))}
      />
      <TransitionModal
        view={showSendInfoModal}
        title={tScholarshipKey('sendInfo-title')}
        buttonOk={tKey('OK')}
        bodyModal={
          <div className={styles.adminReviewStudentInfoPopUp}>
            <p>{tScholarshipKey('sendInfo-p1')}</p>
            <p>{tScholarshipKey('sendInfo-p2')}</p>
          </div>
        }
        handleYes={() => setShowSendInfoModal(false)}
        handleClose={() => setShowSendInfoModal(false)}
      />
      <TransitionModal
        view={showConfirmationInfoModal}
        title={tScholarshipKey('Confirmación')}
        buttonOk={tScholarshipKey('confirmation.alert.ok')}
        buttonKo={tScholarshipKey('confirmation.alert.ko')}
        bodyModal={
          <div className={styles.adminReviewStudentInfoPopUp}>
            <p>{getConfirmationText()}</p>
          </div>
        }
        handleYes={() => {
          setShowConfirmationInfoModal(false);
          sendUserConfirmation();
        }}
        handleClose={() => setShowConfirmationInfoModal(false)}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  isNousCims: selectIsNousCims(state),
  state_transition: selectStateTransition(state),
  can_confirm: selectUserCan(state)('action_confirm_scholarship'),
  total_confirmation: selectTotalConfirmedScholarship(state),
  documentation: selectGetScholarshipDocumentationPerCode(state, [
    Documentation.HOJA_DE_MATRICULA,
    Documentation.CERTIFICADO_MATERIALES,
    Documentation.CERTIFICADO_IBAN,
    Documentation.CERTIFICADO_IBAN_CENTRO,
  ]),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetData(key, value)),
  saveData: (scholarshipData: ScholarshipData): void => dispatch(scholarshipSubmitInformation(scholarshipData)),
  sendConfirmation: (
    id: number,
    state: ScholarshipStateChange,
    redirect: string,
    scholarshipData?: ScholarshipData,
  ): void => dispatch(scholarshipStateTransitionAction(id, state, redirect, scholarshipData)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationForm);

import { InputAdornment } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import React, { Suspense, useState } from 'react';
import { connect } from 'react-redux';
import FncButton from '../../../components/FncButton/FncButton';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInput from '../../../components/Inputs/TextInput';
import { tFormKey, tKey } from '../../../helpers/translate';
import { addEntityRemainingAmountAction } from '../../../redux/entity/agreement/actions';
import { EntityAgreementDispatch } from '../../../redux/entity/agreement/definitions';
import { EntityAgreementData } from '../../../redux/entity/agreement/EntityAgreement';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import styles from './accountingData.module.scss';
import { getDocs } from '../../../helpers/getDocs';
import { getEntityScholarshipPaymentLogsFromApi } from '../../../api/Entity/entityAgreement';

const RemmantModal = React.lazy(() => import('./RemmantModal'));

interface AccountingDataRemainderProps {
  disabled: boolean;
  allowEntityManualRemnant: boolean;
  entityAgreementData: EntityAgreementData;
  addEntityRemainingAmount: (entity_id: number, academic_year: string, amount: number, comment: string) => void;
}

const renderRemainderElement = (name: string, value: number, label: string): JSX.Element => {
  return (
    <TextInput
      type="number"
      name={name}
      disabled
      fullWidth
      label={tFormKey(label)}
      value={value}
      InputProps={{
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
      }}
      placeholder="0"
    />
  );
};

const AccountingDataRemainder: React.FC<AccountingDataRemainderProps> = ({
  entityAgreementData,
  disabled,
  allowEntityManualRemnant,
  addEntityRemainingAmount,
}) => {
  const [remmantModal, setRemmantModal] = useState<boolean>(false);

  const { entity_id, academic_year, remainder, course_remainder } = entityAgreementData;

  const addEntityRemaining = async (amount: number, comment: string) => {
    addEntityRemainingAmount(entity_id, academic_year, amount, comment);
    setRemmantModal(false);
  };

  const onExportScholarshipPaymentsLogs = async () => {
    const csv = await getEntityScholarshipPaymentLogsFromApi(entity_id, academic_year);
    const fileName = `${entity_id}_importes_becas.xlsx`;
    getDocs(csv, true, fileName);
  };

  const renderCourseRemainder = (): JSX.Element => {
    return renderRemainderElement('course_remainder', course_remainder, 'Remanente generado');
  };

  const renderRemainder = (): JSX.Element => {
    return renderRemainderElement('remainder', remainder, 'Remanente pendiente');
  };

  const renderModifyRemainderButton = (): JSX.Element | undefined => {
    if (!allowEntityManualRemnant) {
      return undefined;
    }
    // De momento siempre disabled hasta que se decida cómo actuamos.
    // El remanente se genera al cerrar el curso, a partir de aquí no se debería poder modificar.
    return (
      <FncButton disabled={disabled || true} type="submit" color="primary">
        {tFormKey('Modificar remanente')}
      </FncButton>
    );
  };

  const renderRemainderHistoryButton = (): JSX.Element => {
    return (
      <FncButton
        customClass={styles.iconButton}
        onClick={() => onExportScholarshipPaymentsLogs()}
        type="submit"
        color="primary"
      >
        <HistoryIcon />
        <span>{tFormKey('Historial')}</span>
      </FncButton>
    );
  };

  return (
    <FormContainer className={`${styles.remainderForm} ${styles.sectionContainer}`} title={tKey('Saldo entidad')}>
      {renderCourseRemainder()}
      {renderRemainder()}
      <div className={`${styles.remainderFormActions} ${styles.buttonsWrapper}`}>
        {renderRemainderHistoryButton()}
        {renderModifyRemainderButton()}
      </div>
      <Suspense fallback={<></>}>
        <RemmantModal
          view={remmantModal}
          remainingAmount={remainder}
          onSubmit={addEntityRemaining}
          onClose={() => setRemmantModal(false)}
        />
      </Suspense>
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  allowEntityManualRemnant: selectUserCan(state)('allow_entity_manual_remnant'),
});

const mapDispatchToProps = (dispatch: EntityAgreementDispatch) => ({
  addEntityRemainingAmount: (entity_id: number, academic_year: string, amount: number, comment: string): void =>
    dispatch(addEntityRemainingAmountAction(entity_id, academic_year, amount, comment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDataRemainder);

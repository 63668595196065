import { PaymentId } from '../payments/Payment';

export interface ScholarshipPaymentAdjustmentItem {
  id: string;
  payment_id: PaymentId;
  scholarship_id: number;
  entity_id: number | null;
  payment_amount: number;
  payment_date: string;
  status: string;
  type: string;
  order: number;
  file_id: number;
  filename: string;
  remarks: string;
}

export interface ScholarshipPaymentAdjustmentData {
  scholarship_amount: number;
  total_amount: number;
  adjustment_amount: number;
  scholarship_payments: ScholarshipPaymentAdjustmentItem[];
  scholarship_adjustments: ScholarshipPaymentAdjustmentItem[];
}

export const ScholarshipPaymentAdjustmentInitialState = {
  loading: false as boolean,
  loadingAdjustments: false as boolean,
  error: null as string | null,
  scholarshipPaymentAdjustmentData: {
    scholarship_amount: 0.0,
    total_amount: 0.0,
    adjustment_amount: 0.0,
    scholarship_payments: [] as ScholarshipPaymentAdjustmentItem[],
    scholarship_adjustments: [] as ScholarshipPaymentAdjustmentItem[],
  } as ScholarshipPaymentAdjustmentData,
};

class ScholarshipPaymentType {
  static PAGO = 'Pago';
  static PAGO_BECADO = 'Pago becado';
  static AJUSTE = 'Ajuste';

  constructor(private type: string) {}

  public value(): string {
    return this.type;
  }

  public isPago(): boolean {
    return this.type === ScholarshipPaymentType.PAGO;
  }

  public isPagoBecado(): boolean {
    return this.type === ScholarshipPaymentType.PAGO_BECADO;
  }

  public isAjuste(): boolean {
    return this.type === ScholarshipPaymentType.AJUSTE;
  }

  public static pago(): ScholarshipPaymentType {
    return new ScholarshipPaymentType(ScholarshipPaymentType.PAGO);
  }

  public static pagoBecado(): ScholarshipPaymentType {
    return new ScholarshipPaymentType(ScholarshipPaymentType.PAGO_BECADO);
  }

  public static ajuste(): ScholarshipPaymentType {
    return new ScholarshipPaymentType(ScholarshipPaymentType.AJUSTE);
  }

  public static toArray(): string[] {
    return [ScholarshipPaymentType.PAGO, ScholarshipPaymentType.PAGO_BECADO, ScholarshipPaymentType.AJUSTE];
  }

  public static translationName(status: string): string {
    return `scholarship_payment_type.${status}`;
  }
}

export default ScholarshipPaymentType;

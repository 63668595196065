export enum EntityAgreementDataConsts {
  GET_ENTITY_AGREEMENT_ACADEMIC_YEARS = 'GET:ENTITY:AGREEMENT:ACADEMIC:YEARS',
  GET_ENTITY_AGREEMENT_ACADEMIC_YEARS_OK = 'GET:ENTITY:AGREEMENT:ACADEMIC:YEARS:OK',
  GET_ENTITY_AGREEMENT_ACADEMIC_YEARS_KO = 'GET:ENTITY:AGREEMENT:ACADEMIC:YEARS:KO',

  GET_ENTITY_AGREEMENT_DATA = 'GET:ENTITY:AGREEMENT:DATA',
  GET_ENTITY_AGREEMENT_DATA_OK = 'GET:ENTITY:AGREEMENT:DATA:OK',
  GET_ENTITY_AGREEMENT_DATA_KO = 'GET:ENTITY:AGREEMENT:DATA:KO',

  SEND_ENTITY_ADVANCE_PAYMENT = 'SEND:ENTITY:ADVANCE:PAYMENT',
  SEND_ENTITY_ADVANCE_PAYMENT_OK = 'SEND:ENTITY:ADVANCE:PAYMENT:OK',
  SEND_ENTITY_ADVANCE_PAYMENT_KO = 'SEND:ENTITY:ADVANCE:PAYMENT:KO',

  ADD_ENTITY_REMAINING_AMOUNT = 'ADD:ENTITY:REMAINING:AMOUNT',
  ADD_ENTITY_REMAINING_AMOUNT_OK = 'ADD:ENTITY:REMAINING:AMOUNT:OK',
  ADD_ENTITY_REMAINING_AMOUNT_KO = 'ADD:ENTITY:REMAINING:AMOUNT:KO',

  SEND_ENTITY_ADMIN_MANAGEMENT = 'SEND:ENTITY:ADMIN:MANAGEMENT',
  SEND_ENTITY_ADMIN_MANAGEMENT_OK = 'SEND:ENTITY:ADMIN:MANAGEMENT:OK',
  SEND_ENTITY_ADMIN_MANAGEMENT_KO = 'SEND:ENTITY:ADMIN:MANAGEMENT:KO',

  CANCEL_ENTITY_AGREEMENT = 'CANCEL:ENTITY:AGREEMENT',
  CANCEL_ENTITY_AGREEMENT_OK = 'CANCEL:ENTITY:AGREEMENT:OK',
  CANCEL_ENTITY_AGREEMENT_KO = 'CANCEL:ENTITY:AGREEMENT:KO',
}

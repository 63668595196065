import { ButtonProps } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { exportEntityScholarshipsPaymentsFromApi } from '../../../api/ScholarshipPayment/scholarshipPayment';
import { useButtonAction } from '../../../helpers/customHooks/useHookMethod';
import { getDocs } from '../../../helpers/getDocs';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { AppState } from '../../../redux/root-reducer';
import { getEntityScholarshipPaymentsAction } from '../../../redux/scholarship/payments/actions';
import { ScholarshipPaymentList } from '../../../redux/scholarship/payments/definitions';
import {
  selectEntityScholarshipPayments,
  selectScholarshipPaymentLoading,
  selectScholarshipPaymentReload,
  selectSelectedAcademicYear,
} from '../../../redux/scholarship/payments/selectors';
import PaymentStatus from '../../../types/Payments/PaymentStatus';
import ScholarshipsPaymentFilters from '../../Accounting/ScholarshipsPayment/ScholarshipsPaymentFilters';
import ScholarshipsPaymentList from '../../Accounting/ScholarshipsPayment/ScholarshipsPaymentList';

interface EntityScholarshipPaymentsProps {
  loading: boolean;
  reload: boolean;
  selectedAcademicYear: string;
  entity_id: number;
  scholarshipPaymentList: ScholarshipPaymentList;
  buttonActionExport: ButtonProps;
  getEntityScholarshipPayments: (academic_year: string, entity_id: number | null, status: string) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const EntityScholarshipPayments = ({
  loading,
  reload,
  selectedAcademicYear,
  entity_id,
  scholarshipPaymentList,
  buttonActionExport,
  getEntityScholarshipPayments,
}: EntityScholarshipPaymentsProps) => {
  useEffect(() => {
    if (selectedAcademicYear) {
      getEntityScholarshipPayments(selectedAcademicYear, entity_id, PaymentStatus.EMPTY_STATUS);
    }
  }, [selectedAcademicYear, getEntityScholarshipPayments, entity_id]);

  useEffect(() => {
    if (selectedAcademicYear && reload) {
      getEntityScholarshipPayments(selectedAcademicYear, entity_id, PaymentStatus.EMPTY_STATUS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const exportExcel = async () => {
    if (selectedAcademicYear) {
      const file = await exportEntityScholarshipsPaymentsFromApi(selectedAcademicYear, entity_id);
      const fileName = `pagos_entidad_becas_entidad_${selectedAcademicYear.replace('/', '_')}.xlsx`;
      getDocs(file, true, fileName);
    }
  };

  useButtonAction(buttonActionExport, exportExcel);

  return (
    <>
      <ScholarshipsPaymentFilters
        loading={loading}
        isEntity
        scholarshipPaymentListSummary={scholarshipPaymentList.payments_summary}
      />
      <ScholarshipsPaymentList
        loading={loading}
        isEntity
        title={'Pagos becas entidad'}
        payments={scholarshipPaymentList.payments}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedAcademicYear: selectSelectedAcademicYear(state),
  scholarshipPaymentList: selectEntityScholarshipPayments(state),
  loading: selectScholarshipPaymentLoading(state),
  reload: selectScholarshipPaymentReload(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getEntityScholarshipPayments: (academic_year: string, entity_id: number | null, status: string) =>
    dispatch(getEntityScholarshipPaymentsAction(academic_year, entity_id, status)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityScholarshipPayments);

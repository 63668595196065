import React from 'react';
import { connect } from 'react-redux';
import { tKey, tScholarshipKey } from '../../../../../helpers/translate';
import { AppState } from '../../../../../redux/root-reducer';
import { saveScholarshipPaymentAction } from '../../../../../redux/scholarship/paymentAdjustments/actions';
import { ScholarshipPaymentAdjustmentItem } from '../../../../../redux/scholarship/paymentAdjustments/PaymentAdjustment';
import { selectAdjustmentsLoading } from '../../../../../redux/scholarship/paymentAdjustments/selectors';
import ScholarshipAdjustmentRow from './ScholarshipAdjustmentRow';
import styles from './scholarshipAdjustmentsTable.module.scss';

interface ScholarshipAdjustmentsTableProps {
  loading: boolean;
  editable?: boolean;
  scholarshipAdjustments: ScholarshipPaymentAdjustmentItem[];
}

const ScholarshipAdjustmentsTable: React.FC<ScholarshipAdjustmentsTableProps> = ({
  loading,
  editable = false,
  scholarshipAdjustments,
}) => {
  const renderAdjustmentsPayments = () => {
    if (scholarshipAdjustments && scholarshipAdjustments.length > 0) {
      return scholarshipAdjustments.map((payment: ScholarshipPaymentAdjustmentItem) => {
        return (
          <ScholarshipAdjustmentRow key={payment.id} scholarshipPaymentJustification={payment} editable={editable} />
        );
      });
    }
    return (
      <tr className={styles.justificationTableRow}>
        <td colSpan={4} className={`${styles.cellRow} ${styles.emptyCellRow}`}>
          {loading ? tKey('Cargando...') : tKey('No hay ajustes')}
        </td>
      </tr>
    );
  };

  return (
    <div className={styles.tableContainer}>
      <table className={`${styles.customTable}  ${loading ? styles.customTableLoading : ''}`}>
        <thead className={styles.tableHeader}>
          <tr>
            <th className={styles.cellHeader}>{tScholarshipKey('Importe')}</th>
            <th className={styles.cellHeader}>{tScholarshipKey('Fecha')}</th>
            <th className={styles.cellHeader}>{tScholarshipKey('Comentario')}</th>
            <th className={styles.cellHeader}>{tScholarshipKey('Estado')}</th>
            {editable ? <th className={styles.cellHeader}></th> : null}
          </tr>
        </thead>
        <tbody>{renderAdjustmentsPayments()}</tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectAdjustmentsLoading(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  saveScholarshipPayments: (scholarship_id: number, scholarship_payments: ScholarshipPaymentAdjustmentItem[]): void =>
    dispatch(saveScholarshipPaymentAction(scholarship_id, scholarship_payments)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipAdjustmentsTable);

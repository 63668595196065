import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { EntityInfo } from '../../../redux/entity/definitions';
import AccountingDataByCourse from './AccountingDataByCourse';
import AccountingDataFNC from './AccountingDataFNC';
import styles from './accountingData.module.scss';

interface AccountingDataProps {
  entityData: EntityInfo;
  disabled: boolean;
  isNousCims: boolean;
  buttonActionSave: ButtonProps;
  buttonSageProvider: ButtonProps;
  buttonModifyAccounts: ButtonProps;
}

const AccountingData: React.FC<AccountingDataProps> = ({
  entityData,
  disabled = false,
  isNousCims,
  buttonActionSave,
  buttonSageProvider,
  buttonModifyAccounts,
}) => {
  const renderAccountingDataFNC = (): JSX.Element | undefined => {
    return isNousCims ? (
      <AccountingDataFNC
        entityData={entityData}
        disabled={disabled}
        buttonActionSave={buttonActionSave}
        buttonSageProvider={buttonSageProvider}
        buttonModifyAccounts={buttonModifyAccounts}
      />
    ) : (
      undefined
    );
  };

  const renderAccountingDataByCourse = (): JSX.Element | undefined => {
    return !!entityData.has_scholarship ? (
      <AccountingDataByCourse disabled={disabled} entityData={entityData} />
    ) : (
      undefined
    );
  };

  return (
    <section className={styles.container}>
      {renderAccountingDataFNC()}
      {renderAccountingDataByCourse()}
    </section>
  );
};

export default AccountingData;

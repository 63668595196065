/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './TableMessageArea.module.scss';

interface TableMessageAreaProps {
  error_message?: string;
  info_message?: string;
}

const TableMessageArea: React.FC<TableMessageAreaProps> = ({ info_message, error_message }) => {
  if (!error_message && !info_message) {
    return null;
  }

  const renderMessages = (): JSX.Element => {
    if (error_message) {
      return <span className={styles.tableErrorMessage}>{error_message}</span>;
    }
    return <span className={styles.tableInfoMessage}>{info_message}</span>;
  };

  return <div className={styles.tableMessageArea}>{renderMessages()}</div>;
};

export default TableMessageArea;

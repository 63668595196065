import * as yup from 'yup';
import { tErrorKey } from '../helpers/translate';

const requiredText = tErrorKey('required');
const requiredNumber = tErrorKey('numeric');
const nifNieValidation = tErrorKey('nifnieType');
const emailValidation = tErrorKey('email.format');
const passportValidation = tErrorKey('Pasaporte incorrecto');
const oneMillionMax = tErrorKey('max-millon');
const phoneMax15digits = tErrorKey('El teléfono debe tener máximo 15 dígitos');
const max_length = tErrorKey('max_length');
const text_max_length_255 = tErrorKey('No puedes superar los 255 caracteres');

const student_phone = {
  student_phone: yup.string().test('student_phone', tErrorKey(phoneMax15digits), val => {
    if (val.length > 0) return val.length <= 15;
    return true;
  }),
};

const student_phone2 = {
  student_phone2: yup.string().test('student_phone2', tErrorKey(phoneMax15digits), val => {
    if (val.length > 0) return val.length <= 15;
    return true;
  }),
};

const genericPersonalInformation = {
  student_name: yup.string(),
  student_surname: yup.string(),
  student_surname2: yup.string(),
  student_nationality: yup.string(),
  student_country_origin: yup.string(),
  student_email: yup.string().email(emailValidation),
  student_document_type: yup.string(),
  student_document_number: yup
    .string()
    .required(requiredText)
    .when(['student_document_type'], {
      is: student_document_type => student_document_type === 'DNI',
      then: yup.string().matches(/^(\d{8})([A-Z])$/, nifNieValidation),
    })
    .when(['student_document_type'], {
      is: student_document_type => student_document_type === 'NIE',
      then: yup.string().matches(/^[XYZ]\d{7,8}[A-Z]$/, nifNieValidation),
    })
    .when(['student_document_type'], {
      is: student_document_type => student_document_type === 'Pasaporte',
      then: yup.string().max(10, passportValidation),
    }),
  student_gender: yup.string(),
  /*Address*/
  student_zip_code: yup.string(),
  student_address_type: yup.string(),
  student_address: yup.string().min(3, tErrorKey('Este campo debe tener mínimo 3 caracteres')),
  student_province: yup.string(),
  student_flat: yup.string(),
  student_city: yup.string(),
  student_floor: yup.string().nullable(),
  student_room: yup.string().nullable(),
  student_staircase: yup.string().nullable(),
  student_country: yup.string(),
  ...student_phone,
  ...student_phone2,
  student_email2: yup
    .string()
    .email('Formato incorrecto')
    .nullable(),
  /*Redes*/
  student_instagram: yup.string().nullable(),
  student_linkedin: yup.string().nullable(),
  student_twitter: yup.string().nullable(),
  student_facebook: yup.string().nullable(),
  student_youtube: yup.string().nullable(),
  student_other_social_networks: yup.string().nullable(),
  renounce_comment: yup.string().nullable(),
};

export const entityPersonalInformation = yup.object().shape({
  ...genericPersonalInformation,
  entity_name: yup.string(),
  educator_name: yup.string(),
  educator_surname: yup.string(),
  educator_email: yup.string().email(emailValidation),
  educator_phone: yup.string().test('student_phone', phoneMax15digits, val => {
    if (val.length > 0) return val.length <= 15;
    return true;
  }),
});

export const test = yup.object().shape({
  size: yup.string().nullable(),
});

export const schoolPersonalInformation = (needs_tutor: boolean) =>
  yup.object().shape({
    ...genericPersonalInformation,
    student_document_expedition_place: yup
      .string()
      .max(60, tErrorKey('No puede superar los 60 caracteres'))
      .required(requiredText),
    last_scholarship_academy_id: yup.number(),
    finished_educational_type_id: yup.number(),
    tutor_name: yup.string().max(60, tErrorKey('No puede superar los 60 caracteres')),
    tutor_phone: yup.string().test('phone', phoneMax15digits, val => {
      if (val.length > 0) return val.length <= 15;
      return true;
    }),
    tutor_document_type: yup.string(),
    tutor_document_number: needs_tutor
      ? yup
          .string()
          .when(['tutor_document_type'], {
            is: tutor_document_type => tutor_document_type === 'DNI',
            then: yup.string().matches(/^(\d{8})([A-Z])$/, nifNieValidation),
          })
          .when(['tutor_document_type'], {
            is: tutor_document_type => tutor_document_type === 'NIE',
            then: yup.string().matches(/^[XYZ]\d{7,8}[A-Z]$/, nifNieValidation),
          })
          .when(['tutor_document_type'], {
            is: tutor_document_type => tutor_document_type === 'Pasaporte',
            then: yup.string().max(10, passportValidation),
          })
      : yup.string().max(35, max_length),
    tutor_email: yup.string().email(tErrorKey('Formato incorrecto')),
  });

export const schoolMotivationalValoration = yup.object().shape({
  motivational_letter_file_id: yup.number(),
  academy_recommendation_letter_file_id: yup.number(),
});
const isRequired = () => ({
  is: 1,
  then: yup.string().required(requiredText),
});
export const entityMotivationalValoration = yup.object().shape({
  educator_reason: yup.string(),
  linked_periods: yup.string(),
  linked_periods_comments: yup.string(),
  last_year_info: yup.string(),
  previous_year_highlights: yup.string(),
  has_academic_return: yup.number(),
  academic_return: yup.string().when('has_academic_return', isRequired()),
});

export const schoolCurrentSituation = yup.object().shape({
  current_situation_id: yup.number(),
});

export const schoolCurrentWorkingSituation = yup.object().shape({
  current_situation_id: yup.number(),
  company_name: yup.string().max(100, tErrorKey('Máximo 100 caracteres')),
  employee_role: yup.string().max(100, tErrorKey('Máximo 100 caracteres')),
  current_incomes: yup.string(),
  contract_type: yup.string(),
  salary_document_file_id: yup.mixed().when('is_working', isRequired()),
});

export const schoolEducationalValoration = yup.object().shape({
  educational_level_id: yup.string().required(requiredText),
  educational_type_id: yup.string().required(requiredText),
  professional_group_id: yup.string().required(requiredText),
  scholarship_course_id: yup.string().required(requiredText),
  scholarship_academy_id: yup.string(),
  scholarship_academy: yup.string(),
  other_reasons: yup.string(),
  follow_up_type: yup.string(),
  academic_report_file_id: yup.number(),
  parents_with_post_compulsory_studies: yup.string(),
});

export const studyCenterEducationalValoration = yup.object().shape({
  scholarship_academy_id: yup.string(),
  scholarship_academy: yup.string(),
});

export const schoolEconomicalValoration = yup.object().shape({
  number_people_coexists: yup.number(),
  legal_situation: yup.string(),
  extra_costs: yup.number(),
  extra_costs_resume: yup.string(),
  was_protected: yup.boolean(),
  is_protected: yup.boolean(),
  tax_declaration_file_id: yup.number(),
  complementary_documentation_file_id: yup.number(),
  economic_changes_comments: yup.string(),
  alternative_incomes_resume: yup.string(),
  father_incomes: yup.number().typeError(requiredNumber),
  mother_incomes: yup.number().typeError(requiredNumber),
  other_incomes: yup.number().typeError(requiredNumber),
  student_incomes: yup.number().typeError(requiredNumber),
  living_place_cost: yup.number().typeError(requiredNumber),
  has_dependent_children: yup.boolean(),
  has_disability: yup.boolean(),
  disability_percentage: yup.number().typeError(requiredNumber),
  disability_details: yup.string().max(255, text_max_length_255),
  has_special_needs: yup.boolean(),
  special_needs_details: yup.string().max(255, text_max_length_255),
});

export const entityEconomicalValoration = yup.object().shape({
  number_people_coexists: yup.number(),
  legal_situation: yup.string(),
  was_protected: yup.boolean(),
  family_info: yup.string(),
  economic_changes_comments: yup.string(),
  alternative_incomes_resume: yup.string(),
});

const followSchemaGeneric = {
  scholarship_abandoment_reasons_others: yup
    .string()
    .when('scholarship_abandoment_reasons_id', {
      is: scholarship_abandoment_reasons_id => +scholarship_abandoment_reasons_id === 9,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  average_grade: yup
    .number()
    .typeError(requiredNumber)
    .required(requiredText)
    .max(10, tErrorKey('La nota no puede ser superior a 10'))
    .min(0, tErrorKey('La nota no pude ser inferior a 0')),
  number_failed_subject: yup
    .number()
    .typeError(requiredNumber)
    .required(requiredText),
  number_approved_subject: yup
    .number()
    .typeError(requiredNumber)
    .required(requiredText),
  number_subject_studying: yup
    .number()
    .test('total', tErrorKey('total-aprobadas-suspendidas'), function() {
      return (
        this.parent.number_failed_subject + this.parent.number_approved_subject === this.parent.number_subject_studying
      );
    })
    .typeError(requiredNumber),
  has_work_quaterly: yup.string().required(requiredText),
  has_work_relation_study: yup
    .string()
    .when('has_work_quaterly', {
      is: has_work_quaterly => !!+has_work_quaterly,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  has_quaterly_internship: yup.string().required(requiredText),
};

const followSchemaGenericEntityInstitut = {
  ...followSchemaGeneric,
  keep_studying: yup.string().required(requiredText),
  scholarship_abandoment_reasons_id: yup
    .string()
    .when('keep_studying', {
      is: keep_studying => !+keep_studying,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  significant_situation_change: yup.string().required(requiredText),
  scholarship_change_reasons_id: yup
    .string()
    .when('significant_situation_change', {
      is: significant_situation_change => !!+significant_situation_change,
      then: yup.string().required(requiredText),
    })
    .nullable(),

  support_for_approved: yup.string().required(requiredText),
  scholarship_supports_id: yup
    .string()
    .when('support_for_approved', {
      is: support_for_approved => !!+support_for_approved,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  scholarship_supports_reason_others: yup
    .string()
    .when('scholarship_supports_id', {
      is: scholarship_supports_id => +scholarship_supports_id === 4,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  scholarship_results_id: yup
    .string()
    .when('has_scholarship_state', {
      is: has_scholarship_state => !!+has_scholarship_state,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  state_scholarship_amount: yup
    .number()
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v))
    .when('scholarship_results_id', {
      is: scholarship_results_id => +scholarship_results_id === 1,
      then: yup.number().required(requiredText),
    }),
};

export const followSchemaEntity = yup.object().shape({
  ...followSchemaGenericEntityInstitut,
  enrollment_justify: yup.number().typeError(requiredNumber),
  monthly_price_justify: yup.number().typeError(requiredNumber),
  transport_price_justify: yup.number().typeError(requiredNumber),
  materials_justify: yup.number().typeError(requiredNumber),
  other_costs_justify: yup.number().typeError(requiredNumber),
  adjustment_amount_justify: yup.number().typeError(requiredNumber),
  young_contrib_justify: yup.number().typeError(requiredNumber),
  mec_justify: yup.number().typeError(requiredNumber),
  enrollment_confirm: yup.number().typeError(requiredNumber),
  monthly_price_confirm: yup.number().typeError(requiredNumber),
  materials_confirm: yup.number().typeError(requiredNumber),
  transport_price_confirm: yup.number().typeError(requiredNumber),
  other_costs_confirm: yup.number().typeError(requiredNumber),
  scholarship_student_meetings_id: yup.string().required(requiredText),
  scholarship_student_meetings_justify: yup
    .string()
    .when('scholarship_student_meetings_id', {
      is: scholarship_student_meetings_id => +scholarship_student_meetings_id === 5,
      then: yup.string().required(requiredText),
    })
    .nullable(),
});

export const followSchemaInstitut = yup.object().shape({
  ...followSchemaGenericEntityInstitut,
  has_mentor: yup.string().required(requiredText),
  scholarship_valorations_id: yup
    .string()
    .when('has_mentor', {
      is: has_mentor => !!+has_mentor,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  scholarship_mentor_supports_id: yup
    .string()
    .when('has_mentor', {
      is: has_mentor => !!+has_mentor,
      then: yup.string().required(requiredText),
    })
    .nullable(),
});

const followSchemaGenericT3 = {
  ...followSchemaGeneric,
  scholarship_future_actions_id: yup.string().required(requiredText),
  scholarship_abandoment_reasons_id: yup
    .string()
    .when('scholarship_future_actions_id', {
      is: scholarship_future_actions_id => +scholarship_future_actions_id === 4,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  scholarship_student_changes_id: yup.string().required(requiredText),
  scholarship_student_personal_changes_reason_others: yup
    .string()
    .when('scholarship_student_changes_id', {
      is: scholarship_student_changes_id => +scholarship_student_changes_id === 5,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  per_scholarship_academic_levels_id: yup.string().required(requiredText),
  scholarship_academic_levels_id: yup.string().required(requiredText),
  scholarship_academy_supports_id: yup.string().required(requiredText),
  scholarship_opportunities_id: yup.string().required(requiredText),
  special_academic_center: yup.string().required(requiredText),
  special_academic_center_reasons: yup
    .string()
    .when('special_academic_center', {
      is: special_academic_center => !+special_academic_center,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  academic_center_social_risk: yup.string().required(requiredText),
  academic_center_social_risk_reasons: yup
    .string()
    .when('academic_center_social_risk', {
      is: academic_center_social_risk => !+academic_center_social_risk,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  scholarship_personal_skills_id: yup.string().required(requiredText),
  fnc_scholarship_valorations_id: yup.string().required(requiredText),
  scholarship_valorations_reasons: yup.string().required(requiredText),
  fnc_sup_scholarship_valorations_id: yup.string().required(requiredText),
  valoration_nouscims_support_reason: yup.string().required(requiredText),
  end_scholarship_future_actions_id: yup.string().required(requiredText),
  scholarship_personal_skills_reason_others: yup
    .string()
    .when('scholarship_personal_skills_id', {
      is: scholarship_personal_skills_id => +scholarship_personal_skills_id === 9,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  recommend_academic_center: yup.string().required(requiredText),
  recommend_scholarship: yup.string().required(requiredText),
};

export const followSchemaEntityInstitutT3 = yup.object().shape(followSchemaGenericT3);

export const scholarshipCostSchema = yup.object().shape({
  enrollment_cost: yup
    .number()
    .typeError(requiredNumber)
    .max(999999.99, oneMillionMax),
  monthly_enrollment_cost: yup
    .number()
    .typeError(requiredNumber)
    .max(999999.99, oneMillionMax),
  transportation_id: yup.mixed(),
  transportation_cost: yup
    .number()
    .typeError(requiredNumber)
    .max(999999.99, oneMillionMax),
  other_costs_amount: yup
    .number()
    .typeError(requiredNumber)
    .max(999999.99, oneMillionMax),
  other_costs: yup.string(),
  total_cost: yup.number(),
  able_to_pay: yup
    .number()
    .typeError(requiredNumber)
    .max(999999.99, oneMillionMax),
  how_to_pay: yup.string(),
  is_renewal: yup.boolean(),
  scholarship_resolution_file_id: yup.number(),
});

export const schoolSendData = yup.object().shape({
  privacy_policy: yup.number().required(requiredText),
  send_information: yup.number().required(requiredText),
  image_use: yup.number().required(requiredText),
  whatsapp_aggregation: yup.number().required(),
});

export const instituteScholarshipPaymentSchema = yup.object().shape({
  agreement_number: yup.string(),
  provider_code: yup.string().nullable(),
  payable_account: yup.string().nullable(),
  expense_account: yup.string().nullable(),
  center_cost_area_code: yup.string().required(requiredText),
  center_cost_channel_code: yup.string().required(requiredText),
  center_cost_delegation_code: yup.string().required(requiredText),
  center_cost_project_code: yup.string().nullable(),
  concept: yup.string().max(255, text_max_length_255),
  description: yup.string().max(255, text_max_length_255),
  account_number: yup.string(),
  student_account_number: yup.string(),
});

export const RechargeScholarshipSchema = yup.object().shape({
  card_number: yup
    .number()
    .typeError(requiredNumber)
    .required(requiredText),

  provider_code: yup.string().required(requiredText),
  name: yup.string().required(requiredText),
  amount: yup
    .number()
    .typeError(tErrorKey(requiredNumber))
    .required(requiredText),
  payment_date: yup.string().required(requiredText),
});

export const intituteSchema = [
  schoolPersonalInformation,
  schoolMotivationalValoration,
  schoolCurrentSituation,
  schoolEducationalValoration,
  schoolEconomicalValoration,
  scholarshipCostSchema,
  schoolSendData,
];

export const entitySchema = [
  entityPersonalInformation,
  entityMotivationalValoration,
  schoolCurrentSituation,
  schoolEducationalValoration,
  entityEconomicalValoration,
  scholarshipCostSchema,
  schoolSendData,
];

const scholarshipPaymentSchema = {
  scholarship_amount: yup.number(),
  total_amount: yup.number(),
  adjustment_amount: yup.number(),
};

export const ScholarshipPaymentSchema = yup.object().shape(scholarshipPaymentSchema);

// ***********************

export const checkValidation = async (schema: yup.ObjectSchema<yup.Shape<object, any>>, values: object) => {
  return schema.isValid(values).then((valid: boolean) => {
    return valid;
  });
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Title from '../../../components/Title/Title';
import { tKey } from '../../../helpers/translate';
import { EntityInfo } from '../../../redux/entity/definitions';
import { AppState } from '../../../redux/root-reducer';
import Loading from '../../../components/Loading/Loading';
import { getEntityAgreementDataAction } from '../../../redux/entity/agreement/actions';
import { EntityAgreementDispatch } from '../../../redux/entity/agreement/definitions';
import { EntityAgreementData } from '../../../redux/entity/agreement/EntityAgreement';
import {
  selectEntityAgreementAcademicYears,
  selectEntityAgreementData,
  selectEntityAgreementIsLoading,
} from '../../../redux/entity/agreement/selectors';
import styles from './accountingData.module.scss';
import AccountingDataCourseSelector from './AccountingDataCourseSelector';
import AccountingDataPaymentSettings from './AccountingDataPaymentSettings';
import AccountingDataRemainder from './AccountingDataRemainder';
import AccountingDataSummary from './AccountingDataSummary';
import AccountingDataPaymentAdvance from './AccountingDataPaymentAdvance';
import AccountingDataAdminManagement from './AccountingDataAdminManagement';
import AccountingDataAgreementDocument from './AccountingDataAgreementDocument';
import { selectIsNousCims } from '../../../redux/auth/selectors';
import AgreementState from '../../../types/Agreements/AgreementState';
import SignatureDocuments from '../../../components/SignatureDocuments/SignatureDocuments';

interface AccountingDataByCourseProps {
  entityData: EntityInfo;
  entityAgreementData: EntityAgreementData;
  loading: boolean;
  disabled: boolean;
  isNousCims: boolean;
  getEntityAgreementData: (entity_id: number, academic_year: string) => void;
}

const AccountingDataByCourse: React.FC<AccountingDataByCourseProps> = ({
  entityData,
  entityAgreementData,
  loading,
  disabled = false,
  isNousCims,
  getEntityAgreementData,
}) => {
  const [academicYear, setAcademicYear] = useState<string | undefined>(undefined);
  const { id, joined_49_2020 } = entityData;

  useEffect(() => {
    if (academicYear) {
      getEntityAgreementData(id, academicYear);
    }
  }, [academicYear, id, getEntityAgreementData]);

  const renderSignatureDocuments = (): JSX.Element | undefined => {
    const { id, agreement_state } = entityAgreementData;
    const agreemetnState = new AgreementState(agreement_state);
    if (isNousCims && agreemetnState.isConfirm()) {
      return <SignatureDocuments title={'Addendas convenio'} type={'entity_agreements'} type_id={id} />;
    }
    return undefined;
  };

  const renderPaymentSettings = (): JSX.Element | undefined => {
    return isNousCims ? (
      <AccountingDataPaymentSettings entityAgreementData={entityAgreementData} disabled={disabled} />
    ) : (
      undefined
    );
  };

  const renderAgreementDocument = (): JSX.Element | undefined => {
    return isNousCims ? (
      <AccountingDataAgreementDocument entityAgreementData={entityAgreementData} disabled={disabled} />
    ) : (
      undefined
    );
  };

  const renderPaymentAdvance = (): JSX.Element | undefined => {
    return isNousCims ? (
      <AccountingDataPaymentAdvance entityAgreementData={entityAgreementData} disabled={disabled} />
    ) : (
      undefined
    );
  };

  const renderAdminManagement = (): JSX.Element | undefined => {
    return joined_49_2020 ? (
      <AccountingDataAdminManagement entityAgreementData={entityAgreementData} disabled={disabled} />
    ) : (
      undefined
    );
  };

  return (
    <>
      <Title>{tKey('Area contable')}</Title>
      <div className={styles.accounting_form}>
        <AccountingDataCourseSelector entity_id={entityData.id} selectedAcademicYear={setAcademicYear} />
        {loading ? (
          <Loading big />
        ) : (
          <>
            {renderPaymentSettings()}
            {renderAgreementDocument()}
            <AccountingDataSummary entityAgreementData={entityAgreementData} />
            {renderPaymentAdvance()}
            <AccountingDataRemainder entityAgreementData={entityAgreementData} disabled={disabled} />
            {renderAdminManagement()}
            {renderSignatureDocuments()}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  isNousCims: selectIsNousCims(state),
  academicYears: selectEntityAgreementAcademicYears(state),
  loading: selectEntityAgreementIsLoading(state),
  entityAgreementData: selectEntityAgreementData(state),
});

const mapDispatchToProps = (dispatch: EntityAgreementDispatch) => ({
  getEntityAgreementData: (entity_id: number, academic_year: string): void =>
    dispatch(getEntityAgreementDataAction(entity_id, academic_year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDataByCourse);

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { connect } from 'react-redux';
import FormContainer from '../../../../components/Forms/FormContainer';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { tScholarshipKey } from '../../../../helpers/translate';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { AppState } from '../../../../redux/root-reducer';
import { downloadDocument, getDocument, removeDocument, uploadDocument } from '../../../../redux/scholarship/actions';
import { ScholarshipAgreementData } from '../../../../redux/scholarship/agreement/ScholarshipAgreement';
import { selectGetScholarshipDocumentationPerCode } from '../../../../redux/scholarship/selectors';
import Documentation from '../../../../types/Scholarships/Documentation';

interface PaymentFormIrpfProps {
  scholarshipAgreementData: ScholarshipAgreementData;
  documentation: DocumentationItem[];
  getDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  uploadDocument: (documentation_id: number, data: File, scholarship_id: number, multiDocument?: boolean) => void;
  removeDocument: (documentation_id: number, scholarship_id: number) => void;
}

const PaymentFormIrpf: React.FC<PaymentFormIrpfProps> = ({
  scholarshipAgreementData,
  documentation,
  getDocument,
  downloadDocument,
  uploadDocument,
  removeDocument,
}) => {
  const { scholarship_id } = scholarshipAgreementData;

  return (
    <FormContainer title={tScholarshipKey('Repositorio IRPF')}>
      <FncAddDocuments
        documents={documentation}
        actions={['upload', 'download', 'view', 'remove']}
        originalName
        getFile={getDocument}
        download={downloadDocument}
        upload={uploadDocument}
        remove={removeDocument}
        genericId={scholarship_id}
      />
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  documentation: selectGetScholarshipDocumentationPerCode(state, [Documentation.IRPF]),
});

const mapDispatchToProps = (dispatch: any) => ({
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) =>
    dispatch(downloadDocument(documentation_id, name, scholarship_id)),
  getDocument: (documentation_id: number, name: string, scholarship_id: number) =>
    dispatch(getDocument(documentation_id, name, scholarship_id)),
  uploadDocument: (documentation_id: number, data: File, scholarship_id: number, multiDocument?: boolean) =>
    dispatch(uploadDocument(documentation_id, data, scholarship_id, multiDocument)),
  removeDocument: (documentation_id: number, scholarship_id: number) =>
    dispatch(removeDocument(documentation_id, scholarship_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFormIrpf);

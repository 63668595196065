import { ButtonProps, MenuItem } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { APIValidationError } from '../../../api/api';
import { createTaxRetentions, getOneTaxRetention, updateTaxRetentions } from '../../../api/TaxRetention/taxretention';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import Title from '../../../components/Title/Title';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { TaxRetention } from '../../../redux/common/definitions';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { OperationKey, TaxType, TransactionCode } from '../../../redux/metadata/definitions';
import { selectTaxTypes } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import { TaxRetentionSchema } from '../../../validations/formSchema';
import AccountingSubmenu from '../../Accounting/AccountingSubmenu';
import { getAccountingMetadataAction } from '../../../redux/metadata/actions';

interface EditRetentions extends RouteComponentProps<{ id: string }> {
  create: boolean;
  taxTypes: TaxType[];
  transactionCodes: TransactionCode[];
  operation_keys: OperationKey[];
  getAccountingMetadata: () => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const RetentionsForm: React.FC<EditRetentions> = ({
  match,
  taxTypes,
  transactionCodes,
  operation_keys,
  getAccountingMetadata,
  showSnackbar,
}) => {
  const { handleSubmit, errors, control, setValue, setError } = useForm<TaxRetention>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: TaxRetentionSchema,
  });
  const {
    params: { id },
  } = match;

  const [list, setList] = useState<TaxRetention>();
  const [loading, setLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState({} as APIValidationError);

  useValidatorAPI(validationErrors, setError);

  useEffect(() => {
    getOneTaxRetention(parseFloat(id)).then(taxRetentions => {
      setList(taxRetentions);
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    getAccountingMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = () => (id === '0' ? tKey('Creación de impuesto') : tKey('Edición de impuesto'));

  const onSave = async (data: TaxRetention) => {
    try {
      if (id === '0') {
        const result = await createTaxRetentions(data);
        switch (result.type) {
          case 'ok':
            showSnackbar(tKey('Formulario creado correctamente'), 'success', '/contabilidad/impuestos', 1000);
            return;
          case 'validation-error':
            const error = result.value;
            setValidationErrors(error);
            return;
        }
      } else {
        await updateTaxRetentions(data);
      }
      showSnackbar(tKey('Impuesto actualizado correctamente'), 'success', '/contabilidad/impuestos', 1000);
    } catch (e) {
      showSnackbar(tErrorKey('Error al actualizar el formulario'), 'error', undefined, 1000);
    }
  };

  const buttonsEdit: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: handleSubmit(onSave),
      startIcon: <Save />,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
    },
  ];

  const handleTransactionCode = (e: any) => {
    const result = transactionCodes.find((tc: TransactionCode) => tc.code === e.target.value);
    if (result) setValue('op_key', result.default_op_key);
  };

  if (loading) return <Loading big />;

  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu actionsButtons={buttonsEdit} />}
      leftSubmenu={<AccountingSubmenu selected="retentions" />}
    >
      <Title>{title()}</Title>
      <FormContainer title="">
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={list?.id}
          schema={TaxRetentionSchema}
          label={tFormKey('Id')}
          name="id"
          size="50"
          disabled={true}
          visible={false}
        />
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={list?.code}
          schema={TaxRetentionSchema}
          label={tFormKey('Código')}
          name="code"
          size="50"
        />
        <SelectController
          size="50"
          control={control}
          errors={errors}
          schema={TaxRetentionSchema}
          defaultValue={list?.tax_type}
          name="tax_type"
          label={tFormKey('Tipo de impuesto')}
        >
          {taxTypes.map((type: any) => (
            <MenuItem key={type.code} value={type.code}>
              {type.name}
            </MenuItem>
          ))}
        </SelectController>
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={list?.name}
          schema={TaxRetentionSchema}
          label={tFormKey('Nombre')}
          name="name"
          size="50"
        />
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={list?.percentage}
          schema={TaxRetentionSchema}
          label={tFormKey('Porcentaje')}
          name="percentage"
          size="50"
        />
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={list?.payment_account}
          schema={TaxRetentionSchema}
          label={tFormKey('Cuenta')}
          name="payment_account"
          size="50"
        />
        <SelectController
          name="transaction_code"
          schema={TaxRetentionSchema}
          control={control}
          label={tFormKey('Código de transacción')}
          errors={errors}
          fullWidth
          defaultValue={list?.transaction_code}
          size="50"
          onClick={e => {
            handleTransactionCode(e);
          }}
        >
          {transactionCodes.map(item => (
            <MenuItem key={item.id} value={item.code}>
              {item.code} - {item.name}
            </MenuItem>
          ))}
        </SelectController>
        <SelectController
          name="op_key"
          schema={TaxRetentionSchema}
          control={control}
          label={tFormKey('Clave de operación')}
          errors={errors}
          fullWidth
          defaultValue={list?.op_key}
          value={list?.op_key}
          size="50"
          disabled
        >
          {operation_keys.map(item => {
            return (
              <MenuItem key={item.id} value={item.op_key}>
                {item.op_key} - {item.description}
              </MenuItem>
            );
          })}
        </SelectController>
      </FormContainer>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  taxTypes: selectTaxTypes(state),
  transactionCodes: state.metadataReducer.accounting.transaction_codes,
  operation_keys: state.metadataReducer.accounting.operation_keys,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAccountingMetadata: (): void => dispatch(getAccountingMetadataAction()),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RetentionsForm);

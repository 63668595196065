import { MaterialTableProps } from 'material-table';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import { dbDateToDate } from '../../../../helpers/dateHelper';
import { formatNumber } from '../../../../helpers/formatNumber';
import { tFormKey, tKey, tScholarshipKey } from '../../../../helpers/translate';
import { AppState } from '../../../../redux/root-reducer';
import { getScholarshipPaymentAdjustmentAction } from '../../../../redux/scholarship/paymentAdjustments/actions';
import {
  selectScholarshipAmount,
  selectScholarshipPaymentAdjustmentData,
  selectScholarshipTotalAdjustments,
  selectTotalScholarship,
} from '../../../../redux/scholarship/paymentAdjustments/selectors';
import { ScholarshipPaymentListItem } from '../../../../redux/scholarship/payments/definitions';
import styles from './institutePaymentsModal.module.scss';
import { instituteScholarshipPaymentPayFromApi } from '../../../../api/ScholarshipPayment/scholarshipPayment';
import errorMessage from '../../../../helpers/errorMessage';
import FncLabel from '../../../../components/Forms/FncLabel/FncLabel';

interface InstitutePaymentsModalProps {
  show: boolean;
  scholarshipPayments: ScholarshipPaymentListItem[];
  handleClose: () => void;
  handlePaymentsDone: () => void;
}

export type InfoMessage = {
  type: 'error' | 'info';
  content: string;
};

const InstitutePaymentsModal: React.FC<InstitutePaymentsModalProps> = ({
  show,
  scholarshipPayments,
  handleClose,
  handlePaymentsDone,
}) => {
  const [exit, setExit] = useState<boolean>(false);
  const [infoMessages, setInfoMessages] = useState<InfoMessage[]>([]);
  const tableData: MaterialTableProps<ScholarshipPaymentListItem> = {
    columns: [
      {
        field: 'student_name',
        title: tKey('Joven'),
        //@ts-ignore
        width: '180px',
        maxWidth: '180px',
      },
      {
        field: 'payment_date',
        title: tKey('Fecha'),
        headerStyle: { textAlign: 'center' },
        render: (rowData: ScholarshipPaymentListItem) => <div>{dbDateToDate(rowData.payment_date)}</div>,
        //@ts-ignore
        width: '120px',
      },
      {
        field: 'payment_amount',
        title: tFormKey('Importe'),
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'right' },
        render: (rowData: ScholarshipPaymentListItem) => (
          <div>{formatNumber({ value: rowData.payment_amount }) + ' €'}</div>
        ),
        //@ts-ignore
        width: '100px',
      },
      {
        field: 'account_number',
        title: tKey('IBAN'),
        headerStyle: { width: '180px', maxWidth: '180px' },
        //@ts-ignore
        width: '220px',
      },
    ],
    options: {
      tableLayout: 'auto',
      paging: false,
      showTextRowsSelected: false,
      showTitle: false,
      maxBodyHeight: '400px',
    },
    data: scholarshipPayments,
  };

  const titleText = (): string => {
    const paymentsCount = scholarshipPayments.length;
    return `${paymentsCount} ${
      paymentsCount === 1 ? tScholarshipKey('Pago beca instituto') : tScholarshipKey('Pagos becas instituto')
    }`;
  };

  const sendPayment = async (payment: ScholarshipPaymentListItem) => {
    const message = `Pago a: ${payment.student_name} con importe: ${payment.payment_amount} €`;
    try {
      await instituteScholarshipPaymentPayFromApi(payment.scholarship_payment_id, payment.account_number);
      const messageOk = `${message}: realizado correctamente`;
      setInfoMessages(prevMessages => [...prevMessages, { type: 'info', content: messageOk }]);
    } catch (error) {
      const messageKo = `${message} con error: ${errorMessage(error)}`;
      setInfoMessages(prevMessages => [...prevMessages, { type: 'error', content: messageKo }]);
    }
  };

  const sendSage = async (): Promise<void> => {
    await Promise.all(scholarshipPayments.map((payment: ScholarshipPaymentListItem) => sendPayment(payment)));
    setExit(true);
  };

  const showMessages = (): JSX.Element => {
    const ulName = 'result_list';
    const messages = infoMessages.map((infoMessage: InfoMessage, index) => (
      <li key={index} className={`${styles.list_item} ${styles['list_'.concat(infoMessage.type)]}`}>
        {infoMessage.content}
      </li>
    ));
    return (
      <div className={styles.message_area}>
        <FncLabel className={styles.message_label} htmlFor={ulName}>
          {tKey('Resultado')}
        </FncLabel>
        <ul id={ulName} className={styles.message_list}>
          {messages}
        </ul>
      </div>
    );
  };
  return (
    <TransitionModal
      view={show}
      handleClose={handleClose}
      title={titleText()}
      buttonOk={exit ? tKey('Salir') : tKey('Enviar a SAGE')}
      buttonKo={tKey('Cancelar')}
      handleYes={exit ? handlePaymentsDone : sendSage}
      handleNo={handleClose}
    >
      <div className={styles.modal_content}>
        <TableWithEdit
          noSearch
          customContainer
          tableData={{ ...tableData }}
          key={JSON.stringify(tableData.data)}
          permission={true}
        />
        {showMessages()}
      </div>
    </TransitionModal>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipAmount: selectScholarshipAmount(state),
  totalAdjustments: selectScholarshipTotalAdjustments(state),
  totalScholarship: selectTotalScholarship(state),
  paymentAdjustmentData: selectScholarshipPaymentAdjustmentData(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getScholarshipPaymentData: (scholarshipId: number): void =>
    dispatch(getScholarshipPaymentAdjustmentAction(scholarshipId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstitutePaymentsModal);

import React from 'react';
import { connect } from 'react-redux';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import { tKey } from '../../../../helpers/translate';
import { AppState } from '../../../../redux/root-reducer';
import { replaceScholarshipPaymentDatesAction } from '../../../../redux/scholarship/payments/actions';
import { selectSelectedAcademicYear } from '../../../../redux/scholarship/payments/selectors';
import { ScholarshipPaymentReplaceDates } from '../hooks/useScholarshipPaymentReplaceDates';
import { PaymentDatesReplace } from '../../../../redux/scholarship/payments/Payment';
import * as DateConstants from '../../../../constants/date';

interface ReplaceDatesModalProps {
  show: boolean;
  is_entity: boolean;
  entity_id?: number | null;
  selectedAcademicYear: string | null;
  replaceDates: ScholarshipPaymentReplaceDates;
  replaceScholarshipPaymentDates: (paymentDates: PaymentDatesReplace) => void;
  closeReplaceModal: () => void;
}

const ReplaceDatesModal: React.FC<ReplaceDatesModalProps> = ({
  show,
  is_entity,
  entity_id,
  replaceDates,
  selectedAcademicYear,
  replaceScholarshipPaymentDates,
  closeReplaceModal,
}) => {
  const findAndReplace = async () => {
    const replaceData = {
      academic_year: selectedAcademicYear,
      is_entity,
      date_search: replaceDates.find?.format('YYYY-MM-DD'),
      date_replace: replaceDates.replace?.format('YYYY-MM-DD'),
    } as PaymentDatesReplace;
    if (is_entity) {
      replaceData.entity_id = entity_id || null;
    }

    replaceScholarshipPaymentDates(replaceData);
  };

  const createMessage = (): string => {
    return `Se van a modificar los pagos con fecha: ${replaceDates.find?.format(
      DateConstants.DATE_FORMAT,
    )} a fecha: ${replaceDates.replace?.format(DateConstants.DATE_FORMAT)}. ¿Estaś seguro/a?`;
  };

  return (
    <TransitionModal
      documentMode
      view={show}
      handleClose={() => {
        closeReplaceModal();
      }}
      title={tKey('Actualización de fechas de pago')}
      bodyModal={<div style={{ padding: '20px' }}>{createMessage()}</div>}
      buttonOk={tKey('Sí')}
      buttonKo={tKey('No')}
      handleYes={() => {
        findAndReplace();
        closeReplaceModal();
      }}
      handleNo={() => {
        closeReplaceModal();
      }}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedAcademicYear: selectSelectedAcademicYear(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  replaceScholarshipPaymentDates: (paymentDates: PaymentDatesReplace): void =>
    dispatch(replaceScholarshipPaymentDatesAction(paymentDates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReplaceDatesModal);

import { useState } from 'react';
import { ChangeScholarshipPaymentValues } from '../../../../redux/scholarship/definitions';

type UseScholarshipChangePaymentType = {
  showPaymentModal: boolean;
  changePaymentDateData: ChangeScholarshipPaymentValues;
  closePaymentModal: () => void;
  setChangePaymentInfo: (scholarshipPayment: any) => void;
  setChangePaymentDateData: (scholarshipPayment: any) => void;
};

const useScholarshipChangePayment = (): UseScholarshipChangePaymentType => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [changePaymentDateData, setChangePaymentDateData] = useState({} as ChangeScholarshipPaymentValues);

  const setChangePaymentInfo = (scholarshipPayment: any) => {
    setChangePaymentDateData({
      payment_amount: scholarshipPayment.payment_amount,
      payment_date: scholarshipPayment.payment_date,
    } as ChangeScholarshipPaymentValues);
    setShowPaymentModal(true);
  };

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };

  return {
    showPaymentModal,
    changePaymentDateData,
    closePaymentModal,
    setChangePaymentInfo,
    setChangePaymentDateData,
  };
};

export default useScholarshipChangePayment;

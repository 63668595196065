import { ScholarshipAgreementData } from '../../redux/scholarship/agreement/ScholarshipAgreement';
import { apiBaseUrl, apiCall, APIValidationError, Result, validatedApiCall } from '../api';

const baseURL = apiBaseUrl;

export const getScholarshipAgreementFromApi = async (scholarhip_id: number): Promise<ScholarshipAgreementData> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: ScholarshipAgreementData }>(
    `${baseURL}/scholarship_agreements/scholarship/${scholarhip_id}`,
    init,
  );
  return data;
};

export const updateScholarshipAgreementFromApi = async (
  scholarshipAgreementData: ScholarshipAgreementData,
): Promise<Result<{ data: string }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(scholarshipAgreementData),
  };

  return await validatedApiCall<{ data: string }>(`${baseURL}/scholarship_agreements/scholarship`, init);
};

export const sendStudentProviderInfoFromApi = async (
  scholarshipAgreementData: ScholarshipAgreementData,
): Promise<ScholarshipAgreementData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(scholarshipAgreementData),
  };

  const { data } = await apiCall<{ data: ScholarshipAgreementData }>(
    `${baseURL}/scholarship_agreements/scholarship/provider`,
    init,
  );
  return data;
};

export const cancelScholarshipAgreementFromApi = async (signature_id: string): Promise<void> => {
  const init = {
    method: 'POST',
    auth: true,
  };
  await apiCall<{}>(`${baseURL}/scholarship_agreements/signatures/${signature_id}/cancel`, init);
};

import { put, takeLatest } from 'redux-saga/effects';
import { Await } from '../../../api/api';
import {
  cancelScholarshipAgreementFromApi,
  getScholarshipAgreementFromApi,
  sendStudentProviderInfoFromApi,
  updateScholarshipAgreementFromApi,
} from '../../../api/Scholarship/scholarshipAgreement';
import assertUnreachable from '../../../helpers/assertUnreachable';
import errorMessage from '../../../helpers/errorMessage';
import { showSnackbar } from '../../FeedbackAPI/actions';
import { ScholarshipAgreementDataConsts } from './action_types';
import {
  cancelScholarshipAgreementKoAction,
  cancelScholarshipAgreementOkAction,
  getScholarshipAgreementKoAction,
  getScholarshipAgreementOkAction,
  scholarshipAgreementValidationErrorsAction,
  sendStudentProviderInfoKoAction,
  sendStudentProviderInfoOkAction,
  updateScholarshipAgreementKoAction,
  updateScholarshipAgreementOkAction,
} from './actions';
import {
  CancelScholarshipAgreementAction,
  GetScholarshipAgreementAction,
  SendStudentProviderInfoAction,
  UpdateScholarshipAgreementAction,
} from './definitions';
import AgreementState from '../../../types/Agreements/AgreementState';
import { scholarshipGetOk } from '../actions';
import { getMetadata, getOneScholarship } from '../../../api/Scholarship/scholarship';

function* getScholarshipAgreementFromSaga(action: GetScholarshipAgreementAction): Generator<any, void, any> {
  try {
    const { scholarship_id } = action;
    const scholarshipAgreementData = yield getScholarshipAgreementFromApi(scholarship_id);
    yield put(getScholarshipAgreementOkAction(scholarshipAgreementData));
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(showSnackbar(error.message, 'error'));
    yield put(getScholarshipAgreementKoAction(errorMessage(e)));
  }
}

function* updateScholarshipAgreementFromSaga(action: UpdateScholarshipAgreementAction): Generator<any, void, any> {
  try {
    const { scholarshipAgreementData } = action;
    const result = (yield updateScholarshipAgreementFromApi(scholarshipAgreementData)) as Await<
      ReturnType<typeof updateScholarshipAgreementFromApi>
    >;
    switch (result.type) {
      case 'ok':
        yield put(updateScholarshipAgreementOkAction(scholarshipAgreementData));
        yield put(showSnackbar('Los datos se han guardado correctamente', 'success'));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(scholarshipAgreementValidationErrorsAction(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(updateScholarshipAgreementKoAction(errorMessage(e)));
  }
}

function* sendStudentProviderInfoFromSaga(action: SendStudentProviderInfoAction): Generator<any, void, any> {
  try {
    const { scholarshipAgreementData } = action;
    const scholarshipProviderData = yield sendStudentProviderInfoFromApi(scholarshipAgreementData);
    yield put(sendStudentProviderInfoOkAction(scholarshipProviderData));
    yield put(showSnackbar('Los datos se han enviado correctamente', 'success'));
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(showSnackbar(error.message, 'error'));
    yield put(sendStudentProviderInfoKoAction(errorMessage(e)));
  }
}

function* cancelScholarshipAgreementFromSaga(action: CancelScholarshipAgreementAction): Generator<any, void, any> {
  try {
    const { scholarship_id, signature_id } = action;
    yield cancelScholarshipAgreementFromApi(signature_id);
    yield put(cancelScholarshipAgreementOkAction(AgreementState.SIGN_CANCELED));
    yield put(showSnackbar('Se ha enviado la cancelación correctamente', 'success'));
    const { data: metaData } = yield getMetadata();
    const { data } = yield getOneScholarship(scholarship_id);
    yield put(scholarshipGetOk(data, metaData));
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(showSnackbar(error.message, 'error'));
    yield put(cancelScholarshipAgreementKoAction(errorMessage(e)));
  }
}

export default [
  takeLatest<GetScholarshipAgreementAction>(
    ScholarshipAgreementDataConsts.GET_SCHOLARSHIP_AGREEMENT,
    getScholarshipAgreementFromSaga,
  ),
  takeLatest<UpdateScholarshipAgreementAction>(
    ScholarshipAgreementDataConsts.UPDATE_SCHOLARSHIP_AGREEMENT,
    updateScholarshipAgreementFromSaga,
  ),
  takeLatest<SendStudentProviderInfoAction>(
    ScholarshipAgreementDataConsts.SEND_STUDENT_PROVIDER_INFO,
    sendStudentProviderInfoFromSaga,
  ),
  takeLatest<CancelScholarshipAgreementAction>(
    ScholarshipAgreementDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT,
    cancelScholarshipAgreementFromSaga,
  ),
];

export enum ScholarshipPaymentsConst {
  GET_INSTITUTE_SCHOLARSHIP_PAYMENTS = 'GET:INSTITUTE:SCHOLARSHIP:PAYMENTS',
  GET_INSTITUTE_SCHOLARSHIP_PAYMENTS_OK = 'GET:INSTITUTE:SCHOLARSHIP:PAYMENTS:OK',
  GET_INSTITUTE_SCHOLARSHIP_PAYMENTS_KO = 'GET:INSTITUTE:SCHOLARSHIP:PAYMENTS:KO',

  GET_ENTITY_SCHOLARSHIP_PAYMENTS = 'GET:ENTITY:SCHOLARSHIP:PAYMENTS',
  GET_ENTITY_SCHOLARSHIP_PAYMENTS_OK = 'GET:ENTITY:SCHOLARSHIP:PAYMENTS:OK',
  GET_ENTITY_SCHOLARSHIP_PAYMENTS_KO = 'GET:ENTITY:SCHOLARSHIP:PAYMENTS:KO',

  SET_PAYMENTS_SELECTED_ACADEMIC_YEAR = 'SET:PAYMENTS:SELECTED:ACADEMIC:YEAR',
  SET_PAYMENTS_SELECTED_STATUS = 'SET:PAYMENTS:SELECTED:STATUS',
  SET_PAYMENTS_SELECTED_ENTITY_ID = 'SET:PAYMENTS:SELECTED:ENTITY:ID',

  CHANGE_SCHOLARSHIP_PAYMENT_DATE = 'CHANGE:SCHOLARSHIP:PAYMENT:DATE',
  CHANGE_SCHOLARSHIP_PAYMENT_DATE_OK = 'CHANGE:SCHOLARSHIP:PAYMENT:DATE:OK',
  CHANGE_SCHOLARSHIP_PAYMENT_DATE_KO = 'CHANGE:SCHOLARSHIP:PAYMENT:DATE:KO',

  REPLACE_SCHOLARSHIPS_PAYMENT_DATES = 'REPLACE:SCHOLARSHIPS:PAYMENT:DATES',
  REPLACE_SCHOLARSHIPS_PAYMENT_DATES_OK = 'REPLACE:SCHOLARSHIPS:PAYMENT:DATES:OK',
  REPLACE_SCHOLARSHIPS_PAYMENT_DATES_KO = 'REPLACE:SCHOLARSHIPS:PAYMENT:DATES:KO',

  ENTITY_SCHOLARSHIPS_PAYMENT_SEND_SAGE = 'ENTITY:SCHOLARSHIPS:PAYMENT:SEND:SAGE',
  ENTITY_SCHOLARSHIPS_PAYMENT_SEND_SAGE_OK = 'ENTITY:SCHOLARSHIPS:PAYMENT:SEND:SAGE:OK',
  ENTITY_SCHOLARSHIPS_PAYMENT_SEND_SAGE_KO = 'ENTITY:SCHOLARSHIPS:PAYMENT:SEND:SAGE:KO',

  SET_SCHOLARSHIPS_PAYMENTS_RELOAD = 'SET:SCHOLARSHIPS:PAYMENTS:RELOAD',
}

import moment from 'moment';
import React, { Suspense, useState } from 'react';
import Send from '@material-ui/icons/Send';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { checkAgreementAdvancePaymentFromApi } from '../../../api/Entity/entityAgreement';
import KeyboardDatePickerController from '../../../components/DatePicker/KeyboardDatePickerController';
import FncButton from '../../../components/FncButton/FncButton';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import { DATE_FORMAT, DATE_FORMAT_DB } from '../../../constants/date';
import errorMessage from '../../../helpers/errorMessage';
import { tFormKey, tKey } from '../../../helpers/translate';
import { sendEntityAdvancePaymentAction } from '../../../redux/entity/agreement/actions';
import { EntityAgreementData } from '../../../redux/entity/agreement/EntityAgreement';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { PaymentInfo } from '../../../redux/project/definitions';
import { AppState } from '../../../redux/root-reducer';
import styles from './accountingData.module.scss';
import { dbDateToDate } from '../../../helpers/dateHelper';

const PaymentModal = React.lazy(() => import('../../Project/Modal/PaymentModal/PaymentModal'));

export const AdvancePaymentSchema = yup.object().shape({
  advance_payment: yup
    .number()
    .typeError('Se debe introducir un número superior a 0')
    .min(1, 'El valor debe ser superior a 0')
    .required('Se debe introudcir un valor')
    .nullable(),
  advance_payment_date: yup
    .string()
    .required('Se debe introducir una fecha')
    .nullable(),
});

interface AccountingDataPaymentAdvanceProps {
  entityAgreementData: EntityAgreementData;
  disabled: boolean;
  allowScholarshipPayments: boolean;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
  sendEntityAdvancePaymentAction: (
    entity_id: number,
    academic_year: string,
    payment_date: string,
    payment_amount: number,
    account_number: string,
  ) => void;
}

const AccountingDataPaymentAdvance: React.FC<AccountingDataPaymentAdvanceProps> = ({
  entityAgreementData,
  disabled,
  allowScholarshipPayments,
  showSnackbar,
  sendEntityAdvancePaymentAction,
}) => {
  const [payModal, setPayModal] = useState({ view: false, modal: {} as PaymentInfo });

  const {
    advance_payment,
    advance_payment_date,
    advance_payment_remainder,
    entity_id,
    academic_year,
  } = entityAgreementData;

  const isDisabled = disabled || !allowScholarshipPayments || !!advance_payment_date;

  const { handleSubmit, errors, control } = useForm<EntityAgreementData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: AdvancePaymentSchema,
  });

  const onSubmit = async (formData: EntityAgreementData) => {
    try {
      if (formData.advance_payment && formData.advance_payment_date) {
        const payment_date = moment(formData.advance_payment_date).format(DATE_FORMAT_DB);
        const paymentsData = await checkAgreementAdvancePaymentFromApi(
          entity_id,
          academic_year,
          formData.advance_payment,
          payment_date,
        );
        setPayModal({ view: true, modal: paymentsData });
      }
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const paySage = async (data: PaymentInfo) => {
    const { amount, payment_date, account_number } = data;
    sendEntityAdvancePaymentAction(entity_id, academic_year, payment_date, amount, account_number);
    setPayModal(prevModal => ({ ...prevModal, view: false }));
  };

  const renderAdvancedPayment = (): JSX.Element => {
    return (
      <TextInputController
        schema={AdvancePaymentSchema}
        type="number"
        label={tFormKey('Importe')}
        name={'advance_payment'}
        errors={errors}
        control={control}
        defaultValue={advance_payment}
        placeholder="0"
        disabled={isDisabled}
        price
      />
    );
  };

  const renderAdvancedPaymentDateTextInput = (): JSX.Element => {
    return (
      <TextInputController
        schema={AdvancePaymentSchema}
        label={tFormKey('Fecha de anticipo')}
        name={'advance_payment_date'}
        errors={errors}
        control={control}
        defaultValue={dbDateToDate(advance_payment_date || '')}
        disabled
      />
    );
  };

  const renderAdvancedPaymentDate = (): JSX.Element => {
    return (
      <KeyboardDatePickerController
        name="advance_payment_date"
        variant="inline"
        inputVariant="outlined"
        label={tFormKey('Fecha de anticipo')}
        format={DATE_FORMAT}
        placeholder="" // Prevent google to detect this input as a credit card number
        control={control}
        schema={AdvancePaymentSchema}
        inputValue={advance_payment_date || undefined}
        errors={errors}
        disabled={disabled}
        autoOk
      />
    );
  };

  const renderAdvancedPaymentRemainder = (): JSX.Element => {
    return (
      <TextInputController
        schema={AdvancePaymentSchema}
        type="number"
        label={tFormKey('Importe pendiente')}
        name={'advance_payment_remainder'}
        errors={errors}
        control={control}
        defaultValue={advance_payment_remainder}
        disabled
        price
      />
    );
  };

  const renderSubmitButton = (): JSX.Element | undefined => {
    if (isDisabled) {
      return undefined;
    }
    return (
      <div className={styles.buttonsWrapper}>
        <FncButton customClass={styles.iconButton} type="submit" color="primary">
          <Send />
          <span>{tFormKey('Enviar anticipo')}</span>
        </FncButton>
      </div>
    );
  };

  return (
    <FormContainer title={tKey('Anticipo de pago')}>
      <form className={`${styles.paymentAdvanceForm} ${styles.sectionContainer}`} onSubmit={handleSubmit(onSubmit)}>
        {renderAdvancedPayment()}
        {!!advance_payment_date ? renderAdvancedPaymentDateTextInput() : renderAdvancedPaymentDate()}
        {!!advance_payment_date ? renderAdvancedPaymentRemainder() : renderSubmitButton()}
      </form>
      <Suspense fallback={<></>}>
        <PaymentModal
          view={payModal.view}
          onClose={() => setPayModal(prevModal => ({ ...prevModal, view: false }))}
          onSubmit={paySage}
          inputsData={payModal.modal}
        />
      </Suspense>
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  allowScholarshipPayments: selectUserCan(state)('allow_scholarship_payments'),
});

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
  sendEntityAdvancePaymentAction: (
    entity_id: number,
    academic_year: string,
    payment_date: string,
    payment_amount: number,
    account_number: string,
  ): void =>
    dispatch(sendEntityAdvancePaymentAction(entity_id, academic_year, payment_date, payment_amount, account_number)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDataPaymentAdvance);

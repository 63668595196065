import { AppState } from '../../root-reducer';
import { ScholarshipAgreementDataState } from './definitions';
import { ScholarshipAgreementData } from './ScholarshipAgreement';

export const selectScholarshipAgreement = (state: AppState): ScholarshipAgreementDataState =>
  state.scholarshipAgreementReducer;
export const selectScholarshipAgreementData = (state: AppState): ScholarshipAgreementData =>
  selectScholarshipAgreement(state).agreementData;
export const selectScholarshipAgreementLoading = (state: AppState): boolean =>
  selectScholarshipAgreement(state).loading;
export const selectScholarshipProviderDataLoading = (state: AppState): boolean =>
  selectScholarshipAgreement(state).providerLoading;

import { ButtonProps } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import * as H from 'history';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../../components/Loading/Loading';
import Modal from '../../../../components/Modal/Modal';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import TableEditableRow, { ColumnTableProps } from '../../../../components/Tables/TableEditableRow/TableEditableRow';
import Title from '../../../../components/Title/Title';
import { tKey } from '../../../../helpers/translate';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { AppState } from '../../../../redux/root-reducer';
import {
  acceptUserEducator,
  downloadDocument,
  getDocument,
  rejectUserEducator,
  removeDocument,
  uploadDocument,
} from '../../../../redux/user/actions';
import { ScholarshipUsers, UserData, UserDataDispatch } from '../../../../redux/user/definitions';
import { selectError, selectUserValidationErrors } from '../../../../redux/user/selectors';
import SubmenuStudent from '../../SubmenuStudent/SubmenuStudent';
import ModalTool from './ModalTool';
import styles from './scholarshipList.module.scss';

interface LOPDInfoProps {
  readOnly: boolean;
  avatar: string;
  changeAvatar: boolean;
  loading: boolean;
  documents: DocumentationItem[];
  location: H.Location<{ needsValidation: boolean }>;
  profileData: UserData;
  acceptEducator: (id: number) => void;
  rejectEducator: (id: number) => void;
  uploadUserDocument: (document_id: number, data: File) => void;
  downloadUserDocument: (file_id: number, name: string) => void;
  removeUserDocument: (file_id: number) => void;
  getUserDocument: (file_id: number, name: string) => void;
  handleSetImageById: (image: string) => void;
  onSelectComponent: (componentId: number) => void;
}

const LOPDInfo: React.FC<LOPDInfoProps> = ({
  readOnly,
  avatar,
  profileData,
  loading,
  location,
  changeAvatar,
  handleSetImageById,
  onSelectComponent,
}) => {
  const createData = (data: any) =>
    data.map((element: any, index: number) => {
      return {
        ...element,
        id: index,
        isEditMode: false,
      };
    });

  const [data, setData] = useState(createData(profileData.scholarships));

  const [createdModal, setCreatedModal] = React.useState({
    view: false,
    children: <Fragment />,
    title: 'Ayuda',
    body: <div className={styles.modalHelp}>{tKey('mensaje usario')}</div>,
    startIcon: <HelpOutline />,
  });

  const [modal, setModal] = useState({
    view: false,
    detail: {} as ScholarshipUsers,
  });

  const moreInfoButtons: ButtonProps[] = [
    {
      children: tKey('AYUDA'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      onClick: () =>
        setCreatedModal(prevState => ({
          ...prevState,
          view: true,
        })),
      startIcon: <HelpOutline />,
      hidden: location.state.needsValidation && profileData.state !== 'DISABLED' ? false : true,
    },
  ];

  const options = {
    filter: true,
    tableLayout: 'auto',
  };

  const columns = [
    {
      title: tKey('Curso'),
      name: 'course',
      editable: 'never',
      align: 'center',
    },
    {
      title: tKey('Tipo de estudios'),
      name: 'academic_course',
      align: 'center',
      editable: 'never',
    },
    {
      title: tKey('Categoría de curso'),
      name: 'course_category',
      align: 'center',
      editable: 'never',
    },
    {
      title: tKey('Familia profesional'),
      align: 'left',
      name: 'profesional_family',
      editable: 'never',
    },
    {
      title: tKey('Nombre de estudios'),
      name: 'studies_name',
      align: 'left',
      editable: 'never',
    },
    {
      title: tKey('Centro'),
      name: 'center',
      align: 'left',
      editable: 'never',
    },
    {
      title: tKey('Valoración Nous Cims'),
      align: 'center',
      name: 'valoracion',
      editable: 'never',
      type: 'Numerico',
    },
    {
      title: tKey('Estado'),
      align: 'center',
      name: 'state',
      editable: 'never',
    },
    {
      title: tKey('Importes confirmados'),
      align: 'center',
      type: 'price',
      editable: 'never',
      name: 'total_confirm',
      color: row => (row.payment_total_done ? 'green' : undefined),
      modal: [
        {
          title: tKey('Total matriculación'),
          type: 'price',
          name: 'enrollment_confirm',
        },
        {
          title: tKey('Total materiales'),
          type: 'price',
          name: 'materials_confirm',
        },
        {
          type: 'price',
          title: tKey('Transportes'),
          name: 'transport_price_confirm',
        },
        {
          title: tKey('Otros importes'),
          type: 'price',
          name: 'other_costs_confirm',
        },
        {
          title: tKey('Aportación'),
          type: 'price',
          name: 'young_contrib_approval',
        },
        {
          title: tKey('Fecha 1'),
          name: 'payment_first_date',
          type: 'large',
        },
        {
          title: tKey('Pago 1'),
          name: 'payment_first',
        },
        {
          title: tKey('Fecha 2'),
          name: 'payment_second_date',
        },
        {
          title: tKey('Pago 2'),
          name: 'payment_second',
        },
        {
          title: tKey('Fecha 3'),
          name: 'payment_third_date',
        },
        {
          title: tKey('Pago 3'),
          name: 'payment_third',
        },
        {
          title: tKey('Fecha 4'),
          name: 'payment_fourth_date',
        },
        {
          title: tKey('Pago 4'),
          name: 'payment_fourth',
        },
      ],
    },
    {
      title: tKey('Número de convenio'),
      align: 'center',
      name: 'agreement_number',
      editable: 'never',
    },
  ] as ColumnTableProps[];

  const title = readOnly ? tKey('DETALLES  DE USUARIO') : tKey('EDICIÓN DE USUARIO');

  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu moreInfoButtons={moreInfoButtons} />}
      leftSubmenu={
        <SubmenuStudent
          selected="scholarship_resume"
          avatar={avatar}
          loading={loading}
          onSelectComponent={onSelectComponent}
          setImage={changeAvatar ? handleSetImageById : undefined}
        />
      }
    >
      <React.Fragment>
        {!loading ? (
          <div className={styles.list}>
            <Title>{title}</Title>
            <TableEditableRow
              columns={columns}
              rows={data}
              setRows={setData}
              title={tKey('Lista de becas')}
              option={options}
              exportExcel
              onRowClick={row => setModal({ detail: row, view: true })}
            />
          </div>
        ) : (
          <Loading big />
        )}
      </React.Fragment>
      <TransitionModal
        view={createdModal.view}
        handleClose={() => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
        }}
        title={createdModal.title}
        maxWidth={true}
        bodyModal={createdModal.body}
      >
        {createdModal.children}
      </TransitionModal>
      <Modal open={modal.view} onClose={() => setModal(prev => ({ ...prev, view: false }))} buttons={[]} title="Zinger">
        <ModalTool detail={modal.detail} />
      </Modal>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  error: selectError(state),
  validationErrors: selectUserValidationErrors(state),
});

const mapDispatchToProps = (dispatch: UserDataDispatch) => ({
  uploadUserDocument: (document_id: number, data: File): void => dispatch(uploadDocument(document_id, data)),
  downloadUserDocument: (file_id: number, name: string): void => dispatch(downloadDocument(file_id, name)),
  removeUserDocument: (file_id: number): void => dispatch(removeDocument(file_id)),
  getUserDocument: (file_id: number, name: string): void => dispatch(getDocument(file_id, name)),
  acceptEducator: (id: number): void => dispatch(acceptUserEducator(id)),
  rejectEducator: (id: number): void => dispatch(rejectUserEducator(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LOPDInfo);

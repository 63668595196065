import assertUnreachable from '../../../helpers/assertUnreachable';
import { ScholarshipAgreementDataConsts } from './action_types';
import { ScholarshipAgreementDataAction, ScholarshipAgreementDataState } from './definitions';
import { ScholarshipAgreementInitialState } from './ScholarshipAgreement';

const scholarshipAgreementReducer = (
  state = ScholarshipAgreementInitialState,
  action: ScholarshipAgreementDataAction,
): ScholarshipAgreementDataState => {
  switch (action.type) {
    case ScholarshipAgreementDataConsts.GET_SCHOLARSHIP_AGREEMENT:
      return {
        ...state,
        loading: true,
        error: null,
        validationErrors: null,
      };
    case ScholarshipAgreementDataConsts.SEND_STUDENT_PROVIDER_INFO:
      return {
        ...state,
        providerLoading: true,
        error: null,
        validationErrors: null,
      };
    case ScholarshipAgreementDataConsts.UPDATE_SCHOLARSHIP_AGREEMENT:
    case ScholarshipAgreementDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT:
      return {
        ...state,
        error: null,
        validationErrors: null,
      };
    case ScholarshipAgreementDataConsts.GET_SCHOLARSHIP_AGREEMENT_OK:
    case ScholarshipAgreementDataConsts.UPDATE_SCHOLARSHIP_AGREEMENT_OK:
      return {
        ...state,
        loading: false,
        error: null,
        validationErrors: null,
        agreementData: action.scholarshipAgreementData,
      };
    case ScholarshipAgreementDataConsts.SEND_STUDENT_PROVIDER_INFO_OK:
      const {
        scholarshipProviderData: { provider_code, payable_account, expense_account },
      } = action;

      return {
        ...state,
        providerLoading: false,
        agreementData: {
          ...state.agreementData,
          provider_code,
          payable_account,
          expense_account,
        },
      };
    case ScholarshipAgreementDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT_OK:
      const { agreement_state } = action;

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          agreement_state,
        },
      };
    case ScholarshipAgreementDataConsts.SCHOLARSHIP_AGREEMENT_SET_DATA:
      const { key, value } = action;

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          [key]: value,
        },
      };
    case ScholarshipAgreementDataConsts.SCHOLARSHIP_AGREEMENT_VALIDATION_ERRORS:
      const { validationErrors } = action;
      return {
        ...state,
        validationErrors,
      };
    case ScholarshipAgreementDataConsts.GET_SCHOLARSHIP_AGREEMENT_KO:
    case ScholarshipAgreementDataConsts.UPDATE_SCHOLARSHIP_AGREEMENT_KO:
    case ScholarshipAgreementDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT_KO:
    case ScholarshipAgreementDataConsts.SEND_STUDENT_PROVIDER_INFO_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        providerLoading: false,
        validationErrors: null,
        error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default scholarshipAgreementReducer;

import { ButtonProps, ThemeProvider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import * as DateConstants from '../../../constants/date';
import { tableTheme } from '../../../ui/muiCustomTheme';
import Loading from '../../Loading/Loading';
import ActionButtonsTab from './ActionButtonsTab';
import CustomTableCell from './CustomTableCell';
import FilterRow from './FilterRow';
import { getComparator, searchValue, stableSort } from './logic';
import ModalTool from './ModalTool';
import Pagination from './Pagination';
import SortTableHead from './TableHeadSortLabel';
import ToolBar from './ToolBar';

interface OptionsTable {
  style?: {
    maxHeight?: string;
  };
  filter?: boolean;
  fixedColumn?: {
    num?: number;
    left?: number;
  };
  stickyHeader?: boolean;
}

export interface ColumnTableProps {
  title: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  name: string;
  editable?: 'never' | string;
  modal?: { title: string; name: string }[];
  type?: string;
  cb?: () => void;
  disabled?: boolean | ((data: any) => boolean);
  color?: string | ((data: any) => string);
  hiddenFilter?: boolean;
  options: any[];
}

export interface ButtonsProps {
  title: string;
  label?: string;
  onClick: () => void;
  icon?: JSX.Element;
}

export type TableActions = {
  title: string;
  icon: JSX.Element;
  onClick: (row: any) => void;
  disabled?: boolean;
  hidden?: boolean;
};

interface CanaryTableProps {
  isLoading?: boolean;
  columns: ColumnTableProps[];
  rows: any[];
  title: string;
  buttons?: ButtonProps[];
  disabled?: boolean;
  option?: OptionsTable;
  actions?: TableActions[];
  projectID?: number;
  handleOpenModal?: boolean;
  exportExcel?: boolean;
  isTableWithIdGenerated?: boolean;
  onEditBulk?: boolean;
  disableToggle?: boolean;
  onRowClick?: (row: any) => void;
  handleClose?: () => void;
  handleDeleteClick?: (row: any) => void;
  handleEditClick?: (row: any) => void;
  handleDuplicateClick?: (row: any) => void;
  onPage?: (page: number, rowsPerPage: number) => void;
  setRows: (data: any) => void;
}

const CanaryTable: React.FC<CanaryTableProps> = ({
  isLoading,
  columns,
  rows,
  title,
  disabled,
  option,
  buttons,
  actions,
  projectID,
  handleOpenModal,
  exportExcel,
  isTableWithIdGenerated,
  onEditBulk,
  disableToggle,
  onPage,
  handleDeleteClick,
  handleEditClick,
  handleDuplicateClick,
  setRows,
  onRowClick,
  handleClose,
}) => {
  const [numPages, setNumpages] = React.useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState<string | string[]>('');
  const [value, setValue] = React.useState<string>('');
  const [filter, setFilter] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [modal, setModal] = React.useState({ view: false, modal: [] });
  useEffect(() => {
    setNumpages(rows.length);
  }, [rows]);

  const onToggleEditMode = (rowi: any) => {
    if (isTableWithIdGenerated) {
      setRows(() => {
        return rows.map(row => {
          if (rowi.id === null && rowi.id_generated) {
            if (rowi.id_generated === row.id_generated) {
              return { ...row, isEditMode: true };
            } else {
              return { ...row, isEditMode: false };
            }
          } else {
            if (row.id === rowi.id && !row.isEditMode) {
              return { ...row, isEditMode: !row.isEditMode };
            }
            if (row.id !== rowi.id && row.isEditMode) {
              return { ...row, isEditMode: !row.isEditMode };
            }
          }
          return row;
        });
      });
    } else {
      setRows(() => {
        return rows.map(row => {
          if (row.id === rowi.id && !row.isEditMode) {
            return { ...row, isEditMode: !row.isEditMode };
          }
          if (row.id !== rowi.id && row.isEditMode) {
            return { ...row, isEditMode: !row.isEditMode };
          }
          return row;
        });
      });
    }
  };

  const onChange = (e: any, row: any, cb?: any) => {
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    if (cb) {
      cb({ value, name, row, rows });
    }
    if (isTableWithIdGenerated) {
      const newRows = rows.map(rowI => {
        if (row.id >= 0 && row.id !== null) {
          if (rowI.id === row.id) {
            return { ...rowI, [name]: value };
          }
          return rowI;
        } else if (row.id === null && rowI.id_generated >= 0) {
          if (rowI.id_generated === row.id_generated) {
            return { ...rowI, [name]: value };
          }
          return rowI;
        } else {
          return rowI;
        }
      });
      setRows(newRows);
    } else {
      const newRows = rows.map(rowI => {
        if (rowI.id === id) {
          return { ...rowI, [name]: value };
        }
        return rowI;
      });
      setRows(newRows);
    }
  };
  const onChangeDate = (value: any, row: any, name: any, cb?: any) => {
    const { id } = row;
    if (cb) {
      const date = +moment.utc(value, DateConstants.DATE_FORMAT).format('X');
      cb({ date, name, row, rows });
    }
    const newRowsDate = rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRowsDate);
  };
  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilter = (filters: any) => {
    setFilter(filters);
  };

  const onChangeSelect = (event: any, row: any, name: any) => {
    const newRowsSelect = rows.map(rowI => {
      if (row.id >= 0 && row.id !== null) {
        if (rowI.id === row.id) {
          return { ...rowI, [name]: event.target.value };
        } else {
          return rowI;
        }
      } else if (row.id === null && rowI.id_generated) {
        if (rowI.id_generated === row.id_generated) {
          return { ...rowI, [name]: event.target.value };
        } else {
          return rowI;
        }
      } else {
        return rowI;
      }
    });
    setRows(newRowsSelect);
  };

  const handleSearch = (value: string) => {
    const columnName = columns.map((column: any) => column.name);
    const filterRows = searchValue(rows, value, columnName);
    setOrderBy(columnName);
    setValue(value);
    setNumpages(filterRows?.length);
  };

  return (
    <Fragment>
      <ThemeProvider theme={tableTheme}>
        <Paper>
          <ToolBar
            onSearch={handleSearch}
            title={title}
            disabled={disabled}
            buttons={buttons}
            rows={rows}
            columns={columns}
            exportExcel={exportExcel}
            onEditBulk={onEditBulk}
            setRows={onEditBulk ? setRows : undefined}
          />
          <TableContainer style={{ maxHeight: option?.style?.maxHeight || undefined, maxWidth: 'auto' }}>
            {isLoading ? (
              <Loading big />
            ) : (
              <Table>
                <TableHead>
                  <SortTableHead
                    onRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                    columns={columns}
                    option={option}
                    actions={!!actions || !!handleDeleteClick || !!handleOpenModal}
                  />
                  {option?.filter && (
                    <FilterRow
                      columns={columns}
                      option={option}
                      onFilter={handleFilter}
                      actions={!!actions || !!handleDeleteClick || !!handleOpenModal}
                    />
                  )}
                </TableHead>

                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy), filter, value, orderBy)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => (
                      <TableRow key={index} onClick={onRowClick ? () => onRowClick(row) : undefined}>
                        <>
                          {columns.map((column: any, index: number) => (
                            <CustomTableCell
                              key={index}
                              {...{
                                row,
                                editable: column.editable,
                                name: column.name,
                                onChange,
                                onClick: () => {
                                  if (disabled) return;
                                  if (column.disabled && column.disabled(row)) return;
                                  !disableToggle && onToggleEditMode(row);
                                },
                                index,
                                modal: column.modal,
                                onModal: modal => setModal({ modal, view: onRowClick ? false : true }),
                                type: column.type,
                                column: column,
                                onChangeDate,
                                onChangeSelect,
                                color: column?.color,
                                cb: column.cb,
                                fixed: option?.fixedColumn,
                                disabled: column?.disabled && column.disabled(row),
                                projectID: projectID,
                                entireTable: rows,
                              }}
                            />
                          ))}
                          {(!!handleDeleteClick ||
                            !!handleEditClick ||
                            !!handleDuplicateClick ||
                            !!handleOpenModal) && (
                            <ActionButtonsTab
                              row={row}
                              handleDeleteClick={handleDeleteClick}
                              handleEditClick={handleEditClick}
                              handleDuplicateClick={handleDuplicateClick}
                              disabled={disabled}
                            />
                          )}
                        </>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          <Pagination
            onPage={onPage}
            numPage={{ page, setPage }}
            numRowPage={{ rowsPerPage, setRowsPerPage }}
            rows={numPages}
          />
        </Paper>
      </ThemeProvider>
      <ModalTool view={modal.view} details={modal.modal} onClose={() => setModal({ modal: [], view: false })} />
    </Fragment>
  );
};

export default CanaryTable;

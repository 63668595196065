import assertUnreachable from '../../../helpers/assertUnreachable';
import { ScholarshipPaymentAdjustmentDataConsts } from './action_types';
import { ScholarshipPaymentAdjustmentDataState, ScholarshipPaymentAdjustmentsDataAction } from './definitions';

import { ScholarshipPaymentAdjustmentInitialState, ScholarshipPaymentAdjustmentItem } from './PaymentAdjustment.d';

const scholarshipPaymentAdjustmentsReducer = (
  state = ScholarshipPaymentAdjustmentInitialState,
  action: ScholarshipPaymentAdjustmentsDataAction,
): ScholarshipPaymentAdjustmentDataState => {
  switch (action.type) {
    case ScholarshipPaymentAdjustmentDataConsts.GET_SCHOLARSHIP_PAYMENT_ADJUSTMENT:
      return {
        ...state,
        loading: true,
        loadingAdjustments: true,
        error: null,
      };
    case ScholarshipPaymentAdjustmentDataConsts.ADD_SCHOLARSHIP_PAYMENT_ADJUSTMENT:
    case ScholarshipPaymentAdjustmentDataConsts.DELETE_SCHOLARSHIP_PAYMENT_ADJUSTMENT:
      return {
        ...state,
        loadingAdjustments: true,
        error: null,
      };
    case ScholarshipPaymentAdjustmentDataConsts.SAVE_SCHOLARSHIP_PAYMENT_ADJUSTMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ScholarshipPaymentAdjustmentDataConsts.GET_SCHOLARSHIP_PAYMENT_ADJUSTMENT_OK:
      return {
        ...state,
        loading: false,
        loadingAdjustments: false,
        scholarshipPaymentAdjustmentData: action.scholarshipPaymentAdjustmentData,
        error: null,
      };
    case ScholarshipPaymentAdjustmentDataConsts.ADD_SCHOLARSHIP_PAYMENT_ADJUSTMENT_OK:
      const { scholarshipPayment } = action;

      const newList: ScholarshipPaymentAdjustmentItem[] = [
        ...state.scholarshipPaymentAdjustmentData.scholarship_adjustments,
        scholarshipPayment,
      ];
      return {
        ...state,
        loadingAdjustments: false,
        error: null,
        scholarshipPaymentAdjustmentData: {
          ...state.scholarshipPaymentAdjustmentData,
          scholarship_adjustments: newList,
        },
      };
    case ScholarshipPaymentAdjustmentDataConsts.SAVE_SCHOLARSHIP_PAYMENT_ADJUSTMENT_OK:
      return {
        ...state,
        loading: false,
        error: null,
        scholarshipPaymentAdjustmentData: {
          ...state.scholarshipPaymentAdjustmentData,
          scholarship_payments: action.scholarship_payments,
        },
      };
    case ScholarshipPaymentAdjustmentDataConsts.DELETE_SCHOLARSHIP_PAYMENT_ADJUSTMENT_OK: {
      const { scholarshipPaymentId } = action;
      const {
        scholarshipPaymentAdjustmentData: { scholarship_adjustments },
      } = state;

      const scholarship_adjustments_removed = scholarship_adjustments.filter(
        (scholarship_payment: ScholarshipPaymentAdjustmentItem) => scholarship_payment.id !== scholarshipPaymentId,
      );
      return {
        ...state,
        loadingAdjustments: false,
        error: null,
        scholarshipPaymentAdjustmentData: {
          ...state.scholarshipPaymentAdjustmentData,
          scholarship_adjustments: scholarship_adjustments_removed,
        },
      };
    }
    case ScholarshipPaymentAdjustmentDataConsts.SET_SCHOLARSHIP_PAYMENT_DATA: {
      const { scholarshipPaymentId, payment_amount, payment_date } = action;
      const {
        scholarshipPaymentAdjustmentData: { scholarship_payments },
      } = state;

      const paymentsUpdated = scholarship_payments.map((payment: ScholarshipPaymentAdjustmentItem) => {
        if (payment.id === scholarshipPaymentId) {
          payment.payment_date = payment_date;
          payment.payment_amount = payment_amount;
          return payment;
        }
        return payment;
      });

      return {
        ...state,
        error: null,
        scholarshipPaymentAdjustmentData: {
          ...state.scholarshipPaymentAdjustmentData,
          scholarship_payments: paymentsUpdated,
        },
      };
    }
    case ScholarshipPaymentAdjustmentDataConsts.ADD_SCHOLARSHIP_PAYMENT_ADJUSTMENT_KO:
    case ScholarshipPaymentAdjustmentDataConsts.GET_SCHOLARSHIP_PAYMENT_ADJUSTMENT_KO:
    case ScholarshipPaymentAdjustmentDataConsts.SAVE_SCHOLARSHIP_PAYMENT_ADJUSTMENT_KO:
    case ScholarshipPaymentAdjustmentDataConsts.DELETE_SCHOLARSHIP_PAYMENT_ADJUSTMENT_KO:
      return {
        ...state,
        loading: false,
        loadingAdjustments: false,
        error: action.error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default scholarshipPaymentAdjustmentsReducer;

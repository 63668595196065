/* eslint-disable @typescript-eslint/no-explicit-any */
import Lock from '@material-ui/icons/Send';
import React from 'react';
import { connect } from 'react-redux';
import FncButton from '../../../../components/FncButton/FncButton';
import FormContainer from '../../../../components/Forms/FormContainer';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { tKey } from '../../../../helpers/translate';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { downloadDocument, getDocument, setScholarshipStep } from '../../../../redux/scholarship/actions';
import { cancelScholarshipAgreementAction } from '../../../../redux/scholarship/agreement/actions';
import { ScholarshipAgreementData } from '../../../../redux/scholarship/agreement/ScholarshipAgreement';
import AgreementState from '../../../../types/Agreements/AgreementState';
import styles from './paymentForm.module.scss';
import { selectGetScholarshipDocumentationPerCode } from '../../../../redux/scholarship/selectors';
import Documentation from '../../../../types/Scholarships/Documentation';
import { selectScholarshipPaymentsHasSomePaymentDone } from '../../../../redux/scholarship/paymentAdjustments/selectors';

interface PaymentFormAgreementProps {
  scholarshipAgreementData: ScholarshipAgreementData;
  agreementDocuments: DocumentationItem[];
  canCancelAgreement: boolean;
  readOnly: boolean;
  hasSomePaymentDone: boolean;
  getDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  setScholarshipStep: (step: number) => void;
  cancelScholarshipAgreement: (scholarship_id: number, signature_id: string) => void;
}

const PaymentFormAgreement: React.FC<PaymentFormAgreementProps> = ({
  scholarshipAgreementData,
  agreementDocuments,
  canCancelAgreement,
  readOnly,
  hasSomePaymentDone,
  getDocument,
  downloadDocument,
  setScholarshipStep,
  cancelScholarshipAgreement,
}) => {
  const { scholarship_id, signature_id, agreement_state, agreement_number } = scholarshipAgreementData;

  const agreementState = new AgreementState(agreement_state);
  const disabled = !(agreementState.isConfirm() || !readOnly);

  const onCancelAgreement = () => {
    if (signature_id) {
      cancelScholarshipAgreement(scholarship_id, signature_id);
      setScholarshipStep(0);
    }
  };

  const renderCancelAgreementButton = (): JSX.Element | undefined => {
    const agreementStateVisibility = canCancelAgreement && agreementState.isWaiting();
    if (disabled || !agreementStateVisibility) {
      return undefined;
    }
    return (
      <div className={`${styles.buttonsWrapper} ${styles.buttonActionRight}`}>
        <FncButton
          disabled={hasSomePaymentDone || !signature_id}
          customClass={styles.iconButton}
          onClick={onCancelAgreement}
          color="primary"
        >
          <Lock />
          <span>{tKey('Cancelar convenio')}</span>
        </FncButton>
      </div>
    );
  };

  const titleText = () => {
    return `${tKey('Convenio')}: ${agreement_number} ${
      agreement_state ? ` - Estado: ${tKey('agreementState.'.concat(agreement_state))}` : ''
    }`;
  };

  return (
    <FormContainer className={styles.doc_container} title={titleText()}>
      <FncAddDocuments
        documents={agreementDocuments}
        actions={['download', 'view']}
        disabled={disabled || !canCancelAgreement}
        genericId={scholarship_id}
        required
        originalName
        getFile={getDocument}
        download={downloadDocument}
      />
      {renderCancelAgreementButton()}
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  canCancelAgreement: selectUserCan(state)('allowRestartInstituteAgreements'),
  agreementDocuments: selectGetScholarshipDocumentationPerCode(state, [Documentation.CONVENIO]),
  hasSomePaymentDone: selectScholarshipPaymentsHasSomePaymentDone(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  cancelScholarshipAgreement: (scholarship_id: number, signature_id: string): void =>
    dispatch(cancelScholarshipAgreementAction(scholarship_id, signature_id)),
  setScholarshipStep: (step: number) => dispatch(setScholarshipStep(step)),
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) =>
    dispatch(downloadDocument(documentation_id, name, scholarship_id)),
  getDocument: (documentation_id: number, name: string, scholarship_id: number) =>
    dispatch(getDocument(documentation_id, name, scholarship_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFormAgreement);

import { v4 as uuid } from 'uuid';
import { v7 as uuidv7 } from 'uuid';

import { UUID } from '../types/Shared/standard';

export const generateUuid = (): UUID => {
  return uuid();
};

export const generateUuidV7 = (): UUID => {
  return uuidv7();
};

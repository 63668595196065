import { GetInterviewsCalendar } from '../../redux/calendar-scholarship/definitions';
import { APIResponse, DocumentationItem, IdNamePair, ResponseData } from '../../redux/common/definitions';
import {
  AggreementDataItem,
  CheckScholarshipRemainderData,
  EducatorScholarshipAcademicYear,
  ListOfScholarships,
  PerStateStatisticData,
  RejectScholarshipData,
  ScholarshipAcademy,
  ScholarshipCreatedData,
  ScholarshipData,
  ScholarshipFollow,
  ScholarshipHistoryGlobal,
  ScholarshipMetadata,
  ScholarshipStateChange,
  ScholarshipTransversalCompetencesData,
  ScholarshipTransversalCompetencesResponses,
  ScholarshipTransversalCompetencesSendData,
  ScholarshipType,
  ScholarshipUserCheck,
  ScholarshipUserType,
  TransversalCompetenceResponseItem,
} from '../../redux/scholarship/definitions';
import { APIValidationError, FetchOptions, Result, apiBaseUrl, apiCall, apiCallBlob, validatedApiCall } from '../api';

const baseURL = apiBaseUrl;
interface DocumentsData {
  documents: DocumentationItem[];
}

interface GetResult {
  data: any;
}

interface DocumentsResponse {
  data: DocumentsData;
}

interface SaveResult {
  data: any;
}

export interface NewEntityRequest {
  email: string;
  student_phone: string;
  user_id: number;
  privacy_policy: number;
  send_information: number;
  image_use: number;
  whatsapp_aggregation: number;
  scholarship_id?: number;
}

export const getOneScholarship = async (id: number): Promise<{ data: ScholarshipData }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<{ data: ScholarshipData }>(`${baseURL}/scholarship/${id}`, init);
};

export const getEducatorScholarship = async (
  educator_id: number,
  academic_year: string,
): Promise<{ data: EducatorScholarshipAcademicYear[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: EducatorScholarshipAcademicYear[] }>(
    `${baseURL}/scholarship/change_educator/${educator_id}/${academic_year}`,
    init,
  );
};

export const changeEducatorScholarships = async (
  scholarship_ids: number[],
  new_educator_id: number,
): Promise<SaveResult> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ ids: scholarship_ids, new_educator_id }),
  };

  return await apiCall<SaveResult>(`${baseURL}/scholarship/change_educator`, init);
};

export const getMetadata = async () => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<{ data: ScholarshipMetadata }>(`${baseURL}/scholarship/metadata`, init);
};

export const scholarshipSendInformation = async (
  payload: ScholarshipData,
): Promise<Result<{ data: ScholarshipData }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ ...payload }),
  };

  return await validatedApiCall<{ data: ScholarshipData }>(`${baseURL}/scholarship`, init);
};

export const deleteOneScholarship = async (id: number) => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return await apiCall<GetResult>(`${baseURL}/scholarship/${id}`, init);
};

export const deleteScholarships = async (ids: number[]) => {
  const init: FetchOptions = {
    method: 'DELETE',
    auth: true,
    body: JSON.stringify({ scholarships: ids }),
  };

  return apiCall<ResponseData>(`${baseURL}/scholarships`, init);
};

export const uploadScholarshipDocument = async (
  documentation_id: number,
  file: File,
  scholarshipId: number,
  multiDocument?: boolean,
): Promise<DocumentsData> => {
  const route = multiDocument ? 'multidocument' : 'document';
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<DocumentsResponse>(
    `${baseURL}/scholarship/${route}/${scholarshipId}/${documentation_id}`,
    init,
  );
  return data;
};

export const deleteScholarshipDocument = async (scholarshipId: number, file_id: number): Promise<DocumentsData> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<DocumentsResponse>(
    `${baseURL}/scholarship/document/${scholarshipId}/${file_id}`,
    init,
  );
  return data;
};

export const downloadScholarshipDocument = async (documentation_id: number, scholarshipId: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/scholarship/document/download/${scholarshipId}/${documentation_id}`, init);
};

export const getDocumentation = async (id: number): Promise<DocumentationItem[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<APIResponse>(`${baseURL}/scholarship/documentation/${id}`, init);
  return data;
};

export const scholarshipAsk = async (date: number, id: number, channel: string) => {
  const data = {
    date,
    channel,
  };
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
    auth: true,
  };
  return await apiCall<GetInterviewsCalendar>(`${baseURL}/scholarship/change_state/s03s04/${id}`, init);
};

export const changeState = async (
  id: number,
  states: ScholarshipStateChange,
  data?: ScholarshipData | RejectScholarshipData,
): Promise<SaveResult> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await apiCall<SaveResult>(`${baseURL}/scholarship/change_state/${states}/${id}`, init);
};

export const getRejectScholarshipInfo = async (state_code: string, id: number): Promise<RejectScholarshipData> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: RejectScholarshipData }>(
    `${baseURL}/scholarship/reject/info/${state_code}/${id}`,
    init,
  );
  return data;
};

export const checkScholarshipRemainder = async (id: number): Promise<CheckScholarshipRemainderData> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: CheckScholarshipRemainderData }>(
    `${baseURL}/scholarships/${id}/remainder/check`,
    init,
  );
  return data;
};

export const ScholarhipGetList = async (
  scholarshipType: string,
  academic_year: string | null,
  signal: AbortSignal | null,
): Promise<ListOfScholarships> => {
  const init = {
    method: 'GET',
    auth: true,
    signal,
  };

  let param = '';
  if (academic_year) {
    param = `?academic_year=${academic_year.replace('-', '/')}`;
  }
  const { data } = await apiCall<APIResponse>(`${baseURL}/scholarship/list/${scholarshipType}${param}`, init);
  return data;
};

export const addNewScholarshipEntity = async (
  newEntity: NewEntityRequest,
  entityType: ScholarshipType,
): Promise<number> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(newEntity),
  };

  const { data } = await apiCall<APIResponse>(`${baseURL}/scholarship/${entityType}/create`, init);
  return data;
};

export const checkNewUser = async (email: string, userType: ScholarshipUserType): Promise<ScholarshipUserCheck> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ email }),
  };

  const { data } = await apiCall<APIResponse>(`${baseURL}/scholarship/check/${userType}`, init);
  return data;
};

export const getAcademiesFNC = async (): Promise<IdNamePair[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<APIResponse>(`${baseURL}/scholarship/academy/fnc`, init);
  return data;
};

export const getUsersNouscimsFromApi = async (): Promise<any> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<APIResponse>(`${baseURL}/scholarship/assigned_to/metadata`, init);
  return data;
};

export const assignUserApi = async (id: number, scholarship_id: number): Promise<{}> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ assigned_to_user_id: id }),
  };

  const { data } = await apiCall<APIResponse>(`${baseURL}/scholarship/assignedTo/${scholarship_id}`, init);
  return data;
};

export const StatisticsPerState = async (filter: {
  academic_year?: string;
  type: 'entity' | 'institute' | 'study_center';
}): Promise<PerStateStatisticData[]> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(filter),
  };

  const { data } = await apiCall<{ data: PerStateStatisticData[] }>(
    `${baseURL}/scholarship/statistics/per_state`,
    init,
  );
  return data;
};

export const sendConfigurationFollowupFromApi = async (data: any): Promise<any> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data), //{}
  };
  return apiCall<any>(`${baseURL}/configuration/followup`, init);
};

export const runConfigurationFollowupFromApi = async (): Promise<any> => {
  const init = {
    method: 'POST',
    auth: true,
  };
  return apiCall<any>(`${baseURL}/configuration/followup/execute`, init);
};

export const getConfigurationFollowupFromApi = async (): Promise<any> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<any>(`${baseURL}/configuration/followup`, init);
};

export const saveScholarshipFollowUp = async (follow_up: ScholarshipFollow): Promise<{ data: string }> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ follow_up }),
  };

  return apiCall<{ data: string }>(`${baseURL}/scholarship/followup`, init);
};

export const switchPaymentBlockFromApi = async (scholarship_id: number): Promise<{ data: boolean }> => {
  const init = {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify({ scholarship_id }),
  };

  return apiCall<{ data: boolean }>(`${baseURL}/scholarship/block_payment`, init);
};

export const getCreatedScholarships = async (
  academic_year: string,
  entity: boolean,
): Promise<{ data: ScholarshipCreatedData }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ academic_year, entity }),
  };
  return apiCall<{ data: ScholarshipCreatedData }>(`${baseURL}/scholarship/created`, init);
};

export const removeCreatedScholarships = async (academic_year: string, scholarships: number[], entity: boolean) => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ academic_year, scholarships, entity }),
  };
  return apiCall<{ data: number }>(`${baseURL}/scholarship/remove_created`, init);
};

export const getScholarshipHistory = async (id: number) => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: ScholarshipHistoryGlobal }>(`${baseURL}/scholarship/history/${id}`, init);
};

export const getScholarshipReports = async (
  source: 'committee' | 'zing',
  type: 'entity' | 'institute' | 'study_center',
  academic_year: string,
): Promise<Blob> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ type, academic_year }),
  };
  if (source === 'committee') {
    return await apiCallBlob(`${baseURL}/scholarship/exports/committee`, init);
  } else {
    return await apiCallBlob(`${baseURL}/scholarship/exports/zing_network`, init);
  }
};

export const getScholarshipCommitteeReport = async (
  type: 'entity' | 'institute' | 'study_center',
  academic_year: string,
): Promise<{ data: string }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ type, academic_year }),
  };
  return apiCall<{ data: string }>(`${baseURL}/scholarship/exports/committee`, init);
};

export const uploadScholarshipDocumentTracking = async (
  documentation_id: number,
  file: File,
  scholarshipId: number,
  trackingId: number,
): Promise<DocumentsData> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<DocumentsResponse>(
    `${baseURL}/scholarship/document/tracking/${scholarshipId}/${documentation_id}/${trackingId}`,
    init,
  );
  return data;
};

export const getScholarshipAcademiesByFNCAcademy = async (code: string | 'all'): Promise<ScholarshipAcademy[]> => {
  const init = {
    method: 'GET',
    auth: true,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: ScholarshipAcademy[] }>(
    `${baseURL}/scholarship/academies/fnc_academies/${code}`,
    init,
  );
  return data;
};

export const getScholarshipAgreementTemplateData = async (scholarship_id: number): Promise<AggreementDataItem[]> => {
  const init = {
    method: 'GET',
    auth: true,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: AggreementDataItem[] }>(
    `${baseURL}/scholarship/agreement/template_data/${scholarship_id}`,
    init,
  );
  return data;
};

export const getScholarshipTransversalCompetencesFormApi = async (
  survey_code: string,
): Promise<ScholarshipTransversalCompetencesData> => {
  const init = {
    method: 'GET',
  };
  const { data } = await apiCall<{ data: ScholarshipTransversalCompetencesData }>(
    `${baseURL}/scholarship_transversal_competences?token=${survey_code}`,
    init,
  );
  return data;
};

export const setScholarshipTransversalCompetencesApi = async (
  dataForm: ScholarshipTransversalCompetencesSendData,
): Promise<boolean> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(dataForm),
  };
  const { data } = await apiCall<{ data: string }>(`${baseURL}/scholarship_transversal_competences`, init);
  return data === 'OK';
};

export const sendInitialTransversalCompetencesFromApi = async (scholarship_id: number): Promise<boolean> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ scholarship_id }),
  };
  const { data } = await apiCall<{ data: string }>(`${baseURL}/scholarship_transversal_competences/send/initial`, init);
  return data === 'OK';
};

export const sendFinalTransversalCompetencesFromApi = async (scholarship_id: number): Promise<boolean> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ scholarship_id }),
  };
  const { data } = await apiCall<{ data: string }>(`${baseURL}/scholarship_transversal_competences/send/final`, init);
  return data === 'OK';
};

export const getTransversalCompetencesResponsesFormApi = async (
  scholarship_id: number,
): Promise<TransversalCompetenceResponseItem[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: ScholarshipTransversalCompetencesResponses }>(
    `${baseURL}/scholarship_transversal_competences/responses?scholarship_id=${scholarship_id}`,
    init,
  );
  return data.responses;
};

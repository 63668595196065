class RoleCode {
  static DIRECCION_NOUSCIMS = 'dir_fnc';
  static ADMINISTRACION_NOUSCIMS = 'cdn_adm';
  static SOPORTE_ADMINSTRACION = 'adm_sop';
  static PM_KOMTU = 'pma_kom';
  static PM_BENESTAR_EMOCIONAL = 'pma_sal_men';
  static PM_DOMUM = 'pma_sop_onc';
  static PM_GLOBAL_DEVELOPMENT = 'pma_jun';
  static PM_GB_SENEGAL = 'pma_sen';
  static PM_OCUPABILITAT = 'pma_ocu';
  static PM_FORMACIO = 'pma_for';
  static PM_XARXA = 'pma_xar';
  static PM_INSERCIO_LABORAL = 'pma_lab';
  static DIR_BENESTAR_EMOCIONAL = 'dir_bns';
  static DIR_GLOBAL_DEVELOPMENT = 'dir_dev';
  static DIR_OCUPABILITAT = 'dir_ocu';
  static ENTREVISTADOR = 'bec_ent';
  static RESPONSABLE_ENTIDAD = 'res_ent';
  static RESPONSABLE_PROYECTO = 'res_pro';
  static EDUCADOR = 'edu';
  static JOVEN_INSTITUTO = 'alu_no_tut';
  static JOVEN_ENTIDAD = 'alu_tut';
  static PERSONAL_DE_ESCUELAS = 'esc';
  static SHERPA = 'she';

  constructor(private code: string) {}

  public static administracionFNC(): RoleCode {
    return new RoleCode(RoleCode.ADMINISTRACION_NOUSCIMS);
  }

  public static jovenInstituto(): RoleCode {
    return new RoleCode(RoleCode.JOVEN_INSTITUTO);
  }

  public static educador(): RoleCode {
    return new RoleCode(RoleCode.EDUCADOR);
  }

  public isJovenInstituto(): boolean {
    return this.code === RoleCode.JOVEN_INSTITUTO;
  }

  public isJovenEntidad(): boolean {
    return this.code === RoleCode.JOVEN_ENTIDAD;
  }

  public isStudent(): boolean {
    return this.isJovenEntidad() || this.isJovenInstituto();
  }

  public isEducador(): boolean {
    return this.code === RoleCode.EDUCADOR;
  }

  public isResponsableEntidad(): boolean {
    return this.code === RoleCode.RESPONSABLE_ENTIDAD;
  }

  public isEntrevistador(): boolean {
    return this.code === RoleCode.ENTREVISTADOR;
  }

  public isUsuarioBeca(): boolean {
    return this.isStudent() || this.isEducador() || this.isResponsableEntidad();
  }

  public isDireccionFNC(): boolean {
    return this.code === RoleCode.DIRECCION_NOUSCIMS;
  }

  public isAdministracionFNC(): boolean {
    return this.code === RoleCode.ADMINISTRACION_NOUSCIMS;
  }

  public isSoporteAdministracion(): boolean {
    return this.code === RoleCode.SOPORTE_ADMINSTRACION;
  }

  public isUsuarioAdministracion(): boolean {
    return this.isAdministracionFNC() || this.isSoporteAdministracion();
  }

  public isSherpa(): boolean {
    return this.code === RoleCode.SHERPA;
  }

  public isPersonalEscuelas(): boolean {
    return this.code === RoleCode.PERSONAL_DE_ESCUELAS;
  }

  public isProjectManager(): boolean {
    return this.code.includes('pma');
  }

  public value(): string {
    return this.code;
  }
}

export default RoleCode;

import { Action } from 'redux';
import { APIValidationError } from '../../api/api';
import {
  ColumnOrderData,
  DocumentationItem,
  DocUpload,
  FileItem,
  IdNamePair,
  ResponseData,
} from '../common/definitions';
import { EntityDataConsts } from './action_types';

export enum EntitySections {
  DATOS_GENERALES = 0,
  DATOS_ECONOMICOS = 1,
  CAPACIDAD_INSTITUCIONAL = 2,
  ARTICULACION = 3,
  ANALISIS_FNC = 4,
  DATOS_CONTABLES = 5,
  PAGOS_BECAS = 6,
  CONVENIOS_BECAS = 7,
  CONFIGURACION_BECAS = 8,
  FACTURAS = 9,
  CONTACTOS_ENTIDAD = 10,
  CAMBIO_EDUCADOR = 11,
  CUENTAS_BANCARIAS = 12,
}

export enum EntityStateCodes {
  SOLICITUD_ENTIDAD = 'E01',
  SOLICITUD_FNC = 'E02',
  PENDIENTE_RESPONSABLE = 'E03',
  VALIDACION_SOLICITUD = 'E04',
  REGISTRADA = 'E05',
  RECHAZO = 'E06',
  BAJA = 'E07',
}

export enum TaxRetentionCodes {
  SIN_RETENCION = '00',
  PROFESIONALES = '21',
  PROFESIONALES_7 = '22',
  J_INSTITUT_2 = '23',
  J_INSTITUT_19 = '24',
  J_INSTITUT_24 = '25',
}

export enum TypesEntity {
  PROJECT = 1,
  SCHOLARSHIP = 1,
  NATURAL_PERSON = 1,
  LEGAL_PERSON = 1,
  NO_PAYMENTS_ENTITY = 1,
}

export interface PageSizeData {
  pageSize: number;
}

export interface SearchTextData {
  searchText: string;
}

export type ChangeStateEntity = 'e04e01' | 'e04e05' | 'e04e06';

export interface EntityRequired {
  errorFields: string[];
  documents: number[];
  complete: boolean;
}

export interface EntityContract {
  id: number;
  entity_id: number;
  contract_number: string | null;
  start_date: number | null;
  end_date: number | null;
  file_id: number | null;
  filename: string | null;
}

export interface EntityInfo {
  id: number;
  cif: string | null;
  address: string | null;
  address_type: string | null;
  flat: string | null;
  floor: string | null;
  room: string | null;
  staircase: string | null;
  country: string | null;
  newResponsible: string | null;
  city: string | null;
  created_at: string | null;
  account_number: string | null;
  creation_date: string | number | null;
  email: string | null;
  legal_nature: string | null;
  name: string | null;
  phone: string | null;
  province: string | null;
  responsible_account_number: string | null;
  responsible_address: string | null;
  study_center: boolean;
  responsible_city: string | null;
  responsible_country: string | null;
  responsible_document_number: string | null;
  responsible_document_type: string | null;
  responsible_name: string | null;
  responsible_phone: string | null;
  responsible_province: string | null;
  responsible_surname: string | null;
  responsible_surname2: string | null;
  responsible_zip_code: string | null;
  state: string | null;
  state_code: string | null;
  state_id: number | null;
  zip_code: string | null;
  documents: DocumentationItem[];
  required: EntityRequired;
  has_project: number;
  has_scholarship: number;
  is_provider: number;
  is_visa_provider: number;
  natural_person: number;
  legal_person: number;
  no_payments_entity: number;
  project_web_page: null | string;
  legal_oficial_records: null | string;
  legal_country_records: null | string;
  economic_data_budget_five_years: null | number;
  economic_data_external_accounting_audit: null | number;
  economic_data_external_accounting_audit_text: null | string;
  economic_data_real_state: null | number;
  economic_data_real_state_text: null | string;
  economic_data_salary_table: null | number;
  economic_data_salary_table_text: null | string;
  economic_data_salary_retributions: null | number;
  economic_data_salary_retributions_text: null | string;
  institutional_capacity_operation: null | string;
  institutional_capacity_members: null | string;
  institutional_capacity_strategy: null | number;
  institutional_capacity_strategy_text: null | string;
  institutional_capacity_director_plan: null | number;
  institutional_capacity_director_plan_text: null | string;
  institutional_capacity_territorial_implantation: null | string;
  institutional_capacity_social_base: null | string;
  institutional_capacity_evolution: null | string;
  institutional_capacity_curriculum: null | number;
  institutional_capacity_curriculum_text: null | string;
  institutional_capacity_work_organization_chart: null | number;
  institutional_capacity_work_organization_chart_text: null | string;
  institutional_capacity_laboral_policy: null | number;
  institutional_capacity_laboral_policy_text: null | string;
  institutional_capacity_laboral_external_policy: null | number;
  institutional_capacity_laboral_external_policy_text: null | string;
  institutional_capacity_internal_policy: null | number;
  institutional_capacity_internal_policy_text: null | string;
  institutional_capacity_sexual_harassment: null | number;
  institutional_capacity_sexual_harassment_text: null | string;
  institutional_capacity_internal_formation_plan: null | number;
  institutional_capacity_internal_formation_plan_text: null | string;
  institutional_capacity_volunteering: null | number;
  institutional_capacity_volunteering_text: null | string;
  joint_belongs_other_organization: null | number;
  joint_belongs_other_organization_text: null | string;
  joint_belongs_other_next: null | number;
  joint_belongs_other_next_text: null | string;
  joint_relation_internatinal_institutions: null | string;
  joint_relation_other_institutions: null | string;
  joint_relation_other_organization: null | string;
  joint_educational_collaboration: null | string;
  fnc_analisys_dafo_1: null | string;
  fnc_analisys_dafo_2: null | string;
  fnc_analisys_dafo_3: null | string;
  fnc_analisys_dafo_4: null | string;
  fnc_support: null | string;
  expense_account: null | string;
  provider_code: null | string;
  payable_account: null | string;
  joined_49_2020: null | number | string;
  allow_scholarship_year_renew: number;
  supplier_code: null | string;
  supplier_payable_account: null | string;
  retention_code: null | string;
  transaction_code: null | string;
  needs_347: number;
  user_list: IdNamePair[];
  entity_contracts: EntityContract[];
  entity_ref_user_id: number | null;
  pre_entity_id?: number;
  types_entity: EntityTypes[];
  scholarship_academy_id: number | null;
  payment_type_code: string;
}

export interface EntityListItem {
  id: number;
  cif: string;
  name: string;
  city: string | null;
  phone: string | null;
  has_project: number;
  has_scholarship: number;
  natural_person: number;
  legal_person: number;
  no_payments_entity: number;
  joined_49_2020: string;
  email: string;
  responsible_name: string;
  responsible_surname: string;
  responsible_surname2: string;
  state: string;
  types_entity: string[];
}
export interface EntityContactData {
  id: number;
  name: string;
  surname: string;
  email: string;
  contact_role_code: string;
  contact_role_name: string;
  phone: string;
  description: string | null;
}

export interface EntityIbanData {
  id: number;
  iban: string;
  active: number;
  file_id: number | null;
  filename: string | null;
  alias: string;
  entity_id: number;
}

export interface EntityContactRoles {
  id: number;
  code: string;
  name: string;
}
export type EntityTypes = {
  id: number;
  code: 'has_project' | 'has_scholarship' | 'natural_person' | 'legal_person' | 'no_payments_entity';
  name: string;
  color: string;
};

// This file contains initialState + definitions for notifications

// INITIAL STATE
export const initialState = {
  loading: false as boolean,
  error: null as string | null,
  validationErrors: null as APIValidationError | null,
  entityOneData: {} as EntityInfo,
  entityList: [] as EntityListItem[],
  roleList: [] as EntityContactRoles[],
  ibanList: [] as EntityIbanData[],
  documentation: [] as DocumentationItem[],
  documentsArr: [] as DocUpload[],
  entityContactsList: [] as EntityContactData[],
  contactData: {} as EntityContactData,
  ibanData: {} as EntityIbanData,
  avatar: null as string | null,
  responseData: null as object | null,
  isAllSuccess: false as boolean,
  pageSize: 5 as number,
  searchText: null as string | null,
  columnOrder: { orderDirection: 'asc', orderedColumnId: 1 } as ColumnOrderData,
  creationSended: false as boolean,
  transitionChangeSent: false as boolean,
  changePage: true as boolean,
  contractData: {} as EntityContract,
  loadingModal: false as boolean,
  loadingContract: false as boolean,
  loadingContactList: false as boolean,
  loadingIbanList: false as boolean,
  sendingSage: false as boolean,
  ibanModalView: false as boolean,
  contactModalView: false as boolean,
  contractModalView: false as boolean,
  entityTypes: [
    { id: 1, code: 'has_project', name: 'Proyectos', color: '#5696fc' },
    { id: 2, code: 'has_scholarship', name: 'Becas', color: '#ffd787' },
    { id: 3, code: 'natural_person', name: 'Persona física', color: '#b593fa' },
    { id: 4, code: 'legal_person', name: 'Persona jurídica', color: '#fabc93' },
    { id: 5, code: 'no_payments_entity', name: 'Sin Pagos', color: '#faaB42' },
  ] as EntityTypes[],
};

export type EntityDataState = typeof initialState;

export enum ListEntitiesState {
  ACTIVAS = 'current',
  SOLICITADAS = 'requested',
  CONFIRMADAS = 'confirmed',
  EN_CREACION = 'draft',
}

export interface EntityGetListAction extends Action {
  type: EntityDataConsts.ENTITY_GET_LIST;
  state: ListEntitiesState;
}
export interface EntityGetListOkAction extends Action {
  type: EntityDataConsts.ENTITY_GET_LIST_OK;
  entityList: EntityListItem[];
}

export interface EntityGetListKoAction extends Action {
  type: EntityDataConsts.ENTITY_GET_LIST_KO;
  error: string;
}

export interface EntityValidationErrorsAction extends Action {
  type: EntityDataConsts.ENTITY_VALIDATION_ERRORS;
  validationErrors: APIValidationError;
}
// Get ENTITY CONTACT LIST
export interface EntityContactsListAction extends Action {
  type: EntityDataConsts.GET_ENTITY_CONTACTS;
  entityId: number;
}

export interface EntityContactsListOkAction extends Action {
  type: EntityDataConsts.GET_ENTITY_CONTACTS_OK;
  entityContactsList: EntityContactData[];
}

export interface EntityContactsListKoAction extends Action {
  type: EntityDataConsts.GET_ENTITY_CONTACTS_KO;
  error: string;
}
// GET ENTITY ONE CONTACT
export interface EntityOneContactAction extends Action {
  type: EntityDataConsts.GET_ENTITY_ONE_CONTACT;
  entity_id: number;
  contact_id: number;
}

export interface EntityOneContactOkAction extends Action {
  type: EntityDataConsts.GET_ENTITY_ONE_CONTACT_OK;
  contactData: EntityContactData;
}

export interface EntityOneContactKoAction extends Action {
  type: EntityDataConsts.GET_ENTITY_ONE_CONTACT_KO;
  error: string;
}

//REMOVE ENTITY CONTACT

export interface RemoveContactEntityAction extends Action {
  type: EntityDataConsts.REMOVE_ENTITY_CONTACT;
  entity_id: number;
  contact_id: number;
}

export interface RemoveContactEntityOkAction extends Action {
  type: EntityDataConsts.REMOVE_ENTITY_CONTACT_OK;
  contact_id: number;
}

export interface RemoveContactEntityKoAction extends Action {
  type: EntityDataConsts.REMOVE_ENTITY_CONTACT_KO;
  error: string;
}
// GET ENTITY ROLES
export interface EntityContactRolesAction extends Action {
  type: EntityDataConsts.GET_ENTITY_ROLES;
}

export interface EntityContactRolesOkAction extends Action {
  type: EntityDataConsts.GET_ENTITY_ROLES_OK;
  roleList: EntityContactRoles[];
}

export interface EntityContactRolesKoAction extends Action {
  type: EntityDataConsts.GET_ENTITY_ROLES_KO;
  error: string;
}
//CREATE ENTITY CONTACT
export interface CreateEntityContactAction extends Action {
  type: EntityDataConsts.ADD_ENTITY_CONTACT;
  contactData: EntityContactData;
  entity_id: number;
}

export interface CreateEntityContactOkAction extends Action {
  type: EntityDataConsts.ADD_ENTITY_CONTACT_OK;
  contactData: EntityContactData;
}

export interface CreateEntityContactKoAction extends Action {
  type: EntityDataConsts.ADD_ENTITY_CONTACT_KO;
  error: string;
}

// UPDATE ENTITY CONTACT
export interface EntityUpdateContactAction extends Action {
  type: EntityDataConsts.UPDATE_ENTITY_CONTACT;
  contactInfo: any;
  entity_id: number;
}

export interface EntityUpdateContactOkAction extends Action {
  type: EntityDataConsts.UPDATE_ENTITY_CONTACT_OK;
  contactData: EntityContactData;
}

export interface EntityUpdateContactKoAction extends Action {
  type: EntityDataConsts.UPDATE_ENTITY_CONTACT_KO;
  error: string;
}

// CONTACT MODAL
export interface DeleteContactDataAction extends Action {
  type: EntityDataConsts.DELETE_CONTACT_DATA;
}

export interface OpenContactModalAction extends Action {
  type: EntityDataConsts.OPEN_CONTACT_MODAL;
}

export interface CloseContactModalAction extends Action {
  type: EntityDataConsts.CLOSE_CONTACT_MODAL;
}

//IBAN MODAL
export interface DeleteIbanDataAction extends Action {
  type: EntityDataConsts.DELETE_IBAN_DATA;
}

export interface OpenIbanModalAction extends Action {
  type: EntityDataConsts.OPEN_IBAN_MODAL;
}

export interface CloseIbanModalAction extends Action {
  type: EntityDataConsts.CLOSE_IBAN_MODAL;
}

//GET IBAN LIST
export interface ListEntityIbansAction extends Action {
  type: EntityDataConsts.GET_ENTITY_IBAN;
  entityId: number;
}
export interface ListEntityIbansOkAction extends Action {
  type: EntityDataConsts.GET_ENTITY_IBAN_OK;
  ibanList: EntityIbanData[];
}
export interface ListEntityIbansKoAction extends Action {
  type: EntityDataConsts.GET_ENTITY_IBAN_KO;
  error: string;
}
//ENTITY GET ONE IBAN
export interface EntityOneIbanAction extends Action {
  type: EntityDataConsts.GET_ENTITY_ONE_IBAN;
  entity_id: number;
  iban_id: number;
}

export interface EntityOneIbanOkAction extends Action {
  type: EntityDataConsts.GET_ENTITY_ONE_IBAN_OK;
  ibanData: EntityIbanData;
}

export interface EntityOneIbanKoAction extends Action {
  type: EntityDataConsts.GET_ENTITY_ONE_IBAN_KO;
  error: string;
}

//REMOVE ENTITY IBAN

export interface RemoveIbanEntityAction extends Action {
  type: EntityDataConsts.REMOVE_ENTITY_IBAN;
  entity_id: number;
  iban_id: number;
}

export interface RemoveIbanEntityOkAction extends Action {
  type: EntityDataConsts.REMOVE_ENTITY_IBAN_OK;
  iban_id: number;
}

export interface RemoveIbanEntityKoAction extends Action {
  type: EntityDataConsts.REMOVE_ENTITY_IBAN_KO;
  error: string;
}

//CREATE ENTITY CONTACT
export interface CreateEntityIbantAction extends Action {
  type: EntityDataConsts.ADD_ENTITY_IBAN;
  ibanData: EntityIbanData;
  entity_id: number;
}

export interface CreateEntityIbanOkAction extends Action {
  type: EntityDataConsts.ADD_ENTITY_IBAN_OK;
  ibanData: EntityIbanData;
}

export interface CreateEntityIbanKoAction extends Action {
  type: EntityDataConsts.ADD_ENTITY_IBAN_KO;
  error: string;
}

// UPDATE ENTITY CONTACT
export interface EntityUpdateIbanAction extends Action {
  type: EntityDataConsts.UPDATE_ENTITY_IBAN;
  ibanInfo: any;
  entity_id: number;
}

export interface EntityUpdateIbanOkAction extends Action {
  type: EntityDataConsts.UPDATE_ENTITY_IBAN_OK;
  ibanData: EntityIbanData;
}

export interface EntityUpdateIbanKoAction extends Action {
  type: EntityDataConsts.UPDATE_ENTITY_IBAN_KO;
  error: string;
}

//DOWNLOAD IBAN DOCUMENT

export interface DownloadIbanDocumentAction extends Action {
  type: EntityDataConsts.DOWNLOAD_ENTITY_IBAN_DOCUMENT;
  file_id: number;
  filename: string;
}

export interface DownloadIbanDocumentOkAction extends Action {
  type: EntityDataConsts.DOWNLOAD_ENTITY_IBAN_DOCUMENT_OK;
  data: Blob;
  name: string;
}

export interface DownloadIbanDocumentKoAction extends Action {
  type: EntityDataConsts.DOWNLOAD_ENTITY_IBAN_DOCUMENT_KO;
  error: string;
}

//UPLOAD IBAN DOCUMENT
export interface UploadIbanEntityDocumentAction extends Action {
  type: EntityDataConsts.UPLOAD_ENTITY_IBAN_DOCUMENT;
  file: File;
}

export interface UploadIbanEntityDocumentOkAction extends Action {
  type: EntityDataConsts.UPLOAD_ENTITY_IBAN_DOCUMENT_OK;
  fileData: FileItem;
}

export interface UploadIbanEntityDocumentKoAction extends Action {
  type: EntityDataConsts.UPLOAD_ENTITY_IBAN_DOCUMENT_KO;
  error: string;
}

//REMOVE IBAN DOCUMENT
export interface RemoveIbanEntityDocumentAction extends Action {
  type: EntityDataConsts.REMOVE_ENTITY_IBAN_DOCUMENT;
  file_id: number;
}

export interface RemoveIbanEntityDocumentOkAction extends Action {
  type: EntityDataConsts.REMOVE_ENTITY_IBAN_DOCUMENT_OK;
}

export interface RemoveIbanEntityDocumentKoAction extends Action {
  type: EntityDataConsts.REMOVE_ENTITY_IBAN_DOCUMENT_KO;
  error: string;
}
//SET IBAN ENTITY DATA
export interface EntitySetIbanDataAction extends Action {
  type: EntityDataConsts.ENTITY_SET_IBAN_DATA;
  s_key: string;
  s_value: string | number;
}

// --------- ENTITY-USER CREATE ENTITY

export interface EntityUserCreateAction extends Action {
  type: EntityDataConsts.PRE_ENTITY_SEND;
  entityOneData: EntityInfo;
}

export interface EntityUserCreateOkAction extends Action {
  type: EntityDataConsts.PRE_ENTITY_SEND_OK;
  entityOneData: EntityInfo;
}

export interface EntityUserCreateKoAction extends Action {
  type: EntityDataConsts.PRE_ENTITY_SEND_KO;
  error: string;
}

// --------- FNC CREATE - SEND NOTIFICATION

export interface EntityFncSendAction extends Action {
  type: EntityDataConsts.ENTITY_FNC_SEND;
  entityOneData: EntityInfo;
}

export interface EntityFncSendOkAction extends Action {
  type: EntityDataConsts.ENTITY_FNC_SEND_OK;
  entityOneData: EntityInfo;
}

export interface EntityFncSendKoAction extends Action {
  type: EntityDataConsts.ENTITY_FNC_SEND_KO;
  error: string;
}

// ------ ENTITY FNC SAVE
export interface CreateEntityFncAction extends Action {
  type: EntityDataConsts.CREATE_ENTITY_FNC;
  entityOneData: EntityInfo;
}

// ------ ENTITY USER SAVE
export interface PreEntityUpdateAction extends Action {
  type: EntityDataConsts.PRE_ENTITY_UPDATE;
  entityOneData: EntityInfo;
}

export interface PreEntityUpdateOkAction extends Action {
  type: EntityDataConsts.PRE_ENTITY_UPDATE_OK;
  entityOneData: EntityInfo;
}

export interface PreEntityUpdateKoAction extends Action {
  type: EntityDataConsts.PRE_ENTITY_UPDATE_KO;
  error: string;
}

// --------- EDIT

export interface EntityUpdateAction extends Action {
  type: EntityDataConsts.ENTITY_UPDATE;
  data: EntityInfo;
}

export interface EntityUpdateOkAction extends Action {
  type: EntityDataConsts.ENTITY_UPDATE_OK;
  entityOneData: EntityInfo;
}

export interface EntityUpdateKoAction extends Action {
  type: EntityDataConsts.ENTITY_UPDATE_KO;
  error: string;
}

// ------------- GET ONE

export interface EntityGetOneAction extends Action {
  type: EntityDataConsts.ENTITY_GET_ONE;
  id: number;
}

export interface EntityGetOneActionOk extends Action {
  type: EntityDataConsts.ENTITY_GET_ONE_OK;
  entityOneData: EntityInfo;
  documentation: DocumentationItem[];
  avatar?: string;
}

export interface EntityGetOneActionKo extends Action {
  type: EntityDataConsts.ENTITY_GET_ONE_KO;
  error: string;
}

// -------------- DELETE ONE

export interface EntityDeleteOneAction extends Action {
  type: EntityDataConsts.ENTITY_DELETE_ONE;
  id: number;
}

export interface EntityDeleteOneActionOk extends Action {
  type: EntityDataConsts.ENTITY_DELETE_ONE_OK;
  responseData: ResponseData;
}

export interface EntityDeleteOneActionKo extends Action {
  type: EntityDataConsts.ENTITY_DELETE_ONE_KO;
  error: string;
}

// -------------- PAGESIZE

export interface EntitySetPageSizeTableAction extends Action {
  type: EntityDataConsts.ENTITY_SET_PAGESIZE_TABLE_OPTIONS;
  pageSize: number;
}

// ------------ SEARCHTEXT

export interface EntitySetSearchTextTableAction extends Action {
  type: EntityDataConsts.ENTITY_SET_SEARCHTEXT_TABLE_OPTIONS;
  searchText: string;
}

// --------- COLUMN ORDER

export interface EntitySetColumnOrderTableAction extends Action {
  type: EntityDataConsts.ENTITY_SET_COLUMNORDER_TABLE_OPTIONS;
  columnOrder: ColumnOrderData;
}

// ---------- RESPONSE DATA RESET

export interface ResetResponseDataAction extends Action {
  type: EntityDataConsts.ENTITY_RESET_RESPONSEDATA;
}

// RESET ALLSUCCESS

export interface ResetAllSuccessAction extends Action {
  type: EntityDataConsts.ENTITY_RESET_ALLSUCCESS;
}

// ----- ENTITY USER PRE CREATED GET
export interface EntityGetPreCreatedUserAction extends Action {
  type: EntityDataConsts.ENTITY_GET_PRE_CREATED_USER;
}

export interface EntityGetPreCreatedUserOkAction extends Action {
  type: EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_OK;
  entitiesData: EntityInfo;
  documentation: DocumentationItem[];
  avatar?: string;
}

export interface EntityGetPreCreatedUserKoAction extends Action {
  type: EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_KO;
  error: string;
}

// ----- ENTITY PRECREATED ENTITY ID
export interface GetEntityPreCreatedIdAction extends Action {
  type: EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_ID;
  id: number;
}

export interface GetEntityPreCreatedIdActionOkAction extends Action {
  type: EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_ID_OK;
  entityOneData: EntityInfo;
  documentation: DocumentationItem[];
  avatar?: string;
}

export interface GetEntityPreCreatedIdActionKoAction extends Action {
  type: EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_ID_KO;
  error: string;
}

// ENTITY CHANGE STATE

export interface EntityChangeStateAction extends Action {
  type: EntityDataConsts.CHANGE_STATE_ENTITY;
  id: number;
  states: ChangeStateEntity;
  comments?: string;
}

export interface EntityChangeStateOkAction extends Action {
  type: EntityDataConsts.CHANGE_STATE_ENTITY_OK;
  // entitiesData: EntityInfo;
}

export interface EntityChangeStateKoAction extends Action {
  type: EntityDataConsts.CHANGE_STATE_ENTITY_KO;
  error: string;
}

// ----- GET ENTITY FOR USER
export interface EntityGetForUser extends Action {
  type: EntityDataConsts.ENTITY_GET_USER;
}

export interface EntityGetForUserOk extends Action {
  type: EntityDataConsts.ENTITY_GET_USER_OK;
  entityOneData: EntityInfo;
  documentation: DocumentationItem[];
  avatar?: string;
}

export interface EntityGetForUserKo extends Action {
  type: EntityDataConsts.ENTITY_GET_USER_KO;
  error: string;
}

export interface EntitiesChangeAction extends Action {
  type: EntityDataConsts.ENTITIES_CHANGE;
  changePage: boolean;
}

// DOCUMENTATION

export interface ResetDocumentsAction extends Action {
  type: EntityDataConsts.RESET_DOCUMENTS_ENTITY;
  docToUpload?: DocUpload[];
}

export interface UploadDocumentEntityAction extends Action {
  type: EntityDataConsts.UPLOAD_ENTITY_DOCUMENT;
  documentation_id: number;
  data: File;
  entityId?: number | string;
  force_add?: boolean | false;
}
export interface UploadDocumentEntityOkAction extends Action {
  type: EntityDataConsts.UPLOAD_ENTITY_DOCUMENT_OK;
  documents: DocumentationItem[];
}
export interface UploadDocumentEntityKoAction extends Action {
  type: EntityDataConsts.UPLOAD_ENTITY_DOCUMENT_KO;
  error: string;
}

export interface RemoveDocumentEntityAction extends Action {
  type: EntityDataConsts.REMOVE_ENTITY_DOCUMENT;
  file_id: number;
  entityId: number;
}
export interface RemoveDocumentEntityOkAction extends Action {
  type: EntityDataConsts.REMOVE_ENTITY_DOCUMENT_OK;
  documents: DocumentationItem[];
}
export interface RemoveDocumentEntityKoAction extends Action {
  type: EntityDataConsts.REMOVE_ENTITY_DOCUMENT_KO;
  error: string;
}

export interface DownloadDocumentEntityAction extends Action {
  type: EntityDataConsts.DOWNLOAD_ENTITY_DOCUMENT;
  file_id: number;
  entityId: number;
  name: string;
}
export interface DownloadDocumentEntityOkAction extends Action {
  type: EntityDataConsts.DOWNLOAD_ENTITY_DOCUMENT_OK;
  data: Blob;
  name: string;
}
export interface DownloadDocumentEntityKoAction extends Action {
  type: EntityDataConsts.DOWNLOAD_ENTITY_DOCUMENT_KO;
  error: string;
}

export interface GetDocumentEntityAction extends Action {
  type: EntityDataConsts.GET_ENTITY_DOCUMENT;
  file_id: number;
  name: string;
  entityId: number;
}
export interface GetDocumentEntityOkAction extends Action {
  type: EntityDataConsts.GET_ENTITY_DOCUMENT_OK;
  dataDoc: Blob;
  name: string;
}
export interface GetDocumentEntityKoAction extends Action {
  type: EntityDataConsts.GET_ENTITY_DOCUMENT_KO;
  error: string;
}

export interface EntityGetDocumentsAction extends Action {
  type: EntityDataConsts.ENTITY_GET_DOCUMENTS;
  entityId: number;
}

export interface EntityGetDocumentsOkAction extends Action {
  type: EntityDataConsts.ENTITY_GET_DOCUMENTS_OK;
  documentation: DocumentationItem[];
}

export interface EntityGetDocumentsKoAction extends Action {
  type: EntityDataConsts.ENTITY_GET_DOCUMENTS_KO;
  error: string;
}

export interface UploadDocumentPreEntityAction extends Action {
  type: EntityDataConsts.PREENTITY_UPLOAD_DOCUMENT;
  documentation_id: number;
  data: File;
  force_add?: boolean | false;
}
export interface UploadDocumentPreEntityOkAction extends Action {
  type: EntityDataConsts.PREENTITY_UPLOAD_DOCUMENT_OK;
  documents: DocumentationItem[];
}
export interface UploadDocumentPreEntityKoAction extends Action {
  type: EntityDataConsts.PREENTITY_UPLOAD_DOCUMENT_KO;
  error: string;
}

export interface DownloadDocumentPreEntityAction extends Action {
  type: EntityDataConsts.PREENTITY_DOWNLOAD_DOCUMENT;
  file_id: number;
  name: string;
  entityId: number;
}
export interface DownloadDocumentPreEntityOkAction extends Action {
  type: EntityDataConsts.PREENTITY_DOWNLOAD_DOCUMENT_OK;
  data: Blob;
  name: string;
}
export interface DownloadDocumentPreEntityKoAction extends Action {
  type: EntityDataConsts.PREENTITY_DOWNLOAD_DOCUMENT_KO;
  error: string;
}

export interface GetDocumentPreEntityAction extends Action {
  type: EntityDataConsts.PREENTITY_GET_DOCUMENT;
  file_id: number;
  name: string;
  entityId: number;
}
export interface GetDocumentPreEntityOkAction extends Action {
  type: EntityDataConsts.PREENTITY_GET_DOCUMENT_OK;
  dataDoc: Blob;
  name: string;
}
export interface GetDocumentPreEntityKoAction extends Action {
  type: EntityDataConsts.PREENTITY_GET_DOCUMENT_KO;
  error: string;
}

export interface RemoveDocumentPreEntityAction extends Action {
  type: EntityDataConsts.PREENTITY_REMOVE_DOCUMENT;
  file_id: number;
  entityId: number;
}
export interface RemoveDocumentPreEntityOkAction extends Action {
  type: EntityDataConsts.PREENTITY_REMOVE_DOCUMENT_OK;
  documents: DocumentationItem[];
}
export interface RemoveDocumentPreEntityKoAction extends Action {
  type: EntityDataConsts.PREENTITY_REMOVE_DOCUMENT_KO;
  error: string;
}

export interface SetAvatarPreEntityAction extends Action {
  type: EntityDataConsts.PREENTITY_SET_AVATAR;
  avatar: string;
}
export interface SetAvatarPreEntityOkAction extends Action {
  type: EntityDataConsts.PREENTITY_SET_AVATAR_OK;
  avatar: string;
}
export interface SetAvatarPreEntityKoAction extends Action {
  type: EntityDataConsts.PREENTITY_SET_AVATAR_KO;
  error: string;
}

export interface SetAvatarEntityAction extends Action {
  type: EntityDataConsts.ENTITY_SET_AVATAR;
  avatar: string;
}
export interface SetAvatarEntityOkAction extends Action {
  type: EntityDataConsts.ENTITY_SET_AVATAR_OK;
  avatar: string;
}
export interface SetAvatarEntityKoAction extends Action {
  type: EntityDataConsts.ENTITY_SET_AVATAR_KO;
  error: string;
}

export interface GetAvatarPreEntityAction extends Action {
  type: EntityDataConsts.PREENTITY_GET_AVATAR;
  id: number;
}
export interface GetAvatarPreEntityOkAction extends Action {
  type: EntityDataConsts.PREENTITY_GET_AVATAR_OK;
  avatar: string;
}
export interface GetAvatarPreEntityKoAction extends Action {
  type: EntityDataConsts.PREENTITY_GET_AVATAR_KO;
  error: string;
}

export interface GetAvatarEntityAction extends Action {
  type: EntityDataConsts.ENTITY_GET_AVATAR;
  id: number;
}
export interface GetAvatarEntityOkAction extends Action {
  type: EntityDataConsts.ENTITY_GET_AVATAR_OK;
  avatar: string;
}
export interface GetAvatarEntityKoAction extends Action {
  type: EntityDataConsts.ENTITY_GET_AVATAR_KO;
  error: string;
}

export interface EntitySetDataAction extends Action {
  type: EntityDataConsts.ENTITY_SET_DATA;
  key: string;
  value: string | number;
}
export interface EntityContactSetDataAction extends Action {
  type: EntityDataConsts.ENTITY_CONTACT_SET_DATA;
  co_key: string;
  co_value: string | number;
}

// CHANGE RESPONSIBLE

export interface ChangeResponsibleAction extends Action {
  type: EntityDataConsts.ENTITY_CHANGE_RESPONSIBLE;
  id: number;
}

export interface ChangeResponsibleKoAction extends Action {
  type: EntityDataConsts.ENTITY_CHANGE_RESPONSIBLE_KO;
  error: string;
}

// MAKE PROVIDER

export interface MakeProvider extends Action {
  type: EntityDataConsts.MAKE_PROVIDER;
  id: number;
}

export interface MakeProviderOk extends Action {
  type: EntityDataConsts.MAKE_PROVIDER_OK;
  entity: EntityInfo;
}

export interface MakeProviderKo extends Action {
  type: EntityDataConsts.MAKE_PROVIDER_KO;
  error: string;
}

// SET MANDATORY DOCUMENT

export interface SetMandatoryDocument extends Action {
  type: EntityDataConsts.SET_MANDATORY_DOCUMENT;
  code: string;
  mandatory: boolean;
}

// CREATE NEW CONTRACT

export interface CreateNewContractAction extends Action {
  type: EntityDataConsts.CREATE_NEW_CONTRACT;
  newContract: EntityContract;
}

export interface CreateNewContractOkAction extends Action {
  type: EntityDataConsts.CREATE_NEW_CONTRACT_OK;
  entity_contracts: EntityContract[];
}

export interface CreateNewContractKoAction extends Action {
  type: EntityDataConsts.CREATE_NEW_CONTRACT_KO;
  error: string;
}

export interface SetContractDataAction extends Action {
  type: EntityDataConsts.SET_CONTRACT_DATA;
  key: string;
  value: string | number;
}

export interface AddContractAction extends Action {
  type: EntityDataConsts.ADD_CONTRACT;
  entity_id: number;
}

// GET CONTRACT
export interface GetContractAction extends Action {
  type: EntityDataConsts.GET_CONTRACT;
  contractId: number;
}

export interface GetContractOkAction extends Action {
  type: EntityDataConsts.GET_CONTRACT_OK;
  contract: EntityContract;
}

export interface GetContractKoAction extends Action {
  type: EntityDataConsts.GET_CONTRACT_KO;
  error: string;
}

// EDIT CONTRACT
export interface EditContractAction extends Action {
  type: EntityDataConsts.EDIT_CONTRACT;
  contractData: EntityContract;
}

export interface EditContractOkAction extends Action {
  type: EntityDataConsts.EDIT_CONTRACT_OK;
  entity_contracts: EntityContract[];
}
export interface EditContractKoAction extends Action {
  type: EntityDataConsts.EDIT_CONTRACT_KO;
  error: string;
}

// DELETE CONTRACT

export interface DeleteContractAction extends Action {
  type: EntityDataConsts.DELETE_CONTRACT;
  contractData: EntityContract;
}

export interface DeleteContractOkAction extends Action {
  type: EntityDataConsts.DELETE_CONTRACT_OK;
  contractData: EntityContract;
}

export interface DeleteContractKoAction extends Action {
  type: EntityDataConsts.DELETE_CONTRACT_KO;
  error: string;
}

export interface ResetContractAction extends Action {
  type: EntityDataConsts.RESET_CONTRACT;
}

// CONTRACT DOCUMENTATION

export interface UploadContractDocumentAction extends Action {
  type: EntityDataConsts.UPLOAD_CONTRACT_DOCUMENT;
  file: File;
}

export interface UploadContractDocumentOkAction extends Action {
  type: EntityDataConsts.UPLOAD_CONTRACT_DOCUMENT_OK;
  fileData: FileItem;
}

export interface UploadContractDocumentKoAction extends Action {
  type: EntityDataConsts.UPLOAD_CONTRACT_DOCUMENT_KO;
  error: string;
}

export interface RemoveContractDocumentAction extends Action {
  type: EntityDataConsts.REMOVE_CONTRACT_DOCUMENT;
  file_id: number;
}

export interface RemoveContractDocumentOkAction extends Action {
  type: EntityDataConsts.REMOVE_CONTRACT_DOCUMENT_OK;
}

export interface RemoveContractDocumentKoAction extends Action {
  type: EntityDataConsts.REMOVE_CONTRACT_DOCUMENT_KO;
  error: string;
}

export interface DownloadContractDocumentAction extends Action {
  type: EntityDataConsts.DOWNLOAD_CONTRACT_DOCUMENT;
  file_id: number;
  filename: string;
}

export interface DownloadContractDocumentOkAction extends Action {
  type: EntityDataConsts.DOWNLOAD_CONTRACT_DOCUMENT_OK;
  data: Blob;
  name: string;
}

export interface DownloadContractDocumentKoAction extends Action {
  type: EntityDataConsts.DOWNLOAD_CONTRACT_DOCUMENT_KO;
  error: string;
}

export interface RejectInternalEntityAction extends Action {
  type: EntityDataConsts.REJECT_INTERNAL_ENTITY;
  id: number;
  comment: string;
}

export interface RejectInternalEntityOkAction extends Action {
  type: EntityDataConsts.REJECT_INTERNAL_ENTITY_OK;
  entityData: EntityInfo;
}

export interface RejectInternalEntityKoAction extends Action {
  type: EntityDataConsts.REJECT_INTERNAL_ENTITY_KO;
  error: string;
}

export interface RejectExternalEntityAction extends Action {
  type: EntityDataConsts.REJECT_EXTERNAL_ENTITY;
  id: number;
  comment: string;
}

export interface RejectExternalEntityOkAction extends Action {
  type: EntityDataConsts.REJECT_EXTERNAL_ENTITY_OK;
  entityData: EntityInfo;
}

export interface RejectExternalEntityKoAction extends Action {
  type: EntityDataConsts.REJECT_EXTERNAL_ENTITY_KO;
  error: string;
}

export interface RejectEntityDirectorAction extends Action {
  type: EntityDataConsts.REJECT_ENTITY_DIRECTOR;
  id: number;
  comment: string;
}

export interface RejectEntityDirectorOkAction extends Action {
  type: EntityDataConsts.REJECT_ENTITY_DIRECTOR_OK;
  entityData: EntityInfo;
}

export interface RejectEntityDirectorKoAction extends Action {
  type: EntityDataConsts.REJECT_ENTITY_DIRECTOR_KO;
  error: string;
}

export interface ApproveEntityDirectorAction extends Action {
  type: EntityDataConsts.APPROVE_ENTITY_DIRECTOR;
  id: number;
}

export interface ApproveEntityDirectorOkAction extends Action {
  type: EntityDataConsts.APPROVE_ENTITY_DIRECTOR_OK;
  entityData: EntityInfo;
}

export interface ApproveEntityDirectorKoAction extends Action {
  type: EntityDataConsts.APPROVE_ENTITY_DIRECTOR_KO;
  error: string;
}

export interface ConfirmEntityAction extends Action {
  type: EntityDataConsts.CONFIRM_ENTITY;
  id: number;
}

export interface ConfirmEntityOkAction extends Action {
  type: EntityDataConsts.CONFIRM_ENTITY_OK;
  entityData: EntityInfo;
}

export interface ConfirmEntityKoAction extends Action {
  type: EntityDataConsts.CONFIRM_ENTITY_KO;
  error: string;
}

export interface ClearNavigationAction extends Action {
  type: EntityDataConsts.CLEAR_NAVIGATION;
}

export interface OpenContractModalAction extends Action {
  type: EntityDataConsts.OPEN_CONTRACT_MODAL;
}

export interface CloseContractModalAction extends Action {
  type: EntityDataConsts.CLOSE_CONTRACT_MODAL;
}

export type EntityDataAction =
  | EntityGetListAction
  | EntityGetListOkAction
  | EntityGetListKoAction
  | EntityValidationErrorsAction
  | EntityFncSendAction
  | EntityFncSendOkAction
  | EntityFncSendKoAction
  | EntityUserCreateAction
  | EntityUserCreateOkAction
  | EntityUserCreateKoAction
  | EntityUpdateAction
  | EntityUpdateKoAction
  | EntityUpdateOkAction
  | EntityGetOneAction
  | EntityGetOneActionKo
  | EntityGetOneActionOk
  | EntityDeleteOneAction
  | EntityDeleteOneActionKo
  | EntityDeleteOneActionOk
  | ResetResponseDataAction
  | ResetAllSuccessAction
  | EntitySetPageSizeTableAction
  | EntitySetSearchTextTableAction
  | EntitySetColumnOrderTableAction
  | CreateEntityFncAction
  | PreEntityUpdateAction
  | PreEntityUpdateOkAction
  | PreEntityUpdateKoAction
  | EntityGetPreCreatedUserAction
  | EntityGetPreCreatedUserOkAction
  | EntityGetPreCreatedUserKoAction
  | GetEntityPreCreatedIdAction
  | GetEntityPreCreatedIdActionOkAction
  | GetEntityPreCreatedIdActionKoAction
  | EntityGetForUser
  | EntityGetForUserOk
  | EntityGetForUserKo
  | EntitiesChangeAction
  | UploadDocumentEntityAction
  | UploadDocumentEntityOkAction
  | UploadDocumentEntityKoAction
  | RemoveDocumentEntityAction
  | RemoveDocumentEntityOkAction
  | RemoveDocumentEntityKoAction
  | DownloadDocumentEntityAction
  | DownloadDocumentEntityOkAction
  | DownloadDocumentEntityKoAction
  | GetDocumentEntityAction
  | GetDocumentEntityOkAction
  | GetDocumentEntityKoAction
  | EntityGetDocumentsAction
  | EntityGetDocumentsOkAction
  | EntityGetDocumentsKoAction
  | UploadDocumentPreEntityAction
  | UploadDocumentPreEntityOkAction
  | UploadDocumentPreEntityKoAction
  | DownloadDocumentPreEntityAction
  | DownloadDocumentPreEntityOkAction
  | DownloadDocumentPreEntityKoAction
  | RemoveDocumentPreEntityAction
  | RemoveDocumentPreEntityOkAction
  | RemoveDocumentPreEntityKoAction
  | GetDocumentPreEntityAction
  | GetDocumentPreEntityOkAction
  | GetDocumentPreEntityKoAction
  | SetAvatarPreEntityAction
  | SetAvatarPreEntityOkAction
  | SetAvatarPreEntityKoAction
  | SetAvatarEntityAction
  | SetAvatarEntityOkAction
  | SetAvatarEntityKoAction
  | EntityChangeStateAction
  | EntityChangeStateOkAction
  | EntityChangeStateKoAction
  | EntitySetDataAction
  | GetAvatarPreEntityAction
  | GetAvatarPreEntityKoAction
  | GetAvatarEntityAction
  | GetAvatarEntityOkAction
  | GetAvatarPreEntityOkAction
  | GetAvatarEntityKoAction
  | EntitySetDataAction
  | ChangeResponsibleAction
  | ChangeResponsibleKoAction
  | MakeProvider
  | MakeProviderOk
  | MakeProviderKo
  | SetMandatoryDocument
  | CreateNewContractAction
  | CreateNewContractOkAction
  | CreateNewContractKoAction
  | SetContractDataAction
  | AddContractAction
  | GetContractAction
  | GetContractOkAction
  | GetContractKoAction
  | EditContractAction
  | EditContractOkAction
  | EditContractKoAction
  | DeleteContractAction
  | DeleteContractOkAction
  | DeleteContractKoAction
  | ResetContractAction
  | UploadContractDocumentAction
  | UploadContractDocumentOkAction
  | UploadContractDocumentKoAction
  | RemoveContractDocumentAction
  | RemoveContractDocumentOkAction
  | RemoveContractDocumentKoAction
  | DownloadContractDocumentAction
  | DownloadContractDocumentOkAction
  | DownloadContractDocumentKoAction
  | RejectInternalEntityAction
  | RejectInternalEntityOkAction
  | RejectInternalEntityKoAction
  | RejectExternalEntityAction
  | RejectExternalEntityOkAction
  | RejectExternalEntityKoAction
  | ConfirmEntityAction
  | ConfirmEntityOkAction
  | ConfirmEntityKoAction
  | ClearNavigationAction
  | RejectEntityDirectorAction
  | RejectEntityDirectorOkAction
  | RejectEntityDirectorKoAction
  | ApproveEntityDirectorAction
  | ApproveEntityDirectorOkAction
  | ApproveEntityDirectorKoAction
  | OpenContractModalAction
  | CloseContractModalAction
  | ResetDocumentsAction
  | EntityContactsListAction
  | EntityContactsListOkAction
  | EntityContactsListKoAction
  | EntityOneContactKoAction
  | EntityOneContactOkAction
  | EntityOneContactAction
  | RemoveContactEntityAction
  | RemoveContactEntityOkAction
  | RemoveContactEntityKoAction
  | EntityContactRolesAction
  | EntityContactRolesOkAction
  | EntityContactRolesKoAction
  | CreateEntityContactAction
  | CreateEntityContactOkAction
  | CreateEntityContactKoAction
  | EntityUpdateContactAction
  | EntityUpdateContactOkAction
  | EntityUpdateContactKoAction
  | DeleteContactDataAction
  | OpenContactModalAction
  | CloseContactModalAction
  | CloseIbanModalAction
  | OpenIbanModalAction
  | DeleteIbanDataAction
  | ListEntityIbansAction
  | ListEntityIbansOkAction
  | ListEntityIbansKoAction
  | EntityOneIbanAction
  | EntityOneIbanOkAction
  | EntityOneIbanKoAction
  | RemoveIbanEntityAction
  | RemoveIbanEntityOkAction
  | RemoveIbanEntityKoAction
  | CreateEntityIbantAction
  | CreateEntityIbanOkAction
  | CreateEntityIbanKoAction
  | EntityUpdateIbanAction
  | EntityUpdateIbanOkAction
  | EntityUpdateIbanKoAction
  | DownloadIbanDocumentAction
  | DownloadIbanDocumentOkAction
  | DownloadIbanDocumentKoAction
  | UploadIbanEntityDocumentAction
  | UploadIbanEntityDocumentOkAction
  | UploadIbanEntityDocumentKoAction
  | RemoveIbanEntityDocumentAction
  | RemoveIbanEntityDocumentOkAction
  | RemoveIbanEntityDocumentKoAction
  | EntitySetIbanDataAction
  | EntityContactSetDataAction;

export type EntityGetAllDispatch = (action: EntityDataAction) => void;
export type EntityCreateDispatch = (action: EntityDataAction) => void;
export type EntityUpdateDispatch = (action: EntityDataAction) => void;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';
import {
  acceptInvoiceFromApi,
  authorizeInvoiceFromApi,
  closeInvoicesFromApi,
  confirmInvoiceFromApi,
  createExpenseAccountApi,
  createInvoiceFromApi,
  createNewInvoiceDetailFromApi,
  deleteExpenseAccountByIdApi,
  deleteInvoiceDetailFromApi,
  deleteInvoiceDocumentFromApi,
  deleteInvoiceFromApi,
  doTransitionBackInvoice,
  downloadInvoiceDocumentFromApi,
  editAccountingSettingsApi,
  editExpenseAccountApi,
  editInvoiceDetailFromApi,
  editInvoiceFromApi,
  getAccountingSettingsFromApi,
  getExpenseAccountListFromApi,
  getInvoiceDetailFromApi,
  getInvoiceFromApi,
  getInvoicesDetailListFromApi,
  getOneExpenseAccountByIdApi,
  getOnePendingInvoiceFromApi,
  getPendingToSendInvoicesFromApi,
  getScholarshipSetting,
  markInvoiceAsPaidApi,
  moveBackInvoiceFromApi,
  payInvoices,
  removePendingInvoiceFromApi,
  sendInvoiceFromApi,
  sendInvoicesToPay,
  toPayInvoiceFromApi,
  updatePendingInvoiceFromApi,
  updateScholarshipSetting,
  uploadInvoiceDocumentFromApi,
} from '../../api/accounting';
import { Await } from '../../api/api';
import errorMessage from '../../helpers/errorMessage';
import { tKey } from '../../helpers/translate';
import { showSnackbar } from '../FeedbackAPI/actions';
import {
  acceptInvoiceKo,
  acceptInvoiceOk,
  authorizeInvoiceKo,
  authorizeInvoiceOk,
  changeStateKo,
  changeStateOk,
  closeInvoicesKo,
  closeInvoicesOk,
  confirmInvoiceKo,
  confirmInvoiceOk,
  createExpenseAccountKo,
  createExpenseAccountOk,
  createInvoiceDataKo,
  createInvoiceDataOk,
  createNewInvoiceDetailKo,
  createNewInvoiceDetailOk,
  deleteExpenseAccountKo,
  deleteExpenseAccountOk,
  deleteInvoiceDataKo,
  deleteInvoiceDataOk,
  deleteInvoiceDetailKo,
  deleteInvoiceDetailOk,
  downloadInvoiceDocumentKo,
  downloadInvoiceDocumentOk,
  editAccountingSettingsKo,
  editAccountingSettingsOk,
  editExpenseAccountKo,
  editExpenseAccountOk,
  editInvoiceDataKo,
  editInvoiceDataOk,
  editInvoiceDetailKo,
  editInvoiceDetailOk,
  getAccountingSettingsKo,
  getAccountingSettingsOk,
  getExpenseAccountListKo,
  getExpenseAccountListOk,
  getInvoiceDataKo,
  getInvoiceDataOk,
  getInvoiceDetailKo,
  getInvoiceDetailOk,
  getInvoicesDetailListKo,
  getInvoicesDetailListOk,
  getOneExpenseAccountKo,
  getOneExpenseAccountOk,
  getOnePendingInvoiceKo,
  getOnePendingInvoiceOk,
  getPendingToSendInvoicesKo,
  getPendingToSendInvoicesOk,
  getScholarshipSettingKo,
  getScholarshipSettingOk,
  invoiceValidationErrors,
  markInvoiceAsPaidKo,
  markInvoiceAsPaidOk,
  moveBackInvoiceOk,
  moveBackInvoiceKo,
  payInvoicesOk,
  removeInvoiceDocumentKo,
  removeInvoiceDocumentOk,
  removePendingInvoiceKo,
  scholarshipSettingValidationErrors,
  sendInvoiceKo,
  sendInvoiceOk,
  sendInvoicesToPayOk,
  toPayInvoiceKo,
  toPayInvoiceOk,
  updateScholarshipSettingKo,
  updateScholarshipSettingOk,
  uploadInvoiceDocumentKo,
  uploadInvoiceDocumentOk,
  downloadInvoiceContractOkAction,
  downloadInvoiceContractKoAction,
} from './actions';
import { AccountingConsts } from './action_types';
import {
  AuthorizeInvoiceAction,
  ChangeState,
  CloseInvoicesAction,
  CreateExpenseAccountAction,
  CreateInvoiceAction,
  CreateNewInvoiceDetailAction,
  DeleteExpenseAccountAction,
  DeleteInvoiceDataAction,
  DeleteInvoiceDetailAction,
  DownloadInvoiceContractAction,
  DownloadInvoiceDocumentAction,
  EditAccountingSettingsAction,
  EditExpenseAccountAction,
  EditInvoiceDataAction,
  EditInvoiceDetailAction,
  GetAccountingSettingsAction,
  GetExpenseAccountListAction,
  GetInvoiceDataAction,
  GetInvoiceDetailAction,
  GetInvoicesDetailListAction,
  GetOneExpenseAccountAction,
  GetOnePendingInvoiceAction,
  GetPendingToSendInvoicesAction,
  GetScholarshipSettingAction,
  InvoiceData,
  MarkInvoiceAsPaidAction,
  MoveBackInvoiceAction,
  PayInvoicesAction,
  RemoveInvoiceDocumentAction,
  RemovePendingInvoiceAction,
  SendInvoiceAction,
  SendInvoicesToPayAction,
  ToPayInvoiceAction,
  UpdatePendingInvoiceAction,
  UpdateScholarshipSettingAction,
  UploadInvoiceDocumentAction,
} from './definitions';
import { downloadEntityContractFromApi } from '../../api/Entity/entity';

function* getExpenseAccountListFromSaga(): Generator<any, void, any> {
  try {
    const data = yield getExpenseAccountListFromApi();
    yield put(getExpenseAccountListOk(data));
  } catch (e) {
    yield put(getExpenseAccountListKo(errorMessage(e)));
  }
}

function* createExpenseFromSaga(action: CreateExpenseAccountAction): Generator<any, void, any> {
  try {
    yield createExpenseAccountApi(action.newExpenseAccount);
    yield put(createExpenseAccountOk());
    yield put(
      showSnackbar(
        tKey('La cuenta de gasto se ha creado correctamente'),
        'success',
        '/contabilidad/cuentas-de-gasto',
        1000,
      ),
    );
  } catch (e) {
    yield put(createExpenseAccountKo(errorMessage(e)));
    yield put(
      showSnackbar(
        tKey('Ha ocurrido algún problema al crear la cuenta de gasto'),
        'error',
        '/contabilidad/cuentas-de-gasto',
        1500,
      ),
    );
  }
}

function* deleteExpenseAccountFromSaga(action: DeleteExpenseAccountAction): Generator<any, void, any> {
  try {
    const { id } = action;
    yield deleteExpenseAccountByIdApi(id);
    yield put(deleteExpenseAccountOk(id));
    yield put(showSnackbar(tKey('La cuenta de gasto se ha eliminado correctamente'), 'success', undefined, 1500));
  } catch (e) {
    yield put(deleteExpenseAccountKo(errorMessage(e)));
    yield put(
      showSnackbar(tKey('Ha ocurrido algún problema al eleminar la cuenta de gasto'), 'error', undefined, 1500),
    );
  }
}

function* getOneExpenseAccountFromSaga(action: GetOneExpenseAccountAction): Generator<any, void, any> {
  try {
    const { id } = action;
    const { data } = (yield getOneExpenseAccountByIdApi(id)) as Await<ReturnType<typeof getOneExpenseAccountByIdApi>>;
    yield put(getOneExpenseAccountOk(data));
  } catch (e) {
    yield put(getOneExpenseAccountKo(errorMessage(e)));
  }
}

function* editExpenseAccountFromSaga(action: EditExpenseAccountAction): Generator<any, void, any> {
  try {
    const result = yield editExpenseAccountApi(action.expenseAccount, action.id);
    yield put(editExpenseAccountOk(result.value.data));
    yield put(
      showSnackbar(tKey('Los datos se han guardado correctamente'), 'success', '/contabilidad/cuentas-de-gasto', 1000),
    );
  } catch (e) {
    yield put(showSnackbar(tKey('Ha ocurrido algún problema al editar la cuenta de gasto'), 'error', undefined, 1500));
    yield put(editExpenseAccountKo(errorMessage(e)));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getAccountingSettingsFromSaga(): Generator<any, void, any> {
  try {
    const data = yield getAccountingSettingsFromApi();
    yield put(getAccountingSettingsOk(data));
  } catch (e) {
    yield put(getAccountingSettingsKo(errorMessage(e)));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* editAccoutingSettingsFromSaga(action: EditAccountingSettingsAction): Generator<any, void, any> {
  try {
    yield editAccountingSettingsApi(action.accountingSettings);
    yield put(editAccountingSettingsOk());
    yield put(showSnackbar(tKey('Los datos se han guardado correctamente'), 'success'));
  } catch (e) {
    yield put(editAccountingSettingsKo(errorMessage(e)));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getInvoiceDataFromSaga(action: GetInvoiceDataAction): Generator<any, void, any> {
  try {
    const invoice = (yield getInvoiceFromApi(action.id)) as Await<ReturnType<typeof getInvoiceFromApi>>;
    yield put(getInvoiceDataOk(invoice));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    put(getInvoiceDataKo(errorMessage(e)));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* editInvoiceDataFromSaga(action: EditInvoiceDataAction): Generator<any, void, any> {
  try {
    const result = (yield editInvoiceFromApi(action.invoice)) as Await<ReturnType<typeof editInvoiceFromApi>>;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(editInvoiceDataOk(data));
        yield put(showSnackbar('Factura editada correctamente', 'success', undefined, 3000));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(invoiceValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(showSnackbar(tKey('Ha ocurrido algún problema al editar'), 'error', undefined, 1500));
    put(editInvoiceDataKo(errorMessage(e)));
  }
}

function* getInvoiceDetailFromSaga(action: GetInvoiceDetailAction): Generator<any, void, any> {
  try {
    const result = yield getInvoiceDetailFromApi(action.id);
    yield put(getInvoiceDetailOk(result));
  } catch (e) {
    yield put(getInvoiceDetailKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* getInvoicesDetailListFromSaga(action: GetInvoicesDetailListAction): Generator<any, void, any> {
  try {
    const invoicesDetailList = yield getInvoicesDetailListFromApi(action.invoice_id);
    yield put(getInvoicesDetailListOk(invoicesDetailList));
  } catch (e) {
    yield put(getInvoicesDetailListKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* createNewInvoiceDetailFromSaga(action: CreateNewInvoiceDetailAction): Generator<any, void, any> {
  try {
    const result = (yield createNewInvoiceDetailFromApi(action.newInvoiceDetail)) as Await<
      ReturnType<typeof createNewInvoiceDetailFromApi>
    >;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(createNewInvoiceDetailOk(data));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(invoiceValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(createNewInvoiceDetailKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* editInvoiceDetailFromSaga(action: EditInvoiceDetailAction): Generator<any, void, any> {
  try {
    const result = (yield editInvoiceDetailFromApi(action.invoiceDetail)) as Await<
      ReturnType<typeof editInvoiceDetailFromApi>
    >;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(editInvoiceDetailOk(data));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(invoiceValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(editInvoiceDetailKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* deleteInvoiceDetailFromSaga(action: DeleteInvoiceDetailAction): Generator<any, void, any> {
  try {
    yield deleteInvoiceDetailFromApi(action.invoiceDetail);
    yield put(deleteInvoiceDetailOk(action.invoiceDetail));
  } catch (e) {
    yield put(deleteInvoiceDetailKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any

function* deleteInvoiceDataFromSaga(action: DeleteInvoiceDataAction): Generator<any, void, any> {
  try {
    yield deleteInvoiceFromApi(action.id);
    yield put(deleteInvoiceDataOk());
    yield put(showSnackbar(tKey('La factura se ha eliminado correctamente'), 'success', undefined, 1500));
  } catch (e) {
    yield put(deleteInvoiceDataKo(errorMessage(e)));
    yield put(
      showSnackbar(tKey('Ha ocurrido algún problema al eleminar la cuenta de gasto'), 'error', undefined, 1500),
    );
  }
}

function* uploadInvoiceDocumentFromSaga(action: UploadInvoiceDocumentAction): Generator<any, void, any> {
  try {
    const { file, invoice_id } = action;
    const fileData = yield uploadInvoiceDocumentFromApi(file, invoice_id);
    yield put(uploadInvoiceDocumentOk(fileData));
    yield put(showSnackbar('Factura subida correctamente', 'success'));
  } catch (e) {
    yield put(uploadInvoiceDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al subir el archivo', 'error'));
  }
}

function* removeInvoiceDocumentFromSaga(action: RemoveInvoiceDocumentAction): Generator<any, void, any> {
  try {
    const { file_id } = action;
    yield deleteInvoiceDocumentFromApi(file_id);
    yield put(removeInvoiceDocumentOk());
  } catch (e) {
    yield put(removeInvoiceDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al borrar el archivo', 'error'));
  }
}

function* downloadInvoiceDocumentFromSaga(action: DownloadInvoiceDocumentAction): Generator<any, void, any> {
  try {
    const { file_id, filename } = action;
    const fileData = yield downloadInvoiceDocumentFromApi(file_id);
    yield put(downloadInvoiceDocumentOk(fileData, filename));
  } catch (e) {
    yield put(downloadInvoiceDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al descargar el archivo', 'error'));
  }
}

function* createInvoiceFromSaga(action: CreateInvoiceAction): Generator<any, void, any> {
  try {
    const { entity_id } = action;
    const invoice = yield createInvoiceFromApi(entity_id);
    yield put(createInvoiceDataOk(invoice));
  } catch (e) {
    yield put(createInvoiceDataKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al crear una factura', 'error'));
  }
}

function* changeStateFromSaga(action: ChangeState): Generator<any, void, any> {
  try {
    const { invoiceID, comments, state } = action;
    const result = (yield doTransitionBackInvoice(invoiceID, comments, state)) as Await<
      ReturnType<typeof doTransitionBackInvoice>
    >;
    yield put(changeStateOk(result.data));
    yield put(showSnackbar(tKey('La factura se ha rechazado correctamente'), 'success', undefined, 1000));
  } catch (e) {
    yield put(changeStateKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al rechazar una factura', 'error'));
  }
}
function* sendInvoiceFromSaga(action: SendInvoiceAction): Generator<any, void, any> {
  try {
    const result = (yield sendInvoiceFromApi(action.invoiceData)) as Await<ReturnType<typeof sendInvoiceFromApi>>;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(sendInvoiceOk(data));
        yield put(showSnackbar('Factura enviada correctamente', 'success', undefined, 3000));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(invoiceValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(sendInvoiceKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* acceptInvoiceFromSaga(action: SendInvoiceAction): Generator<any, void, any> {
  try {
    const result = (yield acceptInvoiceFromApi(action.invoiceData)) as Await<ReturnType<typeof sendInvoiceFromApi>>;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(acceptInvoiceOk(data));
        yield put(showSnackbar('Factura aceptada correctamente', 'success', undefined, 3000));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(invoiceValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(acceptInvoiceKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* confirmInvoiceFromSaga(action: SendInvoiceAction): Generator<any, void, any> {
  try {
    const { data } = (yield confirmInvoiceFromApi(action.invoiceData)) as Await<{ data: InvoiceData }>;
    yield put(confirmInvoiceOk(data));
    yield put(showSnackbar('Factura confirmada correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(confirmInvoiceKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* toPayInvoiceFromSaga(action: ToPayInvoiceAction): Generator<any, void, any> {
  try {
    const { data: invoiceData } = yield toPayInvoiceFromApi({ id: action.id });
    yield put(toPayInvoiceOk(invoiceData));
    yield put(showSnackbar('Factura procesada correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(toPayInvoiceKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* payInvoiceFromSaga(): Generator<any, void, any> {
  try {
    const result: { messages: string[]; paid_invoices: number } = yield payInvoices();

    yield put(payInvoicesOk());
    const message = result.paid_invoices.toString().concat(' Facturas envidadas');
    if (result.messages.length > 0) {
      const warn_msg = message.concat(' / ', result.messages.join(' / '));
      yield put(showSnackbar(warn_msg, 'warning', undefined, 10000));
    } else {
      yield put(showSnackbar(message, 'success', undefined, 3000));
    }
  } catch (e) {
    yield put(toPayInvoiceKo(errorMessage(e))); // loading: false
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* sendInvoicesToPayFromSaga(): Generator<any, void, any> {
  try {
    yield sendInvoicesToPay();
    yield put(sendInvoicesToPayOk());
    yield put(showSnackbar('Facturas pendientes de pago', 'success', undefined, 3000));
  } catch (e) {
    yield put(toPayInvoiceKo(errorMessage(e))); // loading: false
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* getScholarshipSettingFromSaga(): Generator<any, void, any> {
  try {
    const setting = yield getScholarshipSetting();
    yield put(getScholarshipSettingOk(setting));
  } catch (e) {
    yield put(showSnackbar('Hubo un error al cargar la configuración.', 'error', undefined, 1500));
    yield put(getScholarshipSettingKo(errorMessage(e)));
  }
}

function* updateScholarshipSettingFromSaga(action: UpdateScholarshipSettingAction): Generator<any, void, any> {
  try {
    const result = yield updateScholarshipSetting(action.value);
    switch (result.type) {
      case 'ok':
        yield put(updateScholarshipSettingOk(result.value.data));
        yield put(showSnackbar('Ajustes guardados correctamente.', 'success', undefined, 1500));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(scholarshipSettingValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(showSnackbar('Hubo un error al actualizar la configuración.', 'error', undefined, 1500));
    yield put(updateScholarshipSettingKo(errorMessage(e)));
  }
}

function* getPendingToSendInvoicesFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getPendingToSendInvoicesFromApi();
    yield put(getPendingToSendInvoicesOk(data));
  } catch (e) {
    yield put(getPendingToSendInvoicesKo(errorMessage(e)));
  }
}

function* removePendingInvoiceFromSaga(action: RemovePendingInvoiceAction): Generator<any, void, any> {
  try {
    const { data } = yield removePendingInvoiceFromApi(action.pendingInvoice_id);
    yield put(getPendingToSendInvoicesOk(data));
  } catch (e) {
    yield put(removePendingInvoiceKo(errorMessage(e)));
  }
}

function* getOnePendingInvoiceFromSaga(action: GetOnePendingInvoiceAction): Generator<any, void, any> {
  try {
    const { data } = yield getOnePendingInvoiceFromApi(action.pendingInvoice_id);
    yield put(getOnePendingInvoiceOk(data));
  } catch (e) {
    yield put(getOnePendingInvoiceKo(errorMessage(e)));
  }
}

function* updatePendingInvoiceFromSaga(action: UpdatePendingInvoiceAction): Generator<any, void, any> {
  try {
    const result = yield updatePendingInvoiceFromApi(action.invoice);
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(getPendingToSendInvoicesOk(data));
        yield put(showSnackbar('Factura modificada correctamente.', 'success', undefined, 1500));
        return;
      case 'validation-error':
        throw Error(result.value);
    }
  } catch (e) {
    yield put(getOnePendingInvoiceKo(errorMessage(e)));
    yield put(showSnackbar('No se ha podido actualizar la factura.', 'error', undefined, 1500));
  }
}

function* markInvoiceAsPaidActionFromSaga(action: MarkInvoiceAsPaidAction): Generator<any, void, any> {
  try {
    const invoice = yield markInvoiceAsPaidApi(action.invoice_id);
    yield put(markInvoiceAsPaidOk(invoice));
    yield put(showSnackbar('Factura marcada como pagada.', 'success', undefined, 1500));
  } catch (e) {
    yield put(markInvoiceAsPaidKo(errorMessage(e)));
    yield put(
      showSnackbar('No se ha podido realizar la acción. Comprobar el estado de la factura', 'error', undefined, 1500),
    );
  }
}

function* authorizeInvoiceFromSaga(action: AuthorizeInvoiceAction): Generator<any, void, any> {
  try {
    const invoice = yield authorizeInvoiceFromApi(action.invoice_id);
    yield put(authorizeInvoiceOk(invoice));
    yield put(showSnackbar('Pago autorizado', 'success', undefined, 3000));
  } catch (e) {
    yield put(authorizeInvoiceKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* closeInvoicesFromSaga(action: CloseInvoicesAction): Generator<any, void, any> {
  try {
    const invoices_affected = yield closeInvoicesFromApi(action.closing_year);
    yield put(closeInvoicesOk());
    yield put(
      showSnackbar(
        `Ejercicio ${action.closing_year} cerrado. ${invoices_affected} facturas cerradas.`,
        'success',
        undefined,
        3000,
      ),
    );
  } catch (e) {
    yield put(closeInvoicesKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* moveBackInvoiceFromSaga(action: MoveBackInvoiceAction): Generator<any, void, any> {
  try {
    const invoice = yield moveBackInvoiceFromApi(action.invoice_id);
    yield put(moveBackInvoiceOk(invoice));
    yield put(showSnackbar('Factura retrocedida a Aceptada.', 'success', undefined, 1500));
  } catch (e) {
    yield put(moveBackInvoiceKo(errorMessage(e)));
    yield put(
      showSnackbar('No se ha podido realizar la acción. Comprobar el estado de la factura', 'error', undefined, 1500),
    );
  }
}

function* downloadInvoiceContractFromApi(action: DownloadInvoiceContractAction): Generator<any, void, any> {
  try {
    const { entity_id, contract_number } = action;
    const fileData = yield downloadEntityContractFromApi(entity_id, contract_number);
    yield put(downloadInvoiceContractOkAction(fileData, contract_number));
  } catch (e) {
    yield put(downloadInvoiceContractKoAction(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al descargar el archivo', 'error'));
  }
}

export default [
  takeLatest<GetExpenseAccountListAction>(AccountingConsts.GET_EXPENSE_ACCOUNT_LIST, getExpenseAccountListFromSaga),
  takeLatest<CreateExpenseAccountAction>(AccountingConsts.CREATE_EXPENSE_ACCOUNT, createExpenseFromSaga),
  takeLatest<DeleteExpenseAccountAction>(AccountingConsts.DELETE_EXPENSE_ACCOUNT, deleteExpenseAccountFromSaga),
  takeLatest<GetOneExpenseAccountAction>(AccountingConsts.GET_ONE_EXPENSE_ACCOUNT, getOneExpenseAccountFromSaga),
  takeLatest<EditExpenseAccountAction>(AccountingConsts.EDIT_EXPENSE_ACCOUNT, editExpenseAccountFromSaga),
  takeLatest<GetAccountingSettingsAction>(AccountingConsts.GET_ACCOUNTING_SETTINGS, getAccountingSettingsFromSaga),
  takeLatest<EditAccountingSettingsAction>(AccountingConsts.EDIT_ACCOUNTING_SETTINGS, editAccoutingSettingsFromSaga),
  takeLatest<GetInvoiceDataAction>(AccountingConsts.GET_INVOICE_DATA, getInvoiceDataFromSaga),
  takeLatest<EditInvoiceDataAction>(AccountingConsts.EDIT_INVOICE_DATA, editInvoiceDataFromSaga),
  takeLatest<DeleteInvoiceDataAction>(AccountingConsts.DELETE_INVOICE_DATA, deleteInvoiceDataFromSaga),
  takeLatest<UploadInvoiceDocumentAction>(AccountingConsts.UPLOAD_INVOICE_DOCUMENT, uploadInvoiceDocumentFromSaga),
  takeLatest<RemoveInvoiceDocumentAction>(AccountingConsts.REMOVE_INVOICE_DOCUMENT, removeInvoiceDocumentFromSaga),
  takeLatest<DownloadInvoiceDocumentAction>(AccountingConsts.DOWNLOAD_INVOICE, downloadInvoiceDocumentFromSaga),
  takeLatest<CreateInvoiceAction>(AccountingConsts.CREATE_INVOICE, createInvoiceFromSaga),
  takeLatest<CreateNewInvoiceDetailAction>(AccountingConsts.CREATE_NEW_INVOICE_DETAIL, createNewInvoiceDetailFromSaga),
  takeLatest<GetInvoiceDetailAction>(AccountingConsts.GET_INVOICE_DETAIL, getInvoiceDetailFromSaga),
  takeLatest<EditInvoiceDetailAction>(AccountingConsts.EDIT_INVOICE_DETAIL, editInvoiceDetailFromSaga),
  takeLatest<DeleteInvoiceDetailAction>(AccountingConsts.DELETE_INVOICE_DETAIL, deleteInvoiceDetailFromSaga),
  takeLatest<GetInvoicesDetailListAction>(AccountingConsts.GET_INVOICES_DETAIL_LIST, getInvoicesDetailListFromSaga),
  takeLatest<ChangeState>(AccountingConsts.CHANGE_STATE, changeStateFromSaga),
  takeLatest<MoveBackInvoiceAction>(AccountingConsts.MOVE_BACK_INVOICE, moveBackInvoiceFromSaga),
  takeLatest<SendInvoiceAction>(AccountingConsts.SEND_INVOICE, sendInvoiceFromSaga),
  takeLatest<SendInvoiceAction>(AccountingConsts.ACCEPT_INVOICE, acceptInvoiceFromSaga),
  takeLatest<SendInvoiceAction>(AccountingConsts.CONFIRM_INVOICE, confirmInvoiceFromSaga),
  takeLatest<ToPayInvoiceAction>(AccountingConsts.TO_PAY_INVOICE, toPayInvoiceFromSaga),
  takeLatest<PayInvoicesAction>(AccountingConsts.PAY_INVOICES, payInvoiceFromSaga),
  takeLatest<SendInvoicesToPayAction>(AccountingConsts.TO_PAY_INVOICES, sendInvoicesToPayFromSaga),
  takeLatest<GetScholarshipSettingAction>(AccountingConsts.GET_SCHOLARSHIP_SETTING, getScholarshipSettingFromSaga),
  takeLatest<GetPendingToSendInvoicesAction>(AccountingConsts.GET_PENDING_INVOICES, getPendingToSendInvoicesFromSaga),
  takeLatest<RemovePendingInvoiceAction>(AccountingConsts.REMOVE_PENDING_INVOICE, removePendingInvoiceFromSaga),
  takeLatest<GetOnePendingInvoiceAction>(AccountingConsts.GET_ONE_PENDING_INVOICE, getOnePendingInvoiceFromSaga),
  takeLatest<UpdatePendingInvoiceAction>(AccountingConsts.UPDATE_PENDING_INVOICE, updatePendingInvoiceFromSaga),
  takeLatest<UpdateScholarshipSettingAction>(
    AccountingConsts.UPDATE_SCHOLARSHIP_SETTING,
    updateScholarshipSettingFromSaga,
  ),
  takeLatest<MarkInvoiceAsPaidAction>(AccountingConsts.MARK_INVOICE_AS_PAID, markInvoiceAsPaidActionFromSaga),
  takeLatest<AuthorizeInvoiceAction>(AccountingConsts.AUTORIZE_INVOICE, authorizeInvoiceFromSaga),
  takeLatest<CloseInvoicesAction>(AccountingConsts.CLOSE_INVOICES, closeInvoicesFromSaga),
  takeLatest<DownloadInvoiceContractAction>(AccountingConsts.DOWNLOAD_INVOICE_CONTRACT, downloadInvoiceContractFromApi),
];

import * as yup from 'yup';
import { tErrorKey } from '../../../../helpers/translate';

const requiredText = tErrorKey('required');
const requiredNumber = tErrorKey('numeric');

export const ScholarshipPaymentsAdjustmentSchema = yup.object().shape({
  payment_amount: yup
    .number()
    .moreThan(0, 'El importe del ajuste debe ser mayor que 0')
    .typeError(tErrorKey(requiredNumber)),
  remarks: yup
    .string()
    .required(requiredText)
    .max(255, 'El comentario no puede tener más de 255 caracteres'),
  payment_date: yup
    .string()
    .required(requiredText)
    .nullable(),
  file_id: yup
    .string()
    .required(requiredText)
    .nullable(),
  file_name: yup
    .string()
    .required(requiredText)
    .nullable(),
});

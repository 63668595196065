/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MessageInfo from '../../../../components/MessageInfo/MessageInfo';
import SignatureDocuments from '../../../../components/SignatureDocuments/SignatureDocuments';
import Title from '../../../../components/Title/Title';
import { tScholarshipKey } from '../../../../helpers/translate';
import { selectIsNousCims } from '../../../../redux/auth/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { ScholarshipAgreementData } from '../../../../redux/scholarship/agreement/ScholarshipAgreement';
import { scholarshipAgreementSetDataAction } from '../../../../redux/scholarship/agreement/actions';
import { selectScholarshipAgreementData } from '../../../../redux/scholarship/agreement/selectors';
import { ScholarshipPaymentAdjustmentData } from '../../../../redux/scholarship/paymentAdjustments/PaymentAdjustment';
import { getScholarshipPaymentAdjustmentAction } from '../../../../redux/scholarship/paymentAdjustments/actions';
import { selectScholarshipPaymentAdjustmentData } from '../../../../redux/scholarship/paymentAdjustments/selectors';
import AgreementState from '../../../../types/Agreements/AgreementState';
import PaymentFormAccountingData from './PaymentFormAccountingData';
import PaymentFormAgreement from './PaymentFormAgreement';
import PaymentFormAgreementPayments from './PaymentFormAgreementPayments';
import PaymentFormIrpf from './PaymentFormIrpf';
import PaymentFormScholarshipData from './PaymentFormScholarshipData';
import styles from './paymentForm.module.scss';

interface PaymentFormProps {
  readOnly: boolean;
  isNousCims: boolean;
  buttonActionSaveAgreement: ButtonProps;
  scholarshipAgreementData: ScholarshipAgreementData;
  scholarshipPayments: ScholarshipPaymentAdjustmentData;
  getScholarshipPayments: (scholarship_id: number) => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  readOnly,
  isNousCims,
  buttonActionSaveAgreement,
  scholarshipAgreementData,
  scholarshipPayments,
  getScholarshipPayments,
}) => {
  const { agreement_number, agreement_state, scholarship_id } = scholarshipAgreementData;
  const agreementState = new AgreementState(agreement_state);

  useEffect(() => {
    getScholarshipPayments(scholarship_id);
  }, [getScholarshipPayments, scholarship_id]);

  const renderAgreementDocument = (): JSX.Element | undefined => {
    if (!!agreement_number) {
      return <PaymentFormAgreement readOnly={readOnly} scholarshipAgreementData={scholarshipAgreementData} />;
    }
    return undefined;
  };

  const renderScholarshipData = (): JSX.Element | undefined => {
    if (!!agreement_number && isNousCims) {
      return <PaymentFormScholarshipData scholarshipAgreementData={scholarshipAgreementData} />;
    }
    return undefined;
  };

  const renderSignatureDocuments = (): JSX.Element | undefined => {
    if (agreementState.isConfirm()) {
      return <SignatureDocuments title={'Addendas convenio'} type={'scholarships'} type_id={scholarship_id} />;
    }
    return undefined;
  };

  const renderAccountingData = (): JSX.Element | undefined => {
    if (!isNousCims) {
      return undefined;
    }
    return (
      <PaymentFormAccountingData
        scholarshipAgreementData={scholarshipAgreementData}
        disabled={false}
        buttonActionSaveAgreement={buttonActionSaveAgreement}
      />
    );
  };

  const renderScholarshipPayments = (): JSX.Element | undefined => {
    if (!isNousCims) {
      return undefined;
    }
    return (
      <PaymentFormAgreementPayments
        scholarshipAgreementData={scholarshipAgreementData}
        scholarshipPayments={scholarshipPayments}
      />
    );
  };

  const renderStudentInfo = (): JSX.Element | undefined => {
    if (!isNousCims) {
      return (
        <>
          <MessageInfo>{tScholarshipKey('PaymentFormP1')}</MessageInfo>

          <MessageInfo>{tScholarshipKey('PaymentFormP2')}</MessageInfo>

          <MessageInfo>{tScholarshipKey('PaymentFormP3')}</MessageInfo>
        </>
      );
    }
    return undefined;
  };

  return (
    <div className={styles.root}>
      <Title>{tScholarshipKey('PAGOS')}</Title>
      {renderAgreementDocument()}
      {renderSignatureDocuments()}
      {renderScholarshipData()}
      {<PaymentFormIrpf scholarshipAgreementData={scholarshipAgreementData} />}
      {renderAccountingData()}
      {renderScholarshipPayments()}
      {renderStudentInfo()}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  isNousCims: selectIsNousCims(state),
  scholarshipAgreementData: selectScholarshipAgreementData(state),
  scholarshipPayments: selectScholarshipPaymentAdjustmentData(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipAgreementSetDataAction(key, value)),
  getScholarshipPayments: (scholarship_id: number): void =>
    dispatch(getScholarshipPaymentAdjustmentAction(scholarship_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);

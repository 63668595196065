import moment from 'moment';
import { APIValidationError } from '../../api/api';
import { DocumentationItem } from '../common/definitions';
import { Academy } from '../metadata/definitions';
import { AppState } from '../root-reducer';
import { AllowedStates } from './changeState/definitions';
import {
  Material,
  ScholarshipData,
  ScholarshipDataState,
  ScholarshipFollow,
  ScholarshipMetadata,
  ScholarshipStateChange,
} from './definitions';

export const selectScholarship = (state: AppState): ScholarshipDataState => state.scholarshipReducer;
export const selectGetScholarship = (state: AppState): ScholarshipData => selectScholarship(state).scholarshipData;
export const selectGetScholarshipMetadata = (state: AppState): ScholarshipMetadata =>
  selectScholarship(state).scholarshipMetadata;
export const selectGetScholarshipDocumentation = (state: AppState): DocumentationItem[] =>
  selectScholarship(state).documentation;
export const selectIsLoading = (state: AppState): boolean => selectScholarship(state).loading;
export const selectError = (state: AppState): string | null => selectScholarship(state).error;
export const selectScholarshipValidationErrors = (state: AppState): APIValidationError | null =>
  selectScholarship(state).validationErrors;

export const selectScholarshipMaterials = (state: AppState): Material[] =>
  selectScholarship(state).scholarshipData.materials;

export const selectTotalMaterialCost = (state: AppState): number => {
  const materials = selectScholarshipMaterials(state);
  return Math.round(materials.reduce((total, { amount }) => total + +amount, 0) * 100) / 100;
};

export const selectLoadingMaterials = (state: AppState): boolean => selectScholarship(state).loadingMaterials;

export const selectTotalCost = (state: AppState): number => {
  const { enrollment_cost, monthly_enrollment_cost, transportation_cost, other_costs_amount } = selectScholarship(
    state,
  ).scholarshipData;

  const total_material_cost = selectTotalMaterialCost(state);
  const total =
    enrollment_cost * 1 +
    total_material_cost * 1 +
    monthly_enrollment_cost * 1 +
    transportation_cost * 1 +
    other_costs_amount * 1;
  return Math.round(total * 100) / 100;
};

export const selectTotalRequested = (state: AppState): number => {
  const { able_to_pay } = selectScholarship(state).scholarshipData;
  const total = selectTotalCost(state);
  const young_contrib_number = able_to_pay * 1;

  return Math.round((total - young_contrib_number) * 100) / 100;
};

export const selectTotalApproval = (state: AppState): number => {
  const {
    enrollment_approval,
    materials_approval,
    monthly_price_approval,
    transport_price_approval,
    other_costs_approval,
    young_contrib_approval,
  } = selectScholarship(state).scholarshipData;
  const young_contrib_number = young_contrib_approval * 1;

  const total =
    enrollment_approval * 1 +
    materials_approval * 1 +
    monthly_price_approval * 1 +
    transport_price_approval * 1 +
    other_costs_approval * 1;

  const res = Math.round((total - young_contrib_number) * 100) / 100;
  if (res < 0) {
    return 0;
  }
  return res;
};

export const selectTotalFollowUpJustify = (
  state: AppState,
  follow_prop: 'follow_t1' | 'follow_t2' | 'follow_t3',
): number => {
  const followUp = selectScholarship(state).scholarshipData[follow_prop];
  if (followUp) {
    const {
      enrollment_justify,
      monthly_price_justify,
      materials_justify,
      transport_price_justify,
      other_costs_justify,
      adjustment_amount_justify,
    } = followUp;
    const total =
      enrollment_justify * 1 +
      monthly_price_justify * 1 +
      materials_justify * 1 +
      transport_price_justify * 1 +
      other_costs_justify * 1 +
      adjustment_amount_justify * 1;
    return Math.round(total * 100) / 100;
  } else {
    return 0;
  }
};

export const selectTotalConfirmedScholarship = (state: AppState): number => {
  const {
    enrollment_confirm,
    materials_confirm,
    monthly_price_confirm,
    transport_price_confirm,
    other_costs_confirm,
    young_contrib_approval,
    adjustment_amount,
  } = selectScholarship(state).scholarshipData;
  const young_contrib_number = young_contrib_approval * 1;
  const adjustment_amount_confirm = adjustment_amount * 1;

  const total =
    enrollment_confirm * 1 +
    materials_confirm * 1 +
    monthly_price_confirm * 1 +
    other_costs_confirm * 1 +
    transport_price_confirm * 1 +
    adjustment_amount_confirm;

  return Math.round((total - young_contrib_number) * 100) / 100;
};

export const selectMonthlyIncomes = (state: AppState): number => {
  const { father_incomes, mother_incomes, other_incomes, student_incomes, alternative_incomes } = selectScholarship(
    state,
  ).scholarshipData;

  const total =
    father_incomes * 1 + mother_incomes * 1 + other_incomes * 1 + student_incomes * 1 + alternative_incomes * 1;
  return Math.round(total * 100) / 100;
};

export const selectTotalEnrollmentCost = (state: AppState): number => {
  const { enrollment_cost, monthly_enrollment_cost } = selectScholarship(state).scholarshipData;
  const total = enrollment_cost * 1 + monthly_enrollment_cost * 1;
  return Math.round(total * 100) / 100;
};

export const selectLoadingScholarshipUser = (state: AppState): boolean =>
  selectScholarship(state).loadingScholarshipUser;

export const selectConfiguratorFollowup = (state: AppState): any => selectScholarship(state).configuratorFollowupData;

export const selectGetScholarshipDocumentationPerCode = (state: AppState, codes: string[]): DocumentationItem[] => {
  const docs = selectScholarship(state).documentation;
  return docs.filter((element: DocumentationItem) => codes.includes(element.code));
};

export const selectProvinceInstituteCode = (state: AppState): string | undefined =>
  state.metadataReducer.scholarship.provinces.find(
    ({ code }) => state.scholarshipReducer.scholarshipData.scholarship_academy_code?.slice(0, 2) === code,
  )?.code;

export const selectProvinceConfirmInstituteCode = (state: AppState): string | undefined =>
  state.metadataReducer.scholarship.provinces.find(
    ({ code }) => state.scholarshipReducer.scholarshipData.scholarship_academy_confirm_code?.slice(0, 2) === code,
  )?.code;

export const selectAcademyInstitute = (state: AppState): Academy[] =>
  state.metadataReducer.scholarship.academies.map(academy => ({
    ...academy,
    name: `${academy.name} (${academy.city})`,
  }));

export const selectUniversity = (state: AppState) => state.metadataReducer.scholarship.universities;

export const selectStateTransition = (state: AppState): ScholarshipStateChange =>
  selectScholarship(state).state_transition;

export const selectEntityScholarshipPaymentDateFilter = (state: AppState): moment.Moment | null =>
  selectScholarship(state).entityScholarshipPaymentDateFilter?.isValid()
    ? selectScholarship(state).entityScholarshipPaymentDateFilter
    : null;

export const selectScholarshipStep = (state: AppState): number => selectScholarship(state).formStep;

export const selectScholarshipFollowUp1 = (state: AppState): ScholarshipFollow | null =>
  selectGetScholarship(state).follow_t1;

export const selectScholarshipFollowUp2 = (state: AppState): ScholarshipFollow | null =>
  selectGetScholarship(state).follow_t2;

export const selectScholarshipFollowUp3 = (state: AppState): ScholarshipFollow | null =>
  selectGetScholarship(state).follow_t3;

export const selectScholarshipAllowedStates = (state: AppState): AllowedStates =>
  selectScholarship(state).scholarshipAllowedStates;

import { PaymentSettingDataConsts } from './action_types';
import { UpdatePaymentSettingAction, UpdatePaymentSettingKoAction, UpdatePaymentSettingOkAction } from './definitions';
import { PaymentSettingData } from './PaymentSetting';

export function updatePaymentSettingAction(paymentSettingData: PaymentSettingData): UpdatePaymentSettingAction {
  return {
    type: PaymentSettingDataConsts.UPDATE_PAYMENT_SETTING,
    paymentSettingData,
  };
}

export function updatePaymentSettingOkAction(paymentSettingData: PaymentSettingData): UpdatePaymentSettingOkAction {
  return {
    type: PaymentSettingDataConsts.UPDATE_PAYMENT_SETTING_OK,
    paymentSettingData,
  };
}

export function updatePaymentSettingKoAction(error: string): UpdatePaymentSettingKoAction {
  return {
    type: PaymentSettingDataConsts.UPDATE_PAYMENT_SETTING_KO,
    error,
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';
import { updatePaymentSettingFromApi } from '../../api/paymentSetting';
import errorMessage from '../../helpers/errorMessage';
import { PaymentSettingDataConsts } from './action_types';
import { updatePaymentSettingKoAction, updatePaymentSettingOkAction } from './actions';
import { UpdatePaymentSettingAction } from './definitions';
import { showSnackbar } from '../FeedbackAPI/actions';

function* updatePaymentSettingFromSaga(action: UpdatePaymentSettingAction): Generator<any, void, any> {
  try {
    const { paymentSettingData } = action;
    const PaymentSettingData = yield updatePaymentSettingFromApi(paymentSettingData);
    yield put(updatePaymentSettingOkAction(PaymentSettingData));
    yield put(showSnackbar('La configuración de pago se ha actualizado correctametne', 'success'));
  } catch (e) {
    yield put(updatePaymentSettingKoAction(errorMessage(e)));
  }
}

export default [
  takeLatest<UpdatePaymentSettingAction>(PaymentSettingDataConsts.UPDATE_PAYMENT_SETTING, updatePaymentSettingFromSaga),
];

import { APIValidationError } from '../../../api/api';

export type ScholarshipAgreementData = {
  id: number;
  scholarship_id: number;
  academy_contact: string;
  account_number: string;
  student_account_number: string;
  agreement_file_id: number;
  agreement_number: string;
  agreement_state: string;
  signature_id: string | null;
  center_cost_area_code: string;
  center_cost_channel_code: string;
  center_cost_delegation_code: string;
  center_cost_project_code: string;
  concept: string;
  description: string;
  last_scholarship_academy: string;
  retention_code: string;
  provider_code: string;
  payable_account: string;
  expense_account: string;
  student_document_number: string;
  student_document_type: string;
  scholarship_academy: string;
  agreement_reload: boolean;
  total_cost: number;
  account_numbers: string[];
};

export type ScholarshipProviderData = {
  provider_code: string;
  payable_account: string;
  expense_account: string;
};

export const ScholarshipAgreementInitialState = {
  loading: false as boolean,
  providerLoading: false as boolean,
  error: null as string | null,
  agreementData: {} as ScholarshipAgreementData,
  validationErrors: null as APIValidationError | null,
};

import { AccountBalance, PeopleAlt, Person } from '@material-ui/icons';
import React from 'react';
import { RouteProps } from 'react-router';
import { tKey } from '../helpers/translate';
import { permissionType } from './permissions_definitions';

export interface AppRoute extends RouteProps {
  path: string;
  name: string;
  icon?: JSX.Element;
  empty?: boolean;
  title?: string;
  display?: 'leftsidebar' | 'menubottom';
  subtitle?: string;
  permission?: permissionType[];
  anonymousAccess?: boolean; // Routes with this flag are accessible *only* if you are not logged in
  fullAccess?: boolean; // Routes with this flag are accesible always.
  mobile?: boolean;
  order?: number;
  platform: 'fnc' | 'zing' | 'common';
}

export const routes_definition: AppRoute[] = [
  {
    name: 'root',
    path: '/',
    anonymousAccess: true,
    platform: 'common',
  },
  {
    name: 'registro',
    path: '/registro',
    anonymousAccess: true,
    platform: 'common',
  },
  {
    name: 'registro-datos',
    path: '/registro-datos',
    platform: 'common',
  },
  {
    name: 'registro-entidad',
    path: '/registro/entidad',
    anonymousAccess: true,
    platform: 'common',
  },
  {
    name: 'acceso',
    path: '/acceso',
    anonymousAccess: true,
    platform: 'common',
  },
  {
    name: 'restablecer-acceso',
    path: '/restablecer-acceso/:token',
    anonymousAccess: true,
    platform: 'common',
  },
  {
    name: 'activar-cuenta',
    path: '/activar-cuenta/:token',
    anonymousAccess: true,
    platform: 'common',
  },
  {
    name: 'validar-entidad',
    path: '/entidad/solicitada/:id',
    permission: ['route_entity_create'],
    platform: 'common',
  },
  {
    name: 'confirmar-entidad',
    path: '/entidad/confirmada/:id',
    permission: ['route_entity_create'],
    platform: 'common',
  },
  {
    name: 'solicitud-entidad',
    path: '/entidad/solicitud',
    permission: ['route_entity_solicitation'],
    platform: 'common',
  },
  {
    name: 'editar-entidad',
    path: '/entidad/editar/:id',
    permission: ['route_entiy_edit'],
    platform: 'common',
  },
  {
    name: 'detalles-entidad',
    path: '/entidad/detalles/:id',
    permission: ['route_entity_details'],
    platform: 'common',
  },
  {
    name: 'activacion-entidad',
    path: '/entidad/activar/:token',
    platform: 'common',
  },
  {
    name: 'responsable-entidad',
    path: '/entidad/pendiente-validar',
    permission: ['route_entity_solicitation'],
    platform: 'common',
  },
  {
    name: 'home',
    title: tKey('Inicio'),
    path: '/home',
    platform: 'common',
    mobile: true,
    order: 1,
  },
  {
    name: 'ayuda',
    path: '/ayuda',
    platform: 'common',
  },
  {
    name: 'mi-perfil',
    icon: <Person />,
    path: '/mi-perfil',
    display: 'leftsidebar',
    title: 'Mi perfil',
    platform: 'common',
  },
  {
    title: 'Tareas pendientes',
    name: 'tareas-pendientes',
    path: '/tareas-pendientes',
    platform: 'common',
  },
  {
    title: 'Notificaciones',
    name: 'notificaciones',
    path: '/alertas',
    display: 'menubottom',
    platform: 'common',
  },
  {
    title: 'Alertas',
    name: 'alerts',
    path: '/alertas',
    platform: 'common',
  },
  {
    name: 'becas-entidad',
    title: 'ZING PROGRAMME',
    subtitle: 'Entidades',
    icon: <Person />,
    path: '/becas-entidad/:academic_year?/:state_code?',
    display: 'menubottom',
    permission: ['route_entity_scholarships'],
    platform: 'common',
    mobile: true,
    order: 40,
  },
  {
    name: 'becas-instituto',
    title: 'ZING PROGRAMME',
    subtitle: 'Institutos',
    icon: <Person />,
    path: '/becas-instituto/:academic_year?/:state_code?',
    display: 'menubottom',
    permission: ['route_institute_scholarships'],
    platform: 'common',
  },
  {
    name: 'becas-escoles-concertades',
    title: 'ZING PROGRAMME',
    subtitle: 'Escuelas concertadas',
    icon: <Person />,
    path: '/becas-escoles-concertades/:academic_year?/:state_code?',
    display: 'menubottom',
    permission: ['route_study_center_scholarships'],
    platform: 'common',
  },
  {
    name: 'mis-becas',
    title: 'Mis becas',
    icon: <Person />,
    path: '/mis-becas',
    display: 'menubottom',
    permission: ['route_my_scholarships'],
    mobile: true,
    order: 30,
    platform: 'common',
  },
  {
    name: 'pago-becas-instituto',
    title: 'Pago a becas instituto',
    path: '/contabilidad/pago-becas/institute',
    platform: 'common',
  },
  {
    name: 'pago-becas-entities',
    title: 'Pago a entidades',
    path: '/contabilidad/pago-becas/entidades',
    platform: 'common',
  },
  {
    name: 'pago-facturas',
    title: 'Facturas de proveedor',
    path: '/contabilidad/pago/facturas',
    platform: 'common',
  },
  {
    name: 'pago-proyectos',
    title: 'Pagos de proyectos',
    path: '/contabilidad/pago/proyectos',
    platform: 'common',
  },
  {
    name: 'kpis', //TODO: Add permission
    title: 'Indicadores',
    path: '/kpis',
    // display: 'menubottom',
    platform: 'common',
  },
  {
    name: 'reuniones', //TODO: Add permission
    title: 'Reuniones',
    path: '/reuniones',
    icon: <PeopleAlt />,
    // display: 'menubottom',
    platform: 'common',
  },
  {
    title: 'Convenios', //TODO: Add permission
    name: 'convenios',
    path: '/convenios',
    // display: 'menubottom',
    platform: 'common',
  },
  {
    name: 'sobre-nous-cims', //TODO: Add permission ????
    title: 'Sobre Nous Cims',
    icon: <Person />,
    path: '/sobre-nous-cims',
    // display: 'menubottom',
    platform: 'common',
  },
  {
    title: 'Encuestas', //TODO: Add permission
    name: 'encuestas',
    path: '/encuestas',
    // display: 'menubottom',
    platform: 'common',
  },
  {
    name: 'entidades',
    path: '/entidades',
    icon: <AccountBalance />,
    title: tKey('Entidades'),
    display: 'menubottom',
    permission: ['route_entities_list'],
    platform: 'common',
  },
  {
    name: 'centros-academicos',
    path: '/centros-academicos',
    permission: ['route_entities_list'],
    platform: 'common',
  },
  {
    name: 'mi-entidad',
    path: '/mi-entidad',
    icon: <AccountBalance />,
    title: 'Mi Entidad',
    display: 'menubottom',
    permission: ['route_my_entity'],
    platform: 'common',
  },
  {
    name: 'usuarios',
    icon: <PeopleAlt />,
    path: '/usuarios/:type?',
    title: 'Usuarios',
    display: 'menubottom',
    permission: ['route_users_list'],
    platform: 'common',
  },
  {
    name: 'editar-usuario',
    path: '/usuario/editar/:id',
    permission: ['route_user_edit'],
    platform: 'common',
  },
  {
    name: 'detalles-usuario',
    path: '/usuario/detalles/:id',
    permission: ['route_user_details'],
    platform: 'common',
  },
  {
    name: 'crear-usuario',
    path: '/usuario/crear',
    permission: ['route_user_create'],
    platform: 'common',
  },
  {
    name: 'crear-entidad-nueva',
    path: '/entidad/nueva/:id',
    permission: ['route_pending_entities'],
    platform: 'common',
  },
  {
    name: 'entidades-nuevas',
    path: '/entidades/nuevas',
    permission: ['route_pending_entities'],
    platform: 'common',
  },
  {
    name: 'entidades-solicitadas',
    path: '/entidades/solicitadas',
    permission: ['route_requested_entities'],
    platform: 'common',
  },
  {
    name: 'entidades-confirmadas',
    path: '/entidades/confirmadas',
    permission: ['route_requested_entities'],
    platform: 'common',
  },
  {
    name: 'solicitudes-entrevista',
    permission: ['route_interview'],
    title: 'Entrevistas',
    icon: <Person />,
    path: '/entrevistas/lista',
    display: 'menubottom',
    platform: 'common',
  },
  {
    name: 'entrevistas-realizadas',
    permission: ['route_interview'],
    title: 'Entrevistas',
    subtitle: 'Realizadas',
    icon: <Person />,
    path: '/entrevistas/',
    display: 'menubottom',
    platform: 'common',
  },
  {
    name: 'calendario-entrevistador',
    path: '/calendario',
    title: 'Calendario',
    permission: ['route_interview'],
    display: 'menubottom',
    platform: 'common',
  },
  {
    name: 'calendario-becario',
    path: '/calendario-becario',
    platform: 'common',
  },
  {
    name: 'auth-throughMail',
    path: '/authThroughMail/:to',
    fullAccess: true,
    platform: 'common',
  },
  {
    title: 'Detalle Beca Instituto',
    name: 'school-scholarship-detail',
    path: '/becas/institute/detalle/:id',
    platform: 'common',
  },
  {
    title: 'Detalle Beca Entidad',
    name: 'entity-scholarship-detail',
    path: '/becas/entity/detalle/:id',
    platform: 'common',
  },
  {
    title: 'Edición Beca',
    name: 'scholarship-edition',
    path: '/becas/edicion/:id',
    platform: 'common',
  },
  {
    name: 'registro-educador',
    permission: ['route_entity_scholarships'],
    path: '/registro-educador',
    platform: 'common',
  },
  {
    name: 'educador-pendiente',
    path: '/pendiente-educador',
    platform: 'common',
  },
  {
    subtitle: tKey('Proyectos'),
    title: tKey('Gestión de'),
    icon: <Person />,
    name: 'proyectos',
    path: '/proyectos',
    display: 'menubottom',
    permission: ['route_list_projects'],
    platform: 'common',
  },
  {
    subtitle: tKey('Proyectos'),
    title: tKey('Mis'),
    icon: <Person />,
    name: 'mis-proyectos',
    path: '/mis-proyectos',
    display: 'menubottom',
    permission: ['route_my_projects'],
    platform: 'common',
  },
  {
    name: 'detalle-proyecto',
    path: '/proyecto/detalle/:id/:section?/:schedule_id?',
    permission: ['route_project'],
    platform: 'common',
  },
  {
    name: 'detalle-proyecto-seccion',
    path: '/proyecto/detalle/:id/:section?',
    permission: ['route_project'],
    platform: 'common',
  },
  {
    name: 'asistente-formularios',
    path: '/proyecto/asistente-formularios/:id_project/:section',
    permission: ['route_project'],
    platform: 'common',
  },

  {
    title: 'Búsqueda',
    name: 'search',
    path: '/busqueda',
    permission: ['route_search_results'],
    platform: 'common',
  },
  {
    title: tKey('Sobre Nosotros'),
    name: 'about-us-view',
    path: '/sobre-nosotros',
    display: 'menubottom',
    permission: ['route_about_us_view'],
    platform: 'common',
  },

  // --------------------- Servicios -----------------------------------------------------
  {
    name: 'services',
    path: '/servicios',
    permission: ['crud_services'],
    platform: 'common',
  },
  {
    name: 'detalle-servicio',
    path: '/servicios/detalle/:id/datos_basicos',
    permission: ['crud_services'],
    platform: 'common',
  },
  {
    name: 'solicitantes-servicio',
    path: '/servicios/detalle/:id/solicitantes',
    permission: ['crud_services'],
    platform: 'common',
  },
  {
    title: 'Mis Servicios',
    name: 'services-usuario',
    path: '/servicios/usuario',
    display: 'menubottom',
    permission: ['route_my_services'],
    platform: 'zing',
  },
  // --------------------- Eventos -----------------------------------------------------
  {
    title: 'Eventos y servicios',
    name: 'events',
    path: '/eventos',
    display: 'menubottom',
    permission: ['crud_events'],
    platform: 'common',
  },
  {
    name: 'detalle-evento',
    path: '/eventos/detalle/:id/datos_basicos',
    permission: ['crud_events'],
    platform: 'common',
  },
  {
    name: 'inscritos-evento',
    path: '/eventos/detalle/:id/inscritos/:code',
    permission: ['crud_events'],
    platform: 'common',
  },
  {
    name: 'suscritos-evento',
    path: '/eventos/detalle/:id/suscritos',
    permission: ['crud_events'],
    platform: 'common',
  },
  {
    title: 'Mis Eventos',
    name: 'eventos-usuario',
    path: '/eventos/usuario',
    display: 'menubottom',
    permission: ['route_my_events'],
    platform: 'zing',
  },
  {
    name: 'event-login',
    path: '/:type/:id/login',
    platform: 'common',
    anonymousAccess: true,
  },
  {
    name: 'event-typeform-auth',
    path: '/:type/:id/typeform/auth',
    platform: 'common',
  },
  {
    name: 'lopd-terms',
    path: '/lopd-terms',
    platform: 'common',
    anonymousAccess: true,
  },
  {
    title: 'Documentos de',
    subtitle: 'Ayuda',
    name: 'documents-help',
    path: '/documentos',
    display: 'menubottom',
    permission: ['route_document_help'],
    mobile: true,
    order: 50,
    platform: 'common',
  },
  {
    title: 'Edición Sobre Nosotros',
    name: 'about-us-edition',
    path: '/configuracion/sobre-nosotros/:code/edicion/:id',
    platform: 'common',
  },
  {
    title: 'Alta Sobre Nosotros',
    name: 'about-us-new',
    path: '/configuracion/sobre-nosotros/:code/creacion/:id',
    platform: 'common',
  },
  {
    title: 'CONFIGURACIÓN',
    name: 'config-menu',
    path: '/configuracion',
    display: 'menubottom',
    permission: ['route_config', 'action_scholarships_permissions'],
    platform: 'common',
  },
  {
    name: 'gestion-areas',
    path: '/configuracion/gestion-areas',
    permission: ['action_areas_config'],
    platform: 'common',
  },
  {
    name: 'config-areas',
    path: '/configuracion/gestion-areas/areas',
    permission: ['route_config'],
    platform: 'common',
  },
  {
    name: 'config-templates',
    path: '/configuracion/gestion-areas/plantillas',
    permission: ['route_config'],
    platform: 'common',
  },
  {
    name: 'config-template-create',
    path: '/configuracion/gestion-areas/plantillas/:code',
    permission: ['route_config'],
    platform: 'common',
  },
  {
    name: 'config-template-edit',
    path: '/configuracion/gestion-areas/plantillas/:code/:id',
    permission: ['route_config'],
    platform: 'common',
  },
  {
    name: 'config-internal-project-templates',
    path: '/configuracion/proyectos-internos/plantillas',
    permission: ['route_config'],
    platform: 'common',
  },
  {
    name: 'config-internal-project-template-edit',
    path: '/configuracion/proyectos-internos/plantilla/editar/:id',
    permission: ['route_config'],
    platform: 'common',
  },
  {
    name: 'config-internal-project-template-general-forms-assistant',
    path: '/proyecto-interno/plantilla/asistente-formularios/:internal_project_template_id/:section',
    permission: ['route_config'],
    platform: 'common',
  },
  {
    title: 'Configuración Sobre Nosotros',
    name: 'config-about-us',
    path: '/configuracion/sobre-nosotros',
    permission: ['route_config'],
    platform: 'common',
  },
  {
    name: 'config-about-us-section',
    path: '/configuracion/sobre-nosotros/:code',
    permission: ['route_config'],
    platform: 'common',
  },
  {
    title: tKey('Becas'),
    name: 'config-scholarship',
    path: '/configuracion/becas',
    permission: ['action_scholarships_permissions', 'allow_add_zing_members'],
    platform: 'common',
  },
  {
    title: 'Activar/desactivar becas',
    name: 'config-enable-scholarship',
    path: '/configuracion/becas/activar-becas',
    permission: ['action_scholarships_permissions'],
    platform: 'common',
  },
  {
    title: 'Configuración fechas de seguimiento',
    name: 'config-followup-date',
    path: '/configuracion/becas/fechas-de-seguimiento',
    permission: ['action_scholarships_permissions'],
    platform: 'common',
  },
  {
    title: 'Limpiar becas por curso',
    name: 'config-remove-scholarships',
    path: '/configuracion/becas/limpiar-becas',
    permission: ['action_scholarships_permissions'],
    platform: 'common',
  },
  {
    title: 'Alta Network',
    name: 'config-add-network',
    path: '/configuracion/becas/alta-network',
    permission: ['allow_add_zing_members'],
    platform: 'common',
  },
  {
    title: 'Validation-Project',
    name: 'validation-project',
    path: '/proyecto/validacion',
    permission: ['route_validation_project'],
    platform: 'common',
  },
  {
    title: 'Política de cookies',
    name: 'cookies-policy',
    path: '/politica-de-cookies',
    fullAccess: true,
    platform: 'common',
  },
  {
    title: 'lopd',
    name: 'lopd',
    path: '/lopd',
    fullAccess: true,
    platform: 'common',
  },
  {
    title: 'Pólitica de privacidad',
    name: 'privacy-policy',
    path: '/politica-de-privacidad',
    fullAccess: true,
    platform: 'common',
  },
  {
    title: 'Aviso legal',
    name: 'legal-advice',
    path: '/aviso-legal',
    fullAccess: true,
    platform: 'common',
  },
  {
    title: 'Comunicaciones comerciales',
    name: 'commercial-communication',
    path: '/comunicaciones-comerciales',
    fullAccess: true,
    platform: 'common',
  },
  {
    title: 'KPIS-Config',
    name: 'kpis-config',
    path: '/configuracion/mantenimiento/:code',
    permission: ['route_config'],
    platform: 'common',
  },
  {
    title: 'KPIS creación',
    name: 'kpis-creacion',
    path: '/configuracion/:field/configuracion-campos/:code/creacion/0',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    title: 'KPIS edición',
    name: 'kpis-edicion',
    path: '/configuracion/:field/configuracion-campos/:code/edicion/:id',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    title: 'KPIS detalle',
    name: 'kpis-detail',
    path: '/configuracion/:field/configuracion-campos/:code/detalle/:id',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    title: 'Tipos de indicador',
    name: 'indicator-types',
    path: '/configuracion/proyectos/configuracion-campos/kpis/kpis_types',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    title: 'Asignación de áreas ',
    name: 'assignament',
    path: '/configuracion/:field/configuracion-campos/:code/area',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    title: 'Asignación de tipo de proyectos ',
    name: 'assignament-type',
    path: '/configuracion/:field/configuracion-campos/:code/type',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    title: 'Asignación de subareas ',
    name: 'assignament-sub',
    path: '/configuracion/:field/configuracion-campos/:code/subarea',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields-config',
    path: '/configuracion/proyectos',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields',
    path: '/configuracion/proyectos-internos/configuracion-campos/pi_info_general/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields',
    path: '/configuracion/proyectos-internos/configuracion-campos/pi_info_general_adicional/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields',
    path: '/configuracion/proyectos-internos/configuracion-campos/pi_seguimiento/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields',
    path: '/configuracion/proyectos-internos/configuracion-campos/pi_seguimiento_adicional/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields',
    path: '/configuracion/proyectos-internos/configuracion-campos/pi_seguimiento_inicial/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields',
    path: '/configuracion/proyectos-internos/configuracion-campos/pi_evaluacion_seguimiento/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields',
    path: '/configuracion/proyectos-internos/configuracion-campos/pi_evaluacion_adicional/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields',
    path: '/configuracion/proyectos-internos/configuracion-campos/pi_seguimiento_final/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields',
    path: '/configuracion/proyectos-internos/configuracion-campos/pi_seguimiento_final_entidad/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields',
    path: '/configuracion/proyectos-internos/configuracion-campos/pi_seguimiento_final_joven/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },

  {
    name: 'config-fields',
    path: '/configuracion/proyectos/configuracion-campos/informe_seguimiento/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },

  {
    name: 'config-fields',
    path: '/configuracion/proyectos/configuracion-campos/aspectos_clave/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },

  {
    name: 'config-fields',
    path: '/configuracion/proyectos/configuracion-campos/logica_proyecto/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },

  {
    name: 'config-fields',
    path: '/configuracion/proyectos/configuracion-campos/kpis/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields',
    path: '/configuracion/proyectos/configuracion-campos/evaluacion_proyecto/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    name: 'config-fields',
    path: '/configuracion/proyectos/configuracion-campos/evaluacion_personal/config',
    permission: ['route_config_custom_fields'],
    platform: 'common',
  },
  {
    title: 'Guía de estilos Nous Cims',
    name: 'style-guide-nc',
    path: '/private/guia-estilos',
    permission: ['route_style_guide'],
    platform: 'common',
  },
  {
    title: 'Tipo de impuestos',
    name: 'config-retentions',
    path: '/contabilidad/impuestos',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'retentions-transaction-code',
    path: '/contabilidad/impuestos/transaction-code',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'retentions-operation-key',
    path: '/contabilidad/impuestos/operation-key',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'editar-retencion',
    path: '/contabilidad/impuestos/editar/:id',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'nueva-retencion',
    path: '/contabilidad/impuestos/crear/:id',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'editar-transaction-code',
    path: '/contabilidad/impuestos/transaction-code/editar/:id',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'nuevo-transaction-code',
    path: '/contabilidad/impuestos/transaction-code/crear/:id',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'editar-operation-key',
    path: '/contabilidad/impuestos/operation-key/editar/:id',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'nueva-operation-key',
    path: '/contabilidad/impuestos/operation-key/crear/:id',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    title: 'Cuentas de gasto',
    name: 'expense-accounts',
    path: '/contabilidad/cuentas-de-gasto',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'send-to-sage',
    path: '/contabilidad/enviar-sage',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'nueva-cuenta-de-gasto',
    path: '/contabilidad/cuentas-de-gasto/crear',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'editar-cuenta-de-gasto',
    path: '/contabilidad/cuentas-de-gasto/editar/:id',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'detalle-cuenta-de-gasto',
    path: '/contabilidad/cuentas-de-gasto/detalle/:id',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    title: 'Configuración',
    name: 'configuration',
    path: '/contabilidad/configuracion',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'cargas-masivas',
    path: '/contabilidad/cargas-masivas',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'pending_invoices',
    title: 'Facturas pendientes de envío',
    path: '/contabilidad/facturas-pendientes-envio',
    permission: ['allow_accounting_section'],
    platform: 'common',
  },
  {
    title: 'Facturas activas',
    name: 'current_invoices',
    path: '/contabilidad/facturas',
    permission: ['allow_accounting_section'],
    platform: 'common',
  },
  {
    title: 'Facturas pagadas',
    name: 'paid_invoices',
    path: '/contabilidad/facturas/pagadas',
    permission: ['allow_accounting_section'],
    platform: 'common',
  },
  {
    name: 'invoice-edit',
    path: '/contabilidad/facturas/editar/:id',
    platform: 'common',
  },
  {
    name: 'accounting',
    path: '/contabilidad/menu',
    display: 'menubottom',
    title: tKey('Contabilidad'),
    permission: ['allow_accounting_section'],
    platform: 'common',
  },
  {
    name: 'center-cost-create',
    path: '/contabilidad/centro-coste/:section/crear/:id',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    name: 'center-cost-edit',
    path: '/contabilidad/centro-coste/:section/editar/:id',
    permission: ['route_accounting'],
    platform: 'common',
  },
  {
    title: tKey('ip.titulo-proyectos'),
    subtitle: tKey('ip.subtitulo-proyectos'),
    name: 'internal-projects',
    path: '/proyectos-internos',
    display: 'menubottom',
    permission: ['route_internal_projects'],
    platform: 'common',
  },
  {
    name: 'internal-projects-config',
    path: '/configuracion/proyectos-internos',
    permission: ['route_internal_projects'],
    platform: 'common',
  },
  {
    name: 'internal-projects-config-date',
    path: '/configuracion/proyectos-internos/fecha-fin-curso',
    permission: ['route_internal_projects'],
    platform: 'common',
  },
  {
    name: 'center-cost',
    path: '/contabilidad/centro-coste/:section',
    permission: ['route_config'],
    platform: 'common',
  },
  {
    name: 'zing-network-services',
    path: '/zing-network/servicios',
    permission: ['route_zing_programme'],
    platform: 'common',
  },
  {
    name: 'traducciones',
    path: '/configuracion/traducciones',
    permission: ['allow_translation_config'],
    platform: 'common',
  },
  {
    name: 'traducciones-aplicativo',
    path: '/configuracion/traducciones/aplicativo',
    permission: ['allow_translation_config'],
    platform: 'common',
  },
  {
    name: 'lopd-mails',
    path: '/configuracion/traducciones/lopd-mail',
    permission: ['allow_translation_config'],
    platform: 'common',
  },
  {
    name: 'mailtraducciones',
    path: '/configuracion/traducciones/mail',
    permission: ['allow_mail_translation_config'],
    platform: 'common',
  },
  {
    name: 'cf-traducciones',
    path: '/configuracion/traducciones/custom-fields',
    permission: ['allow_translation_config'],
    platform: 'common',
  },
  {
    name: 'mail-translate-details',
    path: '/translation_mails/item/:mail_code',
    permission: ['allow_mail_translation_config'],
    platform: 'common',
  },
  // {
  //   title: 'Directorio',
  //   name: 'directory',
  //   path: '/directory',
  //   display: 'menubottom',
  //   //TODO crear el permiso en back para los usuarios que pertenecen a la red zing (user_data.zing_programme)
  //   permission: ['route_zing_programme'],
  //   platform: 'common',
  // },
  {
    title: 'Detalle de los centros',
    name: 'academy-detail',
    path: '/instituto/detalles/:id',
    permission: ['route_entities_list'],
    platform: 'common',
  },

  {
    name: 'internal-projects-edit',
    path: '/proyectos-internos/:id/editar/:section',
    permission: ['route_internal_projects'],
    platform: 'common',
  },
  {
    name: 'internal-projects-detail',
    path: '/proyectos-internos/:id/detalle/:section',
    permission: ['route_internal_projects'],
    platform: 'common',
  },
  {
    title: tKey('ip.titulo-mis-proyectos'),
    name: 'internal-projects-academy',
    path: '/proyectos-internos/centro',
    display: 'menubottom',
    permission: ['route_internal_projects_academy'],
    platform: 'common',
  },
  {
    name: 'internal-projects-academy-edit',
    path: '/proyectos-internos/centro/:id/editar/:section',
    permission: ['route_internal_projects_academy'],
    platform: 'common',
  },
  {
    name: 'internal-projects-academy-detail',
    path: '/proyectos-internos/centro/:id/detalle/:section',
    permission: ['route_internal_projects_academy'],
    platform: 'common',
  },
  {
    name: 'ip-free-evaluation',
    path: '/proyectos-internos/evaluacion/:project_key',
    platform: 'common',
    anonymousAccess: true,
  },
  {
    name: 'page-404',
    path: '/not-found/404',
    platform: 'common',
    anonymousAccess: true,
  },
  {
    name: 'scholarship-free-transversal-competences',
    path: '/competencias/encuesta/:survey_code',
    platform: 'common',
    anonymousAccess: true,
  },
  {
    name: 'scholarship-auth-transversal-competences',
    path: '/competencias/auth/encuesta/:survey_code',
    platform: 'common',
  },
];

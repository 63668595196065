import { InputAdornment } from '@material-ui/core';
import React from 'react';
import { tFormKey, tKey } from '../../../helpers/translate';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInput from '../../../components/Inputs/TextInput';
import { EntityAgreementData } from '../../../redux/entity/agreement/EntityAgreement';
import styles from './accountingData.module.scss';

interface AccountingDataSummaryProps {
  entityAgreementData: EntityAgreementData;
}

const renderTotalElement = (name: string, value: number, label: string, greenFont = false): JSX.Element => {
  return (
    <TextInput
      type="number"
      className={greenFont ? styles.accountingSummary_green : undefined}
      name={name}
      disabled
      fullWidth
      label={tFormKey(label)}
      value={value}
      InputProps={{
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
      }}
      placeholder="0"
    />
  );
};

const AccountingDataSummary: React.FC<AccountingDataSummaryProps> = ({ entityAgreementData }) => {
  const {
    academic_year,
    total_confirm_amount,
    total_justified_amount,
    total_paid_amount,
    total_rejected_and_dropped,
  } = entityAgreementData;

  const renderTotalConfirm = (): JSX.Element => {
    return renderTotalElement('total_confirm_amount', total_confirm_amount, 'Importe aprobado', true);
  };

  const renderTotalPaid = (): JSX.Element => {
    return renderTotalElement('total_paid_amount', total_paid_amount, 'Importe pagado', true);
  };

  const renderTotalJustified = (): JSX.Element => {
    return renderTotalElement('total_justified_amount', total_justified_amount, 'Importe justificado');
  };

  const renderTotalRejected = (): JSX.Element => {
    return renderTotalElement('total_rejected_and_dropped', total_rejected_and_dropped, 'Abandonos y renuncias');
  };

  return (
    <FormContainer
      className={`${styles.accountingDataSummaryForm} ${styles.sectionContainer}`}
      title={`${tKey('Resumen contable')} ${academic_year}`}
    >
      {renderTotalConfirm()}
      {renderTotalPaid()}
      {renderTotalJustified()}
      {renderTotalRejected()}
    </FormContainer>
  );
};

export default AccountingDataSummary;

import { InputAdornment } from '@material-ui/core';
import React from 'react';
import TextInput from '../../../../components/Inputs/TextInput';
import { tFormKey } from '../../../../helpers/translate';
import style from './ScholarshipPaymentAdjustmentModal.module.scss';

interface ScholarshipPaymentsAdjustmentTotalsProps {
  scholarshipAmount: number;
  totalAdjustments: number;
  totalScholarship: number;
}

const ScholarshipPaymentsAdjustmentTotals: React.FC<ScholarshipPaymentsAdjustmentTotalsProps> = ({
  scholarshipAmount,
  totalAdjustments,
  totalScholarship,
}) => {
  return (
    <div className={style.totals_container}>
      <TextInput
        type="number"
        disabled
        label={tFormKey('Importe beca')}
        value={scholarshipAmount}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
      <TextInput
        type="number"
        disabled
        label={tFormKey('Ajustes')}
        value={totalAdjustments}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
      <TextInput
        type="number"
        disabled
        label={tFormKey('Total beca')}
        value={totalScholarship}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
    </div>
  );
};

export default ScholarshipPaymentsAdjustmentTotals;

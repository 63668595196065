import * as yup from 'yup';
import { tErrorKey } from '../helpers/translate';

const max_length = (): string => tErrorKey('max_length');
const required_text = (): string => tErrorKey('Este campo es obligatorio');
export const paymentSettingsForm = {
  center_cost_channel_code: yup.string(),
  center_cost_delegation_code: yup.string(),
  center_cost_area_code: yup.string(),
  center_cost_project_code: yup.string(),
  agreement_number: yup.string().matches(/^[A-Za-z0-9]{9,12}$/, 'Debe ser un código alfanumérico de 9 a 12 caracteres'),
  description: yup.string(),
  concept: yup.string(),
};

export const PaymentSettingsSchema = yup.object().shape({
  center_cost_channel_code: yup
    .string()
    .max(5, max_length())
    .required(required_text())
    .nullable(),
  center_cost_delegation_code: yup
    .string()
    .max(5, max_length())
    .required(required_text())
    .nullable(),
  center_cost_area_code: yup
    .string()
    .max(5, max_length())
    .required(required_text())
    .nullable(),
  center_cost_project_code: yup
    .string()
    .max(5, max_length())
    .nullable(),
  agreement_number: yup.string().matches(/^[A-Za-z0-9]{9,12}$/, 'Debe ser un código alfanumérico de 9 a 12 caracteres'),
  description: yup
    .string()
    .nullable()
    .max(255, max_length()),
  concept: yup
    .string()
    .nullable()
    .max(50, max_length()),
});

import { ButtonProps } from '@material-ui/core';
import {
  AccountBalance,
  AccountTree,
  GetApp as GetAppIcon,
  Payment as PaymentIcon,
  Save,
  Send,
  SyncAlt,
} from '@material-ui/icons';
import Block from '@material-ui/icons/Block';
import BusinessIcon from '@material-ui/icons/Business';
import Check from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SchoolIcon from '@material-ui/icons/School';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import TableChartIcon from '@material-ui/icons/TableChart';
import React, { Fragment, Suspense, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Submenu, { SubmenuItem } from '../../components/Layout/Menus/Submenu/Submenu';
import Loading from '../../components/Loading/Loading';
import AvatarImagev2 from '../../components/MenuImageRound/MenuImageRound';
import { permissionType } from '../../constants/permissions_definitions';
import useButtons from '../../helpers/customHooks/useButtons';
import { documentationFilter } from '../../helpers/docFilter';
import { tKey } from '../../helpers/translate';
import { DocumentationItem } from '../../redux/common/definitions';
import {
  approveEntityDirector,
  clearNavigation,
  confirmEntity,
  downloadDocument,
  entityContactsList,
  entityGetForUser,
  entityGetOne,
  entitySetData,
  getDocument,
  getEntitiesPreCreatedUser,
  rejectEntityDirector,
  rejectExternalEntity,
  rejectInternalEntity,
  removeDocument,
  setAvatarEntity,
  setAvatarPreEntity,
  updateEntity,
  uploadDocument,
} from '../../redux/entity/actions';
import { EntityInfo, EntitySections, EntityStateCodes } from '../../redux/entity/definitions';
import {
  selectDocuments,
  selectEntityAvatar,
  selectGetOneEntity,
  selectIsLoading,
  selectTransitionChangeSent,
} from '../../redux/entity/selectors';
import { selectUserCan } from '../../redux/permissions/selectors';
import { AppState } from '../../redux/root-reducer';
import styles from './entityMenu.module.scss';
import EntityMenuRight from './EntityMenuRight/EntityMenuRight';
import IbanEntityList from './IbanEntityTabs/IbanEntityList';
import AccountingData from './AccountingData/AccountingData';
import ResEntAccountingData from './AccountingData/ResEntAccountingData';
import AnalisisFNC from './projectForm/AnalisisFNC';
import EconomicData from './projectForm/EconomicData';
import ChangeEntityEducator from './projectForm/Educator/ChangeEntityEducator';
import EntityContactList from './projectForm/EntityContactList';
import InstitutionalCapacity from './projectForm/InstitutionalCapacity';
import { buttonsInvoice } from './projectForm/Invoices/invoiceButtons';
import Invoices from './projectForm/Invoices/Invoices';
import Joint from './projectForm/Joint';
import ScholarshipConfig from './projectForm/ScholarshipConfig';
import EntityScholarshipPayments from './ScholarshipPayments/EntityScholarshipPayments';
import SwitchEntity from './SwitchEntity';
import { selectIsNousCims } from '../../redux/auth/selectors';

//import Invoices from './projectForm/Invoices/Invoices';

interface EntityMenuProps extends RouteComponentProps<{ id: string }> {
  edit?: boolean;
  details?: boolean;
  confirmation?: boolean;
  validation?: boolean;
  myEntity?: boolean;
  entity: EntityInfo;
  avatar: string | null;
  loading: boolean;
  canIgoNouscims: boolean;
  documentation: DocumentationItem[];
  canEditMyEntity: boolean;
  canRouteMyEntity: boolean;
  transitionChangeSent: boolean;
  canUseInvoices: boolean;
  canChangeEducator: boolean;
  isNousCims: boolean;
  userCan: (permission: permissionType) => boolean | undefined;
  setAvatarEntity: (avatar: string) => void;
  getEntityUser: () => void;
  getEntityData: (id: number) => void;
  getPreEntityUserData: () => void;
  saveEntityData: (data: EntityInfo) => void;
  uploadEntityDocument: (document_id: number, data: File, entity_id: any, force_add?: boolean | false) => void;
  downloadEntityDocument: (file_id: number, name: string, entityId: number) => void;
  removeEntityDocument: (file_id: number, entityId: number) => void;
  getEntityDocument: (file_id: number, name: string, entityId: number) => void;
  confirmEntity: (id: number) => void;
  rejectExternalEntity: (id: number, comment: string) => void;
  rejectInternalEntity: (id: number, comment: string) => void;
  directorApproval: (id: number) => void;
  directorRejection: (id: number, comment: string) => void;
  clearNavigation: () => void;
  setData: (key: string, value: string | number) => void;
  getEntityContacts: (id: number) => void;
}

const GetRejectionCommentModal = React.lazy(() => import('./Modal/ModalTransitionComment'));

const EntityMenu: React.FC<EntityMenuProps> = ({
  edit,
  details,
  confirmation,
  validation,
  myEntity,
  canUseInvoices,
  match,
  avatar,
  loading,
  entity,
  isNousCims,
  canIgoNouscims,
  documentation,
  canEditMyEntity,
  canRouteMyEntity,
  transitionChangeSent,
  canChangeEducator,
  userCan,
  getEntityUser,
  getEntityData,
  setAvatarEntity,
  uploadEntityDocument,
  downloadEntityDocument,
  removeEntityDocument,
  getEntityDocument,
  confirmEntity,
  rejectExternalEntity,
  rejectInternalEntity,
  directorApproval,
  directorRejection,
  clearNavigation,
  setData,
  saveEntityData,
}) => {
  const refMain = useRef(null);
  const invoicesPermissionFNC = entity.is_provider && !entity.no_payments_entity && (edit || details);
  const invoicesPermissionENT = entity.is_provider && !entity.no_payments_entity && myEntity && canUseInvoices;
  const resetScroll = (ref: any) => ref.current.parentElement.scroll(0, 0);
  const history = useHistory();
  const [idComponent, setIdComponent] = useState(0);

  const [comment, setComment] = React.useState({
    view: false,
  });

  const { stateButtons, setButtonProperty } = useButtons(buttonsInvoice);

  const id = match.params.id ? parseInt(match.params.id) : 0;

  useEffect(() => {
    if (transitionChangeSent) {
      clearNavigation();

      if (validation) {
        history.push('/entidades/solicitadas');
      } else if (confirmation) {
        history.push('/entidades/confirmadas');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitionChangeSent, validation]);

  const handleComponentId = (componentId: number) => {
    !!refMain.current && resetScroll(refMain);
    setIdComponent(componentId);
  };

  useEffect(() => {
    if (edit || details || confirmation || validation) getEntityData(id);
    if (myEntity) getEntityUser();
  }, [getEntityData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = useRef(() => history.go(-1));
  const saveEntity = () => {
    saveEntityData(entity);
  };

  const addDocument = (document: File, description: string, genericId?: number, documentationId?: number) => {
    if (genericId && documentationId) {
      uploadEntityDocument(documentationId, document, entity.id, true);
    }
  };

  const buttonsTransitions: ButtonProps[] = [];

  let rejectionCallback;

  if (confirmation) {
    rejectionCallback = directorRejection.bind(null, entity.id);
  } else if (entity.pre_entity_id) {
    rejectionCallback = rejectExternalEntity.bind(null, entity.id);
  } else {
    rejectionCallback = rejectInternalEntity.bind(null, entity.id);
  }

  const rejectEntity = () => {
    setComment({
      view: true,
    });
  };

  const approvalHandler = () => {
    directorApproval(entity.id);
  };

  const confirmationHandler = () => {
    confirmEntity(entity.id);
  };

  const canAcceptEntity = userCan('transition_e03e04');
  const canRejectEntity = userCan('transition_e03e02');
  const canActivateEntity = userCan('transition_e04e05');
  const canRejectActivateEntity = userCan('transition_e04e03');
  const activate_iban_action = userCan('allow_activate_entity_iban');
  const create_iban_action = userCan('allow_create_entity_iban');

  if (loading) return <Loading big />;

  if (validation && canAcceptEntity) {
    buttonsTransitions.push({
      children: tKey('CONFIRMAR'),
      onClick: confirmationHandler,
      startIcon: <Check />,
      color: 'primary',
      variant: 'contained',
      hidden: idComponent !== EntitySections.DATOS_GENERALES,
      fullWidth: true,
      disableElevation: true,
    });
  }
  if (validation && canAcceptEntity) {
    buttonsTransitions.push({
      children: tKey('GUARDAR'),
      onClick: () => saveEntity(),
      startIcon: <Save />,
      color: 'primary',
      variant: 'contained',
      hidden: idComponent !== EntitySections.DATOS_GENERALES,
      fullWidth: true,
      disableElevation: true,
    });
  }

  if (validation && canRejectEntity) {
    buttonsTransitions.push({
      children: tKey('RECHAZAR'),
      onClick: rejectEntity,
      startIcon: <Block />,
      color: 'primary',
      variant: 'contained',
      hidden: idComponent !== EntitySections.DATOS_GENERALES,
      fullWidth: true,
      disableElevation: true,
    });
  }

  if (confirmation && canActivateEntity) {
    buttonsTransitions.push({
      children: tKey('APROBAR'),
      onClick: approvalHandler,
      startIcon: <Check />,
      color: 'primary',
      variant: 'contained',
      hidden: idComponent !== EntitySections.DATOS_GENERALES,
      fullWidth: true,
      disableElevation: true,
    });
  }

  if (confirmation && canRejectActivateEntity) {
    buttonsTransitions.push({
      children: tKey('DENEGAR'),
      onClick: rejectEntity,
      startIcon: <Block />,
      color: 'primary',
      variant: 'contained',
      hidden: idComponent !== EntitySections.DATOS_GENERALES,
      fullWidth: true,
      disableElevation: true,
    });
  }

  const buttonActionSave: ButtonProps = {
    children: tKey('Guardar'),
    startIcon: <Save />,
    color: 'primary',
    variant: 'contained',
    fullWidth: true,
    disableElevation: true,
    hidden: details || validation || confirmation || idComponent === EntitySections.CAMBIO_EDUCADOR,
  };

  const buttonActionResponsible: ButtonProps = {
    children: tKey('CAMBIAR RESPONSABLE'),
    startIcon: <SwapHorizIcon />,
    color: 'secondary',
    variant: 'contained',
    fullWidth: true,
    hidden: true,
    disableElevation: true,
  };

  const buttonSageProvider: ButtonProps = {
    children: tKey('PROVEEDOR SAGE'),
    startIcon: <Send />,
    variant: 'contained',
    color: 'primary',
    hidden: true,
    fullWidth: true,
    disableElevation: true,
  };

  const buttonModifyAccounts: ButtonProps = {
    children: tKey('MODIFICAR CUENTAS'),
    startIcon: <CreateIcon />,
    variant: 'contained',
    color: 'primary',
    hidden: true,
    fullWidth: true,
    disableElevation: true,
  };

  const buttonExport = {
    children: tKey('Exportar'),
    disableElevation: true,
    fullWidth: true,
    color: 'primary',
    variant: 'contained',
    startIcon: <GetAppIcon />,
  } as ButtonProps;

  const actionButtons: ButtonProps[] = [
    stateButtons.buttonOnSave,
    stateButtons.buttonOnAccept,
    stateButtons.buttonOnSend,
    stateButtons.buttonOnReject,
    stateButtons.buttonOnReview,
    stateButtons.buttonOnCreate,
    stateButtons.buttonModify,
  ];
  const genDocs = ['NIF', 'CIF', 'ESC', 'APO', 'CTA', 'OTR', 'L49', 'ATL'];
  if (entity.natural_person) {
    genDocs.push('ETR', 'FIP', 'IIP', 'SPA');
  }

  const docs = documentationFilter(documentation, genDocs);

  const componentsList = [
    <SwitchEntity
      key={EntitySections.DATOS_GENERALES}
      edit={!!edit}
      details={!!details}
      buttonActionSave={buttonActionSave}
      buttonActionResponsible={buttonActionResponsible}
      myEntity={!!myEntity}
      validation={!!validation || !!confirmation}
      documentation={docs}
      additionalButtons={buttonsTransitions}
    />,
    <EconomicData
      key={EntitySections.DATOS_ECONOMICOS}
      id={entity.id}
      onSave={saveEntity}
      entity={entity}
      upload={uploadEntityDocument}
      download={downloadEntityDocument}
      remove={removeEntityDocument}
      getFile={getEntityDocument}
      buttonActionSave={buttonActionSave}
      documentation={documentation}
      loading={loading}
      disabled={details || validation || (!canIgoNouscims && !canEditMyEntity)}
      leftButtons={details || edit || myEntity || validation}
      addDoc={addDocument}
      setData={setData}
    />,
    <InstitutionalCapacity
      key={EntitySections.CAPACIDAD_INSTITUCIONAL}
      id={entity.id}
      onSave={saveEntity}
      entity={entity}
      upload={uploadEntityDocument}
      download={downloadEntityDocument}
      buttonActionSave={buttonActionSave}
      remove={removeEntityDocument}
      disabled={details || validation || (!canIgoNouscims && !canEditMyEntity)}
      getFile={getEntityDocument}
      loading={loading}
      documentation={documentation}
      leftButtons={details || edit || myEntity || validation}
      addDoc={addDocument}
      setData={setData}
    />,
    <Joint
      key={EntitySections.ARTICULACION}
      buttonActionSave={buttonActionSave}
      buttons={canEditMyEntity || edit}
      disabled={details || validation || (!canIgoNouscims && !canEditMyEntity)}
      onSave={saveEntity}
      entity={entity}
      loading={loading}
      leftButtons={details || edit || myEntity || validation}
      addDoc={addDocument}
      setData={setData}
    />,
    <AnalisisFNC
      key={EntitySections.ANALISIS_FNC}
      id={entity.id}
      onSave={saveEntity}
      buttonActionSave={buttonActionSave}
      entity={entity}
      upload={uploadEntityDocument}
      disabled={details || validation || (!canIgoNouscims && !canEditMyEntity)}
      download={downloadEntityDocument}
      remove={removeEntityDocument}
      getFile={getEntityDocument}
      loading={loading}
      documentation={documentation}
      leftButtons={details || edit || myEntity || validation}
      setData={setData}
    />,
    <AccountingData
      key={EntitySections.DATOS_CONTABLES}
      entityData={entity}
      buttonActionSave={buttonActionSave}
      buttonSageProvider={buttonSageProvider}
      buttonModifyAccounts={buttonModifyAccounts}
      disabled={details || (!canIgoNouscims && !canEditMyEntity)}
      isNousCims={isNousCims}
    />,
    <EntityScholarshipPayments
      key={EntitySections.PAGOS_BECAS}
      entity_id={entity.id}
      buttonActionExport={buttonExport}
    />,
    <ResEntAccountingData key={EntitySections.CONVENIOS_BECAS} entity_id={entity.id} disabled={false} />,
    <ScholarshipConfig
      key={EntitySections.CONFIGURACION_BECAS}
      disabled={details || validation}
      onSave={saveEntity}
      entity={entity}
      loading={loading}
      leftButtons={details || edit || validation}
      setData={setData}
      buttonActionSave={buttonActionSave}
    />,
    <Invoices
      entityId={entity.id}
      key={EntitySections.FACTURAS}
      setButtonProperty={setButtonProperty}
      goBack={handleGoBack}
      history={history}
    />,
    <EntityContactList key={EntitySections.CONTACTOS_ENTIDAD} entityId={entity.id} />,
    <ChangeEntityEducator key={EntitySections.CAMBIO_EDUCADOR} entityId={entity.id} />,
    <IbanEntityList key={EntitySections.CUENTAS_BANCARIAS} entityId={entity.id} />,
  ];
  const optionsMenu: SubmenuItem[] = [
    {
      title: tKey('Datos generales'),
      componentId: EntitySections.DATOS_GENERALES,
      icon: <BusinessIcon />,
      type: 'component',
      selected: idComponent === EntitySections.DATOS_GENERALES,
    },
    {
      title: tKey('contactos_entidad'),
      componentId: EntitySections.CONTACTOS_ENTIDAD,
      icon: <PermContactCalendarIcon />,
      type: 'component',
      selected: idComponent === EntitySections.CONTACTOS_ENTIDAD,
    },
  ];

  if (canIgoNouscims && entity.id && entity.has_scholarship && (edit || details) && !entity.no_payments_entity) {
    const subItems = [
      {
        title: tKey('Configuración becas'),
        componentId: EntitySections.CONFIGURACION_BECAS,
        type: 'component',
        selected: idComponent === EntitySections.CONFIGURACION_BECAS,
      },
      {
        title: tKey('Pagos de becas'),
        componentId: EntitySections.PAGOS_BECAS,
        type: 'component',
        selected: idComponent === EntitySections.PAGOS_BECAS,
      },
    ];

    if (canChangeEducator && !details) {
      subItems.push({
        title: tKey('cambio-de-educador'),
        componentId: EntitySections.CAMBIO_EDUCADOR,
        type: 'component',
        selected: idComponent === EntitySections.CAMBIO_EDUCADOR,
      });
    }

    optionsMenu.push({
      title: tKey('Becas'),
      icon: <SchoolIcon />,
      type: 'items',
      items: subItems as [],
    });
  }

  if (entity.has_project && !entity.no_payments_entity) {
    const subOptions = [
      {
        title: tKey('Datos Económicos'),
        componentId: EntitySections.DATOS_ECONOMICOS,
        type: 'component',
        selected: idComponent === EntitySections.DATOS_ECONOMICOS,
      },
      {
        title: tKey('Capacidad Institucional'),
        componentId: EntitySections.CAPACIDAD_INSTITUCIONAL,
        type: 'component',
        selected: idComponent === EntitySections.CAPACIDAD_INSTITUCIONAL,
      },
      {
        title: tKey('Articulación'),
        componentId: EntitySections.ARTICULACION,
        type: 'component',
        selected: idComponent === EntitySections.ARTICULACION,
      },
    ];
    if (canIgoNouscims) {
      subOptions.push({
        title: tKey('Análisis FNC'),
        componentId: EntitySections.ANALISIS_FNC,
        type: 'component',
        selected: idComponent === EntitySections.ANALISIS_FNC,
      });
    }
    optionsMenu.push({
      title: tKey('Datos de proyecto'),
      icon: <AccountTree />,
      type: 'items',
      items: subOptions as [],
    });
  }
  if (activate_iban_action || create_iban_action) {
    optionsMenu.push({
      title: tKey('Cuentas bancarias'),
      icon: <AccountBalance />,
      componentId: EntitySections.CUENTAS_BANCARIAS,
      type: 'component',
      selected: idComponent === EntitySections.CUENTAS_BANCARIAS,
    });
  }

  if (canIgoNouscims && !entity.no_payments_entity) {
    optionsMenu.push({
      title: tKey('Datos contables'),
      icon: <TableChartIcon />,
      componentId: EntitySections.DATOS_CONTABLES,
      type: 'component',
      selected: idComponent === EntitySections.DATOS_CONTABLES,
    });
  } else if (!canIgoNouscims && entity.has_scholarship) {
    optionsMenu.push({
      title: tKey('Datos contables'),
      icon: <TableChartIcon />,
      componentId: EntitySections.DATOS_CONTABLES,
      type: 'component',
      selected: idComponent === EntitySections.DATOS_CONTABLES,
    });
  }

  if (myEntity && canRouteMyEntity && entity.id && entity.has_scholarship) {
    optionsMenu.push(
      {
        title: tKey('Convenios'),
        icon: <DescriptionIcon />,
        componentId: EntitySections.CONVENIOS_BECAS,
        type: 'component',
        selected: idComponent === EntitySections.CONVENIOS_BECAS,
      },
      {
        title: tKey('Pagos'),
        componentId: EntitySections.PAGOS_BECAS,
        icon: <PaymentIcon />,
        type: 'component',
        selected: idComponent === EntitySections.PAGOS_BECAS,
      },
    );
    if (canChangeEducator) {
      optionsMenu.push({
        title: tKey('cambio-de-educador'),
        icon: <SyncAlt />,
        componentId: EntitySections.CAMBIO_EDUCADOR,
        type: 'component',
        selected: idComponent === EntitySections.CAMBIO_EDUCADOR,
      });
    }
  }

  if (invoicesPermissionFNC || invoicesPermissionENT) {
    optionsMenu.push({
      title: tKey('Facturas'),
      icon: <ReceiptIcon />,
      componentId: EntitySections.FACTURAS,
      type: 'component',
      selected: idComponent === EntitySections.FACTURAS,
    });
  }

  const correctPanel = componentsList[idComponent];

  const buttons: ButtonProps[] = [];

  switch (entity.state_code) {
    case EntityStateCodes.SOLICITUD_FNC:
      if (myEntity) {
        if (idComponent !== EntitySections.DATOS_GENERALES && idComponent !== EntitySections.FACTURAS)
          buttons.push(buttonActionSave);
        if (idComponent === EntitySections.FACTURAS) buttons.push(...actionButtons);
      }
      break;
    case EntityStateCodes.PENDIENTE_RESPONSABLE:
      if (validation) {
        buttons.push(...buttonsTransitions);
      }
      if (myEntity) {
        if (
          idComponent !== EntitySections.DATOS_GENERALES &&
          idComponent !== EntitySections.FACTURAS &&
          idComponent !== EntitySections.CONTACTOS_ENTIDAD
        )
          buttons.push(buttonActionSave);
        if (idComponent === EntitySections.FACTURAS) buttons.push(...actionButtons);
      }
      break;
    case EntityStateCodes.VALIDACION_SOLICITUD:
      if (validation || confirmation) {
        buttons.push(...buttonsTransitions);
      }
      if (myEntity) {
        if (
          idComponent !== EntitySections.DATOS_GENERALES &&
          idComponent !== EntitySections.FACTURAS &&
          idComponent !== EntitySections.CONTACTOS_ENTIDAD
        )
          buttons.push(buttonActionSave);
        if (idComponent === EntitySections.FACTURAS) buttons.push(...actionButtons);
      }
      break;
    case EntityStateCodes.REGISTRADA:
      if (myEntity) {
        if (
          idComponent !== EntitySections.FACTURAS &&
          idComponent !== EntitySections.CONTACTOS_ENTIDAD &&
          idComponent !== EntitySections.PAGOS_BECAS &&
          idComponent !== EntitySections.CONVENIOS_BECAS &&
          idComponent !== EntitySections.DATOS_CONTABLES
        )
          buttons.push(buttonActionSave);
        if (idComponent === EntitySections.DATOS_GENERALES) buttons.push(buttonActionResponsible);
        if (idComponent === EntitySections.FACTURAS) buttons.push(...actionButtons);
      }
      if (edit) {
        if (
          idComponent !== EntitySections.FACTURAS &&
          idComponent !== EntitySections.PAGOS_BECAS &&
          idComponent !== EntitySections.CONTACTOS_ENTIDAD
        )
          buttons.push(buttonActionSave, buttonSageProvider, buttonModifyAccounts);
        if (idComponent === EntitySections.FACTURAS) buttons.push(...actionButtons);
      }
      if (idComponent === EntitySections.PAGOS_BECAS) buttons.push(buttonExport);
      break;
    default:
      break;
  }
  if (idComponent === EntitySections.FACTURAS && !buttons.length) {
    buttons.push(stateButtons.buttonModify);
  }
  const hasActions = () => {
    if (
      EntityStateCodes.VALIDACION_SOLICITUD === entity.state_code &&
      idComponent !== EntitySections.DATOS_GENERALES &&
      confirmation
    ) {
      return true;
    }
  };
  return (
    <Fragment>
      {!loading && (
        <Fragment>
          <Layout
            leftSubmenu={
              <Submenu
                titleMenu={tKey('DATOS DE ENTIDAD')}
                defaultOpen
                onSelectComponentId={handleComponentId}
                optionsMenu={optionsMenu}
                sectionAvatar={<AvatarImagev2 imageLink={avatar} setImage={setAvatarEntity} />}
              />
            }
            rightSubmenu={
              hasActions() ? undefined : <EntityMenuRight actionButtons={buttons} onGoBack={handleGoBack.current} />
            }
          >
            <div ref={refMain} className={styles.container}>
              {correctPanel}
            </div>
            <Suspense fallback={<></>}>
              {comment.view ? (
                <GetRejectionCommentModal
                  view={comment.view}
                  reject={rejectionCallback}
                  handleClose={() => setComment({ view: false })}
                />
              ) : (
                <Fragment />
              )}
            </Suspense>
          </Layout>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  entity: selectGetOneEntity(state),
  avatar: selectEntityAvatar(state),
  loading: selectIsLoading(state),
  isNousCims: selectIsNousCims(state),
  canIgoNouscims: selectUserCan(state)('action_nouscims'),
  documentation: selectDocuments(state),
  canEditMyEntity: selectUserCan(state)('crud_my_entity'),
  canUseInvoices: selectUserCan(state)('allow_use_invoices'),
  canRouteMyEntity: selectUserCan(state)('route_my_entity'),
  canChangeEducator: selectUserCan(state)('allow_change_educator'),
  transitionChangeSent: selectTransitionChangeSent(state),
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number) => dispatch(entitySetData(key, value)),
  getPreEntityUserData: (): void => dispatch(getEntitiesPreCreatedUser()),
  getEntityContacts: (id: number): void => dispatch(entityContactsList(id)),
  getEntityData: (id: number): void => dispatch(entityGetOne(id)),
  getEntityUser: (): void => dispatch(entityGetForUser()),
  setAvatarEntity: (avatar: string): void => dispatch(setAvatarEntity(avatar)),
  saveEntityData: (data: EntityInfo) => dispatch(updateEntity(data)),
  setAvatarPreEntity: (avatar: string): void => dispatch(setAvatarPreEntity(avatar)),
  uploadEntityDocument: (document_id: number, data: File, entityId: any, force_add?: boolean | false): void =>
    dispatch(uploadDocument(document_id, data, entityId, force_add)),
  downloadEntityDocument: (file_id: number, name: string, entityId: number): void =>
    dispatch(downloadDocument(file_id, name, entityId)),
  removeEntityDocument: (file_id: number, entityId: number): void => dispatch(removeDocument(file_id, entityId)),
  getEntityDocument: (file_id: number, name: string, entityId: number): void =>
    dispatch(getDocument(file_id, name, entityId)),
  confirmEntity: (id: number): void => dispatch(confirmEntity(id)),
  rejectInternalEntity: (id: number, comment: string): void => dispatch(rejectInternalEntity(id, comment)),
  rejectExternalEntity: (id: number, comment: string): void => dispatch(rejectExternalEntity(id, comment)),
  directorApproval: (id: number): void => dispatch(approveEntityDirector(id)),
  directorRejection: (id: number, comment: string): void => dispatch(rejectEntityDirector(id, comment)),
  clearNavigation: (): void => dispatch(clearNavigation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityMenu);

import { ButtonProps } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { APIValidationError } from '../../../api/api';
import { createOperationKey, getOneOperationKey, updateOperationKey } from '../../../api/TaxRetention/taxretention';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import Title from '../../../components/Title/Title';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { OperationKey } from '../../../redux/metadata/definitions';
import { OperationKeySchema } from '../../../validations/formSchema';
import AccountingSubmenu from '../../Accounting/AccountingSubmenu';

interface EditOperationKey extends RouteComponentProps<{ id: string }> {
  create: boolean;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const OperationKeyConfig: React.FC<EditOperationKey> = ({ match, showSnackbar }) => {
  const { setError, handleSubmit, errors, control } = useForm<OperationKey>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: OperationKeySchema,
  });
  const {
    params: { id },
  } = match;

  const [list, setList] = React.useState<OperationKey>();
  const [validationErrors, setValidationErrors] = useState({} as APIValidationError);

  useValidatorAPI(validationErrors, setError);

  useEffect(() => {
    id === '0'
      ? setList({} as OperationKey)
      : getOneOperationKey(parseFloat(id)).then(operationKey => setList(operationKey));
  }, [id]);
  const title = () => (id === '0' ? tKey('Creación de clave') : tKey('Edición de clave'));

  const onSave = async (data: OperationKey) => {
    try {
      if (id === '0') {
        const result = await createOperationKey(data);
        switch (result.type) {
          case 'ok':
            showSnackbar(
              tKey('Formulario creado correctamente'),
              'success',
              '/contabilidad/impuestos/operation-key',
              1000,
            );
            return;
          case 'validation-error':
            const error = result.value;
            setValidationErrors(error);
            return;
        }
      } else {
        await updateOperationKey({ ...data, id: parseInt(id) });
      }
      showSnackbar(
        tKey('Formulario actualizado correctamente'),
        'success',
        '/contabilidad/impuestos/operation-key',
        1000,
      );
    } catch (e) {
      showSnackbar(tErrorKey('Error al actualizar el formulario'), 'error', undefined, 1000);
    }
  };

  const buttonsEdit: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: handleSubmit(onSave),
      startIcon: <Save />,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
    },
  ];

  if (!list) return <Loading big />;

  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu actionsButtons={buttonsEdit} />}
      leftSubmenu={<AccountingSubmenu selected="key_operation" submenu={tKey('Impuestos')} />}
    >
      <Title>{title()}</Title>
      <FormContainer title="">
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={list.op_key}
          schema={OperationKeySchema}
          label={tFormKey('Clave de operación')}
          name="op_key"
          size="50"
          disabled={id === '0' ? false : true}
        />
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={list.description}
          schema={OperationKeySchema}
          label={tFormKey('Descripción')}
          name="description"
          size="50"
        />
      </FormContainer>
    </LayoutForm>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});
export default connect(undefined, mapDispatchToProps)(OperationKeyConfig);

import React from 'react';
import Lock from '@material-ui/icons/Send';
import { connect } from 'react-redux';
import { downloadFileFromApi } from '../../../api/file';
import FncAddDocuments from '../../../components/UploadFile/FncAddDocuments';
import errorMessage from '../../../helpers/errorMessage';
import { getDocs } from '../../../helpers/getDocs';
import { tKey } from '../../../helpers/translate';
import { EntityAgreementData } from '../../../redux/entity/agreement/EntityAgreement';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { selectIsNousCims } from '../../../redux/auth/selectors';
import { AppState } from '../../../redux/root-reducer';
import FncButton from '../../../components/FncButton/FncButton';
import styles from './accountingData.module.scss';
import AgreementState from '../../../types/Agreements/AgreementState';
import FormContainer from '../../../components/Forms/FormContainer';
import { cancelEntityAgreementAction } from '../../../redux/entity/agreement/actions';

interface AccountingDataAgreementDocumentProps {
  entityAgreementData: EntityAgreementData;
  isNousCims: boolean;
  disabled: boolean;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
  cancelEntityAgreement: (signature_id: string) => void;
}

const AccountingDataAgreementDocument: React.FC<AccountingDataAgreementDocumentProps> = ({
  entityAgreementData,
  isNousCims,
  disabled,
  showSnackbar,
  cancelEntityAgreement,
}) => {
  const actions = ['download', 'view'];
  const {
    entity_id,
    agreement_number,
    agreement_state,
    total_paid_amount,
    documentation,
    signature_id,
  } = entityAgreementData;
  const agreementState = new AgreementState(agreement_state);

  const downloadFile = (file_id: number, name: string, download: boolean) => {
    try {
      downloadFileFromApi(file_id)
        .then(res => getDocs(res, download, name))
        .catch(error => {
          showSnackbar(error.message, 'error', undefined, 1500);
        });
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const download = (file_id: number, name: string) => {
    downloadFile(file_id, name, true);
  };

  const show = (file_id: number, name: string) => {
    downloadFile(file_id, name, false);
  };

  const titleText = () => {
    return `${tKey('Convenio')}: ${agreement_number} ${
      agreement_state ? ` - Estado: ${tKey('agreementState.'.concat(agreement_state))}` : ''
    }`;
  };

  const onCancelAgreement = () => {
    if (signature_id) {
      cancelEntityAgreement(signature_id);
    }
  };

  const renderCancelAgreementButton = (): JSX.Element | undefined => {
    const agreementStateVisibility = agreementState.isWaiting() && total_paid_amount === 0;
    if (!isNousCims || disabled || !agreementStateVisibility) {
      return undefined;
    }
    return (
      <div className={`${styles.buttonsWrapper} ${styles.buttonActionRight}`}>
        <FncButton disabled={!signature_id} customClass={styles.iconButton} onClick={onCancelAgreement} color="primary">
          <Lock />
          <span>{tKey('Cancelar convenio')}</span>
        </FncButton>
      </div>
    );
  };

  return (
    <FormContainer className={styles.sectionContainer} title={tKey('Documento convenio')}>
      <FncAddDocuments
        title={titleText()}
        documents={documentation}
        actions={actions}
        genericId={entity_id}
        download={download}
        getFile={show}
        required
        disabled={disabled}
      />
      {renderCancelAgreementButton()}
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  isNousCims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
  cancelEntityAgreement: (signature_id: string): void => dispatch(cancelEntityAgreementAction(signature_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDataAgreementDocument);

export type PaymentSettingData = {
  id: number;
  agreement_number: string;
  center_cost_channel_code: string;
  center_cost_delegation_code: string;
  center_cost_area_code: string;
  center_cost_project_code: string | null;
  concept: string | null;
  description: string | null;
};

export const PaymentSettingInitialState = {
  loading: false as boolean,
  error: null as string | null,
};

import { ButtonProps, MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import MessageInfo from '../../../../components/MessageInfo/MessageInfo';
import Modal from '../../../../components/Modal/Modal';
import SelectController from '../../../../components/Select/SelectController';
import { tProjectKey } from '../../../../helpers/translate';
import { PaymentInfo } from '../../../../redux/project/definitions';
import { PaymentModalSchema } from '../../../../validations/formSchema';
import styles from './paymentModal.module.scss';
import { dbDateToDate } from '../../../../helpers/dateHelper';

interface PaymentModalProps {
  view: boolean;
  inputsData: PaymentInfo;
  onClose: () => void;
  onSubmit: (data: PaymentInfo) => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({ view, inputsData, onClose, onSubmit }) => {
  const { errors, handleSubmit, control, reset, watch } = useForm<PaymentInfo>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: PaymentModalSchema,
  });

  useEffect(() => {
    reset(inputsData);
  }, [inputsData, reset]);

  const { account_numbers } = inputsData;

  const pay = (data: PaymentInfo) => {
    const { id, payment_field, payment_field_id, to_pay_amount } = inputsData;
    onSubmit({ ...data, id, payment_field, payment_field_id, to_pay_amount });
    onClose();
  };

  const buttons: ButtonProps[] = [
    {
      children: tProjectKey('Cancelar'),
      onClick: onClose,
      color: 'secondary',
      variant: 'contained',
      disableElevation: true,
    },
    {
      onClick: handleSubmit(pay),
      children: tProjectKey('Enviar a SAGE'),
      variant: 'contained',
      color: 'primary',
      disableElevation: true,
      disabled:
        !watch('iban') &&
        !watch('agreement_number') &&
        !watch('provider_code') &&
        !watch('description_project') &&
        !watch('concept') &&
        !watch('account_number') &&
        !watch('provider_code'),
    },
  ];
  let accountIbanList;
  let oneIbanAccount;

  if (account_numbers && account_numbers.length > 1) {
    accountIbanList = (
      <SelectController
        size="50"
        control={control}
        errors={errors}
        schema={PaymentModalSchema}
        defaultValue={watch('account_number') || ''}
        name="account_number"
        label={tProjectKey('Número de cuenta')}
      >
        {account_numbers?.map((iban: string, index: number) => (
          <MenuItem key={`${iban}_${index}`} value={iban}>
            {iban}
          </MenuItem>
        ))}
      </SelectController>
    );
  } else {
    oneIbanAccount = (
      <TextInputController
        errors={errors}
        size="50"
        label={tProjectKey('Número de cuenta')}
        name="account_number"
        control={control}
        schema={PaymentModalSchema}
        defaultValue={watch('account_number')}
        disabled
      />
    );
  }

  let view_info;
  if (inputsData.payment_info) {
    view_info = <MessageInfo>{inputsData.payment_info}</MessageInfo>;
  }

  return (
    <Modal title={tProjectKey('Importe de pago')} onClose={onClose} open={view} buttons={buttons}>
      <form className={styles.form_container}>
        {view_info}
        <TextInputController
          errors={errors}
          size="50"
          label={tProjectKey('Nº de convenio')}
          name="agreement_number"
          control={control}
          schema={PaymentModalSchema}
          defaultValue={watch('agreement_number')}
          disabled
        />
        <TextInputController
          errors={errors}
          size="50"
          label={tProjectKey('Número de documento')}
          name="document_number"
          control={control}
          schema={PaymentModalSchema}
          defaultValue={watch('document_number')}
          disabled
        />
        <TextInputController
          size="50"
          errors={errors}
          label={tProjectKey('Concepto')}
          name="concept"
          control={control}
          schema={PaymentModalSchema}
          defaultValue={watch('concept')}
        />
        {accountIbanList}
        {oneIbanAccount}
        <TextInputController
          size="50"
          errors={errors}
          label={tProjectKey('Código de proveedor')}
          name="provider_code"
          control={control}
          schema={PaymentModalSchema}
          defaultValue={watch('provider_code')}
          disabled
        />
        <TextInputController
          size="50"
          errors={errors}
          label={tProjectKey('Nombre')}
          name="name"
          control={control}
          schema={PaymentModalSchema}
          defaultValue={watch('name')}
          disabled
        />
        <TextInputController
          errors={errors}
          size="50"
          label={tProjectKey('Importe de pago')}
          name="amount"
          control={control}
          schema={PaymentModalSchema}
          defaultValue={watch('amount') || 0}
          type="number"
          disabled
          price
        />
        <TextInputController
          errors={errors}
          size="50"
          label={tProjectKey('Fecha de pago')}
          name="payment_date"
          control={control}
          schema={PaymentModalSchema}
          defaultValue={dbDateToDate(watch('payment_date'))}
          disabled
        />

        <TextInputController
          errors={errors}
          size="50"
          label={tProjectKey('Área')}
          name="center_cost_area_code"
          control={control}
          schema={PaymentModalSchema}
          defaultValue={watch('center_cost_area_code')}
          disabled
        />
        <TextInputController
          errors={errors}
          size="50"
          label={tProjectKey('Canal')}
          name="center_cost_channel_code"
          control={control}
          schema={PaymentModalSchema}
          defaultValue={watch('center_cost_channel_code')}
          disabled
        />
        <TextInputController
          errors={errors}
          size="50"
          label={tProjectKey('Delegación')}
          name="center_cost_delegation_code"
          control={control}
          schema={PaymentModalSchema}
          defaultValue={watch('center_cost_delegation_code')}
          disabled
        />
        <TextInputController
          errors={errors}
          size="50"
          label={tProjectKey('Proyecto')}
          name="center_cost_project_code"
          control={control}
          schema={PaymentModalSchema}
          defaultValue={watch('center_cost_project_code')}
          disabled
        />
        <TextInputController
          size="100"
          errors={errors}
          label={tProjectKey('Descripción')}
          name="description_project"
          control={control}
          schema={PaymentModalSchema}
          defaultValue={watch('description_project')}
        />
      </form>
    </Modal>
  );
};

export default PaymentModal;

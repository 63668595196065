/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';
import {
  addScholarshipPaymentFromApi,
  deleteScholarshipPaymentFromApi,
  getScholarshipPaymentAdjustmentFromApi,
  updateScholarshipPayments,
} from '../../../api/ScholarshipPayment/scholarshipPayment';
import errorMessage from '../../../helpers/errorMessage';
import { ScholarshipPaymentAdjustmentDataConsts } from './action_types';
import {
  addScholarshipPaymentKoAction,
  addScholarshipPaymentOkAction,
  deleteScholarshipPaymentAdjustmentKoAction,
  deleteScholarshipPaymentAdjustmentOkAction,
  getScholarshipPaymentAdjustmentKoAction,
  getScholarshipPaymentAdjustmentOkAction,
  saveScholarshipPaymentKoAction,
  saveScholarshipPaymentOkAction,
} from './actions';
import {
  AddScholarshipPaymentAction,
  DeleteScholarshipPaymentAdjustmentAction,
  GetScholarshipPaymentAdjustmentAction,
  SaveScholarshipPaymentAction,
} from './definitions';

function* getScholarshipByIdFromSaga(action: GetScholarshipPaymentAdjustmentAction): Generator<any, void, any> {
  try {
    const scholarshipPayments = yield getScholarshipPaymentAdjustmentFromApi(action.scholarshipId);
    yield put(getScholarshipPaymentAdjustmentOkAction(scholarshipPayments));
  } catch (e) {
    yield put(getScholarshipPaymentAdjustmentKoAction(errorMessage(e)));
  }
}

function* addScholarshipPaymentFromSaga(action: AddScholarshipPaymentAction): Generator<any, void, any> {
  try {
    const { scholarshipPayment } = action;
    yield addScholarshipPaymentFromApi(scholarshipPayment);
    yield put(addScholarshipPaymentOkAction(scholarshipPayment));
  } catch (e) {
    yield put(addScholarshipPaymentKoAction(errorMessage(e)));
  }
}

function* saveScholarshipPaymentSagas(action: SaveScholarshipPaymentAction): Generator<any, void, any> {
  try {
    const { scholarship_id, scholarship_payments } = action;
    yield updateScholarshipPayments(scholarship_id, scholarship_payments);
    yield put(saveScholarshipPaymentOkAction(scholarship_id, scholarship_payments));
  } catch (e) {
    yield put(saveScholarshipPaymentKoAction(errorMessage(e)));
  }
}

function* deleteScholarshipPaymentFromSaga(
  action: DeleteScholarshipPaymentAdjustmentAction,
): Generator<any, void, any> {
  try {
    yield deleteScholarshipPaymentFromApi(action.scholarshipPaymentId);
    yield put(deleteScholarshipPaymentAdjustmentOkAction(action.scholarshipPaymentId));
  } catch (e) {
    yield put(deleteScholarshipPaymentAdjustmentKoAction(errorMessage(e)));
  }
}

export default [
  takeLatest<GetScholarshipPaymentAdjustmentAction>(
    ScholarshipPaymentAdjustmentDataConsts.GET_SCHOLARSHIP_PAYMENT_ADJUSTMENT,
    getScholarshipByIdFromSaga,
  ),
  takeLatest<AddScholarshipPaymentAction>(
    ScholarshipPaymentAdjustmentDataConsts.ADD_SCHOLARSHIP_PAYMENT_ADJUSTMENT,
    addScholarshipPaymentFromSaga,
  ),
  takeLatest<SaveScholarshipPaymentAction>(
    ScholarshipPaymentAdjustmentDataConsts.SAVE_SCHOLARSHIP_PAYMENT_ADJUSTMENT,
    saveScholarshipPaymentSagas,
  ),
  takeLatest<DeleteScholarshipPaymentAdjustmentAction>(
    ScholarshipPaymentAdjustmentDataConsts.DELETE_SCHOLARSHIP_PAYMENT_ADJUSTMENT,
    deleteScholarshipPaymentFromSaga,
  ),
];

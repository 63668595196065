import { ButtonProps, MenuItem } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { APIValidationError } from '../../../api/api';
import {
  createTransactionCode,
  getOneTransactionCode,
  updateTransactionCode,
} from '../../../api/TaxRetention/taxretention';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import Title from '../../../components/Title/Title';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { getAccountingMetadataAction } from '../../../redux/metadata/actions';
import { InvoiceType, OperationKey, Serial, TransactionCode } from '../../../redux/metadata/definitions';
import { selectOperationKeys, selectSerials } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import { TransactionCodeSchema } from '../../../validations/formSchema';
import AccountingSubmenu from '../../Accounting/AccountingSubmenu';

interface EditTransactionCode extends RouteComponentProps<{ id: string }> {
  operationsKeys: OperationKey[];
  serials: Serial[];
  create: boolean;
  getAccountingMetadata: () => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const TransactionCodeConfig: React.FC<EditTransactionCode> = ({
  match,
  serials,
  getAccountingMetadata,
  showSnackbar,
  operationsKeys,
}) => {
  const { setError, handleSubmit, errors, control } = useForm<TransactionCode>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: TransactionCodeSchema,
  });
  const {
    params: { id },
  } = match;

  const [list, setList] = React.useState<TransactionCode>();
  const [validationErrors, setValidationErrors] = useState({} as APIValidationError);

  useValidatorAPI(validationErrors, setError);

  useEffect(() => {
    id === '0'
      ? setList({} as TransactionCode)
      : getOneTransactionCode(parseFloat(id)).then(transactionCode => setList(transactionCode));
  }, [id]);

  useEffect(() => {
    getAccountingMetadata();
  }, [getAccountingMetadata]);

  const title = () => (id === '0' ? tKey('Creación de código') : tKey('Edición de código'));

  const onSave = async (data: TransactionCode) => {
    try {
      if (id === '0') {
        const result = await createTransactionCode(data);
        switch (result.type) {
          case 'ok':
            showSnackbar(
              tKey('Formulario creado correctamente'),
              'success',
              '/contabilidad/impuestos/transaction-code',
              1000,
            );
            return;
          case 'validation-error':
            const error = result.value;
            setValidationErrors(error);
            return;
        }
      } else {
        await updateTransactionCode({ ...data, id: parseInt(id) });
      }
      showSnackbar(
        tKey('Formulario actualizado correctamente'),
        'success',
        '/contabilidad/impuestos/transaction-code',
        1000,
      );
    } catch (e) {
      showSnackbar(tErrorKey('Error al actualizar el formulario'), 'error', undefined, 1000);
    }
  };

  const invoiceType = [tKey('Emitida'), tKey('Recibida'), null] as InvoiceType[];

  const buttonsEdit: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: handleSubmit(onSave),
      startIcon: <Save />,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
    },
  ];

  if (!list) return <Loading big />;

  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu actionsButtons={buttonsEdit} />}
      leftSubmenu={<AccountingSubmenu selected="transaction_code" submenu={tKey('Impuestos')} />}
    >
      <Title>{title()}</Title>
      <FormContainer title="">
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={list.code}
          schema={TransactionCodeSchema}
          label={tFormKey('Código')}
          name="code"
          size="50"
          disabled={+id ? true : false}
        />
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={list.name}
          schema={TransactionCodeSchema}
          label={tFormKey('Nombre')}
          name="name"
          size="50"
        />
        <SelectController
          size="50"
          control={control}
          errors={errors}
          schema={TransactionCodeSchema}
          defaultValue={list.invoice_type}
          name="invoice_type"
          label={tFormKey('Tipo de factura')}
        >
          {invoiceType.map((element: InvoiceType) => (
            <MenuItem key={element} value={element}>
              {element}
            </MenuItem>
          ))}
        </SelectController>
        <SelectController
          size="50"
          control={control}
          errors={errors}
          schema={TransactionCodeSchema}
          defaultValue={list.default_op_key}
          name="default_op_key"
          label={tFormKey('Clave de operación')}
        >
          {operationsKeys.map((type: OperationKey) => (
            <MenuItem key={type.op_key} value={type.op_key}>
              {type.op_key} - {type.description}
            </MenuItem>
          ))}
        </SelectController>
        <SelectController
          size="50"
          control={control}
          errors={errors}
          schema={TransactionCodeSchema}
          defaultValue={list.serial}
          name="serial"
          label={tFormKey('Serie')}
        >
          {serials.map((element: Serial) => (
            <MenuItem key={element} value={element}>
              {element}
            </MenuItem>
          ))}
        </SelectController>
      </FormContainer>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  operationsKeys: selectOperationKeys(state),
  serials: selectSerials(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
  getAccountingMetadata: () => dispatch(getAccountingMetadataAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(TransactionCodeConfig);

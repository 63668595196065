import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Edit } from '@material-ui/icons';
import moment from 'moment';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { DATE_FORMAT } from '../../../../../constants/date';
import { formatNumber } from '../../../../../helpers/formatNumber';
import { setScholarshipPaymentDataAction } from '../../../../../redux/scholarship/paymentAdjustments/actions';
import { ScholarshipPaymentAdjustmentItem } from '../../../../../redux/scholarship/paymentAdjustments/PaymentAdjustment';
import { UUID } from '../../../../../types/Shared/standard';
import useScholarshipChangePayment from '../../../../Accounting/ScholarshipsPayment/hooks/useScholarshipChangePayment';
import styles from './scholarshipPaymentsTable.module.scss';
import PaymentStatus from '../../../../../types/Payments/PaymentStatus';
import { tKey } from '../../../../../helpers/translate';

const ScholarshipPaymentEditModal = React.lazy(() =>
  import('../ScholarshipPaymentEditModal/ScholarshipPaymentEditModal'),
);

interface ScholarshipPaymentsRowProps {
  payment: ScholarshipPaymentAdjustmentItem;
  disabled: boolean;
  editable: boolean;
  setScholarshipPaymentData: (scholarshipPaymentId: UUID, payment_date: string, payment_amount: number) => void;
}

const ScholarshipPaymentRow: React.FC<ScholarshipPaymentsRowProps> = ({
  payment,
  disabled,
  editable,
  setScholarshipPaymentData,
}) => {
  const { showPaymentModal, closePaymentModal, setChangePaymentInfo } = useScholarshipChangePayment();

  const iconColor = (disabled: boolean) => {
    const opacity = disabled ? 0.2 : 0.54;
    return { color: `rgba(0, 0, 0, ${opacity})` };
  };

  const onSubmitChangeDate = async (date: string, amount: number) => {
    setScholarshipPaymentData(payment.id, date, amount);
    closePaymentModal();
  };

  const renderModifyPayment = (payment: ScholarshipPaymentAdjustmentItem) => {
    setChangePaymentInfo(payment);
  };

  const renderActions = (payment: ScholarshipPaymentAdjustmentItem): JSX.Element | undefined => {
    if (!editable) {
      return undefined;
    }
    const shouldOpenModal = !disabled && showPaymentModal;
    return (
      <>
        <Suspense fallback={<></>}>
          {shouldOpenModal ? (
            <ScholarshipPaymentEditModal
              view={showPaymentModal}
              handleClose={() => closePaymentModal()}
              onApplying={onSubmitChangeDate}
              defaultAmountValue={payment.payment_amount}
              defaultDateValue={payment.payment_date}
            />
          ) : null}
        </Suspense>
        <td className={`${styles.cellRow}  ${styles.actionCell}`}>
          <Tooltip title="Editar">
            <IconButton hidden={!editable} aria-label="Editar">
              <Edit style={iconColor(disabled)} onClick={() => renderModifyPayment(payment)} />
            </IconButton>
          </Tooltip>
        </td>
      </>
    );
  };

  const { payment_amount, payment_date, type, status } = payment;

  const paymentDate = moment(payment_date).format(DATE_FORMAT);
  return (
    <tr className={styles.tableRow}>
      <td className={`${styles.cellRow}  ${styles.amountCell}`}>{`${formatNumber({
        value: payment_amount,
      })} €`}</td>
      <td className={`${styles.cellRow}  ${styles.dateCell}`}>{paymentDate}</td>
      <td className={`${styles.cellRow}  ${styles.typeCell}`}>{type}</td>
      <td className={`${styles.cellRow}  ${styles.statusCell}`}>{tKey(PaymentStatus.translationName(status))}</td>
      {renderActions(payment)}
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  setScholarshipPaymentData: (scholarshipPaymentId: UUID, payment_date: string, payment_amount: number): void =>
    dispatch(setScholarshipPaymentDataAction(scholarshipPaymentId, payment_date, payment_amount)),
});

export default connect(undefined, mapDispatchToProps)(ScholarshipPaymentRow);

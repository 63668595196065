/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps, MenuItem } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../../api/api';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../components/Select/SelectController';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import { tErrorKey, tScholarshipKey } from '../../../../helpers/translate';
import { Areas, Delegations, GenericMetadata, Projects } from '../../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../../redux/metadata/selectors';
import { AppState } from '../../../../redux/root-reducer';
import {
  scholarshipAgreementSetDataAction,
  updateScholarshipAgreementAction,
} from '../../../../redux/scholarship/agreement/actions';
import { ScholarshipAgreementData } from '../../../../redux/scholarship/agreement/ScholarshipAgreement';
import { selectScholarshipValidationErrors } from '../../../../redux/scholarship/selectors';
import { instituteScholarshipPaymentSchema } from '../../../../validations/scholarshipSchema';
import PaymentFormProviderInfo from './PaymentFormProviderInfo';
import validate from '../../../../validations/validators';
import { selectScholarshipPaymentsHasSomePaymentDone } from '../../../../redux/scholarship/paymentAdjustments/selectors';

interface PaymentFormAccountingDataProps {
  scholarshipAgreementData: ScholarshipAgreementData;
  disabled: boolean;
  genericMetadata: GenericMetadata;
  buttonActionSaveAgreement: ButtonProps;
  validationErrors: APIValidationError | null;
  hasSomePaymentDone: boolean;
  setData: (key: string, value: string | number | null) => void;
  updateScholarshipAgreement: (scholarshipAgreementData: ScholarshipAgreementData) => void;
}

const PaymentFormAccountingData: React.FC<PaymentFormAccountingDataProps> = ({
  scholarshipAgreementData,
  disabled,
  genericMetadata,
  buttonActionSaveAgreement,
  validationErrors,
  hasSomePaymentDone,
  setData,
  updateScholarshipAgreement,
}) => {
  const {
    scholarship_id,
    agreement_number,
    center_cost_channel_code,
    center_cost_delegation_code,
    center_cost_area_code,
    center_cost_project_code,
    concept,
    description,
    retention_code,
    account_number,
    student_account_number,
    account_numbers,
  } = scholarshipAgreementData;
  const { channels, delegations, areas, projects, taxRetentions } = genericMetadata;

  const { control, errors, setValue, handleSubmit, setError, clearError, watch } = useForm<ScholarshipAgreementData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: instituteScholarshipPaymentSchema,
  });

  const onUpdateAgreement = (data: ScholarshipAgreementData) => {
    clearError();
    updateScholarshipAgreement({ ...data, scholarship_id });
  };

  useButtonAction(buttonActionSaveAgreement, handleSubmit(onUpdateAgreement), { hidden: disabled });

  useValidatorAPI(validationErrors, setError);

  const handleSelectChange = (target: any, child: string[]) => {
    if (target.name) {
      setData(target.name, target.value);
      child.forEach(input => {
        setValue(input, null);
        setData(input, null);
      });
    }
  };

  const renderCenterCostChannel = (): JSX.Element => {
    return (
      <SelectController
        name={'center_cost_channel_code'}
        control={control}
        errors={errors}
        schema={instituteScholarshipPaymentSchema}
        disabled={disabled}
        size="50"
        label={tScholarshipKey('Canal')}
        defaultValue={center_cost_channel_code}
        onClick={e =>
          handleSelectChange(e.target, [
            'center_cost_delegation_code',
            'center_cost_area_code',
            'center_cost_project_code',
          ])
        }
      >
        {channels.map(element => (
          <MenuItem key={'ch'.concat(element.code || '')} value={element.code}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderCenterCostDelegation = (): JSX.Element => {
    const delegationsFiltered = delegations.filter(
      (delegation: Delegations) =>
        delegation.channel_code === watch('center_cost_channel_code', center_cost_channel_code) ||
        delegation.channel_code === null,
    );

    return (
      <SelectController
        name={'center_cost_delegation_code'}
        control={control}
        errors={errors}
        schema={instituteScholarshipPaymentSchema}
        disabled={disabled}
        size="50"
        label={tScholarshipKey('Delegación')}
        defaultValue={center_cost_delegation_code}
        onClick={e => handleSelectChange(e.target, ['center_cost_area_code', 'center_cost_project_code'])}
      >
        {delegationsFiltered.map(element => (
          <MenuItem key={'de'.concat(element.code || '')} value={element.code}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderCenterCostAreas = (): JSX.Element => {
    const areasFiltered = areas.filter(
      (area: Areas) =>
        area.delegation_code === watch('center_cost_delegation_code', center_cost_delegation_code) ||
        area.delegation_code === null,
    );

    return (
      <SelectController
        name={'center_cost_area_code'}
        control={control}
        errors={errors}
        schema={instituteScholarshipPaymentSchema}
        disabled={disabled}
        size="50"
        label={tScholarshipKey('Departamento')}
        defaultValue={center_cost_area_code}
        onClick={e => handleSelectChange(e.target, ['center_cost_project_code'])}
      >
        {areasFiltered.map(element => (
          <MenuItem key={'ar'.concat(element.code || '')} value={element.code}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderProjectCenterCost = (): JSX.Element => {
    const projectsFiltered = projects.filter(
      (e: Projects) => e.area_code === watch('center_cost_area_code', center_cost_area_code) || e.area_code === null,
    );
    return (
      <SelectController
        fullWidth
        control={control}
        errors={errors}
        schema={instituteScholarshipPaymentSchema}
        name="center_cost_project_code"
        disabled={disabled}
        size="50"
        defaultValue={center_cost_project_code}
        label={tScholarshipKey('Proyecto')}
        onClick={e => handleSelectChange(e.target, [])}
      >
        {projectsFiltered.map(type => (
          <MenuItem key={'pr'.concat(type.code || '')} value={type.code}>
            {type.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderRetentionCode = (): JSX.Element => {
    return (
      <SelectController
        fullWidth
        control={control}
        errors={errors}
        schema={instituteScholarshipPaymentSchema}
        name="retention_code"
        disabled={disabled || hasSomePaymentDone}
        size="50"
        defaultValue={retention_code}
        label={tScholarshipKey('Número de retención')}
        onClick={e => handleSelectChange(e.target, [])}
      >
        {taxRetentions
          ?.filter(e => e.tax_type === 'R')
          .map(type => (
            <MenuItem key={type.code} value={type.code}>
              {type.name}
            </MenuItem>
          ))}
      </SelectController>
    );
  };

  const renderAccountNumbers = (): JSX.Element => {
    return (
      <SelectController
        fullWidth
        control={control}
        errors={errors}
        schema={instituteScholarshipPaymentSchema}
        name="account_number"
        disabled={disabled}
        size="50"
        defaultValue={account_number}
        label={tScholarshipKey('IBAN de pago al centro')}
        onClick={e => handleSelectChange(e.target, [])}
      >
        {!!account_numbers &&
          account_numbers.map((account_number: string) => (
            <MenuItem key={account_number} value={account_number}>
              {account_number}
            </MenuItem>
          ))}
      </SelectController>
    );
  };

  const checkAccountNumber = (name: string, number: string) => {
    if (validate.accountNumber(number, 'España')) {
      clearError(name);
      return true;
    } else {
      setError(name, 'invalidAccountNumber', tErrorKey('IBAN incorrecto'));
      return false;
    }
  };

  return (
    <FormContainer title={tScholarshipKey('Datos contables')}>
      <PaymentFormProviderInfo scholarshipAgreementData={scholarshipAgreementData} disabled={disabled} />
      {renderCenterCostChannel()}
      {renderCenterCostDelegation()}
      {renderCenterCostAreas()}
      {renderProjectCenterCost()}
      <TextInputController
        schema={instituteScholarshipPaymentSchema}
        label={tScholarshipKey('Número de convenio')}
        size="50"
        name="agreement_number"
        errors={errors}
        defaultValue={agreement_number}
        disabled
        control={control}
      />
      <TextInputController
        schema={instituteScholarshipPaymentSchema}
        label={tScholarshipKey('Concepto')}
        size="50"
        name="concept"
        errors={errors}
        defaultValue={concept}
        control={control}
        disabled={disabled}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('concept', e.target.value)}
      />
      {renderRetentionCode()}
      <TextInputController
        schema={instituteScholarshipPaymentSchema}
        label={tScholarshipKey('Descripción')}
        size="100"
        rows="8"
        rowsMax="8"
        fullWidth
        multiline
        name="description"
        errors={errors}
        defaultValue={description}
        control={control}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('description', e.target.value)}
      />
      <TextInputController
        schema={instituteScholarshipPaymentSchema}
        label={tScholarshipKey('student_account_number.title')}
        size="50"
        name={'student_account_number'}
        defaultValue={student_account_number}
        errors={errors}
        control={control}
        disabled={disabled}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          setData('student_account_number', e.target.value);
          checkAccountNumber('student_account_number', e.target.value);
        }}
      />
      {renderAccountNumbers()}
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  genericMetadata: selectGenericMetadata(state),
  validationErrors: selectScholarshipValidationErrors(state),
  hasSomePaymentDone: selectScholarshipPaymentsHasSomePaymentDone(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number | null) => dispatch(scholarshipAgreementSetDataAction(key, value)),
  updateScholarshipAgreement: (scholarshipAgreementData: ScholarshipAgreementData) =>
    dispatch(updateScholarshipAgreementAction(scholarshipAgreementData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFormAccountingData);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';
import { ScholarshipPaymentsConst } from './action_types';

import {
  getEntityScholarshipPaymentFromApi,
  getInstituteScholarshipPaymentFromApi,
  changeScholarshipPaymentDateFromApi,
  replacePaymentsDatesFromApi,
  entityScholarshipPaymentPayFromApi,
} from '../../../api/ScholarshipPayment/scholarshipPayment';

import {
  changeScholarshipPaymentDateActionKo,
  changeScholarshipPaymentDateActionOk,
  getEntityScholarshipPaymentsKoAction,
  getEntityScholarshipPaymentsOkAction,
  getInstituteScholarshipPaymentsKoAction,
  getInstituteScholarshipPaymentsOkAction,
  replaceScholarshipPaymentDatesKoAction,
  replaceScholarshipPaymentDatesOkAction,
} from './actions';

import {
  ChangeScholarshipPaymentDateAction,
  EntityScholarshipPaymentSendSageAction,
  GetEntityScholarshipPaymentsAction,
  GetInstituteScholarshipPaymentsAction,
  ReplaceScholarshipPaymentDatesAction,
} from './definitions';

import errorMessage from '../../../helpers/errorMessage';
import PaymentStatus from '../../../types/Payments/PaymentStatus';
import { showSnackbar } from '../../FeedbackAPI/actions';
import { tKey } from '../../../helpers/translate';

function* getInstituteScholarshipPaymentsFromSaga(
  action: GetInstituteScholarshipPaymentsAction,
): Generator<any, void, any> {
  try {
    const { academic_year, status } = action;
    const scholarshipPayments = yield getInstituteScholarshipPaymentFromApi(academic_year, new PaymentStatus(status));
    yield put(getInstituteScholarshipPaymentsOkAction(scholarshipPayments));
  } catch (e) {
    yield put(getInstituteScholarshipPaymentsKoAction(errorMessage(e)));
  }
}

function* getEntityScholarshipPaymentsFromSaga(action: GetEntityScholarshipPaymentsAction): Generator<any, void, any> {
  try {
    const { academic_year, entity_id, status } = action;
    const scholarshipPayments = yield getEntityScholarshipPaymentFromApi(
      academic_year,
      entity_id,
      new PaymentStatus(status),
    );
    yield put(getEntityScholarshipPaymentsOkAction(scholarshipPayments));
  } catch (e) {
    yield put(getEntityScholarshipPaymentsKoAction(errorMessage(e)));
  }
}

function* changeScholarshipPaymentDateFromSaga(action: ChangeScholarshipPaymentDateAction): Generator<any, void, any> {
  try {
    yield changeScholarshipPaymentDateFromApi(action.payment_id, action.payment_date);
    yield put(changeScholarshipPaymentDateActionOk(action.payment_id, action.payment_date, action.is_entity));
    yield put(showSnackbar('Se ha modificado la fecha correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 1500));
    yield put(changeScholarshipPaymentDateActionKo(errorMessage(e)));
  }
}

function* replacePaymentsDatesFromSaga(action: ReplaceScholarshipPaymentDatesAction): Generator<any, void, any> {
  try {
    const { paymentDates } = action;
    const resultCount = yield replacePaymentsDatesFromApi(paymentDates) as Promise<number>;
    yield put(replaceScholarshipPaymentDatesOkAction());
    yield put(
      showSnackbar(resultCount.toString().concat(' ', tKey('fechas reemplazadas')), 'success', undefined, 1500),
    );
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 1500));
    yield put(replaceScholarshipPaymentDatesKoAction(errorMessage(e)));
  }
}

function* entityScholarshipPaymentSendSageFromSaga(
  action: EntityScholarshipPaymentSendSageAction,
): Generator<any, void, any> {
  try {
    const { entity_id, entityPaymentSendSageData } = action;
    yield entityScholarshipPaymentPayFromApi(entity_id, entityPaymentSendSageData) as Promise<void>;
    yield put(replaceScholarshipPaymentDatesOkAction());
    yield put(showSnackbar('Se ha realizado el pago correctametne', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 1500));
    yield put(replaceScholarshipPaymentDatesKoAction(errorMessage(e)));
  }
}

export default [
  takeLatest<GetInstituteScholarshipPaymentsAction>(
    ScholarshipPaymentsConst.GET_INSTITUTE_SCHOLARSHIP_PAYMENTS,
    getInstituteScholarshipPaymentsFromSaga,
  ),
  takeLatest<GetEntityScholarshipPaymentsAction>(
    ScholarshipPaymentsConst.GET_ENTITY_SCHOLARSHIP_PAYMENTS,
    getEntityScholarshipPaymentsFromSaga,
  ),
  takeLatest<ChangeScholarshipPaymentDateAction>(
    ScholarshipPaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE,
    changeScholarshipPaymentDateFromSaga,
  ),
  takeLatest<ReplaceScholarshipPaymentDatesAction>(
    ScholarshipPaymentsConst.REPLACE_SCHOLARSHIPS_PAYMENT_DATES,
    replacePaymentsDatesFromSaga,
  ),
  takeLatest<EntityScholarshipPaymentSendSageAction>(
    ScholarshipPaymentsConst.ENTITY_SCHOLARSHIPS_PAYMENT_SEND_SAGE,
    entityScholarshipPaymentSendSageFromSaga,
  ),
];

import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { deleteFileFromApi, downloadFileFromApi, uploadFileFromApi } from '../../../../api/file';
import KeyboardDatePickerController from '../../../../components/DatePicker/KeyboardDatePickerController';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import SubTitle from '../../../../components/SubTitle/Subtitle';
import UploadFileButton from '../../../../components/UploadFileButton/UploadFileButton';
import errorMessage from '../../../../helpers/errorMessage';
import { getDocs } from '../../../../helpers/getDocs';
import { tScholarshipKey } from '../../../../helpers/translate';
import { generateUuidV7 } from '../../../../helpers/uuidGenerator';
import { AppState } from '../../../../redux/root-reducer';
import styles from './ScholarshipPaymentsAdjustmentWidget.module.scss';

import FncButton from '../../../../components/FncButton/FncButton';
import { addScholarshipPaymentAction } from '../../../../redux/scholarship/paymentAdjustments/actions';
import { ScholarshipPaymentAdjustmentItem } from '../../../../redux/scholarship/paymentAdjustments/PaymentAdjustment';
import { selectPaymentsLoading } from '../../../../redux/scholarship/paymentAdjustments/selectors';
import PaymentStatus from '../../../../types/Payments/PaymentStatus';
import ScholarshipPaymentType from '../../../../types/Scholarships/ScholarshipPaymentType';
import { ScholarshipPaymentsAdjustmentSchema } from './scholarshipPaymentAdjustmentsSchema';

interface ScholarshipPaymentsAdjustmentWidgetProps {
  scholarshipId: number;
  entityId: number | null;
  addScholarshipPaymentData: (scholarshipPaymentAdjustmentData: ScholarshipPaymentAdjustmentItem) => void;
}

type ScholarshipPaymentAdjustmentFields = {
  file_id: number | null;
  file_name: string | null;
  payment_date: string | null;
  payment_amount: number;
  remarks: string;
};

const ScholarshipPaymentsAdjustmentWidget: React.FC<ScholarshipPaymentsAdjustmentWidgetProps> = ({
  scholarshipId,
  entityId,
  addScholarshipPaymentData,
}) => {
  const { register, watch, setError, reset, clearError, handleSubmit, setValue, control, errors } = useForm<
    ScholarshipPaymentAdjustmentFields
  >({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ScholarshipPaymentsAdjustmentSchema,
  });

  const uploadFile = async (file: File) => {
    try {
      const fileItem = await uploadFileFromApi(file);
      setValue('file_id', fileItem.id);
      setValue('file_name', fileItem.original_name);
    } catch (error) {
      setError('file_id', errorMessage(error));
    }
  };

  const deleteFile = async (file_id: number) => {
    const deleted = await deleteFileFromApi(file_id);
    if (deleted) {
      setValue('file_id', null);
      setValue('file_name', null);
    }
  };

  const downloadFile = async (file_id: number, filename: string) => {
    const blobFile = await downloadFileFromApi(file_id);
    getDocs(blobFile, true, filename);
  };

  const onSubmit = (data: ScholarshipPaymentAdjustmentFields) => {
    const { file_id, file_name, payment_amount, payment_date, remarks } = data;

    const newScholarshipPaymentAdjustmentData = {
      id: generateUuidV7(),
      payment_id: generateUuidV7(),
      scholarship_id: scholarshipId,
      entity_id: entityId,
      payment_amount: payment_amount,
      payment_date: payment_date,
      type: ScholarshipPaymentType.AJUSTE,
      order: 1,
      file_id: file_id,
      filename: file_name,
      status: PaymentStatus.PENDING,
      remarks: remarks,
    } as ScholarshipPaymentAdjustmentItem;
    clearError();
    reset({});
    setValue('file_id', null);
    setValue('file_name', null);
    addScholarshipPaymentData(newScholarshipPaymentAdjustmentData);
  };

  return (
    <>
      <div className={styles.justificationTableContainer}>
        <div className={styles.tableTitle}>
          <SubTitle>{tScholarshipKey('Ajustes')}</SubTitle>
        </div>
        <div className={styles.justificationTableContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formFields}>
              <TextInputController
                control={control}
                schema={ScholarshipPaymentsAdjustmentSchema}
                type="number"
                errors={errors}
                label={tScholarshipKey('Importe Ajuste')}
                name="payment_amount"
                defaultValue={0}
                fullWidth
                price
              />
              <KeyboardDatePickerController
                label={tScholarshipKey('Fecha pago')}
                name="payment_date"
                control={control}
                schema={ScholarshipPaymentsAdjustmentSchema}
                ref={register}
                defaultValue={null}
                fullWidth
                errors={errors}
              />
              <UploadFileButton
                file_id={watch('file_id')}
                filename={watch('file_name')}
                downloadFile={downloadFile}
                removeFile={deleteFile}
                onUploadFile={uploadFile}
                setValue={setValue}
                errors={errors}
              />
            </div>
            <TextInputController
              schema={ScholarshipPaymentsAdjustmentSchema}
              control={control}
              label={tScholarshipKey('Observaciones ajuste')}
              name="remarks"
              errors={errors}
              multiline
              rows="4"
              rowsMax="10"
              size="100"
            />
            <TextInputController
              control={control}
              schema={ScholarshipPaymentsAdjustmentSchema}
              type="hidden"
              errors={errors}
              name="file_id"
              defaultValue={null}
              visible={false}
            />
            <TextInputController
              control={control}
              schema={ScholarshipPaymentsAdjustmentSchema}
              type="hidden"
              errors={errors}
              name="file_name"
              defaultValue={null}
              visible={false}
            />
            <div className={styles.actionButton}>
              <FncButton type="submit" color="primary">
                {tScholarshipKey('Crear Ajuste')}
              </FncButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loadingPayment: selectPaymentsLoading(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  addScholarshipPaymentData: (scholarshipPayment: ScholarshipPaymentAdjustmentItem): void =>
    dispatch(addScholarshipPaymentAction(scholarshipPayment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipPaymentsAdjustmentWidget);

import { Tooltip } from '@material-ui/core';
import { CalendarToday, Link, Payment } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import { Column, MaterialTableProps } from 'material-table';
import moment from 'moment';
import React, { CSSProperties, Suspense } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { dbDateToDate } from '../../../helpers/dateHelper';
import { formatNumber } from '../../../helpers/formatNumber';
import { tFormKey, tKey, tScholarshipKey } from '../../../helpers/translate';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import { PaymentId } from '../../../redux/scholarship/payments/Payment';
import {
  changeScholarshipPaymentDateAction,
  setScholarshipPaymensReloadAction,
} from '../../../redux/scholarship/payments/actions';
import { ScholarshipPaymentListItem } from '../../../redux/scholarship/payments/definitions';
import { selectSelectedPaymentStatus } from '../../../redux/scholarship/payments/selectors';
import PaymentStatus from '../../../types/Payments/PaymentStatus';
import InstitutePaymentsModal from '../../Scholarship/Modals/InstitutePayments/InstitutePaymentsModal';
import useScholarshipChangeDate from './hooks/useScholarshipChangeDate';
import useScholarshipPaymentInfo from './hooks/useScholarshipPaymentInfo';
import useScholarshipPaymentsAdjustment from './hooks/useScholarshipPaymentsAdjustment';

const ModalDatePicker = React.lazy(() => import('../../../components/Modal/ModalDatePicker/ModalDatePicker'));
const ScholarshipPaymentAdjustmentModal = React.lazy(() =>
  import('../../Scholarship/Modals/PaymentAdjustment/ScholarshipPaymentAdjustmentModal'),
);

interface ScholarshipsPaymentListProps {
  loading: boolean;
  title: string;
  isEntity: boolean;
  allowScholarshipPayments: boolean;
  payments: ScholarshipPaymentListItem[];
  selectedStatus: string;
  changePaymentDate: (payment_id: PaymentId, payment_date: string, is_entity: boolean) => void;
  setScholarshipPaymentsReload: () => void;
}

const ScholarshipsPaymentList: React.FC<ScholarshipsPaymentListProps> = ({
  loading,
  title,
  isEntity,
  payments,
  selectedStatus,
  allowScholarshipPayments,
  changePaymentDate,
  setScholarshipPaymentsReload,
}) => {
  const history = useHistory();
  const { showDateModal, paymentId, paymentDate, closeDateModal, setChangeDateInfo } = useScholarshipChangeDate();
  const {
    paymentsToPay,
    showPaymentModal,
    openPaymentModal,
    closePaymentModal,
    setPaymentsToPay,
  } = useScholarshipPaymentInfo();
  const {
    showAdjustmentModal,
    scholarshipId,
    entityId,
    studentName,
    closeAdjustmentModal,
    setAdjustmentInfo,
  } = useScholarshipPaymentsAdjustment();

  const disabledCell: CSSProperties = {
    color: '#00000074',
  };

  const paidCell: CSSProperties = {
    color: 'green',
  };

  const canceledCell: CSSProperties = {
    color: 'red',
  };

  const tableIcons = {
    Link: () => <Link style={{ color: 'rgba(0, 0, 0, 0.8)' }} />,
    Pay: () => <Payment style={{ color: 'rgba(0, 0, 0, 0.8)' }} />,
    PayDisabled: () => <Payment style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
    Date: () => <CalendarToday style={{ color: 'rgba(0, 0, 0, 0.8)' }} />,
    DateDisabled: () => <CalendarToday style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
    Adjust: () => <SettingsIcon style={{ color: 'rgba(0, 0, 0, 0.8)' }} />,
    AdjustDisabled: () => <SettingsIcon style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  };

  const selectScholarshipCellCSS = (
    payment_status: string,
    disabled: boolean,
    textAlign: 'center' | 'right' | 'left' = 'left',
  ) => {
    const status = new PaymentStatus(payment_status);

    let cellStyle: CSSProperties = {};

    if (status.isCanceled()) {
      cellStyle = canceledCell;
    }
    // Verificar si está pagado
    else if (status.isPaid()) {
      cellStyle = paidCell;
    }
    // Verificar si está deshabilitado
    else if (disabled) {
      cellStyle = disabledCell;
    }

    return { ...cellStyle, textAlign } as CSSProperties;
  };

  const doScholarshipPayment = async () => {
    openPaymentModal();
  };

  const handleCloseAdjustments = async () => {
    closeAdjustmentModal();
    setScholarshipPaymentsReload();
  };

  const handlePaymentsDone = async () => {
    closePaymentModal();
    setScholarshipPaymentsReload();
  };

  const isRowActive = (rowData: ScholarshipPaymentListItem): boolean => {
    const paymentStatus = new PaymentStatus(rowData.payment_status);
    return paymentStatus.isPending();
  };

  const rowDataActive = (rowData: ScholarshipPaymentListItem | ScholarshipPaymentListItem[]): boolean => {
    if (Array.isArray(rowData)) {
      if (rowData.length === 1) {
        return isRowActive(rowData[0]);
      }
      return false;
    }
    return isRowActive(rowData);
  };

  const rowPaymentsActive = (rowDatas: ScholarshipPaymentListItem | ScholarshipPaymentListItem[]): boolean => {
    if (Array.isArray(rowDatas)) {
      return rowDatas.some((rowData: ScholarshipPaymentListItem) => isRowActive(rowData));
    }
    return false;
  };

  const selectScholarshipPayments = (payments: ScholarshipPaymentListItem | ScholarshipPaymentListItem[]) => {
    let selected = [] as ScholarshipPaymentListItem[];
    if (Array.isArray(payments)) {
      selected = payments.filter((payment: ScholarshipPaymentListItem) => isRowActive(payment));
    } else {
      if (isRowActive(payments)) selected.push(payments);
    }
    setPaymentsToPay(selected);
  };

  const getRowData = (
    rowData: ScholarshipPaymentListItem | ScholarshipPaymentListItem[],
  ): ScholarshipPaymentListItem => {
    if (Array.isArray(rowData)) {
      return rowData[rowData.length - 1];
    }
    return rowData;
  };

  const goToScholarship = (rowDatas: ScholarshipPaymentListItem | ScholarshipPaymentListItem[]) => {
    const { scholarship_id } = getRowData(rowDatas);
    const scholarship_type = isEntity ? 'entity' : 'institute';
    history.push({
      pathname: `/becas/${scholarship_type}/detalle/${scholarship_id}`,
    });
  };

  const handleAction = (
    rowDatas: ScholarshipPaymentListItem | ScholarshipPaymentListItem[],
    action: (rowData: ScholarshipPaymentListItem) => void,
  ) => {
    const rowData = getRowData(rowDatas);
    if (!rowDataActive(rowData)) {
      return;
    }
    action(rowData);
  };

  const onDateClickAction = (rowDatas: ScholarshipPaymentListItem | ScholarshipPaymentListItem[]) =>
    handleAction(rowDatas, setChangeDateInfo);

  const onAdjustmentClickAction = (rowDatas: ScholarshipPaymentListItem | ScholarshipPaymentListItem[]) =>
    handleAction(rowDatas, setAdjustmentInfo);

  const onPayClickAction = (rowDatas: ScholarshipPaymentListItem | ScholarshipPaymentListItem[]) =>
    handleAction(rowDatas, () => doScholarshipPayment());

  const tableActions = [
    (rowData: ScholarshipPaymentListItem | ScholarshipPaymentListItem[]) => ({
      icon: tableIcons.Link,
      tooltip: tFormKey('Ir a la beca'),
      onClick: () => goToScholarship(rowData),
      isFreeAction: true,
    }),
  ];

  if (allowScholarshipPayments) {
    tableActions.push((rowData: ScholarshipPaymentListItem | ScholarshipPaymentListItem[]) => ({
      icon: rowDataActive(rowData) ? tableIcons.Date : tableIcons.DateDisabled,
      tooltip: tFormKey('Modificar fecha de pago'),
      onClick: () => onDateClickAction(rowData),
      isFreeAction: true,
    }));
    tableActions.push((rowData: ScholarshipPaymentListItem | ScholarshipPaymentListItem[]) => ({
      icon: rowDataActive(rowData) ? tableIcons.Adjust : tableIcons.AdjustDisabled,
      tooltip: tScholarshipKey('Ajuste de pagos'),
      onClick: () => onAdjustmentClickAction(rowData),
      isFreeAction: true,
    }));
  }
  if (!isEntity) {
    tableActions.push((rowData: ScholarshipPaymentListItem | ScholarshipPaymentListItem[]) => ({
      icon: rowPaymentsActive(rowData) ? tableIcons.Pay : tableIcons.PayDisabled,
      hidden: !allowScholarshipPayments,
      tooltip: tFormKey('Pagar'),
      onClick: () => onPayClickAction(rowData),
      isFreeAction: true,
    }));
  }

  const renderCellTooltip = (value: string): JSX.Element => {
    return (
      <Tooltip title={value}>
        <span>{value}</span>
      </Tooltip>
    );
  };

  const getPaymentColumnType = (): Column<ScholarshipPaymentListItem> => {
    if (isEntity) {
      return {
        field: 'scholarship_type',
        title: tFormKey('Tipo beca'),
        headerStyle: { textAlign: 'center' },
        cellStyle: (data: ScholarshipPaymentListItem[], rowData: ScholarshipPaymentListItem) =>
          selectScholarshipCellCSS(rowData.payment_status, rowData.disabled, 'center'),
        render: (rowData: ScholarshipPaymentListItem) => renderCellTooltip(rowData.scholarship_type),
        //@ts-ignore
        width: '140px',
      };
    }
    return {
      field: 'payment_type',
      title: tFormKey('Tipo pago'),
      headerStyle: { textAlign: 'center' },
      cellStyle: (data: ScholarshipPaymentListItem[], rowData: ScholarshipPaymentListItem) =>
        selectScholarshipCellCSS(rowData.payment_status, rowData.disabled, 'center'),
      render: (rowData: ScholarshipPaymentListItem) => renderCellTooltip(rowData.payment_type),
      //@ts-ignore
      width: '140px',
    };
  };

  const getPaymentColumnInfo = (): Column<ScholarshipPaymentListItem> => {
    if (isEntity) {
      return {
        field: 'entity_name',
        title: tKey('Entidad'),
        headerStyle: { width: '160px', maxWidth: '160px' },
        cellStyle: (data: ScholarshipPaymentListItem[], rowData: ScholarshipPaymentListItem) =>
          selectScholarshipCellCSS(rowData.payment_status, rowData.disabled),
        render: (rowData: ScholarshipPaymentListItem) => renderCellTooltip(rowData.entity_name || ''),
        //@ts-ignore
        width: '260px',
        maxWidth: '260px',
      };
    }
    return {
      field: 'account_number',
      title: tKey('IBAN'),
      headerStyle: { width: '180px', maxWidth: '180px' },
      cellStyle: (data: ScholarshipPaymentListItem[], rowData: ScholarshipPaymentListItem) =>
        selectScholarshipCellCSS(rowData.payment_status, rowData.disabled),
      render: (rowData: ScholarshipPaymentListItem) => renderCellTooltip(rowData.account_number || ''),
      //@ts-ignore
      width: '220px',
    };
  };

  const checkDate = (rowData: ScholarshipPaymentListItem, term: string): boolean => {
    const dateFormatted = dbDateToDate(rowData.payment_date);
    return dateFormatted.includes(term);
  };

  const filteredPayments = (): ScholarshipPaymentListItem[] => {
    const paymentStatus = new PaymentStatus(selectedStatus);
    if (paymentStatus.isEmpty()) {
      return payments;
    }
    return payments.filter((payment: ScholarshipPaymentListItem) => payment.payment_status === selectedStatus);
  };

  const tableData: MaterialTableProps<ScholarshipPaymentListItem> = {
    title: tKey(title),
    columns: [
      {
        field: 'student_name',
        title: tKey('Joven'),
        cellStyle: (data: ScholarshipPaymentListItem[], rowData: ScholarshipPaymentListItem) =>
          selectScholarshipCellCSS(rowData.payment_status, rowData.disabled),
        render: (rowData: ScholarshipPaymentListItem) => (
          <Tooltip title={rowData.disabled ? rowData.disabled_reason : rowData.student_name}>
            <span>{rowData.student_name}</span>
          </Tooltip>
        ),
        //@ts-ignore
        width: '180px',
        maxWidth: '180px',
      },
      {
        field: 'payment_date',
        title: tKey('Fecha'),
        headerStyle: { textAlign: 'center' },
        cellStyle: (data: ScholarshipPaymentListItem[], rowData: ScholarshipPaymentListItem) =>
          selectScholarshipCellCSS(rowData.payment_status, rowData.disabled, 'center'),
        render: (rowData: ScholarshipPaymentListItem) => <div>{dbDateToDate(rowData.payment_date)}</div>,
        customFilterAndSearch: (term, rowData: ScholarshipPaymentListItem) => checkDate(rowData, term),
        //@ts-ignore
        width: '120px',
      },
      {
        field: 'payment_amount',
        title: tFormKey('Importe'),
        headerStyle: { textAlign: 'center' },
        cellStyle: (data: ScholarshipPaymentListItem[], rowData: ScholarshipPaymentListItem) =>
          selectScholarshipCellCSS(rowData.payment_status, rowData.disabled, 'right'),
        render: (rowData: ScholarshipPaymentListItem) => (
          <div>{formatNumber({ value: rowData.payment_amount }) + ' €'}</div>
        ),
        //@ts-ignore
        width: '100px',
      },
      getPaymentColumnType(),
      {
        field: 'scholarship_state',
        title: tKey('Estado'),
        headerStyle: { textAlign: 'center' },
        cellStyle: (data: ScholarshipPaymentListItem[], rowData: ScholarshipPaymentListItem) =>
          selectScholarshipCellCSS(rowData.payment_status, rowData.disabled, 'center'),
        render: (rowData: ScholarshipPaymentListItem) => renderCellTooltip(rowData.scholarship_state),
        //@ts-ignore
        width: '100px',
      },
      getPaymentColumnInfo(),
    ],
    actions: tableActions,
    options: {
      tableLayout: 'fixed',
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      actionsColumnIndex: -1,
      selection: !isEntity,
    },
  };
  tableData.data = filteredPayments();
  tableData.isLoading = loading;

  const onSubmitChangeDate = async (payment_date: string) => {
    if (paymentId) {
      const formattedDate = moment(payment_date).format();
      changePaymentDate(paymentId, formattedDate, isEntity);
    }
    closeDateModal();
  };

  const renderChangeDateModal = (): JSX.Element | undefined => {
    if (!showDateModal) {
      return undefined;
    }
    return (
      <ModalDatePicker
        view={showDateModal}
        handleClose={closeDateModal}
        label={tKey('Nueva fecha de pago')}
        title={tKey('Modificar fecha de pago')}
        buttonLabelOk={tKey('Ok')}
        buttonLabelKo={tKey('Cancelar')}
        onApplying={onSubmitChangeDate}
        defaultValue={paymentDate ? dbDateToDate(paymentDate) : undefined}
        required
      />
    );
  };

  const renderAdjustmentModal = (): JSX.Element | undefined => {
    if (!showAdjustmentModal) {
      return undefined;
    }
    return (
      <ScholarshipPaymentAdjustmentModal
        show={showAdjustmentModal}
        handleClose={handleCloseAdjustments}
        scholarshipId={scholarshipId}
        entityId={entityId}
        studentName={studentName}
      />
    );
  };

  const renderPaymentModal = (): JSX.Element | undefined => {
    return showPaymentModal ? (
      <InstitutePaymentsModal
        show={showPaymentModal}
        handleClose={closePaymentModal}
        scholarshipPayments={paymentsToPay}
        handlePaymentsDone={handlePaymentsDone}
      />
    ) : (
      undefined
    );
  };

  return (
    <>
      <TableWithEdit
        tableData={{ ...tableData }}
        onSelectionChange={selectScholarshipPayments}
        filtering
        key={JSON.stringify(tableData.data)}
        permission={true}
      />
      <Suspense fallback={<></>}>
        {renderChangeDateModal()}
        {renderAdjustmentModal()}
        {renderPaymentModal()}
      </Suspense>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedStatus: selectSelectedPaymentStatus(state),
  allowScholarshipPayments: selectUserCan(state)('allow_scholarship_payments'),
});

const mapDispatchToProps = (dispatch: any) => ({
  changePaymentDate: (payment_id: PaymentId, payment_date: string, is_entity: boolean): void =>
    dispatch(changeScholarshipPaymentDateAction(payment_id, payment_date, is_entity)),
  setScholarshipPaymentsReload: (): void => dispatch(setScholarshipPaymensReloadAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipsPaymentList);

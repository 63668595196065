import React from 'react';
import { connect } from 'react-redux';
import FncButton from '../../../../../components/FncButton/FncButton';
import SubTitle from '../../../../../components/SubTitle/Subtitle';
import TableMessageArea from '../../../../../components/Tables/TableMessageArea/TableMessageArea';
import { tFormKey, tKey, tScholarshipKey } from '../../../../../helpers/translate';
import { AppState } from '../../../../../redux/root-reducer';
import { saveScholarshipPaymentAction } from '../../../../../redux/scholarship/paymentAdjustments/actions';
import { ScholarshipPaymentAdjustmentItem } from '../../../../../redux/scholarship/paymentAdjustments/PaymentAdjustment';
import {
  selectActiveScholarshipPayments,
  selectPaymentsLoading,
  selectScholarshipPaymentDiffs,
} from '../../../../../redux/scholarship/paymentAdjustments/selectors';
import PaymentStatus from '../../../../../types/Payments/PaymentStatus';
import ScholarshipPaymentRow from './ScholarshipPaymentsRow';
import styles from './scholarshipPaymentsTable.module.scss';

interface ScholarshipPaymentsTableProps {
  loading: boolean;
  editable?: boolean;
  scholarshipId: number;
  scholarshipPaymentsAmountDiff: number;
  scholarshipPayments: ScholarshipPaymentAdjustmentItem[];
  saveScholarshipPayments: (scholarship_id: number, scholarship_payments: ScholarshipPaymentAdjustmentItem[]) => void;
}

const ScholarshipPaymentsTable: React.FC<ScholarshipPaymentsTableProps> = ({
  loading,
  editable = false,
  scholarshipPayments,
  scholarshipId,
  scholarshipPaymentsAmountDiff,
  saveScholarshipPayments,
}) => {
  const save = function() {
    saveScholarshipPayments(scholarshipId, scholarshipPayments);
  };

  const scholarshipPaymentsDiffMessage = (): string | undefined => {
    if (scholarshipPaymentsAmountDiff === 0) {
      return undefined;
    }
    return `La suma de los pagos debe ser igual al ${tFormKey(
      'Importe beca',
    )}. Hay una diferencia de: ${scholarshipPaymentsAmountDiff}€`;
  };

  const renderSaveButton = (): JSX.Element | undefined => {
    if (!editable) {
      return undefined;
    }
    return (
      <div className={styles.actionButton}>
        <FncButton disabled={scholarshipPaymentsAmountDiff !== 0} color="primary" onClick={() => save()}>
          {tScholarshipKey('guardar')}
        </FncButton>
      </div>
    );
  };

  const renderScholarshipPayments = () => {
    if (scholarshipPayments && scholarshipPayments.length > 0) {
      return scholarshipPayments
        .filter((payment: ScholarshipPaymentAdjustmentItem) => payment.status !== PaymentStatus.CANCELED)
        .map((payment: ScholarshipPaymentAdjustmentItem) => {
          const paymentStatus = new PaymentStatus(payment.status);
          return (
            <ScholarshipPaymentRow
              key={payment.id}
              payment={payment}
              disabled={!paymentStatus.isPending()}
              editable={editable}
            />
          );
        });
    }
    return (
      <tr className={styles.tableRow}>
        <td colSpan={4} className={`${styles.cellRow} ${styles.emptyCellRow}`}>
          {loading ? tKey('Cargando...') : tKey('No hay pagos geneardos')}
        </td>
      </tr>
    );
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableTitle}>
        <SubTitle>{tScholarshipKey('Pagos')}</SubTitle>
      </div>
      <table className={`${styles.customTable}  ${loading ? styles.customTableLoading : ''}`}>
        <thead className={styles.tableHeader}>
          <tr>
            <th className={styles.cellHeader}>{tScholarshipKey('Importe')}</th>
            <th className={styles.cellHeader}>{tScholarshipKey('Fecha')}</th>
            <th className={styles.cellHeader}>{tScholarshipKey('Tipo')}</th>
            <th className={styles.cellHeader}>{tScholarshipKey('Estado')}</th>
            {editable ? <th className={styles.cellHeader}></th> : null}
          </tr>
        </thead>
        <tbody>{renderScholarshipPayments()}</tbody>
      </table>
      <TableMessageArea error_message={scholarshipPaymentsDiffMessage()} />
      <div>{renderSaveButton()}</div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectPaymentsLoading(state),
  scholarshipPayments: selectActiveScholarshipPayments(state),
  scholarshipPaymentsAmountDiff: selectScholarshipPaymentDiffs(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  saveScholarshipPayments: (scholarship_id: number, scholarship_payments: ScholarshipPaymentAdjustmentItem[]): void =>
    dispatch(saveScholarshipPaymentAction(scholarship_id, scholarship_payments)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipPaymentsTable);

import { EntityAgreementDataConsts } from './action_types';
import {
  AddEntityRemainingAmountAction,
  AddEntityRemainingAmountKoAction,
  AddEntityRemainingAmountOkAction,
  CancelEntityAgreementAction,
  CancelEntityAgreementKoAction,
  CancelEntityAgreementOkAction,
  GetEntityAgreementAcademicYearsAction,
  GetEntityAgreementAcademicYearsKoAction,
  GetEntityAgreementAcademicYearsOkAction,
  GetEntityAgreementDataAction,
  GetEntityAgreementDataKoAction,
  GetEntityAgreementDataOkAction,
  SendEntityAdminManagementAction,
  SendEntityAdminManagementKoAction,
  SendEntityAdminManagementOkAction,
  SendEntityAdvancePaymentAction,
  SendEntityAdvancePaymentKoAction,
  SendEntityAdvancePaymentOkAction,
} from './definitions';
import { EntityAgreementData } from './EntityAgreement';

export function getEntityAgreementAcademicYearsAction(entity_id: number): GetEntityAgreementAcademicYearsAction {
  return {
    type: EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_ACADEMIC_YEARS,
    entity_id,
  };
}

export function getEntityAgreementAcademicYearsOkAction(
  academic_years: string[],
): GetEntityAgreementAcademicYearsOkAction {
  return {
    type: EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_ACADEMIC_YEARS_OK,
    academic_years,
  };
}

export function getEntityAgreementAcademicYearsKoAction(error: string): GetEntityAgreementAcademicYearsKoAction {
  return {
    type: EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_ACADEMIC_YEARS_KO,
    error,
  };
}

export function getEntityAgreementDataAction(entity_id: number, academic_year: string): GetEntityAgreementDataAction {
  return {
    type: EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_DATA,
    entity_id,
    academic_year,
  };
}

export function getEntityAgreementDataOkAction(agreementData: EntityAgreementData): GetEntityAgreementDataOkAction {
  return {
    type: EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_DATA_OK,
    agreementData,
  };
}

export function getEntityAgreementDataKoAction(error: string): GetEntityAgreementDataKoAction {
  return {
    type: EntityAgreementDataConsts.GET_ENTITY_AGREEMENT_DATA_KO,
    error,
  };
}

export function sendEntityAdvancePaymentAction(
  entity_id: number,
  academic_year: string,
  payment_date: string,
  payment_amount: number,
  account_number: string,
): SendEntityAdvancePaymentAction {
  return {
    type: EntityAgreementDataConsts.SEND_ENTITY_ADVANCE_PAYMENT,
    entity_id,
    academic_year,
    payment_date,
    payment_amount,
    account_number,
  };
}

export function sendEntityAdvancePaymentOkAction(
  payment_date: string,
  payment_amount: number,
): SendEntityAdvancePaymentOkAction {
  return {
    type: EntityAgreementDataConsts.SEND_ENTITY_ADVANCE_PAYMENT_OK,
    payment_date,
    payment_amount,
  };
}

export function sendEntityAdvancePaymentKoAction(error: string): SendEntityAdvancePaymentKoAction {
  return {
    type: EntityAgreementDataConsts.SEND_ENTITY_ADVANCE_PAYMENT_KO,
    error,
  };
}

export function addEntityRemainingAmountAction(
  entity_id: number,
  academic_year: string,
  amount: number,
  comment: string,
): AddEntityRemainingAmountAction {
  return {
    type: EntityAgreementDataConsts.ADD_ENTITY_REMAINING_AMOUNT,
    entity_id,
    academic_year,
    amount,
    comment,
  };
}

export function addEntityRemainingAmountOkAction(amount: number): AddEntityRemainingAmountOkAction {
  return {
    type: EntityAgreementDataConsts.ADD_ENTITY_REMAINING_AMOUNT_OK,
    amount,
  };
}

export function addEntityRemainingAmountKoAction(error: string): AddEntityRemainingAmountKoAction {
  return {
    type: EntityAgreementDataConsts.ADD_ENTITY_REMAINING_AMOUNT_KO,
    error,
  };
}

export function sendEntityAdminManagementAction(
  entity_id: number,
  academic_year: string,
  amount: number,
  payment_date: string,
): SendEntityAdminManagementAction {
  return {
    type: EntityAgreementDataConsts.SEND_ENTITY_ADMIN_MANAGEMENT,
    entity_id,
    academic_year,
    amount,
    payment_date,
  };
}

export function sendEntityAdminManagementOkAction(
  amount: number,
  payment_date: string,
): SendEntityAdminManagementOkAction {
  return {
    type: EntityAgreementDataConsts.SEND_ENTITY_ADMIN_MANAGEMENT_OK,
    amount,
    payment_date,
  };
}

export function sendEntityAdminManagementKoAction(error: string): SendEntityAdminManagementKoAction {
  return {
    type: EntityAgreementDataConsts.SEND_ENTITY_ADMIN_MANAGEMENT_KO,
    error,
  };
}

export function cancelEntityAgreementAction(signature_id: string): CancelEntityAgreementAction {
  return {
    type: EntityAgreementDataConsts.CANCEL_ENTITY_AGREEMENT,
    signature_id,
  };
}

export function cancelEntityAgreementOkAction(agreement_state: string): CancelEntityAgreementOkAction {
  return {
    type: EntityAgreementDataConsts.CANCEL_ENTITY_AGREEMENT_OK,
    agreement_state,
  };
}

export function cancelEntityAgreementKoAction(error: string): CancelEntityAgreementKoAction {
  return {
    type: EntityAgreementDataConsts.CANCEL_ENTITY_AGREEMENT_KO,
    error,
  };
}

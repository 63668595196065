import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { CloudDownload, Delete } from '@material-ui/icons';
import React from 'react';
import { useWindowSize } from '../../../../helpers/customHooks/useHookMethod';
import { formatNumber } from '../../../../helpers/formatNumber';
import { tKey } from '../../../../helpers/translate';
import styles from '../Shared/justificationsTable.module.scss';
import { Justification } from '../../../../redux/scholarship/justifications/Justification';

interface JustificationRowProps {
  justification: Justification;
  disabled: boolean;
  onRemove: (justification: Justification) => void;
  onDownload: (file_id: number, filename: string) => void;
}

const JustificationRow: React.FC<JustificationRowProps> = ({ justification, disabled, onRemove, onDownload }) => {
  const iconColor = (disabled: boolean) => {
    const opacity = disabled ? 0.2 : 0.54;
    return { color: `rgba(0, 0, 0, ${opacity})` };
  };

  const isMobile = useWindowSize().IsMobile;

  const disableDownload = !justification.file_id;

  const downloadTitle = justification.filename || tKey('Descargar');
  const renderActions = () => {
    return (
      <td className={`${styles.justificationCellRow}  ${styles.actionCell}`}>
        {!isMobile ? (
          <>
            <Tooltip title={downloadTitle}>
              <IconButton aria-label={downloadTitle}>
                <CloudDownload
                  style={iconColor(disableDownload)}
                  onClick={() =>
                    disableDownload ? undefined : onDownload(justification.file_id || 0, justification.filename || '')
                  }
                />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          undefined
        )}
        <Tooltip title="Eliminar">
          <IconButton aria-label="eliminar">
            <Delete style={iconColor(disabled)} onClick={() => (disabled ? undefined : onRemove(justification))} />
          </IconButton>
        </Tooltip>
      </td>
    );
  };

  return (
    <tr className={styles.justificationTableRow}>
      <td className={`${styles.justificationCellRow}  ${styles.justificationTypeCell}`}>{justification.type}</td>
      <td className={`${styles.justificationCellRow}  ${styles.descriptionCell}`}>{justification.description}</td>
      <td className={`${styles.justificationCellRow}  ${styles.amountCell}`}>{`${formatNumber({
        value: justification.amount | 0,
      })} €`}</td>
      {renderActions()}
    </tr>
  );
};

export default JustificationRow;

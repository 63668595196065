import { MenuItem, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { tKey } from '../../../helpers/translate';
import { AppState } from '../../../redux/root-reducer';

import { getEntityAgreementAcademicYearsAction } from '../../../redux/entity/agreement/actions';
import { selectEntityAgreementAcademicYears } from '../../../redux/entity/agreement/selectors';
import styles from './accountingData.module.scss';
import { EntityAgreementDispatch } from '../../../redux/entity/agreement/definitions';
interface AccountingDataCourseSelectorProps {
  entity_id: number;
  academicYears: string[];
  getEntityAgreementAcademicYears: (entity_id: number) => void;
  selectedAcademicYear: (academic_year: string) => void;
}

const AccountingDataCourseSelector: React.FC<AccountingDataCourseSelectorProps> = ({
  entity_id,
  academicYears,
  getEntityAgreementAcademicYears,
  selectedAcademicYear,
}) => {
  const [academicYear, setAcademicYear] = useState<string | undefined>(undefined);

  useEffect(() => {
    getEntityAgreementAcademicYears(entity_id);
  }, [entity_id, getEntityAgreementAcademicYears]);

  const onSelectAcademicYear = (value: string) => {
    setAcademicYear(value);
    selectedAcademicYear(value);
  };

  const renderAcademicYearOptions = (): JSX.Element[] | undefined => {
    if (academicYears.length === 0) {
      return undefined;
    }
    if (!academicYear) onSelectAcademicYear(academicYears[0]);

    return academicYears.map(element => (
      <MenuItem key={element} value={element}>
        {element}
      </MenuItem>
    ));
  };

  return (
    <TextField
      name={'academic_years'}
      label={tKey('Curso')}
      className={styles.academic_year_selector}
      value={academicYear || ''}
      onChange={e => onSelectAcademicYear(e.target.value as string)}
      variant="outlined"
      select
    >
      {renderAcademicYearOptions()}
    </TextField>
  );
};

const mapStateToProps = (state: AppState) => ({
  academicYears: selectEntityAgreementAcademicYears(state),
});

const mapDispatchToProps = (dispatch: EntityAgreementDispatch) => ({
  getEntityAgreementAcademicYears: (entity_id: number) => dispatch(getEntityAgreementAcademicYearsAction(entity_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDataCourseSelector);

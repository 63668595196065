import { APIValidationError } from '../../api/api';
import { FileItem } from '../common/definitions';
import { AcademyDataConsts } from './action_types';
import {
  AcademyContactAction,
  AcademyContactData,
  AcademyContactKoAction,
  AcademyContactOkAction,
  AcademyContactsListAction,
  AcademyContactsListKoAction,
  AcademyContactsListOkAction,
  AcademyData,
  AcademyDataList,
  AcademyIban,
  AcademyUpdateContactAction,
  AcademyUpdateContactKoAction,
  AcademyUpdateContactOkAction,
  AcademyValidationErrorsAction,
  AddAcademyIbanAction,
  AddAcademyIbanKoAction,
  AddAcademyIbanOkAction,
  ClearIbanDataAction,
  CloseContactModalAction,
  CloseIbanModalAction,
  ContactSetInfoDataAction,
  CreateAcademyContactAction,
  CreateAcademyContactKoAction,
  CreateAcademyContactOkAction,
  DeleteContactDataAction,
  DownloadIbanDocumentAction,
  DownloadIbanDocumentKoAction,
  DownloadIbanDocumentOkAction,
  EditAcademyIbanAction,
  EditAcademyIbanKoAction,
  EditAcademyIbanOkAction,
  EditBulkAcademyIbanAction,
  EditBulkAcademyIbanKoAction,
  EditBulkAcademyIbanOkAction,
  FilterListAcademiesAction,
  GetAcademicCenterAction,
  GetAcademicCenterKoAction,
  GetAcademicCenterOkAction,
  GetAcademiesAllAction,
  GetAcademiesAllOkAction,
  GetAcademyDataAction,
  GetAcademyDataKoAction,
  GetAcademyDataOkAction,
  GetAcademyIbanAction,
  GetAcademyIbanKoAction,
  GetAcademyIbanOkAction,
  GetAcademyIbansKoAction,
  GetAcademyIbansOkAction,
  ImportAcademiesAction,
  ImportAcademiesKoAction,
  ImportAcademiesOkAction,
  ListAcademyIbansAction,
  OpenContactModalAction,
  OpenIbanModalAction,
  RemoveAcademyIbanAction,
  RemoveAcademyIbanKoAction,
  RemoveAcademyIbanOkAction,
  RemoveContactAcademyAction,
  RemoveContactAcademyKoAction,
  RemoveContactAcademyOkAction,
  RemoveIbanDocumentAction,
  RemoveIbanDocumentKoAction,
  RemoveIbanDocumentOkAction,
  ReplaceAcademyContactListAction,
  ReplaceAcademyContactListKoAction,
  ReplaceAcademyContactListOkAction,
  SetAcademyIbanAction,
  SetIbanDataAction,
  UploadIbanDocumentAction,
  UploadIbanDocumentKoAction,
  UploadIbanDocumentOkAction,
} from './definitions';

// GET ACADEMY DATA
export function getAcademyData(id: number): GetAcademyDataAction {
  return {
    type: AcademyDataConsts.GET_ACADEMY_DATA,
    id,
  };
}

export function getAcademyDataOk(academyData: AcademyData): GetAcademyDataOkAction {
  return {
    type: AcademyDataConsts.GET_ACADEMY_DATA_OK,
    academyData,
  };
}

export function getAcademyDataKo(error: string): GetAcademyDataKoAction {
  return {
    type: AcademyDataConsts.GET_ACADEMY_DATA_KO,
    error,
  };
}

export function getAcademiesAll(): GetAcademiesAllAction {
  return {
    type: AcademyDataConsts.GET_ACADEMIES_ALL,
  };
}

export function getAcademiesAllOk(academyList: AcademyDataList[]): GetAcademiesAllOkAction {
  return {
    type: AcademyDataConsts.GET_ACADEMIES_ALL_OK,
    academyList,
  };
}

export function getAcademicsCenters(): GetAcademicCenterAction {
  return {
    type: AcademyDataConsts.GET_ACADEMIC_CENTER,
  };
}

export function getAcademicsCentersOk(academyList: AcademyDataList[]): GetAcademicCenterOkAction {
  return {
    type: AcademyDataConsts.GET_ACADEMIC_CENTER_OK,
    academyList,
  };
}

export function getAcademicsCentersKo(error: string): GetAcademicCenterKoAction {
  return {
    type: AcademyDataConsts.GET_ACADEMIC_CENTER_KO,
    error,
  };
}

export function importAcademies(user_id: number): ImportAcademiesAction {
  return {
    type: AcademyDataConsts.IMPORT_ACADEMIES,
    user_id,
  };
}

export function importAcademiesOk(): ImportAcademiesOkAction {
  return {
    type: AcademyDataConsts.IMPORT_ACADEMIES_OK,
  };
}

export function importAcademiesKo(error: string): ImportAcademiesKoAction {
  return {
    type: AcademyDataConsts.IMPORT_ACADEMIES_KO,
    error,
  };
}

// GET ACADEMY CONTACTS LIST

export function academyContactsList(academy_id: number): AcademyContactsListAction {
  return {
    type: AcademyDataConsts.GET_ACADEMY_CONTACTS,
    academy_id,
  };
}

export function academyContactsListOk(academyContactsList: any): AcademyContactsListOkAction {
  return {
    type: AcademyDataConsts.GET_ACADEMY_CONTACTS_OK,
    academyContactsList,
  };
}

export function academyContactsListKo(error: string): AcademyContactsListKoAction {
  return {
    type: AcademyDataConsts.GET_ACADEMY_CONTACTS_KO,
    error,
  };
}

export function addAcademyIban(academyId: number, academyIban: AcademyIban): AddAcademyIbanAction {
  return {
    type: AcademyDataConsts.ADD_ACADEMY_IBAN,
    academyId,
    academyIban,
  };
}

export function addAcademyIbanOk(academyIban: AcademyIban): AddAcademyIbanOkAction {
  return {
    type: AcademyDataConsts.ADD_ACADEMY_IBAN_OK,
    academyIban,
  };
}

export function addAcademyIbanKo(error: string): AddAcademyIbanKoAction {
  return {
    type: AcademyDataConsts.ADD_ACADEMY_IBAN_KO,
    error,
  };
}

export function getAcademyIban(academyId: number, academyIbanId: number): GetAcademyIbanAction {
  return {
    type: AcademyDataConsts.GET_ACADEMY_IBAN,
    academyId,
    academyIbanId,
  };
}

export function getAcademyIbanOk(academyIban: AcademyIban): GetAcademyIbanOkAction {
  return {
    type: AcademyDataConsts.GET_ACADEMY_IBAN_OK,
    academyIban,
  };
}

export function getAcademyIbanKo(error: string): GetAcademyIbanKoAction {
  return {
    type: AcademyDataConsts.GET_ACADEMY_IBAN_KO,
    error,
  };
}
//REMOVE ACADEMY IBAN
export function removeAcademyIban(academyId: number, academyIbanId: number): RemoveAcademyIbanAction {
  return {
    type: AcademyDataConsts.REMOVE_ACADEMY_IBAN,
    academyId,
    academyIbanId,
  };
}
export function removeAcademyIbanOk(academyIbanId: number): RemoveAcademyIbanOkAction {
  return {
    type: AcademyDataConsts.REMOVE_ACADEMY_IBAN_OK,
    academyIbanId,
  };
}

export function removeAcademyIbanKo(error: string): RemoveAcademyIbanKoAction {
  return {
    type: AcademyDataConsts.REMOVE_ACADEMY_IBAN_KO,
    error,
  };
}
// GET ACADEMY CONTACT

export function academyContact(academy_id: number, staff_id: number): AcademyContactAction {
  return {
    type: AcademyDataConsts.GET_ACADEMY_CONTACT,
    academy_id,
    staff_id,
  };
}

export function academyContactOk(contactData: AcademyContactData): AcademyContactOkAction {
  return {
    type: AcademyDataConsts.GET_ACADEMY_CONTACT_OK,
    contactData,
  };
}

export function academyContactKo(error: string): AcademyContactKoAction {
  return {
    type: AcademyDataConsts.GET_ACADEMY_CONTACT_KO,
    error,
  };
}

export function listAcademyIbans(id: number): ListAcademyIbansAction {
  return {
    type: AcademyDataConsts.LIST_ACADEMY_IBANS,
    id,
  };
}

export function listAcademyIbansOk(academyIbans: AcademyIban[]): GetAcademyIbansOkAction {
  return {
    type: AcademyDataConsts.LIST_ACADEMY_IBANS_OK,
    academyIbans,
  };
}

export function listAcademyIbansKo(error: string): GetAcademyIbansKoAction {
  return {
    type: AcademyDataConsts.LIST_ACADEMY_IBANS_KO,
    error,
  };
}

export function editAcademyIban(academyIban: AcademyIban, academyId: number): EditAcademyIbanAction {
  return {
    type: AcademyDataConsts.EDIT_ACADEMY_IBAN,
    academyIban,
    academyId,
  };
}

export function editAcademyIbanOk(academyIban: AcademyIban): EditAcademyIbanOkAction {
  return {
    type: AcademyDataConsts.EDIT_ACADEMY_IBAN_OK,
    academyIban,
  };
}

export function editAcademyIbanKo(error: string): EditAcademyIbanKoAction {
  return {
    type: AcademyDataConsts.EDIT_ACADEMY_IBAN_KO,
    error,
  };
}

// CREATE ACADEMY CONTACT

export function createAcademyContact(contactData: any, academy_id: number): CreateAcademyContactAction {
  return {
    type: AcademyDataConsts.ADD_ACADEMY_CONTACT,
    contactData,
    academy_id,
  };
}

export function createAcademyContactOk(contactData: any): CreateAcademyContactOkAction {
  return {
    type: AcademyDataConsts.ADD_ACADEMY_CONTACT_OK,
    contactData,
  };
}

export function createAcademyContactKo(error: string): CreateAcademyContactKoAction {
  return {
    type: AcademyDataConsts.ADD_ACADEMY_CONTACT_KO,
    error,
  };
}

// UPDATE ACADEMY CONTACT

export function academyUpdateContact(contactInfo: any, academy_id: number): AcademyUpdateContactAction {
  return {
    type: AcademyDataConsts.UPDATE_ACADEMY_CONTACT,
    contactInfo,
    academy_id,
  };
}

export function academyUpdateContactOk(contactData: any): AcademyUpdateContactOkAction {
  return {
    type: AcademyDataConsts.UPDATE_ACADEMY_CONTACT_OK,
    contactData,
  };
}

export function academyUpdateContactKo(error: string): AcademyUpdateContactKoAction {
  return {
    type: AcademyDataConsts.UPDATE_ACADEMY_CONTACT_KO,
    error,
  };
}

// DELETE ACADEMY CONTACT

export function removeContactAcademy(academy_id: number, staff_id: number): RemoveContactAcademyAction {
  return {
    type: AcademyDataConsts.REMOVE_ACADEMY_CONTACT,
    academy_id,
    staff_id,
  };
}

export function removeContactAcademyOk(staff_id: number): RemoveContactAcademyOkAction {
  return {
    type: AcademyDataConsts.REMOVE_ACADEMY_CONTACT_OK,
    staff_id,
  };
}

export function removeContactAcademyKo(error: string): RemoveContactAcademyKoAction {
  return {
    type: AcademyDataConsts.REMOVE_ACADEMY_CONTACT_KO,
    error,
  };
}

export function contactSetInfoData(key_detail: string, value_detail: string | number): ContactSetInfoDataAction {
  return {
    type: AcademyDataConsts.CONTACT_SET_DATA,
    key_detail,
    value_detail,
  };
}

// REPLACE ACADEMY CONTACTS LIST

export function replaceAcademyContactsList(dataList: any[], academy_id: number): ReplaceAcademyContactListAction {
  return {
    type: AcademyDataConsts.REPLACE_ACADEMY_CONTACTS_LIST,
    dataList,
    academy_id,
  };
}

export function replaceAcademyContactsListOk(
  academyContactsList: AcademyContactData[],
): ReplaceAcademyContactListOkAction {
  return {
    type: AcademyDataConsts.REPLACE_ACADEMY_CONTACTS_LIST_OK,
    academyContactsList,
  };
}

export function replaceAcademyContactsListKo(error: string): ReplaceAcademyContactListKoAction {
  return {
    type: AcademyDataConsts.REPLACE_ACADEMY_CONTACTS_LIST_KO,
    error,
  };
}

export function openIbanModal(): OpenIbanModalAction {
  return {
    type: AcademyDataConsts.OPEN_IBAN_MODAL,
  };
}

export function closeIbanModal(): CloseIbanModalAction {
  return {
    type: AcademyDataConsts.CLOSE_IBAN_MODAL,
  };
}

export function setAcademyIban(
  id: number,
  key: keyof AcademyIban,
  value: AcademyIban[keyof AcademyIban],
): SetAcademyIbanAction {
  return {
    type: AcademyDataConsts.SET_ACADEMY_LIST_IBAN,
    id,
    key,
    value,
  };
}
export function clearIbanData(): ClearIbanDataAction {
  return {
    type: AcademyDataConsts.CLEAR_IBAN_DATA,
  };
}

export function setAcademyIbanData(key: string, value: string | number): SetIbanDataAction {
  return {
    type: AcademyDataConsts.SET_IBAN_DATA,
    key,
    value,
  };
}

export function editBulkAcademyIban(academiesIbans: AcademyIban[]): EditBulkAcademyIbanAction {
  return {
    type: AcademyDataConsts.EDIT_BULK_ACADEMY_IBAN,
    academiesIbans,
  };
}

export function editBulkAcademyIbanOk(): EditBulkAcademyIbanOkAction {
  return {
    type: AcademyDataConsts.EDIT_BULK_ACADEMY_IBAN_OK,
  };
}

export function editBulkAcademyIbanKo(error: string): EditBulkAcademyIbanKoAction {
  return {
    type: AcademyDataConsts.EDIT_BULK_ACADEMY_IBAN_KO,
    error,
  };
}
export function uploadIbanDocument(file: File): UploadIbanDocumentAction {
  return {
    type: AcademyDataConsts.UPLOAD_IBAN_DOCUMENT,
    file,
  };
}

export function uploadIbanDocumentOk(fileData: FileItem): UploadIbanDocumentOkAction {
  return {
    type: AcademyDataConsts.UPLOAD_IBAN_DOCUMENT_OK,
    fileData,
  };
}

export function uploadIbanDocumentKo(error: string): UploadIbanDocumentKoAction {
  return {
    type: AcademyDataConsts.UPLOAD_IBAN_DOCUMENT_KO,
    error,
  };
}

export function removeIbanDocument(file_id: number): RemoveIbanDocumentAction {
  return {
    type: AcademyDataConsts.REMOVE_IBAN_DOCUMENT,
    file_id,
  };
}

export function removeIbanDocumentOk(): RemoveIbanDocumentOkAction {
  return {
    type: AcademyDataConsts.REMOVE_IBAN_DOCUMENT_OK,
  };
}

export function removeIbanDocumentKo(error: string): RemoveIbanDocumentKoAction {
  return {
    type: AcademyDataConsts.REMOVE_IBAN_DOCUMENT_KO,
    error,
  };
}

export function openContactModal(): OpenContactModalAction {
  return {
    type: AcademyDataConsts.OPEN_CONTACT_MODAL,
  };
}

export function closeContactModal(): CloseContactModalAction {
  return {
    type: AcademyDataConsts.CLOSE_CONTACT_MODAL,
  };
}

export function deleteContactData(): DeleteContactDataAction {
  return {
    type: AcademyDataConsts.DELETE_CONTACT_DATA,
  };
}

export function downloadIbanDocument(file_id: number, filename: string): DownloadIbanDocumentAction {
  return {
    type: AcademyDataConsts.DOWNLOAD_IBAN_DOCUMENT,
    file_id,
    filename,
  };
}

export function downloadIbanDocumentOk(data: Blob, name: string): DownloadIbanDocumentOkAction {
  return {
    type: AcademyDataConsts.DOWNLOAD_IBAN_DOCUMENT_OK,
    data,
    name,
  };
}

export function downloadIbanDocumentKo(error: string): DownloadIbanDocumentKoAction {
  return {
    type: AcademyDataConsts.DOWNLOAD_IBAN_DOCUMENT_KO,
    error,
  };
}

export function filterListAcademy(types: string[]): FilterListAcademiesAction {
  return {
    type: AcademyDataConsts.FILTER_LIST_ACADEMIES,
    types,
  };
}

export function academyValidationErrors(validationErrors: APIValidationError): AcademyValidationErrorsAction {
  return {
    type: AcademyDataConsts.ACADEMY_VALIDATION_ERRORS,
    validationErrors,
  };
}

import { ButtonProps, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../api/api';
import { entityProviderInfoChangeFromApi } from '../../../api/Entity/entity';
import CheckboxCompo from '../../../components/Checkbox/CheckboxCompo';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import { useButtonAction } from '../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import errorMessage from '../../../helpers/errorMessage';
import { tFormKey } from '../../../helpers/translate';
import { selectUserInfo } from '../../../redux/auth/selectors';
import { CodeNamePair } from '../../../redux/common/definitions';
import { entitySetData, makeProvider, updateEntity } from '../../../redux/entity/actions';
import { EntityInfo, TaxRetentionCodes } from '../../../redux/entity/definitions';
import { selectSendingSage, selectValidationErrors } from '../../../redux/entity/selectors';
import { getAccountingMetadataAction } from '../../../redux/metadata/actions';
import { GenericMetadata, TransactionCode } from '../../../redux/metadata/definitions';
import {
  selectGenericMetadata,
  selectPaymentTypeCodes,
  selectTransactionCodes,
} from '../../../redux/metadata/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import RoleCode from '../../../types/Roles/roleCode';
import { AccountingDataSchema } from '../../../validations/formSchema';
import styles from './accountingData.module.scss';

interface AccountingDataProviderProps {
  entityData: EntityInfo;
  allowSetAccount: boolean;
  allowTransactionCodes: boolean;
  userInfoRole: string;
  disabled: boolean;
  genericMetadata: GenericMetadata;
  transactionCodesMetadata: TransactionCode[];
  paymentTypeCodesMetadata: CodeNamePair[];
  buttonActionSave: ButtonProps;
  buttonSageProvider: ButtonProps;
  buttonModifyAccounts: ButtonProps;
  validationErrors: APIValidationError | null;
  sendingSage: boolean;
  setData: (key: string, value: string | number) => void;
  saveEntityData: (data: EntityInfo) => void;
  makeProvider: (id: number) => void;
  getAccountingMetadata: () => void;
}

const AccountingDataProvider: React.FC<AccountingDataProviderProps> = ({
  entityData,
  allowTransactionCodes,
  userInfoRole,
  disabled,
  genericMetadata,
  transactionCodesMetadata,
  paymentTypeCodesMetadata,
  buttonActionSave,
  buttonSageProvider,
  buttonModifyAccounts,
  validationErrors,
  sendingSage,
  setData,
  saveEntityData,
  makeProvider,
  getAccountingMetadata,
}) => {
  const {
    id: entity_id,
    natural_person,
    legal_person,
    provider_code,
    payable_account,
    expense_account,
    supplier_code,
    supplier_payable_account,
    needs_347,
    payment_type_code,
    transaction_code,
    retention_code,
  } = entityData;

  const userRole = new RoleCode(userInfoRole);
  const { taxRetentions } = genericMetadata;

  const [selectedRetentionCode, setSelectedRetentionCode] = useState(retention_code);
  const [allowSetAccount, setAllowSetAccount] = useState(false);

  const entityAllowSetAccount = () => {
    try {
      setAllowSetAccount(!allowSetAccount);
      // Desactivamos notificaciones, si las hay, de entidad activada.
      entityProviderInfoChangeFromApi(entity_id);
    } catch (error) {
      console.log(errorMessage(error));
    }
  };

  useEffect(() => {
    if (retention_code) {
      setSelectedRetentionCode(retention_code);
    } else if (!selectedRetentionCode && natural_person) {
      setSelectedRetentionCode(TaxRetentionCodes.PROFESIONALES);
    } else if (!selectedRetentionCode) {
      setSelectedRetentionCode(TaxRetentionCodes.SIN_RETENCION);
    }
  }, [retention_code, selectedRetentionCode, setSelectedRetentionCode, natural_person]);

  useEffect(() => {
    if (!transactionCodesMetadata.length) {
      getAccountingMetadata();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccountingMetadata]);

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const { errors, control, setError, register, handleSubmit } = useForm<EntityInfo>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: AccountingDataSchema,
  });

  const updateEntity = (data: EntityInfo) => {
    const {
      id,
      email,
      cif,
      account_number,
      name,
      phone,
      responsible_phone,
      country,
      is_visa_provider,
      no_payments_entity,
      has_scholarship,
    } = entityData;
    saveEntityData({
      ...data,
      id,
      email,
      cif,
      account_number,
      name,
      phone,
      responsible_phone,
      natural_person,
      country,
      is_visa_provider,
      no_payments_entity,
      has_scholarship,
    });
  };

  const isAdmin = userRole.isDireccionFNC() || userRole.isAdministracionFNC() || userRole.isSoporteAdministracion();

  useButtonAction(buttonActionSave, handleSubmit(updateEntity), {
    hidden: !isAdmin || disabled,
  });
  useButtonAction(buttonSageProvider, () => makeProvider(entity_id), { hidden: !isAdmin });
  useButtonAction(buttonModifyAccounts, () => entityAllowSetAccount(), { hidden: !isAdmin });

  useValidatorAPI(validationErrors, setError);

  const renderDonationSageData = (): JSX.Element => {
    return (
      <>
        <TextInputController
          size="20"
          schema={AccountingDataSchema}
          control={control}
          name="provider_code"
          errors={errors}
          value={provider_code}
          defaultValue={provider_code}
          label={tFormKey('Código de proveedor para donaciones')}
          disabled={!allowSetAccount}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('provider_code', e.target.value)}
        />
        <TextInputController
          size="20"
          schema={AccountingDataSchema}
          control={control}
          name="payable_account"
          errors={errors}
          defaultValue={payable_account}
          label={tFormKey('Cuenta contable de donación')}
          disabled={!allowSetAccount}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('payable_account', e.target.value)}
        />
        <TextInputController
          size="20"
          schema={AccountingDataSchema}
          control={control}
          name="expense_account"
          errors={errors}
          defaultValue={expense_account}
          label={tFormKey('Cuenta contable de gasto')}
          disabled={!allowSetAccount}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('expense_account', e.target.value)}
        />
      </>
    );
  };

  const renderProviderSageData = (): JSX.Element => {
    return (
      <>
        <TextInputController
          size="20"
          schema={AccountingDataSchema}
          control={control}
          name="supplier_code"
          errors={errors}
          value={supplier_code}
          defaultValue={supplier_code}
          label={tFormKey('Código de proveedor')}
          disabled={!allowSetAccount}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('supplier_code', e.target.value)}
        />
        <TextInputController
          size="20"
          schema={AccountingDataSchema}
          control={control}
          name="supplier_payable_account"
          errors={errors}
          defaultValue={supplier_payable_account}
          label={tFormKey('Cuenta contable de proveedor')}
          disabled={!allowSetAccount}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('supplier_payable_account', e.target.value)}
        />
      </>
    );
  };

  const renderProviderAccountingData = (): JSX.Element => {
    return (
      <div className={styles.supplier_container}>
        <div className={styles.carga_347_checkbox}>
          <CheckboxCompo
            name="needs_347"
            questionText={tFormKey('Carga 347')}
            defaultValue={needs_347 ? 1 : 0 || undefined}
            register={register}
            disabled={disabled || !allowTransactionCodes}
            onChange={(value: boolean) => setData('needs_347', value ? 1 : 0)}
          />
        </div>

        <SelectController
          control={control}
          errors={errors}
          size="20"
          schema={AccountingDataSchema}
          name="payment_type_code"
          disabled={disabled || !allowTransactionCodes}
          defaultValue={payment_type_code}
          label={tFormKey('Tipo de efecto')}
          onClick={e => handleSelectChange(e.target)}
        >
          {paymentTypeCodesMetadata.map(type => (
            <MenuItem key={type.code} value={type.code}>
              {type.name}
            </MenuItem>
          ))}
        </SelectController>

        <SelectController
          control={control}
          errors={errors}
          size="20"
          schema={AccountingDataSchema}
          name="transaction_code"
          disabled={disabled || !allowTransactionCodes}
          defaultValue={transaction_code}
          label={tFormKey('Código de transacción')}
          onClick={e => handleSelectChange(e.target)}
        >
          {transactionCodesMetadata
            .sort((a, b) => a.code - b.code)
            .map(type => (
              <MenuItem key={type.code} value={type.code}>
                {type.code} - {type.name}
              </MenuItem>
            ))}
        </SelectController>
        <SelectController
          control={control}
          errors={errors}
          schema={AccountingDataSchema}
          name="retention_code"
          disabled={disabled || !allowTransactionCodes}
          size="30"
          defaultValue={selectedRetentionCode}
          label={tFormKey('Retención')}
          onClick={e => handleSelectChange(e.target)}
        >
          {taxRetentions
            ?.filter(e => e.tax_type === 'R')
            .map(type => (
              <MenuItem key={type.code} value={type.code}>
                {type.name}
              </MenuItem>
            ))}
        </SelectController>
      </div>
    );
  };

  const renderProviderEntityData = (): JSX.Element | undefined => {
    if (!natural_person && !legal_person) {
      return undefined;
    }

    return (
      <>
        {renderProviderSageData()}
        {renderProviderAccountingData()}
      </>
    );
  };

  return (
    <form className={styles.accounting_form}>
      {sendingSage ? (
        <Loading big />
      ) : (
        <>
          {renderDonationSageData()}
          {renderProviderEntityData()}
        </>
      )}
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  validationErrors: selectValidationErrors(state),
  genericMetadata: selectGenericMetadata(state),
  transactionCodesMetadata: selectTransactionCodes(state),
  paymentTypeCodesMetadata: selectPaymentTypeCodes(state),
  allowTransactionCodes: selectUserCan(state)('allow_transaction_code'),
  userInfoRole: selectUserInfo(state).role_code,
  sendingSage: selectSendingSage(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  saveEntityData: (data: EntityInfo) => dispatch(updateEntity(data)),
  setData: (key: string, value: string | number) => dispatch(entitySetData(key, value)),
  makeProvider: (id: number): void => dispatch(makeProvider(id)),
  getAccountingMetadata: () => dispatch(getAccountingMetadataAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDataProvider);

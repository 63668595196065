import { ButtonProps } from '@material-ui/core';
import { CalendarToday, Check, Edit, Email, Lock, LockOpen, Send, Warning } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import React from 'react';
import { tScholarshipKey } from '../../../helpers/translate';
import { KeyButton } from '../../../redux/common/definitions';
import { ScholarshipStateCode, SubmenuItemTypeOption } from '../../../redux/scholarship/definitions';

const buttonDefault: ButtonProps = {};

const buttonSave: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  key: KeyButton.SAVE,
  startIcon: <SaveIcon />,
};

const buttonRennounce: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'secondary',
  fullWidth: true,
  startIcon: <SendIcon />,
  key: KeyButton.RENOUNCE,
};

const buttonConfirm: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  startIcon: <ThumbUp />,
  key: KeyButton.CONFIRM,
};

const buttonApprove: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  startIcon: <SendIcon />,
  key: KeyButton.APPROVE,
};

const buttonSendInitialCompetences: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  startIcon: <Email />,
  key: KeyButton.SEND_INITIAL_COMPETENCES,
};

const buttonSendFinalCompetences: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  startIcon: <Email />,
  key: KeyButton.SEND_FINAL_COMPETENCES,
};

const buttonEdit: ButtonProps = {
  children: tScholarshipKey('Editar'),
  disableElevation: true,
  variant: 'contained',
  color: 'secondary',
  fullWidth: true,
  startIcon: <Edit />,
  key: KeyButton.EDIT,
};

const buttonChangeScholarshipState: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  startIcon: <EditIcon />,
  key: KeyButton.CHANGE_STATE,
};

const buttonSendRequest: ButtonProps = {
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  disableElevation: true,
  startIcon: <SendIcon />,
  key: KeyButton.SEND_REQUEST,
};

const buttonAccept: ButtonProps = {
  disableElevation: true,
  startIcon: <Check />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  key: KeyButton.ACCEPT,
};

const buttonReject: ButtonProps = {
  variant: 'contained',
  color: 'secondary',
  fullWidth: true,
  disableElevation: true,
  startIcon: <Warning />,
  key: KeyButton.REJECT,
};

const buttonNoValidate: ButtonProps = {
  disableElevation: true,
  startIcon: <ThumbDownIcon />,
  fullWidth: true,
  variant: 'contained',
  color: 'secondary',
  key: KeyButton.NO_VALIDATE,
};

const buttonAskDate: ButtonProps = {
  disableElevation: true,
  startIcon: <CalendarToday />,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  key: KeyButton.ASK_DATE,
};

const buttonSendAssessment: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  startIcon: <SendIcon />,
  key: KeyButton.SEND_ASSESSMENT,
};

const buttonSendCorrectedInformation: ButtonProps = {
  variant: 'contained',
  color: 'primary',
  startIcon: <SendIcon />,
  fullWidth: true,
  key: KeyButton.SEND_CORRECTED_INFORMATION,
};

const buttonGetInfo: ButtonProps = {
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  startIcon: <SendIcon />,
  key: KeyButton.GET_INFO,
};

const buttonDeny: ButtonProps = {
  variant: 'contained',
  color: 'secondary',
  fullWidth: true,
  startIcon: <SendIcon />,
  key: KeyButton.DENY,
};

const buttonSaveAgreement: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  key: KeyButton.SAVE_AGREEMENT,
  startIcon: <SaveIcon />,
};

const buttonUpdateSchedule: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  startIcon: <RefreshIcon />,
  key: KeyButton.UPDATE_SCHEDULE,
};

const buttonSendInterview: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  startIcon: <SendIcon />,
  key: KeyButton.SEND_INTERVIEW,
};

const buttonAbandonment: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'secondary',
  fullWidth: true,
  startIcon: <SendIcon />,
  key: KeyButton.ABANDONMENT,
};

const buttonSaveFollowUp: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  key: KeyButton.SAVE_FOLLOW,
  startIcon: <SaveIcon />,
};

const buttonBlockPayment: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  startIcon: <Lock />,
  key: KeyButton.BLOCK_PAYMENT,
  hidden: false,
};

const buttonSendFollowUp: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  startIcon: <Send />,
  key: KeyButton.SEND_FOLLOW,
};

const buttonSendJustifications: ButtonProps = {
  disableElevation: true,
  variant: 'contained',
  color: 'primary',
  fullWidth: true,
  startIcon: <Send />,
  key: KeyButton.SEND_JUSTIFICATIONS,
};

export const getButtonType = (type: KeyButton): ButtonProps => {
  switch (type) {
    case KeyButton.SAVE:
      return buttonSave;
    case KeyButton.RENOUNCE:
      return buttonRennounce;
    case KeyButton.CONFIRM:
      return buttonConfirm;
    case KeyButton.APPROVE:
      return buttonApprove;
    case KeyButton.SEND_FINAL_COMPETENCES:
      return buttonSendFinalCompetences;
    case KeyButton.SEND_INITIAL_COMPETENCES:
      return buttonSendInitialCompetences;
    case KeyButton.EDIT:
      return buttonEdit;
    case KeyButton.CHANGE_STATE:
      return buttonChangeScholarshipState;
    case KeyButton.SEND_REQUEST:
      return buttonSendRequest;
    case KeyButton.ACCEPT:
      return buttonAccept;
    case KeyButton.REJECT:
      return buttonReject;
    case KeyButton.NO_VALIDATE:
      return buttonNoValidate;
    case KeyButton.ASK_DATE:
      return buttonAskDate;
    case KeyButton.SEND_ASSESSMENT:
      return buttonSendAssessment;
    case KeyButton.SEND_CORRECTED_INFORMATION:
      return buttonSendCorrectedInformation;
    case KeyButton.GET_INFO:
      return buttonGetInfo;
    case KeyButton.DENY:
      return buttonDeny;
    case KeyButton.SAVE_AGREEMENT:
      return buttonSaveAgreement;
    case KeyButton.UPDATE_SCHEDULE:
      return buttonUpdateSchedule;
    case KeyButton.SEND_INTERVIEW:
      return buttonSendInterview;
    case KeyButton.ABANDONMENT:
      return buttonAbandonment;
    case KeyButton.BLOCK_PAYMENT:
      return buttonBlockPayment;
    case KeyButton.SAVE_FOLLOW:
      return buttonSaveFollowUp;
    case KeyButton.SEND_FOLLOW:
      return buttonSendFollowUp;
    case KeyButton.SEND_JUSTIFICATIONS:
      return buttonSendJustifications;
    default:
      return buttonDefault;
  }
};

export interface ButtonsOptionsScholarship {
  stateCode: ScholarshipStateCode;
  stepsComplete: boolean;
  componentType: SubmenuItemTypeOption;
  isCustomer: boolean;
  isInterviewer: boolean;
  isTecBec: boolean;
  canRenounce: boolean;
  canAbandon: boolean;
  canBlockPayment: boolean;
  isNouscims: boolean;
  isInstitut: boolean;
  canIEdit: boolean;
  isEdit: boolean;
  paymentBlocked: boolean;
  canChangeState: boolean;
  totalPaid: number;
}

const buttonTranslations = (paymentBlocked: boolean) => {
  buttonChangeScholarshipState.children = tScholarshipKey('cambiar-estado');
  buttonSave.children = tScholarshipKey('Guardar');
  buttonSendRequest.children = tScholarshipKey('Enviar solicitud');
  buttonRennounce.children = tScholarshipKey('Renuncia');
  buttonConfirm.children = tScholarshipKey('Confirmar');
  buttonApprove.children = tScholarshipKey('Aprobar');
  buttonSendInitialCompetences.children = tScholarshipKey('Enviar encuesta');
  buttonSendFinalCompetences.children = tScholarshipKey('Enviar encuesta');
  buttonEdit.children = tScholarshipKey('Editar');
  buttonAccept.children = tScholarshipKey('Aceptar');
  buttonReject.children = tScholarshipKey('Rechazar');
  buttonNoValidate.children = tScholarshipKey('No validar');
  buttonAskDate.children = tScholarshipKey('Pedir cita');
  buttonSendAssessment.children = tScholarshipKey('Enviar valoración');
  buttonSendCorrectedInformation.children = tScholarshipKey('Enviar información corregida');
  buttonGetInfo.children = tScholarshipKey('Pedir información');
  buttonDeny.children = tScholarshipKey('Desestimar');
  buttonSaveAgreement.children = tScholarshipKey('Guardar');
  buttonUpdateSchedule.children = tScholarshipKey('Actualizar calendario');
  buttonAbandonment.children = tScholarshipKey('Abandono');
  buttonSendInterview.children = tScholarshipKey('Enviar entrevista');
  buttonSendFollowUp.children = tScholarshipKey('Enviar');
  buttonSaveFollowUp.children = tScholarshipKey('Guardar');
  buttonBlockPayment.children = paymentBlocked
    ? tScholarshipKey('Desbloqueo de pago')
    : tScholarshipKey('Bloqueo de pago');
  buttonBlockPayment.startIcon = paymentBlocked ? <LockOpen /> : <Lock />;
  buttonSendJustifications.children = tScholarshipKey('send_justifications');
};

export const getButtonsScholarship = (options: ButtonsOptionsScholarship): ButtonProps[] => {
  const {
    stateCode,
    componentType,
    isCustomer,
    isInterviewer,
    isTecBec,
    isNouscims,
    isInstitut,
    canIEdit,
    isEdit,
    stepsComplete,
    canRenounce,
    canAbandon,
    canBlockPayment,
    paymentBlocked,
    canChangeState,
  } = options;

  buttonTranslations(paymentBlocked);

  const isRenounceDate = (date: Date): boolean => {
    return [8, 9].includes(date.getMonth() + 1);
  };

  const buttons: ButtonProps[] = [];

  if (canChangeState) {
    buttons.push(buttonChangeScholarshipState);
  }

  if (stateCode) {
    switch (stateCode) {
      case ScholarshipStateCode.CREATION:
        if (!isNouscims) {
          buttonSendRequest.disabled = !stepsComplete;
          buttons.push(buttonSave);
          buttons.push(buttonSendRequest);
        }
        break;
      case ScholarshipStateCode.REQUEST:
        if (isNouscims) {
          buttonSave.hidden = !isEdit;
          buttonAccept.hidden = !isTecBec;
          buttonReject.hidden = !isTecBec;
          buttonNoValidate.hidden = !isTecBec;
          buttons.push(buttonAccept);
          buttons.push(buttonSave);
          buttons.push(buttonReject);
          buttons.push(buttonNoValidate);
        }
        break;
      case ScholarshipStateCode.PENDING_INTERVIEW:
        if (isCustomer) {
          buttons.push(getButtonType(KeyButton.ASK_DATE));
        }
        if (isNouscims) {
          buttonSave.hidden = !isEdit;
          buttons.push(buttonSave);
        }
        if (canRenounce) {
          buttons.push(buttonRennounce);
        }
        break;
      case ScholarshipStateCode.PENDING_INTERVIEW_VALORATION:
        if (isInterviewer && componentType === SubmenuItemTypeOption.ENTREVISTA) {
          buttons.push(buttonSave);
          buttons.push(buttonSendInterview);
        }
        if (isNouscims) {
          buttonSave.hidden = !isEdit;
          buttons.push(buttonSave);
        }
        if (canRenounce) {
          buttons.push(buttonRennounce);
        }
        break;
      case ScholarshipStateCode.PENDING_VALUATION:
        if (isInterviewer && componentType === SubmenuItemTypeOption.ENTREVISTA) {
          buttonSave.hidden = !isEdit;
          buttons.push(buttonSave);
          break;
        }
        if (isNouscims) {
          buttonSave.hidden = !isEdit;
          buttonEdit.hidden = false;
          buttons.push(buttonSave);
          if (componentType === SubmenuItemTypeOption.VALORACION_FORM) {
            buttonSave.hidden = false;
            buttonEdit.hidden = true;
            buttons.push(buttonSendAssessment);
          }
        }
        if (canRenounce) {
          buttons.push(buttonRennounce);
        }
        break;
      case ScholarshipStateCode.VALUATED:
        if (isInterviewer && componentType === SubmenuItemTypeOption.ENTREVISTA) {
          buttons.push(buttonSave);
          break;
        }
        if (isNouscims) {
          buttons.push(buttonSave);
          buttonSave.hidden = !isEdit;
          buttonEdit.hidden = false;
          if (componentType === SubmenuItemTypeOption.APROBACION) {
            buttonSave.hidden = false;
            buttonEdit.hidden = true;
            buttons.push(buttonApprove);
            buttons.push(buttonGetInfo);
            buttons.push(buttonDeny);
          }
        }
        if (canRenounce) {
          buttons.push(buttonRennounce);
        }
        break;
      case ScholarshipStateCode.APPROVED:
        if (isNouscims) {
          if (!isInstitut) {
            buttons.push(buttonSendInitialCompetences);
          }
          buttonSave.hidden = !isEdit;
          buttons.push(buttonSave);
        }
        if (isCustomer && componentType === SubmenuItemTypeOption.CONFIRMACION) {
          buttons.push(buttonSave);
          buttons.push(buttonConfirm);
        }
        if (canRenounce) {
          buttons.push(buttonRennounce);
        }
        break;
      case ScholarshipStateCode.ADMIN_REVIEW:
        if (isNouscims) {
          buttonSave.hidden = !isEdit;
          buttons.push(buttonSave);
          if (componentType === SubmenuItemTypeOption.CONFIRMACION) {
            buttons.push(buttonConfirm);
          }
        }
        if (canRenounce) {
          buttons.push(buttonRennounce);
        }
        break;
      case ScholarshipStateCode.PENDING_INFO:
        if (isInterviewer && componentType === SubmenuItemTypeOption.ENTREVISTA) {
          buttons.push(buttonSave);
          break;
        }
        if (isNouscims) {
          buttonSave.hidden = !isEdit;
          buttons.push(buttonSave);
          buttons.push(buttonSendCorrectedInformation);
        }
        if (canRenounce) {
          buttons.push(buttonRennounce);
        }
        break;
      case ScholarshipStateCode.CONFIRMATION:
        if (isNouscims) {
          if (!isInstitut) {
            buttons.push(buttonSendInitialCompetences);
          }
          if (isInstitut) {
            if (componentType === SubmenuItemTypeOption.PAGOS) {
              buttonSave.hidden = true;
              buttons.push(buttonSaveAgreement);
            }
          }
          buttonSave.hidden = !isEdit;
          buttons.push(buttonSave);
        }
        if (canRenounce) {
          buttons.push(buttonRennounce);
        }
        break;
      case ScholarshipStateCode.ASSIGNED:
        if (isNouscims) {
          if (!isInstitut) {
            buttons.push(buttonSendInitialCompetences);
          }
          if (isInstitut && componentType === SubmenuItemTypeOption.PAGOS) {
            buttons.push(buttonSaveAgreement);
          }
          buttonSave.hidden = !isEdit;
          buttonBlockPayment.hidden = !canBlockPayment;
          buttons.push(buttonBlockPayment);
          buttons.push(buttonSave);
        }
        if (isCustomer && componentType === SubmenuItemTypeOption.FOLLOW_T1) {
          buttons.push(buttonSaveFollowUp);
        }
        // Depende de la fecha
        const date = new Date();
        if (isRenounceDate(date) && canRenounce) {
          buttons.push(buttonRennounce);
        }
        if (!isRenounceDate(date) && canAbandon) {
          buttons.push(buttonAbandonment);
        }
        break;
      case ScholarshipStateCode.RENUNCIATION:
      case ScholarshipStateCode.ABANDONMENT:
      case ScholarshipStateCode.DESTIMATED:
        if (isNouscims) {
          buttonSave.hidden = !isEdit;
          buttons.push(buttonSave);
        }
        break;
      case ScholarshipStateCode.FOLLOW_S1:
      case ScholarshipStateCode.FOLLOW_T1:
      case ScholarshipStateCode.FOLLOW_T2:
        if (isNouscims) {
          buttonBlockPayment.hidden = !canBlockPayment;
          buttons.push(buttonBlockPayment);
          buttonSave.hidden = !isEdit;
          buttons.push(buttonSave);
        }
        if (componentType === SubmenuItemTypeOption.FOLLOW_T1 || componentType === SubmenuItemTypeOption.FOLLOW_T2) {
          buttonEdit.hidden = true;
          buttonSave.hidden = true;
          buttons.push(buttonSaveFollowUp);
          buttons.push(buttonSendFollowUp);
        }
        if (canAbandon) {
          buttons.push(buttonAbandonment);
        }
        break;
      case ScholarshipStateCode.END_EVALUATION_T1:
      case ScholarshipStateCode.END_EVALUATION_S1:
        if (isCustomer && componentType === SubmenuItemTypeOption.FOLLOW_T2) {
          buttonSaveFollowUp.hidden = false;
          buttons.push(buttonSaveFollowUp);
        }
        if (canAbandon) {
          buttons.push(buttonAbandonment);
        }
        break;
      case ScholarshipStateCode.END_EVALUATION_T2:
        if (isCustomer && componentType === SubmenuItemTypeOption.FOLLOW_T3) {
          buttonSaveFollowUp.hidden = false;
          buttons.push(buttonSaveFollowUp);
        }
        if (canAbandon) {
          buttons.push(buttonAbandonment);
        }
        break;
      case ScholarshipStateCode.FOLLOW_S2:
        if (!isInstitut) {
          buttons.push(buttonSendFinalCompetences);
        }
        if (isNouscims) {
          buttonBlockPayment.hidden = !canBlockPayment;
          buttons.push(buttonBlockPayment);
          buttonSave.hidden = !isEdit;
          buttons.push(buttonSave);
        }
        if (componentType === SubmenuItemTypeOption.FOLLOW_T2) {
          buttonEdit.hidden = true;
          buttonSave.hidden = true;
          buttons.push(buttonSaveFollowUp);
          buttons.push(buttonSendFollowUp);
        }
        if (canAbandon) {
          buttons.push(buttonAbandonment);
        }
        break;
      case ScholarshipStateCode.FOLLOW_T3:
        if (isNouscims) {
          if (!isInstitut) {
            buttons.push(buttonSendFinalCompetences);
          }
          buttonBlockPayment.hidden = !canBlockPayment;
          buttons.push(buttonBlockPayment);
          buttonSave.hidden = !isEdit;
          buttons.push(buttonSave);
        }
        if (componentType === SubmenuItemTypeOption.FOLLOW_T3) {
          buttonEdit.hidden = true;
          buttonSave.hidden = true;
          buttons.push(buttonSaveFollowUp);
          buttons.push(buttonSendFollowUp);
        }
        if (canAbandon) {
          buttons.push(buttonAbandonment);
        }
        break;
      case ScholarshipStateCode.COMPLETED:
        if (isNouscims && isEdit) {
          if (
            [SubmenuItemTypeOption.FOLLOW_T1, SubmenuItemTypeOption.FOLLOW_T2, SubmenuItemTypeOption.FOLLOW_T3].indexOf(
              componentType,
            ) >= 0
          ) {
            buttonEdit.hidden = true;
            if (!isInstitut) {
              buttons.push(buttonSendFinalCompetences);
            }
            buttons.push(buttonSaveFollowUp);
          } else {
            buttons.push(buttonSave);
          }
        }
        break;
      case ScholarshipStateCode.PENDING_JUSTIFICATION:
        if (isNouscims && isEdit) {
          if (
            [SubmenuItemTypeOption.FOLLOW_T1, SubmenuItemTypeOption.FOLLOW_T2, SubmenuItemTypeOption.FOLLOW_T3].indexOf(
              componentType,
            ) >= 0
          ) {
            buttons.push(buttonSaveFollowUp);
          } else {
            buttons.push(buttonSave);
          }
        }
        if (isCustomer && componentType === SubmenuItemTypeOption.JUSTIFICACION_ECONOMICA) {
          buttons.push(buttonSendJustifications);
        }
        break;
    }
  }

  if (
    isNouscims &&
    canIEdit &&
    stateCode !== ScholarshipStateCode.CREATION &&
    stateCode !== ScholarshipStateCode.PENDING_JUSTIFICATION &&
    componentType !== SubmenuItemTypeOption.ENTREVISTA
  ) {
    buttons.push(buttonEdit);
  }
  return buttons;
};

class PaymentStatus {
  static PENDING = 'pending';
  static PAID = 'paid';
  static CANCELED = 'canceled';
  static EMPTY_STATUS = 'empty';

  private status: string;

  constructor(status: string | null) {
    this.status = !!status ? status : PaymentStatus.EMPTY_STATUS;
  }

  public value(): string | null {
    return !this.isEmpty() ? this.status : null;
  }

  public isPending(): boolean {
    return this.status === PaymentStatus.PENDING;
  }

  public isPaid(): boolean {
    return this.status === PaymentStatus.PAID;
  }

  public isCanceled(): boolean {
    return this.status === PaymentStatus.CANCELED;
  }

  public isEmpty(): boolean {
    return this.status === PaymentStatus.EMPTY_STATUS;
  }

  public static pending(): PaymentStatus {
    return new PaymentStatus(PaymentStatus.PENDING);
  }

  public static paid(): PaymentStatus {
    return new PaymentStatus(PaymentStatus.PAID);
  }

  public static canceled(): PaymentStatus {
    return new PaymentStatus(PaymentStatus.CANCELED);
  }

  public static toArray(): string[] {
    return [PaymentStatus.EMPTY_STATUS, PaymentStatus.PENDING, PaymentStatus.PAID, PaymentStatus.CANCELED];
  }

  public static translationName(status: string): string {
    return `payment_status.${status}`;
  }
}

export default PaymentStatus;

import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteOneScholarship, getTaxRetentions } from '../../../api/TaxRetention/taxretention';
import TableLayout from '../../../components/Layout/TableLayout';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import colorPalette from '../../../helpers/colorPalette';
import { formatNumber } from '../../../helpers/formatNumber';
import { tKey } from '../../../helpers/translate';
import { TaxRetention } from '../../../redux/common/definitions';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { getAccountingMetadataAction } from '../../../redux/metadata/actions';
import { OperationKey } from '../../../redux/metadata/definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import AccountingSubmenu from '../../Accounting/AccountingSubmenu';

interface RetentionsConfigProps {
  canIConfig: boolean;
  operation_keys: OperationKey[];
  getAccountingMetadata: () => void;
}

const RetentionsConfig: React.FC<RetentionsConfigProps> = ({ canIConfig, operation_keys, getAccountingMetadata }) => {
  const [modal, setModal] = React.useState({ view: false, body: [''] });
  const [id, setId] = React.useState<number>(0);
  const [list, setList] = useState<TaxRetention[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteReload, setDeleteReload] = useState<boolean>(false);

  const actionIcons = {
    Delete: () => <DeleteOutline style={{ color: colorPalette.secondary80 }} />,
    Edit: () => <Edit style={{ color: colorPalette.secondary80 }} />,
  };

  useEffect(() => {
    setLoading(true);
    getTaxRetentions()
      .then(taxRetentions => {
        setList(
          taxRetentions.map(element => {
            return element;
          }),
        );
      })
      .then(() => {
        setLoading(false);
        setDeleteReload(false);
      });
  }, [deleteReload]);

  useEffect(() => {
    getAccountingMetadata();
  }, [getAccountingMetadata]);

  const history = useHistory();

  const onEditOne = (id: number, rowData: TaxRetention) => history.push(`/contabilidad/impuestos/editar/${rowData.id}`);

  const idItem = (id: number) => {
    setId(id);
    setModal({ view: true, body: [] });
  };

  const goToNew = () => {
    history.push(`/contabilidad/impuestos/crear/0`);
  };

  const onRemoveOne = () => {
    deleteOneScholarship(id).then(() => setDeleteReload(true));
  };

  const tableData: MaterialTableProps<TaxRetention> = {
    title: tKey('IMPUESTOS'),
    columns: [
      {
        field: 'code',
        title: tKey('Código'),
        //@ts-ignore
        width: '75px',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'tax_type_name',
        title: tKey('Tipo de impuesto'),
        //@ts-ignore
        width: '125px',
      },
      {
        field: 'name',
        title: tKey('Nombre'),
        //@ts-ignore
        width: '175px',
      },
      {
        field: 'percentage',
        title: tKey('Porcentaje'),
        //@ts-ignore
        width: '100px',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: rowData => <div>{formatNumber({ value: rowData.percentage })} %</div>,
      },
      {
        field: 'payment_account',
        title: tKey('Cuenta contable'),
        //@ts-ignore
        width: '125px',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'transaction_code',
        title: tKey('Código de transacción'),
        //@ts-ignore
        width: '125px',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'op_key',
        title: tKey('Clave de operación'),
        //@ts-ignore
        width: '125px',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: TaxRetention) => {
          const data = operation_keys.find(ok => {
            return (ok.op_key = rowData.op_key);
          });

          return data?.op_key;
        },
      },
    ],
    data: list,
    options: {
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
    actions: [
      (rowData: TaxRetention) => ({
        icon: actionIcons.Delete,
        onClick: () => idItem(rowData.id),
        hidden: !canIConfig,
      }),
      (rowData: TaxRetention) => ({
        icon: actionIcons.Edit,
        onClick: () => onEditOne(rowData.id, rowData),
        hidden: !canIConfig,
      }),
    ],
    isLoading: loading,
  };

  return (
    <TableLayout leftSubmenu={<AccountingSubmenu selected="tipo_impuestos" submenu={tKey('Impuestos')} />}>
      <TableWithEdit
        tableData={tableData}
        key={JSON.stringify(tableData.data)}
        optionsNew={{
          newButtonText: tKey('Nuevo impuesto'),
          onNew: goToNew,
        }}
        permission={canIConfig}
      />
      <TransitionModal
        view={modal.view}
        handleClose={() => {
          setModal({ view: false, body: [] });
        }}
        bodyModal={tKey('¿Seguro que desea eliminar este campo?')}
        buttonOk={tKey('Sí')}
        buttonKo={tKey('No')}
        title={tKey('¡Atención!')}
        handleYes={() => {
          onRemoveOne();
          setModal({ view: false, body: [] });
        }}
      />
    </TableLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  operation_keys: state.metadataReducer.accounting.operation_keys,
  canIConfig: selectUserCan(state)('allow_transaction_code'),
});

const mapDispatchToProps = (dispatch: any) => ({
  getAccountingMetadata: (): void => dispatch(getAccountingMetadataAction()),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RetentionsConfig);

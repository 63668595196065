import { EntityAgreementData } from '../../redux/entity/agreement/EntityAgreement';
import { PaymentInfo } from '../../redux/project/definitions';
import { apiBaseUrl, apiCall, apiCallBlob } from '../api';

const baseURL = apiBaseUrl;

export const getEntityAgreementAcademicYearsFromApi = async (entity_id: number): Promise<string[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return (await apiCall<{ data: string[] }>(`${baseURL}/entities/${entity_id}/agreement/academic_years`, init)).data;
};

export const getEntityAgreementDataFromApi = async (
  entity_id: number,
  academic_year: string,
): Promise<EntityAgreementData> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: EntityAgreementData }>(
    `${baseURL}/entities/${entity_id}/agreement?academic_year=${academic_year}`,
    init,
  );
  return data;
};

export const checkAgreementAdvancePaymentFromApi = async (
  id: number,
  academic_year: string,
  amount: number,
  advance_payment_date: string,
): Promise<PaymentInfo> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ id, academic_year, amount, advance_payment_date }),
  };
  const { data } = await apiCall<{ data: PaymentInfo }>(`${baseURL}/entity/check_scholarship_advance_payments`, init);
  return data;
};

export const makeAgreementAdvancePaymentFromApi = async (
  id: number,
  academic_year: string,
  amount: number,
  advance_payment_date: string,
  account_number: string,
): Promise<void> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ id, academic_year, amount, advance_payment_date, account_number }),
  };
  await apiCall<{}>(`${baseURL}/entity/make_scholarship_advance_payments`, init);
};

export const addEntityRemainingAmountFromApi = async (
  entity_id: number,
  academic_year: string,
  amount: number,
  comment: string,
): Promise<void> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ entity_id, academic_year, amount, comment }),
  };
  await apiCall<{ data: number }>(`${baseURL}/entity/remaining_amount/change`, init);
};

export const getEntityScholarshipPaymentLogsFromApi = async (
  entity_id: number,
  academic_year: string,
): Promise<Blob> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ entity_id, academic_year }),
  };

  return await apiCallBlob(`${baseURL}/entity/scholarship/payment/logs`, init);
};

export const sendAdminManagementFromApi = async (
  entity_id: number,
  academic_year: string,
  amount: number,
  payment_date: string,
): Promise<void> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ entity_id, academic_year, amount, payment_date }),
  };

  await apiCall<{ data: string }>(`${baseURL}/entity/scholarship/admin_management/pay`, init);
};

export const cancelEntityAgreementFromApi = async (signature_id: string): Promise<string> => {
  const init = {
    method: 'POST',
    auth: true,
  };
  const { data } = await apiCall<{ data: { agreement_state: string } }>(
    `${baseURL}/entity/agreement/undo/${signature_id}`,
    init,
  );
  return data.agreement_state;
};

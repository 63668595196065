import moment from 'moment';
import Send from '@material-ui/icons/Send';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as yup from 'yup';
import KeyboardDatePickerController from '../../../components/DatePicker/KeyboardDatePickerController';
import FncButton from '../../../components/FncButton/FncButton';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import { DATE_FORMAT, DATE_FORMAT_DB } from '../../../constants/date';
import { dbDateToDate } from '../../../helpers/dateHelper';
import { tFormKey, tKey } from '../../../helpers/translate';
import { sendEntityAdminManagementAction } from '../../../redux/entity/agreement/actions';
import { EntityAgreementData } from '../../../redux/entity/agreement/EntityAgreement';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import styles from './accountingData.module.scss';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { formatNumber } from '../../../helpers/formatNumber';

export const AdminManagementSchema = yup.object().shape({
  admin_management_amount: yup
    .number()
    .min(1, 'El valor debe ser superior a 0')
    .required('Se debe introudcir un valor')
    .nullable(),
  admin_management_date: yup
    .string()
    .required('Se debe introducir una fecha')
    .nullable(),
});

interface AccountingDataAdminManagementProps {
  entityAgreementData: EntityAgreementData;
  disabled: boolean;
  allowScholarshipPayments: boolean;
  sendEntityAdminManagement: (entity_id: number, academic_year: string, amount: number, payment_date: string) => void;
}

const AccountingDataAdminManagement: React.FC<AccountingDataAdminManagementProps> = ({
  entityAgreementData,
  disabled,
  allowScholarshipPayments,
  sendEntityAdminManagement,
}) => {
  const { entity_id, academic_year, admin_management_amount, admin_management_date } = entityAgreementData;
  const [confirmModal, setConfirmModal] = useState<{ show: boolean; payment_date: string; amount: number }>({
    show: false,
    payment_date: '',
    amount: 0,
  });
  const isDisabled = disabled || !allowScholarshipPayments || !!admin_management_date;

  const { handleSubmit, errors, control } = useForm<EntityAgreementData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: AdminManagementSchema,
  });

  const onSubmit = async (formData: EntityAgreementData) => {
    const { admin_management_amount, admin_management_date } = formData;
    if (admin_management_amount && admin_management_date) {
      const payment_date = moment(admin_management_date).format(DATE_FORMAT_DB);
      setConfirmModal({ show: true, payment_date, amount: admin_management_amount });
    }
  };

  const closeConfirmModal = () => {
    setConfirmModal({ show: false, payment_date: '', amount: 0 });
  };

  const handleConfirmModal = () => {
    sendEntityAdminManagement(entity_id, academic_year, confirmModal.amount, confirmModal.payment_date);
    closeConfirmModal();
  };

  const renderAdminManagementAmount = (): JSX.Element => {
    return (
      <TextInputController
        schema={AdminManagementSchema}
        type="number"
        label={tFormKey('Importe')}
        name={'admin_management_amount'}
        errors={errors}
        control={control}
        defaultValue={admin_management_amount}
        placeholder="0"
        disabled={isDisabled}
        price
      />
    );
  };

  const renderAdminManagementDateTextInput = (): JSX.Element => {
    return (
      <TextInputController
        schema={AdminManagementSchema}
        label={tFormKey('Fecha vencimiento')}
        name={'admin_management_date'}
        errors={errors}
        control={control}
        defaultValue={dbDateToDate(admin_management_date || '')}
        disabled
      />
    );
  };

  const renderAdminManagementDate = (): JSX.Element => {
    return (
      <KeyboardDatePickerController
        name="admin_management_date"
        variant="inline"
        inputVariant="outlined"
        label={tFormKey('Fecha vencimiento')}
        format={DATE_FORMAT}
        placeholder="" // Prevent google to detect this input as a credit card number
        control={control}
        schema={AdminManagementSchema}
        inputValue={admin_management_date || undefined}
        errors={errors}
        disabled={disabled}
        autoOk
      />
    );
  };

  const renderSubmitButton = (): JSX.Element | undefined => {
    if (isDisabled) {
      return undefined;
    }
    return (
      <div className={styles.buttonsWrapper}>
        <FncButton customClass={styles.iconButton} type="submit" color="primary">
          <Send />
          <span>{tKey('Enviar a SAGE')}</span>
        </FncButton>
      </div>
    );
  };

  return (
    <FormContainer title={tKey('Gestión admininstrativa')}>
      <form className={`${styles.adminManagementForm} ${styles.sectionContainer}`} onSubmit={handleSubmit(onSubmit)}>
        {renderAdminManagementAmount()}
        {!!admin_management_date ? renderAdminManagementDateTextInput() : renderAdminManagementDate()}
        {renderSubmitButton()}
      </form>
      <TransitionModal
        view={confirmModal.show}
        handleClose={closeConfirmModal}
        handleYes={handleConfirmModal}
        buttonOk={tKey('ok')}
        buttonKo={tKey('cancel')}
        title={tKey('Gestión administrativa')}
        bodyModal=""
      >
        <p
          style={{ padding: '0 10px' }}
        >{`Se creará un pago en concepto de gestión administrativa por valor de ${formatNumber({
          value: confirmModal.amount,
        })} € con vencimiento el día ${dbDateToDate(confirmModal.payment_date)}. ¿Estás seguro/a?`}</p>
      </TransitionModal>
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  allowScholarshipPayments: selectUserCan(state)('allow_scholarship_payments'),
});

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
  sendEntityAdminManagement: (entity_id: number, academic_year: string, amount: number, payment_date: string): void =>
    dispatch(sendEntityAdminManagementAction(entity_id, academic_year, amount, payment_date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDataAdminManagement);

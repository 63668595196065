import { UUID } from '../../../types/Shared/standard';
import { ScholarshipPaymentAdjustmentDataConsts } from './action_types';
import {
  AddScholarshipPaymentAction,
  AddScholarshipPaymentKoAction,
  AddScholarshipPaymentOkAction,
  DeleteScholarshipPaymentAdjustmentAction,
  DeleteScholarshipPaymentAdjustmentKoAction,
  DeleteScholarshipPaymentAdjustmentOkAction,
  GetScholarshipPaymentAdjustmentAction,
  GetScholarshipPaymentAdjustmentKoAction,
  GetScholarshipPaymentAdjustmentOkAction,
  SaveScholarshipPaymentAction,
  SaveScholarshipPaymentKoAction,
  SaveScholarshipPaymentOkAction,
  SetScholarshipPaymentDataAction,
} from './definitions';
import { ScholarshipPaymentAdjustmentData, ScholarshipPaymentAdjustmentItem } from './PaymentAdjustment';

export function getScholarshipPaymentAdjustmentAction(scholarshipId: number): GetScholarshipPaymentAdjustmentAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.GET_SCHOLARSHIP_PAYMENT_ADJUSTMENT,
    scholarshipId,
  };
}

export function getScholarshipPaymentAdjustmentOkAction(
  scholarshipPaymentAdjustmentData: ScholarshipPaymentAdjustmentData,
): GetScholarshipPaymentAdjustmentOkAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.GET_SCHOLARSHIP_PAYMENT_ADJUSTMENT_OK,
    scholarshipPaymentAdjustmentData,
  };
}

export function getScholarshipPaymentAdjustmentKoAction(error: string): GetScholarshipPaymentAdjustmentKoAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.GET_SCHOLARSHIP_PAYMENT_ADJUSTMENT_KO,
    error,
  };
}

export function addScholarshipPaymentAction(
  scholarshipPayment: ScholarshipPaymentAdjustmentItem,
): AddScholarshipPaymentAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.ADD_SCHOLARSHIP_PAYMENT_ADJUSTMENT,
    scholarshipPayment,
  };
}

export function addScholarshipPaymentOkAction(
  scholarshipPayment: ScholarshipPaymentAdjustmentItem,
): AddScholarshipPaymentOkAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.ADD_SCHOLARSHIP_PAYMENT_ADJUSTMENT_OK,
    scholarshipPayment,
  };
}

export function addScholarshipPaymentKoAction(error: string): AddScholarshipPaymentKoAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.ADD_SCHOLARSHIP_PAYMENT_ADJUSTMENT_KO,
    error,
  };
}

export function saveScholarshipPaymentAction(
  scholarship_id: number,
  scholarship_payments: ScholarshipPaymentAdjustmentItem[],
): SaveScholarshipPaymentAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.SAVE_SCHOLARSHIP_PAYMENT_ADJUSTMENT,
    scholarship_id,
    scholarship_payments,
  };
}

export function saveScholarshipPaymentOkAction(
  scholarship_id: number,
  scholarship_payments: ScholarshipPaymentAdjustmentItem[],
): SaveScholarshipPaymentOkAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.SAVE_SCHOLARSHIP_PAYMENT_ADJUSTMENT_OK,
    scholarship_id,
    scholarship_payments,
  };
}

export function saveScholarshipPaymentKoAction(error: string): SaveScholarshipPaymentKoAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.SAVE_SCHOLARSHIP_PAYMENT_ADJUSTMENT_KO,
    error,
  };
}

export function deleteScholarshipPaymentAdjustmentAction(
  scholarshipPaymentId: UUID,
): DeleteScholarshipPaymentAdjustmentAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.DELETE_SCHOLARSHIP_PAYMENT_ADJUSTMENT,
    scholarshipPaymentId,
  };
}

export function deleteScholarshipPaymentAdjustmentOkAction(
  scholarshipPaymentId: UUID,
): DeleteScholarshipPaymentAdjustmentOkAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.DELETE_SCHOLARSHIP_PAYMENT_ADJUSTMENT_OK,
    scholarshipPaymentId,
  };
}

export function deleteScholarshipPaymentAdjustmentKoAction(error: string): DeleteScholarshipPaymentAdjustmentKoAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.DELETE_SCHOLARSHIP_PAYMENT_ADJUSTMENT_KO,
    error,
  };
}

export function setScholarshipPaymentDataAction(
  scholarshipPaymentId: UUID,
  payment_date: string,
  payment_amount: number,
): SetScholarshipPaymentDataAction {
  return {
    type: ScholarshipPaymentAdjustmentDataConsts.SET_SCHOLARSHIP_PAYMENT_DATA,
    scholarshipPaymentId,
    payment_date,
    payment_amount,
  };
}

import { ButtonProps } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { exportInstituteScholarshipsPaymentsFromApi } from '../../../../api/ScholarshipPayment/scholarshipPayment';
import Layout from '../../../../components/Layout/Layout';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import { getDocs } from '../../../../helpers/getDocs';
import { tKey } from '../../../../helpers/translate';
import { AppState } from '../../../../redux/root-reducer';
import { getInstituteScholarshipPaymentsAction } from '../../../../redux/scholarship/payments/actions';
import { ScholarshipPaymentList } from '../../../../redux/scholarship/payments/definitions';
import {
  selectScholarshipPaymentLoading,
  selectScholarshipPaymentReload,
  selectInstituteScholarshipPayments,
  selectSelectedAcademicYear,
} from '../../../../redux/scholarship/payments/selectors';
import PaymentStatus from '../../../../types/Payments/PaymentStatus';
import AccountingSubmenu from '../../AccountingSubmenu';
import ScholarshipsPaymentFilters from '../ScholarshipsPaymentFilters';
import ScholarshipsPaymentList from '../ScholarshipsPaymentList';

interface InstituteScholarshipsPaymentsProps extends RouteComponentProps<{ id: string }> {
  loading: boolean;
  reload: boolean;
  selectedAcademicYear: string;
  selectedStatus: string;
  scholarshipPaymentList: ScholarshipPaymentList;
  setSelectedEntityId: (entity_id: number | null) => void;
  setSelectedAcademicYear: (academic_year: string) => void;
  getInstituteScholarshipPayments: (academic_year: string, status: string) => void;
}

const InstituteScholarshipsPayments: React.FC<InstituteScholarshipsPaymentsProps> = ({
  loading,
  reload,
  selectedAcademicYear,
  scholarshipPaymentList,
  getInstituteScholarshipPayments,
}) => {
  useEffect(() => {
    if (selectedAcademicYear) {
      getInstituteScholarshipPayments(selectedAcademicYear, PaymentStatus.EMPTY_STATUS);
    }
  }, [getInstituteScholarshipPayments, selectedAcademicYear]);

  useEffect(() => {
    if (reload && selectedAcademicYear) {
      getInstituteScholarshipPayments(selectedAcademicYear, PaymentStatus.EMPTY_STATUS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const exportExcel = async () => {
    if (selectedAcademicYear) {
      const file = await exportInstituteScholarshipsPaymentsFromApi(selectedAcademicYear);
      const fileName = `pagos_becas_instituto_${selectedAcademicYear.replace('/', '_')}.xlsx`;
      getDocs(file, true, fileName);
    }
  };

  const actionButtons: ButtonProps[] = [
    {
      children: tKey('Exportar'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      variant: 'contained',
      startIcon: <GetAppIcon />,
      onClick: () => exportExcel(),
    },
  ];

  return (
    <Layout
      leftSubmenu={<AccountingSubmenu selected="institute" submenu={tKey('Pagos')} />}
      rightSubmenu={<ActionsMenu actionsButtons={actionButtons} goBack />}
    >
      <ScholarshipsPaymentFilters
        loading={loading}
        title={tKey('Becas instituto')}
        scholarshipPaymentListSummary={scholarshipPaymentList.payments_summary}
      />
      <ScholarshipsPaymentList
        loading={loading}
        isEntity={false}
        title={'Pagos becas instituto'}
        payments={scholarshipPaymentList.payments}
      />
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedAcademicYear: selectSelectedAcademicYear(state),
  scholarshipPaymentList: selectInstituteScholarshipPayments(state),
  loading: selectScholarshipPaymentLoading(state),
  reload: selectScholarshipPaymentReload(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getInstituteScholarshipPayments: (academic_year: string, status: string) =>
    dispatch(getInstituteScholarshipPaymentsAction(academic_year, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstituteScholarshipsPayments);

import assertUnreachable from '../../helpers/assertUnreachable';
import { PaymentSettingDataConsts } from './action_types';
import { PaymentSettingDataAction, PaymentSettingDataState } from './definitions';
import { PaymentSettingInitialState } from './PaymentSetting';

const PaymentSettingReducer = (
  state = PaymentSettingInitialState,
  action: PaymentSettingDataAction,
): PaymentSettingDataState => {
  switch (action.type) {
    case PaymentSettingDataConsts.UPDATE_PAYMENT_SETTING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PaymentSettingDataConsts.UPDATE_PAYMENT_SETTING_OK:
      return {
        ...state,
        loading: false,
      };
    case PaymentSettingDataConsts.UPDATE_PAYMENT_SETTING_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default PaymentSettingReducer;

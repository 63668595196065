/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, putResolve, takeLatest } from '@redux-saga/core/effects';
import { getFamilyIncomes } from '../../api/Scholarship/familyIncomes';
import { getScholarshipJustificationsFromApi } from '../../api/Scholarship/justifications';
import {
  deleteScholarshipDocument,
  downloadScholarshipDocument,
  getConfigurationFollowupFromApi,
  getDocumentation,
  getMetadata,
  getOneScholarship,
  getScholarshipAcademiesByFNCAcademy,
  getScholarshipCommitteeReport,
  getScholarshipReports,
  runConfigurationFollowupFromApi,
  saveScholarshipFollowUp,
  scholarshipSendInformation,
  sendConfigurationFollowupFromApi,
  switchPaymentBlockFromApi,
  uploadScholarshipDocument,
  uploadScholarshipDocumentTracking,
} from '../../api/Scholarship/scholarship';
import {
  addScholarshipMaterial,
  removeScholarshipMaterial,
  updateScholarshipMaterial,
} from '../../api/Scholarship/scholarshipMaterial';
import { Await } from '../../api/api';
import errorMessage from '../../helpers/errorMessage';
import { showSnackbar } from '../FeedbackAPI/actions';
import { ScholarshipDataConsts } from './action_types';
import {
  addScholarshipMaterialOk,
  downloadDocumentKo,
  downloadDocumentOk,
  exportScholarshipsReportsKo,
  exportScholarshipsReportsOk,
  getConfigurationFollowupKo,
  getConfigurationFollowupOk,
  getDocumentKo,
  getDocumentOk,
  getScholarshipAcademiesMetadataKo,
  getScholarshipAcademiesMetadataOk,
  getScholarshipDocumentationKo,
  getScholarshipDocumentationOk,
  removeDocumentKo,
  removeDocumentOk,
  removeScholarshipMaterialOk,
  runConfigurationFollowupKo,
  runConfigurationFollowupOk,
  scholarshipGetKo,
  scholarshipGetMetadataKo,
  scholarshipGetMetadataOk,
  scholarshipGetOk,
  scholarshipMaterialKo,
  scholarshipSubmitInformationKo,
  scholarshipSubmitInformationOk,
  scholarshipValidationErrors,
  sendConfigurationFollowupKo,
  sendConfigurationFollowupOk,
  switchBlockPaymentKo,
  switchBlockPaymentOk,
  updateScholarshipMaterialOk,
  uploadDocumentKo,
  uploadDocumentOk,
} from './actions';
import {
  ExportScholarshipReportsAction,
  GetConfigurationFollowupAction,
  GetDocumentScholarshipAction,
  RemoveDocumentScholarshipAction,
  RunConfigurationFollowupAction,
  SaveFollowUpAction,
  ScholarshipAcademiesMetadataAction,
  ScholarshipAddMaterialAction,
  ScholarshipDownloadDocumentAction,
  ScholarshipGetAction,
  ScholarshipGetDocumentationAction,
  ScholarshipRemoveMaterialAction,
  ScholarshipSubmitInformationAction,
  ScholarshipUpdateMaterialAction,
  SendConfigurationFollowupAction,
  SwitchBlockPaymentAction,
  UploadDocumentAction,
  UploadDocumentTrackingAction,
} from './definitions';
import { actionGetFamilyIncomesKo, actionGetFamilyIncomesOk } from './familyIncomes/list/action';
import { getScholarshipJustificationsActionOk, scholarshipJustificationsActionKo } from './justifications/actions';

function* fetchJustifications(id: number): Generator<any, void, any> {
  try {
    const justifications = yield getScholarshipJustificationsFromApi(id);
    yield putResolve(getScholarshipJustificationsActionOk(justifications));
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(scholarshipJustificationsActionKo(error.message));
    throw e;
  }
}

function* getScholarshipById(action: ScholarshipGetAction): Generator<any, void, any> {
  try {
    const { data: metaData } = yield getMetadata();
    const { data } = yield getOneScholarship(action.id);
    const documentation = yield getDocumentation(data.id);
    yield putResolve(scholarshipGetOk(data, metaData));
    yield putResolve(getScholarshipDocumentationOk(documentation));
    try {
      const familyIncomes = yield getFamilyIncomes(action.id);
      yield putResolve(actionGetFamilyIncomesOk(familyIncomes));
    } catch (e) {
      const error = e as ErrorEvent;
      yield put(actionGetFamilyIncomesKo(error.message));
    }
    yield fetchJustifications(action.id);
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(scholarshipGetKo(error.message));
  }
}

function* getScholarshipMetadata(): Generator<any, void, any> {
  try {
    const { data } = yield getMetadata();
    yield put(scholarshipGetMetadataOk(data));
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(scholarshipGetMetadataKo(error.message));
  }
}

function* getSubmitInformation(action: ScholarshipSubmitInformationAction): Generator<any, void, any> {
  try {
    const result = (yield scholarshipSendInformation(action.data)) as Await<
      ReturnType<typeof scholarshipSendInformation>
    >;

    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(scholarshipSubmitInformationOk(data));
        yield put(showSnackbar('Los datos se han guardado correctamente', 'success', undefined, 1500));
        break;
      case 'validation-error':
        const error = result.value;
        yield put(scholarshipValidationErrors(error));
        break;
    }
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(scholarshipSubmitInformationKo(error.message));
  }
}

function* uploadDocumentFromSaga(action: UploadDocumentAction): Generator<any, void, any> {
  try {
    const { documentation_id, data, scholarshipId, multiDocument } = action;
    const documents = yield uploadScholarshipDocument(documentation_id, data, scholarshipId, multiDocument);
    yield put(uploadDocumentOk(documents));
    yield put(showSnackbar('El documento se ha subido correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('Ha ocurrido algún problema al subir el documento', 'error', undefined, 1500));
    yield put(uploadDocumentKo(errorMessage(e)));
  }
}

function* getScholarshipDocumentation(action: ScholarshipGetDocumentationAction): Generator<any, void, any> {
  try {
    const data = yield getDocumentation(action.id);
    yield put(getScholarshipDocumentationOk(data));
  } catch (e) {
    yield put(getScholarshipDocumentationKo(errorMessage(e)));
  }
}

function* downloadDocumentFromSaga(action: ScholarshipDownloadDocumentAction): Generator<any, void, any> {
  try {
    const { file_id, name, scholarshipId } = action;
    const info = yield downloadScholarshipDocument(file_id, scholarshipId);
    yield put(downloadDocumentOk(info, name));
    yield put(showSnackbar('El documento se ha descargado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(downloadDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha ocurrido algún problema al descargar el documento', 'error', undefined, 1500));
  }
}

function* getDocumentFromSaga(action: GetDocumentScholarshipAction): Generator<any, void, any> {
  try {
    const { file_id, scholarshipId, name } = action;
    const info = yield downloadScholarshipDocument(file_id, scholarshipId);
    yield put(getDocumentOk(info, name));
  } catch (e) {
    yield put(getDocumentKo(errorMessage(e)));
  }
}

function* removeDocumentFromSaga(action: RemoveDocumentScholarshipAction): Generator<any, void, any> {
  try {
    const { file_id, scholarshipId } = action;
    const info = yield deleteScholarshipDocument(scholarshipId, file_id);
    yield put(removeDocumentOk(info));
    yield put(showSnackbar('El documento se ha eliminado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(removeDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha ocurrido algún problema al eliminar el documento', 'error', undefined, 1500));
  }
}

function* sendConfigurationFollowupSaga(action: SendConfigurationFollowupAction): Generator<any, void, any> {
  try {
    yield sendConfigurationFollowupFromApi(action.data);
    yield sendConfigurationFollowupOk();
    yield put(showSnackbar('Configuración Guardada Correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(sendConfigurationFollowupKo(errorMessage(e)));
    yield put(showSnackbar('No se ha podido guardar la configuración', 'error', undefined, 1500));
  }
}

function* runConfigurationFollowupSaga(): Generator<any, void, any> {
  try {
    yield runConfigurationFollowupFromApi();
    yield runConfigurationFollowupOk();
    yield put(showSnackbar('Se han ejecutado los seguimientos', 'success', undefined, 1500));
  } catch (e) {
    yield put(runConfigurationFollowupKo(errorMessage(e)));
    yield put(showSnackbar('No se han podido ejecutar los seguimientos', 'error', undefined, 1500));
  }
}

function* getConfigurationFollowupSaga(): Generator<any, void, any> {
  try {
    const info = yield getConfigurationFollowupFromApi();
    yield put(getConfigurationFollowupOk(info));
  } catch (e) {
    yield put(getConfigurationFollowupKo(errorMessage(e)));
  }
}

function* updateScholarshipFollowUpFromSaga(action: SaveFollowUpAction): Generator<any, void, any> {
  try {
    yield saveScholarshipFollowUp(action.follow_up);
    yield put(showSnackbar('Los datos se han guardado correctamente', 'success'));
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(showSnackbar(error.message, 'error', undefined, 4000));
  }
}

function* switchPaymentBlockFromSaga(action: SwitchBlockPaymentAction): Generator<any, void, any> {
  try {
    const info = yield switchPaymentBlockFromApi(action.id);
    yield put(switchBlockPaymentOk(info.data));
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(switchBlockPaymentKo(error.message));
  }
}

function* addScholarshipMaterialsFromSaga(action: ScholarshipAddMaterialAction): Generator<any, void, any> {
  try {
    const { filename } = action.material;
    const materialResult = yield addScholarshipMaterial(action.material);
    yield put(addScholarshipMaterialOk({ ...materialResult, filename }));
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(scholarshipMaterialKo(error.message));
  }
}

function* removeScholarshipMaterialsFromSaga(action: ScholarshipRemoveMaterialAction): Generator<any, void, any> {
  try {
    yield removeScholarshipMaterial(action.scholarship_id, action.material_id);
    yield put(removeScholarshipMaterialOk(action.scholarship_id, action.material_id));
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(scholarshipMaterialKo(error.message));
  }
}

function* updateScholarshipMaterialsFromSaga(action: ScholarshipUpdateMaterialAction): Generator<any, void, any> {
  try {
    yield updateScholarshipMaterial(action.material);
    yield put(updateScholarshipMaterialOk(action.material));
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(scholarshipMaterialKo(error.message));
  }
}

function* getExportScholarshipReports(action: ExportScholarshipReportsAction): Generator<any, void, any> {
  try {
    if (action.source === 'committee') {
      const { data } = yield getScholarshipCommitteeReport(action.scholarship_type, action.academic_year);
      yield put(showSnackbar(data, 'success', undefined, 5000));
    } else {
      const info = yield getScholarshipReports(action.source, action.scholarship_type, action.academic_year);
      yield put(exportScholarshipsReportsOk(action.source, action.scholarship_type, info));
    }
  } catch (e) {
    yield put(exportScholarshipsReportsKo(errorMessage(e)));
  }
}

function* uploadDocumentTrackingFromSaga(action: UploadDocumentTrackingAction): Generator<any, void, any> {
  try {
    const { documentation_id, data, scholarshipId, trackingId } = action;
    const documents = yield uploadScholarshipDocumentTracking(documentation_id, data, scholarshipId, trackingId);
    yield put(uploadDocumentOk(documents));
    yield put(showSnackbar('El documento se ha subido correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('Ha ocurrido algún problema al subir el documento', 'error', undefined, 1500));
    yield put(uploadDocumentKo(errorMessage(e)));
  }
}

function* getScholarshipAcademiesFromSaga(action: ScholarshipAcademiesMetadataAction): Generator<any, void, any> {
  try {
    const data = yield getScholarshipAcademiesByFNCAcademy(action.code);
    yield put(getScholarshipAcademiesMetadataOk(data));
  } catch (e) {
    yield put(getScholarshipAcademiesMetadataKo(errorMessage(e)));
  }
}

export default [
  takeLatest<ScholarshipGetAction>(ScholarshipDataConsts.SCHOLARSHIP_GET, getScholarshipById),
  takeLatest<ScholarshipGetAction>(ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA, getScholarshipMetadata),
  takeLatest<UploadDocumentAction>(ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT, uploadDocumentFromSaga),
  takeLatest<ScholarshipSubmitInformationAction>(
    ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION,
    getSubmitInformation,
  ),
  takeLatest<ScholarshipGetDocumentationAction>(
    ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION,
    getScholarshipDocumentation,
  ),
  takeLatest<ScholarshipDownloadDocumentAction>(
    ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT,
    downloadDocumentFromSaga,
  ),
  takeLatest<GetDocumentScholarshipAction>(ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT, getDocumentFromSaga),
  takeLatest<RemoveDocumentScholarshipAction>(
    ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT,
    removeDocumentFromSaga,
  ),
  takeLatest<SendConfigurationFollowupAction>(
    ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP,
    sendConfigurationFollowupSaga,
  ),
  takeLatest<RunConfigurationFollowupAction>(
    ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP,
    runConfigurationFollowupSaga,
  ),
  takeLatest<GetConfigurationFollowupAction>(
    ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP,
    getConfigurationFollowupSaga,
  ),
  takeLatest<SwitchBlockPaymentAction>(ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT, switchPaymentBlockFromSaga),
  takeLatest<ScholarshipAddMaterialAction>(
    ScholarshipDataConsts.SCHOLARSHIP_ADD_MATERIAL,
    addScholarshipMaterialsFromSaga,
  ),
  takeLatest<ScholarshipUpdateMaterialAction>(
    ScholarshipDataConsts.SCHOLARSHIP_UPDATE_MATERIAL,
    updateScholarshipMaterialsFromSaga,
  ),
  takeLatest<ScholarshipRemoveMaterialAction>(
    ScholarshipDataConsts.SCHOLARSHIP_REMOVE_MATERIAL,
    removeScholarshipMaterialsFromSaga,
  ),
  takeLatest<SaveFollowUpAction>(ScholarshipDataConsts.SAVE_FOLLOW_UP, updateScholarshipFollowUpFromSaga),
  takeLatest<ExportScholarshipReportsAction>(
    ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS,
    getExportScholarshipReports,
  ),
  takeLatest<UploadDocumentTrackingAction>(
    ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_TRACKING,
    uploadDocumentTrackingFromSaga,
  ),
  takeLatest<ScholarshipAcademiesMetadataAction>(
    ScholarshipDataConsts.GET_SCHOLARSHIP_ACADEMIES_METADATA,
    getScholarshipAcademiesFromSaga,
  ),
];

import { APIValidationError } from '../../../api/api';
import { ScholarshipAgreementDataConsts } from './action_types';
import {
  CancelScholarshipAgreementAction,
  CancelScholarshipAgreementKoAction,
  CancelScholarshipAgreementOkAction,
  GetScholarshipAgreementAction,
  GetScholarshipAgreementKoAction,
  GetScholarshipAgreementOkAction,
  ScholarshipAgreementSetDataAction,
  ScholarshipAgreementValidationErrorsAction,
  SendStudentProviderInfoAction,
  SendStudentProviderInfoKoAction,
  SendStudentProviderInfoOkAction,
  UpdateScholarshipAgreementAction,
  UpdateScholarshipAgreementKoAction,
  UpdateScholarshipAgreementOkAction,
} from './definitions';
import { ScholarshipAgreementData, ScholarshipProviderData } from './ScholarshipAgreement';

export function getScholarshipAgreementAction(scholarship_id: number): GetScholarshipAgreementAction {
  return {
    type: ScholarshipAgreementDataConsts.GET_SCHOLARSHIP_AGREEMENT,
    scholarship_id,
  };
}

export function getScholarshipAgreementOkAction(
  scholarshipAgreementData: ScholarshipAgreementData,
): GetScholarshipAgreementOkAction {
  return {
    type: ScholarshipAgreementDataConsts.GET_SCHOLARSHIP_AGREEMENT_OK,
    scholarshipAgreementData,
  };
}

export function getScholarshipAgreementKoAction(error: string): GetScholarshipAgreementKoAction {
  return {
    type: ScholarshipAgreementDataConsts.GET_SCHOLARSHIP_AGREEMENT_KO,
    error,
  };
}

export function updateScholarshipAgreementAction(
  scholarshipAgreementData: ScholarshipAgreementData,
): UpdateScholarshipAgreementAction {
  return {
    type: ScholarshipAgreementDataConsts.UPDATE_SCHOLARSHIP_AGREEMENT,
    scholarshipAgreementData,
  };
}

export function updateScholarshipAgreementOkAction(
  scholarshipAgreementData: ScholarshipAgreementData,
): UpdateScholarshipAgreementOkAction {
  return {
    type: ScholarshipAgreementDataConsts.UPDATE_SCHOLARSHIP_AGREEMENT_OK,
    scholarshipAgreementData,
  };
}

export function updateScholarshipAgreementKoAction(error: string): UpdateScholarshipAgreementKoAction {
  return {
    type: ScholarshipAgreementDataConsts.UPDATE_SCHOLARSHIP_AGREEMENT_KO,
    error,
  };
}

export function sendStudentProviderInfoAction(
  scholarshipAgreementData: ScholarshipAgreementData,
): SendStudentProviderInfoAction {
  return {
    type: ScholarshipAgreementDataConsts.SEND_STUDENT_PROVIDER_INFO,
    scholarshipAgreementData,
  };
}

export function sendStudentProviderInfoOkAction(
  scholarshipProviderData: ScholarshipProviderData,
): SendStudentProviderInfoOkAction {
  return {
    type: ScholarshipAgreementDataConsts.SEND_STUDENT_PROVIDER_INFO_OK,
    scholarshipProviderData,
  };
}

export function sendStudentProviderInfoKoAction(error: string): SendStudentProviderInfoKoAction {
  return {
    type: ScholarshipAgreementDataConsts.SEND_STUDENT_PROVIDER_INFO_KO,
    error,
  };
}

export function cancelScholarshipAgreementAction(
  scholarship_id: number,
  signature_id: string,
): CancelScholarshipAgreementAction {
  return {
    type: ScholarshipAgreementDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT,
    scholarship_id,
    signature_id,
  };
}

export function cancelScholarshipAgreementOkAction(agreement_state: string): CancelScholarshipAgreementOkAction {
  return {
    type: ScholarshipAgreementDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT_OK,
    agreement_state,
  };
}

export function cancelScholarshipAgreementKoAction(error: string): CancelScholarshipAgreementKoAction {
  return {
    type: ScholarshipAgreementDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT_KO,
    error,
  };
}

export function scholarshipAgreementSetDataAction(
  key: string,
  value: string | number | null,
): ScholarshipAgreementSetDataAction {
  return {
    type: ScholarshipAgreementDataConsts.SCHOLARSHIP_AGREEMENT_SET_DATA,
    key,
    value,
  };
}

export function scholarshipAgreementValidationErrorsAction(
  validationErrors: APIValidationError,
): ScholarshipAgreementValidationErrorsAction {
  return {
    type: ScholarshipAgreementDataConsts.SCHOLARSHIP_AGREEMENT_VALIDATION_ERRORS,
    validationErrors,
  };
}

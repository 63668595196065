import { Button, InputAdornment, MenuItem } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { deleteFileFromApi, uploadFileFromApi } from '../../../../api/file';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../components/Select/SelectController';
import UploadFileButton from '../../../../components/UploadFileButton/UploadFileButton';
import { tKey, tScholarshipKey } from '../../../../helpers/translate';
import { JustificationSchema } from '../FormSchema';
import styles from '../Shared/justificationTableModal.module.scss';
import errorMessage from '../../../../helpers/errorMessage';
import { Justification, justificationTypesArray } from '../../../../redux/scholarship/justifications/Justification.d';

interface ModalCreateProps {
  justification: Justification;
  onSave: (data: Justification) => void;
  onDownloadFile: (file_id: number, filename: string) => void;
  setJustification: (data: Justification) => void;
  closeModal: () => void;
}

const JustificationTableModal: React.FC<ModalCreateProps> = ({
  justification,
  onSave,
  onDownloadFile,
  setJustification,
  closeModal,
}) => {
  const { id, scholarship_id, type, description, amount, file_id, filename } = justification;

  const [fileId, setFileId] = React.useState<number | null>(file_id);

  const justificationSchema = JustificationSchema();

  const { errors, control, setError, setValue, watch, handleSubmit } = useForm<Justification>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: justificationSchema,
  });

  const onSubmit = (data: Justification) => {
    onSave(data);
    closeModal();
  };

  const uploadFile = async (file: File) => {
    try {
      const fileItem = await uploadFileFromApi(file);
      setFileId(fileItem.id);
      setValue('file_id', fileItem.id);
      setValue('filename', fileItem.original_name);
    } catch (error) {
      setError('file_id', errorMessage(error));
    }
  };

  const deleteFile = async (file_id: number) => {
    const deleted = await deleteFileFromApi(file_id);
    if (deleted) {
      setFileId(null);
      setValue('file_id', null);
      setValue('filename', null);
      setJustification({ ...justification, file_id: null, filename: null });
    }
  };

  const renderUploadButton = () => {
    return (
      <div className={styles.uploadFileButton}>
        <UploadFileButton
          file_id={fileId}
          filename={watch('filename') || filename}
          errors={errors}
          readOnly={false}
          onUploadFile={uploadFile}
          downloadFile={onDownloadFile}
          removeFile={deleteFile}
          setValue={setValue}
        />
      </div>
    );
  };

  const renderHiddenFields = (): JSX.Element => {
    return (
      <>
        <TextInputController
          type="hidden"
          control={control}
          key="id"
          name="id"
          errors={errors}
          schema={justificationSchema}
          defaultValue={id}
          visible={false}
        />
        <TextInputController
          type="hidden"
          control={control}
          key="scholarship_id"
          name="scholarship_id"
          errors={errors}
          schema={justificationSchema}
          defaultValue={scholarship_id}
          visible={false}
        />
        <TextInputController
          type="hidden"
          control={control}
          key="file_id"
          name="file_id"
          errors={errors}
          schema={justificationSchema}
          defaultValue={file_id}
          visible={false}
        />
        <TextInputController
          type="hidden"
          control={control}
          key="filename"
          name="filename"
          errors={errors}
          schema={justificationSchema}
          defaultValue={filename}
          visible={false}
        />
      </>
    );
  };

  return (
    <div>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {renderHiddenFields()}
        <div className={styles.newcontact}>
          <TextInputController
            control={control}
            key="description"
            name="description"
            label={tScholarshipKey('justification_description')}
            errors={errors}
            schema={justificationSchema}
            defaultValue={description}
            fullWidth
          />
        </div>
        <div className={styles.newcontact}>
          <SelectController
            control={control}
            size="Large"
            key="type"
            name="type"
            defaultValue={type}
            errors={errors}
            schema={justificationSchema}
            label={tScholarshipKey('justification_type')}
          >
            {justificationTypesArray().map((value: string) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectController>
        </div>
        <div className={styles.newcontact}>
          <TextInputController
            type="number"
            size="Large"
            label={tScholarshipKey('justification_amount')}
            name="amount"
            defaultValue={amount || 0}
            control={control}
            errors={errors}
            schema={justificationSchema}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        </div>
        {renderUploadButton()}
        <div className={styles.btn_create}>
          <Button color="primary" variant="outlined" onClick={handleSubmit(onSubmit)}>
            {tKey('Guardar')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default JustificationTableModal;

export enum ScholarshipPaymentAdjustmentDataConsts {
  GET_SCHOLARSHIP_PAYMENT_ADJUSTMENT = 'GET:SCHOLARSHIP:PAYMENT:ADJUSTMENT',
  GET_SCHOLARSHIP_PAYMENT_ADJUSTMENT_OK = 'GET:SCHOLARSHIP:PAYMENT:ADJUSTMENT:OK',
  GET_SCHOLARSHIP_PAYMENT_ADJUSTMENT_KO = 'GET:SCHOLARSHIP:PAYMENT:ADJUSTMENT:KO',

  ADD_SCHOLARSHIP_PAYMENT_ADJUSTMENT = 'ADD:SCHOLARSHIP:PAYMENT:ADJUSTMENT',
  ADD_SCHOLARSHIP_PAYMENT_ADJUSTMENT_OK = 'ADD:SCHOLARSHIP:PAYMENT:ADJUSTMENT:OK',
  ADD_SCHOLARSHIP_PAYMENT_ADJUSTMENT_KO = 'ADD:SCHOLARSHIP:PAYMENT:ADJUSTMENT:KO',

  SAVE_SCHOLARSHIP_PAYMENT_ADJUSTMENT = 'SAVE:SCHOLARSHIP:PAYMENT:ADJUSTMENT',
  SAVE_SCHOLARSHIP_PAYMENT_ADJUSTMENT_OK = 'SAVE:SCHOLARSHIP:PAYMENT:ADJUSTMENT:OK',
  SAVE_SCHOLARSHIP_PAYMENT_ADJUSTMENT_KO = 'SAVE:SCHOLARSHIP:PAYMENT:ADJUSTMENT:KO',

  DELETE_SCHOLARSHIP_PAYMENT_ADJUSTMENT = 'DELETE:SCHOLARSHIP:PAYMENT:ADJUSTMENT',
  DELETE_SCHOLARSHIP_PAYMENT_ADJUSTMENT_OK = 'DELETE:SCHOLARSHIP:PAYMENT:ADJUSTMENT:OK',
  DELETE_SCHOLARSHIP_PAYMENT_ADJUSTMENT_KO = 'DELETE:SCHOLARSHIP:PAYMENT:ADJUSTMENT:KO',

  SET_SCHOLARSHIP_PAYMENT_DATA = 'SET:SCHOLARSHIP:PAYMENT:DATA',
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { connect } from 'react-redux';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInput from '../../../../components/Inputs/TextInput';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { tScholarshipKey } from '../../../../helpers/translate';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { AppState } from '../../../../redux/root-reducer';
import { downloadDocument, getDocument } from '../../../../redux/scholarship/actions';
import { ScholarshipAgreementData } from '../../../../redux/scholarship/agreement/ScholarshipAgreement';
import { ScholarshipData } from '../../../../redux/scholarship/definitions';
import {
  selectGetScholarship,
  selectGetScholarshipDocumentationPerCode,
} from '../../../../redux/scholarship/selectors';
import Documentation from '../../../../types/Scholarships/Documentation';

interface PaymentFormScholarshipDataProps {
  scholarshipAgreementData: ScholarshipAgreementData;
  scholarshipData: ScholarshipData;
  documentation: DocumentationItem[];
  getDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
}

const PaymentFormScholarshipData: React.FC<PaymentFormScholarshipDataProps> = ({
  scholarshipAgreementData,
  scholarshipData,
  documentation,
  getDocument,
  downloadDocument,
}) => {
  const { scholarship_academy_confirm } = scholarshipData;
  const {
    scholarship_id,
    last_scholarship_academy,
    student_document_type,
    student_document_number,
  } = scholarshipAgreementData;

  return (
    <FormContainer title={tScholarshipKey('Datos Beca')}>
      <TextInput
        label={tScholarshipKey('Último centro de estudios')}
        size="50"
        name="last_scholarship_academy"
        disabled
        defaultValue={last_scholarship_academy}
      />
      <TextInput
        label={tScholarshipKey('Centro de estudios')}
        size="50"
        name="scholarship_academy_confirm"
        disabled
        defaultValue={scholarship_academy_confirm}
      />
      <TextInput
        label={tScholarshipKey('Tipo de documento')}
        size="50"
        name="student_document_type"
        disabled
        defaultValue={student_document_type}
      />
      <TextInput
        label={tScholarshipKey('DNI/NIE/OTROS estudiante')}
        size="50"
        name="student_document_number"
        disabled
        defaultValue={student_document_number}
      />
      <FncAddDocuments
        documents={documentation}
        actions={['download', 'view']}
        originalName
        download={downloadDocument}
        getFile={getDocument}
        genericId={scholarship_id}
      />
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipData: selectGetScholarship(state),
  documentation: selectGetScholarshipDocumentationPerCode(state, [Documentation.DNI, Documentation.NIF_TUTOR]),
});

const mapDispatchToProps = (dispatch: any) => ({
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) =>
    dispatch(downloadDocument(documentation_id, name, scholarship_id)),
  getDocument: (documentation_id: number, name: string, scholarship_id: number) =>
    dispatch(getDocument(documentation_id, name, scholarship_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFormScholarshipData);

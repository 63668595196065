/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import SendIcon from '@material-ui/icons/Send';
import { connect } from 'react-redux';
import FncButton from '../../../../components/FncButton/FncButton';
import TextInput from '../../../../components/Inputs/TextInput';
import { tScholarshipKey } from '../../../../helpers/translate';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { sendStudentProviderInfoAction } from '../../../../redux/scholarship/agreement/actions';
import { ScholarshipAgreementData } from '../../../../redux/scholarship/agreement/ScholarshipAgreement';
import {
  selectGetScholarship,
  selectGetScholarshipDocumentationPerCode,
} from '../../../../redux/scholarship/selectors';
import Documentation from '../../../../types/Scholarships/Documentation';
import styles from './paymentForm.module.scss';
import { ScholarshipData, ScholarshipStateCode } from '../../../../redux/scholarship/definitions';
import { selectScholarshipProviderDataLoading } from '../../../../redux/scholarship/agreement/selectors';
import Loading from '../../../../components/Loading/Loading';

interface PaymentFormProviderInfoDataProps {
  scholarshipAgreementData: ScholarshipAgreementData;
  scholarshipData: ScholarshipData;
  disabled: boolean;
  allowPaymentAdjustment: boolean;
  loading: boolean;
  sendStudentProviderInfo: (scholarshipAgreementData: ScholarshipAgreementData) => void;
}

const PaymentFormProviderInfo: React.FC<PaymentFormProviderInfoDataProps> = ({
  scholarshipAgreementData,
  scholarshipData,
  disabled,
  allowPaymentAdjustment,
  loading,
  sendStudentProviderInfo,
}) => {
  const {
    provider_code,
    payable_account,
    expense_account,
    center_cost_channel_code,
    center_cost_delegation_code,
    center_cost_area_code,
    center_cost_project_code,
  } = scholarshipAgreementData;
  const { state_code } = scholarshipData;

  const onSendProviderInfo = () => {
    sendStudentProviderInfo(scholarshipAgreementData);
  };

  const renderSendProviderInfo = (): JSX.Element | undefined => {
    const disableButton =
      !(center_cost_channel_code && center_cost_delegation_code && center_cost_area_code && center_cost_project_code) ||
      disabled;
    if (!allowPaymentAdjustment || state_code !== ScholarshipStateCode.ASSIGNED) {
      return undefined;
    }
    return (
      <div className={`${styles.buttonsWrapper} ${styles.buttonActionProvider}`}>
        <FncButton
          disabled={disableButton}
          customClass={styles.iconButton}
          onClick={onSendProviderInfo}
          color="primary"
        >
          <SendIcon />
          <span>{tScholarshipKey('Proveedor SAGE')}</span>
        </FncButton>
      </div>
    );
  };

  const renderProviderInfo = (): JSX.Element => {
    if (loading) {
      return <Loading small />;
    }
    return (
      <>
        <TextInput
          name="provider_code"
          defaultValue={provider_code}
          label={tScholarshipKey('Código de proveedor')}
          fullWidth
          disabled
        />
        <TextInput
          name="payable_account"
          defaultValue={payable_account}
          label={tScholarshipKey('Cuenta contable de proveedor')}
          fullWidth
          disabled
        />
        <TextInput
          name="expense_account"
          defaultValue={expense_account}
          label={tScholarshipKey('Cuenta contable de gasto')}
          fullWidth
          disabled
        />
      </>
    );
  };
  return (
    <div className={styles.providerContainer}>
      <div className={styles.providerInfo}>{renderProviderInfo()}</div>
      {renderSendProviderInfo()}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  documentation: selectGetScholarshipDocumentationPerCode(state, [Documentation.IRPF]),
  allowPaymentAdjustment: selectUserCan(state)('allow_payment_adjustment'),
  scholarshipData: selectGetScholarship(state),
  loading: selectScholarshipProviderDataLoading(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  sendStudentProviderInfo: (scholarshipAgreementData: ScholarshipAgreementData) =>
    dispatch(sendStudentProviderInfoAction(scholarshipAgreementData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFormProviderInfo);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Title from '../../../components/Title/Title';
import { tKey } from '../../../helpers/translate';
import { EntityAgreementData } from '../../../redux/entity/agreement/EntityAgreement';
import { getEntityAgreementDataAction } from '../../../redux/entity/agreement/actions';
import { EntityAgreementDispatch } from '../../../redux/entity/agreement/definitions';
import { selectEntityAgreementData, selectEntityAgreementIsLoading } from '../../../redux/entity/agreement/selectors';
import { AppState } from '../../../redux/root-reducer';
import AccountingDataCourseSelector from './AccountingDataCourseSelector';
import styles from './accountingData.module.scss';
import AccountingDataAgreementDocument from './AccountingDataAgreementDocument';
import Loading from '../../../components/Loading/Loading';

interface ResEntAccountingDataProps {
  entity_id: number;
  loading: boolean;
  disabled: boolean;
  entityAgreementData: EntityAgreementData;
  getEntityAgreementData: (entity_id: number, academic_year: string) => void;
}

const ResEntAccountingData: React.FC<ResEntAccountingDataProps> = ({
  entity_id,
  loading,
  disabled,
  entityAgreementData,
  getEntityAgreementData,
}) => {
  const [academicYear, setAcademicYear] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (academicYear) {
      getEntityAgreementData(entity_id, academicYear);
    }
  }, [academicYear, entity_id, getEntityAgreementData]);

  return (
    <div className={styles.accounting_form}>
      <Title>{tKey('Convenios')}</Title>
      <AccountingDataCourseSelector entity_id={entity_id} selectedAcademicYear={setAcademicYear} />
      {loading ? (
        <Loading big />
      ) : (
        <AccountingDataAgreementDocument entityAgreementData={entityAgreementData} disabled={disabled} />
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectEntityAgreementIsLoading(state),
  entityAgreementData: selectEntityAgreementData(state),
});

const mapDispatchToProps = (dispatch: EntityAgreementDispatch) => ({
  getEntityAgreementData: (entity_id: number, academic_year: string): void =>
    dispatch(getEntityAgreementDataAction(entity_id, academic_year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResEntAccountingData);
